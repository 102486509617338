import { Party } from "../types";
import { initialState, OrdersAction, State, TypeKeys } from "./orders";

export const chargePartyInitState = { status: "unloaded" };

export interface ChargePartyAction<TAction> {
    type: TAction;
    party: Party;
    error: any | undefined;
}

export const chargePartyActionCreators = {
    chargePartyFailed: (error: any) => ({ type: TypeKeys.CHARGE_PARTY_FAILED, error }),
    chargePartyProcessed: (party: Party) => ({ type: TypeKeys.CHARGE_PARTY_PROCESSED, party }),
    chargePartyProcessing: () => ({ type: TypeKeys.CHARGE_PARTY_PROCESSING }),
    chargePartyReset: () => ({ type: TypeKeys.CHARGE_PARTY_RESET }),
};

export const chargePartyReducer = (state: State = initialState, action: OrdersAction): State => {
    if (action.type === TypeKeys.CHARGE_PARTY_FAILED) {
        return {
            ...state,
            forceChargePartyResponse: {
                status: "failed",
                error: action.error,
            },
        };
    }

    if (action.type === TypeKeys.CHARGE_PARTY_PROCESSED) {
        if (state.activePaymentParties?.status === "loaded") {
            let existingParties = state.activePaymentParties.data.map((p) =>
                p.partyId === action.party.partyId ? action.party : p
            );

            return {
                ...state,
                activePaymentParties: {
                    status: "loaded",
                    data: existingParties,
                },
                forceChargePartyResponse: {
                    data: true,
                    status: "loaded",
                },
            };
        }
        return {
            ...state,
            forceChargePartyResponse: {
                data: true,
                status: "loaded",
            },
        };
    }

    if (action.type === TypeKeys.CHARGE_PARTY_PROCESSING) {
        return {
            ...state,
            forceChargePartyResponse: {
                status: "loading",
            },
        };
    }

    if (action.type === TypeKeys.CHARGE_PARTY_RESET) {
        return {
            ...state,
            forceChargePartyResponse: { status: "unloaded" },
        };
    }

    return state;
};
