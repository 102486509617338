export type State =
    | { status: "initial" }
    | { status: "unenroled" }
    | { status: "processing" }
    | { status: "enroled"; id: string; access_token: string; location_id: string; expires_at: number, can_bypass_staff_login: boolean }
    | { status: "failed"; error: any };

export type EnrolmentStatus = State["status"];

const initialState: State = { status: "initial" };

export enum TypeKeys {
    RESTORE = "DEVICE_ENROLMENT/RESTORE",
    SUCCESS = "DEVICE_ENROLMENT/SUCCESS",
    FAILED = "DEVICE_ENROLMENT/FAILED",
    PROCESSING = "DEVICE_ENROLMENT/PROCESSING",
}

export interface DeviceEnrolmentResult {
    id: string;
    access_token: string;
    location_id: string;
    expires_at: number;
    can_bypass_staff_login: boolean;
}

export const actionCreators = {
    processing: () => ({ type: TypeKeys.PROCESSING }),

    restore: (result: DeviceEnrolmentResult | null) => ({ type: TypeKeys.RESTORE, result }),

    success: (result: DeviceEnrolmentResult) => ({ type: TypeKeys.SUCCESS, result }),

    failed: (error: any) => ({ type: TypeKeys.FAILED, error }),
};

export type StaffLoginAction =
    | { type: TypeKeys.SUCCESS; result: DeviceEnrolmentResult }
    | { type: TypeKeys.RESTORE; result: DeviceEnrolmentResult | null }
    | { type: TypeKeys.PROCESSING }
    | { type: TypeKeys.FAILED; error: any };

export function reducer(state = initialState, action: StaffLoginAction): State {
    if (action.type === TypeKeys.SUCCESS || action.type === TypeKeys.RESTORE) {
        if (action.result === null) {
            return { status: "unenroled" };
        }

        const { id, access_token, location_id, expires_at, can_bypass_staff_login } = action.result;
        return {
            status: "enroled",
            id,
            access_token,
            location_id,
            expires_at: expires_at,
            can_bypass_staff_login
        };
    }

    if (action.type === TypeKeys.FAILED) {
        return {
            status: "failed",
            error: action.error,
        };
    }

    if (action.type === TypeKeys.PROCESSING) {
        return {
            status: "processing",
        };
    }

    return state;
}
