import _ from "lodash";
import { createSelector } from "reselect";
import { getOrdersState, getSelectedHistoryFilters, getSelectedHistorySearch, getSelectedSection } from ".";

export const getActiveOrdersState = createSelector(getOrdersState, (orders) => orders.activeOrders);
export const getHistoryOrdersState = createSelector(getOrdersState, (orders) => orders.historyOrders);

export const getActiveOrdersData = createSelector(getActiveOrdersState, (activeOrders) =>
    activeOrders?.status === "loaded" ? activeOrders.data : []
);

export const getActiveOrders = createSelector(
    getActiveOrdersData,
    getSelectedHistoryFilters,
    getSelectedHistorySearch,
    getSelectedSection,
    (activeOrders, filters, search, section) =>
        filters.length || search || section
            ? activeOrders.filter((order) => {
                  let match = !section || order.section === section.displayName;
                  match = match && order.table.includes(search);
                  return (!filters.length || filters.includes(order.status)) && match;
              })
            : activeOrders
);
export const getHistoryOrders = createSelector(getHistoryOrdersState, (historyOrders) =>
    historyOrders?.status === "loaded" ? historyOrders.data : []
);

export const getHistoryOrdersFetchedCount = createSelector(getOrdersState, (orders) =>
    orders.historyOrders?.status === "loaded" ? orders.historyOrdersFetchedCount : undefined
);

export const getActiveOrdersCount = createSelector(getActiveOrders, (orders) => orders.length);
export const getHistoryOrdersCount = createSelector(getOrdersState, (orders) =>
    orders.historyOrders?.status !== "failed" ? orders.historyOrdersTotal : undefined
);

export const getActiveOrdersUnloaded = createSelector(
    getActiveOrdersState,
    (activeOrders) => activeOrders?.status === "unloaded"
);

export const getActiveOrdersLoading = createSelector(
    getActiveOrdersState,
    (activeOrders) => activeOrders?.status === "loading"
);

export const getHistoryOrdersLoading = createSelector(
    getHistoryOrdersState,
    (historyOrders) => historyOrders?.status === "loading"
);

export const getHistoryPageOrders = createSelector(getActiveOrders, getHistoryOrders, (activeOrders, historyOrders) => {
    return _.unionBy(activeOrders, historyOrders, "orderId").sort((a, b) => b.time.valueOf() - a.time.valueOf());
});

export const getHistoryPageOrdersFetchedPartyCount = createSelector(
    getActiveOrdersCount,
    getHistoryOrdersFetchedCount,
    (activeOrdersTotal = 0, historyOrdersFetchedTotal = 0) => activeOrdersTotal + historyOrdersFetchedTotal
);

export const getHistoryPageOrdersTotal = createSelector(
    getActiveOrdersCount,
    getHistoryOrdersCount,
    (activeOrdersTotal = 0, historyOrdersTotal = 0) => activeOrdersTotal + historyOrdersTotal
);

export const getHistoryPageOrdersLoading = createSelector(
    getActiveOrdersUnloaded,
    getActiveOrdersLoading,
    getHistoryOrdersLoading,
    (activeOrdersUnloaded, activeOrdersLoading, historyOrdersLoading) =>
        !!activeOrdersUnloaded || !!activeOrdersLoading || !!historyOrdersLoading
);

export const getHistoryChangeCount = createSelector(getOrdersState, (orders) => orders.historyOrdersCountChange);

export const getUnconfirmedOrdersCount = createSelector(getOrdersState, (orders) => orders.unconfirmedOrdersTotal ?? 0);

export const getUnconfirmedOrders = createSelector(getOrdersState, (orders) =>
    orders.unconfirmedOrders?.status === "loaded"
        ? orders.unconfirmedOrders.data.sort((a, b) => a.time.valueOf() - b.time.valueOf())
        : []
);

export const getUnconfirmedOrdersLoading = createSelector(
    getOrdersState,
    (orders) => orders.unconfirmedOrders.status === "loading"
);
