import "../../assets/DetailsCard.scss";

import React, { FC } from "react";
import { GroupTabsData, GroupTabType, Order, OrderTypes } from "../../types";
import { PartyType } from "../../../../common/types";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { getRefundAmount } from "../../selectors";
import { config } from "common/config";
import { OrderCardPaymentSection } from "./OrderCardPaymentSection";
import { OrderCardOrderSection } from "./OrderCardOrderSection";
import { OrderCardInfoSection } from "./OrderCardInfoSection";
import { OrderCardHeader } from "./OrderCardHeader";
import { OrderCardFooter } from "./OrderCardFooter";

export interface Props {
    order: Order | null;
    onAction?: (order: Order) => boolean;
    getActionText?: (order: Order) => string;
    getActionBgColor?: (order: Order) => string;
    onClose: (cancel: boolean) => void;
    onCloseRefund?: () => void;
    onShowRefund?: () => void;
    saved: boolean;
    buttonDisabled?: boolean;
    refundOpen?: boolean;
    loading: boolean;
    onOrderGroupTab: (order: Order) => void;
    viaTabsCard?: boolean;
    setResubmitOrder: (order: Order) => void;
}

export const OrderCard: FC<Props> = ({
    order,
    onAction,
    getActionText,
    getActionBgColor,
    onClose,
    onCloseRefund,
    saved,
    buttonDisabled,
    onShowRefund,
    loading,
    refundOpen = false,
    onOrderGroupTab,
    viaTabsCard,
    setResubmitOrder,
}) => {
    const refundedAmount = useSelector(getRefundAmount);

    if (!order) return null;

    const isPaymentType = order.type === OrderTypes.PAYMENT;
    const isOpenTable = order.partyType === PartyType.MULTIUSER;
    const showPaymentSection = !isOpenTable || isPaymentType;

    return (
        <div className={classNames("details-card", (saved || refundedAmount > 0) && "saved")}>
            <OrderCardHeader
                displayOrderId={order.displayOrderId}
                viaTabsCard={viaTabsCard}
                isRefundOpen={refundOpen}
                onCloseRefund={onCloseRefund}
                onClose={onClose}
            />
            <div className="details-card__scrollable">
                <div className="details-card__scrollable-inner">
                    <OrderCardInfoSection
                        order={order}
                        buttonDisabled={buttonDisabled}
                        viaTabsCard={viaTabsCard}
                        onOrderGroupTab={onOrderGroupTab}
                    />
                    {!isPaymentType && <OrderCardOrderSection order={order} />}
                    {showPaymentSection && <OrderCardPaymentSection order={order} />}
                </div>
            </div>
            <OrderCardFooter
                order={order}
                isRefundOpen={refundOpen}
                buttonDisabled={buttonDisabled}
                loading={loading}
                saved={saved}
                onShowRefund={onShowRefund}
                setResubmitOrder={setResubmitOrder}
                getActionText={getActionText}
                onClose={onClose}
                onAction={onAction}
                getActionBgColor={getActionBgColor}
            />
        </div>
    );
};

export const disableTabOrderRefundButton = (groupTab?: GroupTabsData) => {
    const openTabRefundsEnabled = config.VITE_ENABLE_OPEN_TAB_REFUNDS === "1";
    return openTabRefundsEnabled && groupTab ? groupTab.type === GroupTabType.PayOnCreate : !!groupTab;
};
