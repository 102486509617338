import { connect } from "react-redux";
import { AppState, AppDispatch } from "features/index";
import { DataProps, FunctionProps } from "types/utils";
import { Props, OrderCardModal } from "../components/OrderCardModal";
import { createAction } from "../../shared/reducers";

const mapStateToProps = (state: AppState): DataProps<Props> => {
    const {
        bumpScreen: { closedParty },
    } = state;
    return {
        closedParty,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch): FunctionProps<Props> => {
    return {
        closeModal: () => dispatch(createAction.showClosedParty(undefined)),
    };
};

export const OrderCardModalContainer = connect(mapStateToProps, mapDispatchToProps)(OrderCardModal);
