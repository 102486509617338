import { parse } from "graphql/language/parser";
import { print } from "graphql/language/printer";
import { DocumentNode, OperationDefinitionNode, FieldNode } from "graphql";

export class PendingQuery {
    private document: DocumentNode;
    public promise: Promise<{}>;

    constructor(public query: string, public variables: {}, invoker: (query: string, variables: {}) => Promise<{}>) {
        this.document = parse(query);

        this.promise = new Promise<{}>((res, rej) => {
            setTimeout(() => {
                invoker(print(this.document), this.variables).then(res, rej);
            });
        });
    }

    tryMerge(query: string, variables?: {}) {
        const parsedQuery = parse(query);

        const definition = parsedQuery.definitions[0];

        if (definition.kind !== "OperationDefinition") {
            return false;
        }

        const selections = definition.selectionSet.selections;

        const sourceDefinition = this.document.definitions[0] as OperationDefinitionNode;
        const sourceSelectionSet = sourceDefinition.selectionSet;

        if (definition.operation !== sourceDefinition.operation) {
            return false;
        }

        const sourceSelectionNames = sourceSelectionSet.selections
            .filter((s) => s.kind === "Field")
            .map((s) => fieldAlias(s as FieldNode));

        if (!selections.every((s) => s.kind !== "Field" || sourceSelectionNames.indexOf(fieldAlias(s)) === -1)) {
            return false;
        }

        const sourceVariables = sourceDefinition.variableDefinitions!.map((vd) => vd.variable.name.value);

        if (variables) {
            for (let variableName of Object.getOwnPropertyNames(variables)) {
                if (
                    this.variables.hasOwnProperty(variableName) &&
                    this.variables[variableName] !== variables[variableName]
                ) {
                    return false;
                }
            }
        }

        const uniqueVariableDefinitions = definition.variableDefinitions!.filter(
            (vd) => !sourceVariables.some((vr) => vd.variable.name.value === vr)
        );

        this.document = {
            ...this.document,
            definitions: [
                {
                    ...sourceDefinition,
                    selectionSet: {
                        ...sourceSelectionSet,
                        selections: [...sourceSelectionSet.selections, ...selections],
                    },
                    variableDefinitions: [...sourceDefinition.variableDefinitions!, ...uniqueVariableDefinitions],
                },
            ],
        };

        if (variables) {
            this.variables = {
                ...this.variables,
                ...variables,
            };
        }

        return true;
    }
}

const fieldAlias = (n: FieldNode) => (n.alias ? n.alias.value : n.name.value);
