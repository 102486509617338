import { AppState } from "../../../state";
import { createSelector } from "reselect";
import { getActiveParties, getBumpScreenMode } from "../../shared/selectors";
import { ActivePartyCounts, BumpScreenType, GroupedClosedParty } from "../../shared/types";
import { getCustomerName } from "common/utility/memberUtils";
import { AN_HOUR, MINUTE } from "common/utility";

export const getClosedParties = (state: AppState) =>
    state.bumpScreen.closedParties.status === "loaded" ? state.bumpScreen.closedParties.data.data : undefined;

export const getBuzzerActiveParties = createSelector(getActiveParties, (parties) =>
    parties.filter((p) => !p.statusChanging && p.deliveryOptions?.filter((d) => d.type === "pickup").length)
);

export const getSelectedSection = (state: AppState) => state.bumpScreen.selectedSection;

export const getActivePartiesFilteredBySection = createSelector(
    getBuzzerActiveParties,
    getSelectedSection,
    (parties, section) => parties.filter((p) => !section || p.sectionId === section.id)
);

export const getFilteredAndSortedActiveParties = createSelector(
    getActivePartiesFilteredBySection,
    getBumpScreenMode,
    (parties, mode) => {
        if (!parties.length) return parties;

        parties = parties.filter(
            (p) =>
                mode === BumpScreenType.ACTIVE ||
                (mode === BumpScreenType.PREPARING && p.status === "preparing") ||
                (mode === BumpScreenType.READY && p.status === "ready")
        );

        parties.sort((a, b) => {
            if (a.status === b.status) {
                return a.dateStatusChanged - b.dateStatusChanged;
            }
            return a.status === "ready" ? -1 : 1;
        });

        return parties;
    }
);

export const getActivePartyCounts = createSelector(getActivePartiesFilteredBySection, (parties) => {
    const preparing = parties.filter((p) => p.status === "preparing").length;

    return {
        total: parties.length,
        preparing,
        ready: parties.length - preparing,
    } as ActivePartyCounts;
});

export const getClosedPartiesTable = createSelector(getClosedParties, (closedParties) => {
    if (!closedParties) return undefined;
    const rearrangedData: GroupedClosedParty[] = [
        {
            displayName: "Recently closed",
            data: [],
        },
        {
            displayName: "30 minutes ago",
            data: [],
        },
        {
            displayName: "More than an hour ago",
            data: [],
        },
    ];
    const currentTime = new Date();
    closedParties.forEach((party) => {
        party.memberName = getCustomerName(party.tableNumber) || "";
        party.dateSubmitted = party.dateSubmitted.replace("+10:00", "+00:00");
        party.dateClosed = party.dateClosed.replace("+10:00", "+00:00");
        const partyCloseTime = new Date(party.dateClosed);
        const diff = Math.ceil((currentTime.getTime() - partyCloseTime.getTime()) / MINUTE);
        party.timePast = diff + " min ago";
        if (diff < 30) {
            rearrangedData[0].data.push(party);
        } else if (diff < 60) {
            rearrangedData[1].data.push(party);
        } else {
            const hours = Math.floor((currentTime.getTime() - partyCloseTime.getTime()) / AN_HOUR);
            party.timePast = `${hours} hour${hours === 1 ? "" : "s"} ago`;
            rearrangedData[2].data.push(party);
        }
    });
    return rearrangedData;
});
