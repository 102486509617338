import { Order, OrderSearchOptions, Party } from "../types";
import { opsApi } from "common/api";
import { fetchAsStaff } from "../../staffLogin/selectors/getAccessToken";
import { getPaymentsTotal, mapOrder } from "./commonGraphFunctions";
import { mapActivePartyToPartyPayment, toGroupTabsData } from "../utils";
import { OrderWithoutPayment, OrderWithPayment, ActiveParty, PartyType } from "common/types";
import { defaultOptions } from "./fetchClosedOrdersApi";
import { PartiesResponse } from "./fetchActivePaymentsApi";
import { getPartyFields } from "./commonGraphFields";

export async function fetchClosedPartiesApi(
    locationId: string,
    options: OrderSearchOptions = defaultOptions,
    typeFilter?: PartyType[]
): Promise<PartiesResponse> {
    const searchOptions = { ...defaultOptions, ...options };
    const statusFilter = searchOptions.filters?.length ? searchOptions.filters : null;
    const res = await opsApi.graphQLQuery<GraphQLPayload>(
        query,
        {
            locationId,
            diningDate: searchOptions.diningDate,
            skip: (searchOptions.page! - 1) * searchOptions.pageSize!,
            limit: searchOptions.pageSize!,
            sectionId: searchOptions.sectionId,
            tableSearch: searchOptions.search,
            ascending: searchOptions.ascending,
            statusFilter,
            typeFilter: typeFilter,
        },
        fetchAsStaff(true)
    );
    const parties: Party[] = mapActivePartyToPartyPayment(res.data.closedParties.data, false);

    return {
        parties,
        partiesTotal: Math.max(
            res.data.closedParties.totalCount - (res.data.closedParties.data.length - parties.length),
            0
        ),
    };
}

type GraphQLPayload = { closedParties: { data: any[]; totalCount: number } };

const query: string = `
query (
    $locationId: ID!, 
    $diningDate: String!, 
    $skip: Int!, 
    $limit: Int!, 
    $sectionId: String, 
    $tableSearch: String, 
    $ascending: Boolean!,
    $statusFilter: [String!],
    $typeFilter: [PartyType!]) 
{  
    closedParties(
        locationId: $locationId,
        diningDate: $diningDate,
        skip: $skip,
        limit: $limit,
        section: $sectionId,
        tableSearch: $tableSearch,
        ascending: $ascending,
        statusFilter: $statusFilter,
        typeFilter: $typeFilter) 
    {    
        totalCount    
        data {      
            ${getPartyFields}
        }
    }
}
`;

export const mapPartyToOrderWithPayment = (party: ActiveParty, order: OrderWithPayment): Order => {
    const mappedOrder = mapOrder(party, order);
    const partyRefund = party.refunds?.[0];
    return {
        ...mappedOrder,
        fullName: party.members[0].displayName,
        itemTotal: order.totalIncludingTaxes,
        totalValue: order.posTotal,
        promotions: order.promotions,
        cepOffers: order.cepOffers,
        refundablePaymentId: party.refundablePaymentId,
        paymentsTotal: getPaymentsTotal(party, partyRefund),
        refundableTotal: party.refundableTotal,
        refundedTotal: partyRefund?.transactionTotal,
        payments: party.members[0].payments,
        groupTab: party.groupTab ? toGroupTabsData([party.groupTab])[0] : undefined,
        dateCollected: (party.dateCollected && new Date(party.dateCollected)) || undefined,
        refunds: party.refunds,
        stampsEarned: order.stampsEarned,
    };
};

export const mapPartyToOrderWithoutPayment = (party: ActiveParty, order: OrderWithoutPayment): Order => {
    const mappedOrder = mapOrder(party, order);
    return {
        ...mappedOrder,
        payments: party.members[0].payments,
        groupTab: party.groupTab ? toGroupTabsData([party.groupTab])[0] : undefined,
        dateCollected: (party.dateCollected && new Date(party.dateCollected)) || undefined,
    };
};
