import { GroupTabsData, GroupTab } from "../types";
import { opsApi } from "common/api";
import { fetchAsStaff } from "../../staffLogin/selectors/getAccessToken";
import { getGroupTabFields } from "./commonGraphFields";
import { toGroupTabsData } from "../utils";

type GroupTabResponse = { groupTab: GroupTabsData };
type GraphQLPayload = { groupTab: GroupTab };

const getGroupTabByIdQuery = `query ($groupTabId: ID!, $locationId: ID!) {
    groupTab(groupTabId: $groupTabId, locationId: $locationId) {
        ${getGroupTabFields}
    }
}`;

export async function getTabById(groupTabId: string, locationId: string): Promise<GroupTabResponse> {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(
        getGroupTabByIdQuery,
        {
            groupTabId,
            locationId,
        },
        fetchAsStaff(true)
    );

    const groupTab = toGroupTabsData([res.data.groupTab])[0];

    return {
        groupTab,
    };
}
