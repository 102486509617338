import { Loaded } from "common/loader";
import { Availability } from "./availability";
import { BaseAvailabilityUpdateInput } from ".";

export type SoldOut = {
    filter?: FilterTypes;
    search?: string;
    products: Loaded<ProductDetails[]>;
    modifiers: Loaded<ModifierDetails[]>;
};

export type SoldOutDetailsBase = {
    sku: string;
    displayName: string;
    availability?: number | null;
    status?: Availability;
    fixedMinutes?: Availability;
    internalName?: string;
    key: string;
    hasChildMatch?: boolean;
};

export type ProductDetails = SoldOutDetailsBase & {
    price: number;
    isVariant?: boolean;
    global?: boolean;
    categories: string[];
    variants?: ProductDetails[];
    children?: ProductDetails[];
};

export type ModifierDetails = SoldOutDetailsBase & {
    usedIn?: string;
    products: ProductDetails[];
};

export type FiltersCount = {
    mixed?: number;
    tomorrow?: number;
    unavailable?: number;
};

export enum FilterTypes {
    VIEW_ALL = "View all",
    AVAILABLE = "Available",
    MIXED = "Mixed",
    TOMORROW = "Sold out today",
    UNAVAILABLE = "Unavailable",
}

export type ProductAvailabilityUpdateInput = BaseAvailabilityUpdateInput & {
    sku: string;
};

export type SoldOutTrackingData = {
    displayName: string;
    sku: string;
    status: string;
    children?: string[];
};
