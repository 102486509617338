import "./FloorItemSearch.scss";
import React, { useCallback, useEffect } from "react";
import { Input } from "antd";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "features";
import { actionCreator } from "../reducers/manageSection";
import { Indicator } from "components/indicator/Indicator";
import { Text } from "components/text";
import { HiddenIcon } from "common/icons";
import { getShouldFilterUnavailableItems } from "../selectors/getManangeSection";

export const FloorItemSearch = () => {
    const dispatch = useDispatch<AppDispatch>();
    const shouldFilterUnavailable = useSelector(getShouldFilterUnavailableItems);

    const changeHandler = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(actionCreator.setFloorItemSearchTerm(event.target.value));
        },
        [dispatch]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedChangeHandler = useCallback(debounce(changeHandler, 250), [changeHandler]);

    useEffect(() => {
        return () => {
            debouncedChangeHandler.cancel();
            dispatch(actionCreator.setFloorItemSearchTerm());
        };
    }, [dispatch, debouncedChangeHandler]);

    const showUnavailableHandler = useCallback(() => {
        dispatch(actionCreator.setFilterUnavailableItems(!shouldFilterUnavailable));
    }, [dispatch, shouldFilterUnavailable]);

    return (
        <div className="floor-item-search__container">
            <Input.Search
                className="floor-item-search__search-bar"
                placeholder="Search section or beacon name"
                onChange={debouncedChangeHandler}
                allowClear
            />
            <Indicator icon={HiddenIcon} primary={shouldFilterUnavailable} onClick={showUnavailableHandler}>
                <Text>Show unavailable</Text>
            </Indicator>
        </div>
    );
};
