import * as React from "react";
import { FeatureConfiguration } from "..";
import { Route } from "react-router";
import { OrdersPageNoSessionTimeout } from "./components/OrdersPage";
import { ordersReducer } from "./reducers";
import { ordersMiddleware } from "./middleware/ordersMiddleware";

export default function (config: FeatureConfiguration) {
    config.reducers.orders = ordersReducer;
    config.middleware.push(ordersMiddleware());
    config.addRoutes(config.routes.locationRoutes, [
        <Route key="orders" path={`/orders`} render={() => <OrdersPageNoSessionTimeout />} />,
    ]);
}
