import { AppDispatch, AppState } from "../../index";
import { DeliveryOptionType, MenuItemType } from "../types";
import { createAction } from "../reducers";
import { updateSectionDeliveryOptionType } from "../api/updateSectionDeliveryOptionType";

export const updateDeliveryOptionType =
    (sectionId: string, menuItemType: MenuItemType, deliveryOptionType: DeliveryOptionType) =>
    async (dispatch: AppDispatch, getState: () => AppState) => {
        const {
            location: { locationId },
        } = getState();

        try {
            const updatedSection = await updateSectionDeliveryOptionType(
                locationId!,
                sectionId,
                menuItemType,
                deliveryOptionType
            );
            dispatch(createAction.updated(updatedSection));
        } catch (e) {
            console.warn(e);
        }
    };
