import React from "react";
import { Text } from "../../../../components/text";

export interface Props {
    title: string;
}

export const OrderCardTitleRow = (props: Props) => {
    const { title } = props;
    return (
        <div className="details-card__title-row">
            <Text preset="g-16" mode={["bold", "block"]} value={title} />
        </div>
    );
};
