import { AppDispatch } from "features";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CloseOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { fetchHistoryOrders } from "../actions";
import { actionCreators } from "../reducers";
import { OrderSearchOptions, OrderStatus } from "../types";
import { Text } from "../../../components/text";
import classNames from "classnames";
import { ordersFilterKey } from "../reducers/orders";
import { Moment } from "moment";
import "./HistoryOrders.scss";
import { ReloadData } from "common/loader/ReloadData";
import {
    getHistoryChangeCount,
    getSelectedHistoryFilters,
    getSelectedHistorySearch,
    getSelectedSection,
} from "../selectors";
import { trackPageView } from "common/AppInsights/AppInsights";
import { OrdersTable } from "./VirtualiseOrdersTable";
import { getLocationId } from "features/staffLogin/selectors/getLocationId";
import { getSectionsLoaded } from "features/floorManager/selectors/getSections";

export const PAGE_SIZE = Math.max(10, Math.floor((window.innerHeight - 260) / 65));

export interface FetchOptions {
    forceReLoad?: boolean;
    loadMore?: boolean;
}
interface Props {
    today: Moment;
    date: Moment;
    setDate: (date: Moment) => void;
}

export const HistoryOrders = ({ date, setDate, today }: Props) => {
    const dispatch = useDispatch<AppDispatch>();

    const historyOrdersCountChange = useSelector(getHistoryChangeCount);
    const selectedSection = useSelector(getSelectedSection);
    const historyOrdersFilter = useSelector(getSelectedHistoryFilters);
    const sectionsLoaded = useSelector(getSectionsLoaded);
    const locationId = useSelector(getLocationId);
    const search = useSelector(getSelectedHistorySearch);

    const scrollRef = useRef<HTMLElement | Window | null>();
    const [showChanged, setShowChanged] = useState(!!historyOrdersCountChange);
    const closeChangeTimeout = useRef(0);

    useEffect(() => {
        setShowChanged(!!historyOrdersCountChange);
    }, [historyOrdersCountChange]);

    const onUnload = useCallback(() => {
        if (historyOrdersFilter && historyOrdersFilter.length) {
            sessionStorage.setItem(ordersFilterKey, JSON.stringify(historyOrdersFilter));
        } else {
            sessionStorage.removeItem(ordersFilterKey);
        }
    }, [historyOrdersFilter]);

    useEffect(() => {
        window.addEventListener("unload", onUnload);
        return () => {
            window.removeEventListener("unload", onUnload);
        };
    }, [onUnload]);

    const clearFilter = useCallback(() => {
        dispatch(actionCreators.selectHistoryOrdersFilter([]));
    }, [dispatch]);

    const setSearch = useCallback(
        (value: string) => {
            dispatch(actionCreators.seHistoryOrdersSearch(value));
        },
        [dispatch]
    );

    useEffect(() => {
        return () => {
            clearFilter();
        };
    }, [clearFilter]);

    const setPage = useCallback(
        (page: number) => {
            dispatch(actionCreators.selectHistoryOrdersPage(page));
        },
        [dispatch]
    );

    useEffect(() => {
        return () => {
            setPage(1);
        };
    }, [setPage]);

    useEffect(() => {
        return () => {
            setDate(today);
        };
    }, [setDate, today]);

    useEffect(() => {
        dispatch(actionCreators.activeOrdersReset());
    }, [dispatch]);

    const fetch = useCallback(
        (options?: FetchOptions) => {
            const searchOptions: OrderSearchOptions = {
                diningDate: date.format("YYYY-MM-DD"),
                filters: historyOrdersFilter,
                search: search || null,
                pageSize: PAGE_SIZE,
                ascending: false,
                sectionId: null,
            };

            if (selectedSection && selectedSection.id) {
                searchOptions.sectionId = selectedSection.id;
            }

            sectionsLoaded &&
                locationId &&
                dispatch(fetchHistoryOrders(locationId, searchOptions, options?.forceReLoad, options?.loadMore));
        },
        [historyOrdersFilter, search, locationId, selectedSection, date, sectionsLoaded, dispatch]
    );

    useEffect(() => {
        fetch();
        dispatch(trackPageView(window.location.href + "?date=" + date.format("YYYY-MM-DD")));
    }, [fetch, dispatch, date]);

    const closeChanged = useCallback(() => {
        setShowChanged(false);
        closeChangeTimeout.current = window.setTimeout(() => {
            dispatch(actionCreators.showHistoryCountChange(undefined));
        }, 200);
    }, [dispatch]);

    useEffect(() => {
        let timeout = 0;
        if (historyOrdersCountChange) {
            window.clearTimeout(closeChangeTimeout.current);
            timeout = window.setTimeout(() => {
                closeChanged();
            }, 3000);
        }
        return () => {
            window.clearTimeout(timeout);
            window.clearTimeout(closeChangeTimeout.current);
            if (historyOrdersCountChange) {
                dispatch(actionCreators.showHistoryCountChange(undefined));
            }
        };
    }, [historyOrdersCountChange, dispatch, closeChanged]);

    return (
        <div className="history-orders">
            <Input.Search
                allowClear
                onBlur={(e) => {
                    setPage(1);
                    setSearch(e.target.value.trim());
                }}
                onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                        e.preventDefault();
                        setPage(1);
                    }
                }}
                onSearch={setSearch}
                placeholder="Search table number"
            />
            <div
                className={classNames("history-orders__filter-message", !!historyOrdersFilter.length && "show")}
                onClick={() => {
                    setPage(1);
                    clearFilter();
                }}
            >
                <Text preset="g-14">
                    {" "}
                    Showing only {historyOrdersFilter.join(" & ")} orders.&nbsp;
                    <Text preset="g-14" mode="bold">
                        {" "}
                        Clear filter
                    </Text>
                </Text>
            </div>
            <div className="history-orders__table">
                <OrdersTable fetch={fetch} scrollRef={scrollRef} />
            </div>
            <ReloadData
                fetch={() => {
                    scrollRef.current?.scrollTo({ top: 0, behavior: "smooth" });

                    dispatch(actionCreators.selectHistoryOrdersPage(1));
                    fetch({ forceReLoad: true });
                }}
            />
            {!!historyOrdersCountChange && (
                <div className={classNames("history-orders__count", showChanged && "active")} onClick={closeChanged}>
                    <div className="history-orders__count__content">
                        <Text preset="g-16" mode="bold">
                            {historyOrdersCountChange} new order
                            {!!historyOrdersCountChange && historyOrdersCountChange > 1 ? "s have" : " has"} been added
                        </Text>
                        <CloseOutlined />
                    </div>
                </div>
            )}
        </div>
    );
};

export const getOrderStatus = (orderStatus: OrderStatus) =>
    `Order ${orderStatus === "Refund failed" ? "refunded" : orderStatus.toLowerCase()}${
        orderStatus === "Confirmed" ? " by POS" : ""
    }`;
