import { AppDispatch, AppMiddleware } from "../../index";
import { regionHelper } from "../index";
import { actionCreators } from "../reducers/region";
import { AnyAction } from "redux";

export const regionMiddleware = () => (store: AppMiddleware) => {
    regionHelper.onRegionChanged((region) => store.dispatch(actionCreators.changed(region)));
    regionHelper.init();

    return (next: AppDispatch) => (action: AnyAction) => next(action);
};
