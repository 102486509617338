import { AppDispatch } from "features";
import { AppState } from "features/state";
import { actionCreators } from "../reducers/staffLogin";
import * as staffLoginApi from "../api";
import { getJwtExpiry } from "../util/getJwtValues";

export function extend() {
    return async function (dispatch: AppDispatch, getState: () => AppState) {
        const { staffLogin } = getState();

        if (staffLogin.status !== "authenticated") {
            return;
        }

        dispatch(actionCreators.extending());

        try {
            const result = await staffLoginApi.extend(staffLogin.token);

            const expiresAt = getJwtExpiry(result.access_token);
            dispatch(actionCreators.extended(result.access_token, result.location_id, expiresAt, result.name));
        } catch (e) {
            dispatch(actionCreators.failed(e));
        }
    };
}
