import { trackEvent } from "common/AppInsights/AppInsights";
import { AppDispatch } from "features";
import { AppState } from "features/state";
import { actionCreators } from "../reducers";
import { getOrdersState } from "../selectors";

export const showMoreOrders = () => async (dispatch: AppDispatch, getState: () => AppState) => {
    const { historyOrdersPage } = getOrdersState(getState());
    trackEvent(
        {
            name: "ORDER/SHOW_MORE_CLICKED",
        },
        {
            request_x: historyOrdersPage,
        }
    );
    dispatch(actionCreators.historyOrdersNextPage());
};
