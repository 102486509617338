import { FeatureConfiguration } from "..";
import { TakeawayPage } from "./takeaway/components/TakeawayPage";
import { bumpScreenReducer } from "./shared/reducers";
import { Route } from "react-router";
import * as React from "react";
import { BumpScreenContainer } from "./buzzer/containers/BumpScreenContainer";
import { openPartiesMiddleware } from "./shared/middleware/openPartiesMiddleware";

export default function (config: FeatureConfiguration) {
    config.middleware.push(openPartiesMiddleware());
    config.reducers.bumpScreen = bumpScreenReducer;
    config.addRoutes(config.routes.locationRoutes, [
        <Route key="bump-screen-buzzer" path="/bump-screen/buzzer" render={() => <BumpScreenContainer />} />,
    ]);
    config.addRoutes(config.routes.locationRoutes, [
        <Route key="bump-screen-takeaway" path="/bump-screen/takeaway" render={() => <TakeawayPage />} />,
    ]);
}
