import { PartyType } from "common/types";
import { getSectionsState } from "features/floorManager/selectors/getSections";
import { getMomentDiningDate } from "features/location/selectors/getDiningDate";
import { getLocationLocale } from "features/location/selectors/getLocationLocale";
import { AppState } from "features/state";
import { AppDispatch } from "../../index";
import { fetchActivePaymentsApi } from "../api/fetchActivePaymentsApi";
import { fetchClosedPartiesApi } from "../api/fetchClosedPartiesApi";
import { actionCreators } from "../reducers";
import { unconfirmedOrdersPageSize } from "../reducers/orders";
import { getActivePaymentPartiesState } from "../selectors/getPaymentParties";
import { OrderSearchOptions } from "../types";

export const fetchPaymentParties = (
    locationId: string,
    options?: OrderSearchOptions,
    forceReload?: boolean,
    loadMore?: boolean
) => {
    return async function (dispatch: AppDispatch, getState: () => AppState) {
        const state = getState();
        const { status } = getSectionsState(state);
        const activePaymentPartiesState = getActivePaymentPartiesState(state);
        if (status === "unloaded" || status === "loading") return;

        const {
            orders: { closedPaymentPartiesPage },
        } = state;

        if (options) {
            options.page = forceReload ? 1 : closedPaymentPartiesPage;
        }

        const today = getMomentDiningDate(state).format("YYYY-MM-DD");
        const sameDate = today === options?.diningDate;
        const loadActive =
            sameDate &&
            (forceReload ||
                (activePaymentPartiesState === null && options?.diningDate && options.page && options.pageSize));
        const loadClosedParties = !options?.partyFilters?.length || options.partyFilters.includes("Closed");
        try {
            loadClosedParties && dispatch(actionCreators.closedPaymentPartiesLoading());

            loadActive && dispatch(actionCreators.activePaymentPartiesLoading());
            const [ordersRes, activeRes] = await Promise.all([
                loadClosedParties
                    ? fetchClosedPartiesApi(locationId, options, [PartyType.MULTIUSER, PartyType.PAYONLY])
                    : undefined,
                loadActive
                    ? fetchActivePaymentsApi(
                          locationId,
                          {
                              diningDate: getLocationLocale(getState()).getLocalTime(),
                              skip: 0,
                              limit: unconfirmedOrdersPageSize * 2,
                              sectionId: options?.sectionId ?? null,
                              ascending: false,
                          },
                          [PartyType.MULTIUSER, PartyType.PAYONLY]
                      )
                    : undefined,
            ]);
            dispatch(
                actionCreators.closedPaymentPartiesLoaded(
                    ordersRes?.parties ?? [],
                    ordersRes?.partiesTotal ?? 0,
                    !!loadMore
                )
            );
            if (activeRes) {
                dispatch(actionCreators.activePaymentPartiesLoaded(activeRes.parties, activeRes.partiesTotal));
            } else if (!sameDate) {
                dispatch(actionCreators.activePaymentPartiesLoaded([], 0));
            }
        } catch (e) {
            loadClosedParties && dispatch(actionCreators.historyOrdersFailed(e));
            loadActive && dispatch(actionCreators.activeOrdersFailed(e));
        }
    };
};
