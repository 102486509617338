import { fetchAsStaff } from "../../staffLogin/selectors/getAccessToken";
import moment, { Moment } from "moment";
import { opsApi } from "../../../common/api";

export async function fetchSalesCsvExport(locationId: string, date: Moment) {
    const request = new Request(`${opsApi.baseUrl}/reports/sales/${locationId}/${date.format("YYYY-MM-DD")}`);
    return fetchAsStaff(true)(request)
        .then((res) => res.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.setAttribute("style", "position:absolute;opacity:0;");
            a.href = url;
            a.download = `meu-sales-export-${date.format("YYYYMMDD")}-snapshot-${moment().format("YYYYMMDD-HHmm")}.csv`;
            document.body.appendChild(a);
            a.click();
            a.remove();
        });
}

export async function fetchItemSalesCsvExport(locationId: string, date: Moment) {
    const request = new Request(`${opsApi.baseUrl}/reports/itemsales/${locationId}/${date.format("YYYY-MM-DD")}`);
    return fetchAsStaff(true)(request)
        .then((res) => res.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.setAttribute("style", "position:absolute;opacity:0;");
            a.href = url;
            a.download = `meu-item-sales-export-${date.format("YYYYMMDD")}-snapshot-${moment().format(
                "YYYYMMDD-HHmm"
            )}.csv`;
            document.body.appendChild(a);
            a.click();
            a.remove();
        });
}
