import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getFilteredAndSortedTakeawayActiveParties } from "features/bumpScreen/takeaway/selectors";
import alertMp3 from "../../../assets/sounds/alert.mp3";

type PartyIds = string[];

export function usePreparingTakeaways() {
    const parties = useSelector(getFilteredAndSortedTakeawayActiveParties);
    const [preparingPartiesCounter, setPreparingPartiesCounter] = useState(0);
    const preparingPartyIdsRef = useRef<PartyIds>([]);

    const alert = useMemo(() => new Audio(alertMp3), []);
    const playAlert = useCallback(() => alert.play(), [alert]);

    useEffect(() => {
        const partyIds = parties.reduce<PartyIds>((partyIds, party) => {
            if (party.status === "preparing") partyIds.push(party.id);
            return partyIds;
        }, []);

        if (partyIds.some((partyId) => !preparingPartyIdsRef.current.includes(partyId))) playAlert();
        setPreparingPartiesCounter(partyIds.length);
        preparingPartyIdsRef.current = partyIds;
    }, [parties, playAlert]);

    return preparingPartiesCounter;
}
