import {
    ApplicationInsights,
    ICustomProperties,
    IEventTelemetry,
    IPageViewTelemetry,
    SeverityLevel,
    Snippet,
} from "@microsoft/applicationinsights-web";
import { ReactPlugin, useTrackEvent as useCustomEvent } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import { useRef } from "react";
import { Region } from "features/region/types/region";
import { AppDispatch, AppState } from "features";
import { StateEnrichDataPlugin } from "./plugins/StateEnrichDataPlugin";
import { auth } from "common/auth";
import { getActiveLocation } from "features/location/selectors/getLocationLocale";

export const browserHistory = createBrowserHistory({ basename: "/" });
const reactPlugin = new ReactPlugin();
const locationPlugin = new StateEnrichDataPlugin();
const snippet: Snippet = {
    config: {
        // Has to be set to empty string to avoid initialization error:
        // "Uncaught Error: Please provide instrumentation key"
        instrumentationKey: "",
        // Has to be set to true to avoid initalization error: 
        // "Invalid Instrumentation key"
        disableInstrumentationKeyValidation: true,
        connectionString: "",
        extensions: [reactPlugin, locationPlugin],
        enableAutoRouteTracking: true,
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory },
        },
    },
};
const appInsights = new ApplicationInsights(snippet);
let init = false;

function initAppInsights(getState: (state: AppState) => void, region?: Region | null) {
    if (region && region.appInsightsConnectionString) {
        snippet.config.connectionString = region.appInsightsConnectionString;
        snippet.config.extensionConfig![locationPlugin.identifier] = {
            getState: getState,
        };
        if (init) {
            appInsights.flush();
            appInsights.updateSnippetDefinitions(snippet);
        } else {
            init = true;
            appInsights.loadAppInsights();
        }
    }
}

// set skipFirstRun to false if needed to be trigger on creation
function useTrackEvent<T>(eventName: string, eventData: T, skipFirstRun: boolean = true) {
    const trackEvent = useRef(useCustomEvent(reactPlugin, "Venue/" + eventName, eventData, skipFirstRun));
    return trackEvent.current;
}

// set skipFirstRun to false if needed to be trigger on creation
function trackPageView(uri: string, additionalPageViewData: IPageViewTelemetry = {}) {
    return async (_: AppDispatch, getState: () => AppState) => {
        const authState = auth.getState();

        const activeLocation = getActiveLocation(getState());

        if (additionalPageViewData.name) {
            additionalPageViewData.name = "Venue/" + additionalPageViewData.name;
        }
        reactPlugin.trackPageView({
            uri,
            ...additionalPageViewData,
            properties: {
                device_width: window.innerWidth.toString(),
                device_height: window.innerHeight.toString(),
                user_domain: authState?.email?.split("@")[1] ?? "",
                user_role: activeLocation?.authInfo.userRoles?.join() ?? "",
            },
        });
    };
}

function trackException(message?: string, severity: SeverityLevel = SeverityLevel.Error) {
    appInsights.trackException({ error: new Error(message), severityLevel: severity });
}

function trackEvent(event: IEventTelemetry, customProperties?: ICustomProperties) {
    if (event.name) {
        event.name = "Venue/" + event.name;
    }

    reactPlugin.trackEvent(event, customProperties);
}

export { reactPlugin, appInsights, initAppInsights, useTrackEvent, trackEvent, trackPageView, trackException };
