import "./FloorManagerPage.scss";

import { Table } from "antd";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { isBuzzerOrTakeawayEnabled } from "../../nav/selectors";
import { getSectionsLoading } from "../selectors/getSections";
import { FloorItem } from "../types";
import { getFloorManagerColumns } from "./floorManagerColumns";
import { useDispatch } from "react-redux";
import { DisableSectionModal } from "./DisableSectionModal";
import { DeliveryOptionModal } from "./DeliveryOptionModal";
import { getExpandedFloorItemsKeys, getFloorItems } from "../selectors/getFloorItems";
import { AvailabilityStatusModal } from "./AvailabilityStatusModal";
import { FloorItemSearch } from "./FloorItemSearch";
import { getFloorItemSearchTerm } from "../selectors/getManangeSection";
import { useDeepCompareEffect } from "react-use";
import { getLocationPermissions } from "features/location/selectors/getPermissions";
import { FloorManagerFooter } from "./FloorManagerFooter";
import { updateSectionAvailability } from "../actions/updateSectionAvailability";
import { AppDispatch } from "features";
import { UpdateSectionBehaviourModal } from "./UpdateSectionBehaviourModal";
import { actionCreator } from "../reducers/manageSection";

export const FloorManagerPage = () => {
    const shouldShowDeliveryOptions = useSelector(isBuzzerOrTakeawayEnabled);
    const sectionLoading = useSelector(getSectionsLoading);
    const floorItems = useSelector(getFloorItems);
    const floorItemSearchTerm = useSelector(getFloorItemSearchTerm);
    const expandedMatchingSections = useSelector(getExpandedFloorItemsKeys);
    const hasSectionEditPermissions = useSelector(getLocationPermissions).has("section:update");
    const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
    const dispatch = useDispatch<AppDispatch>();

    const updateAvailability = useCallback(
        (item: FloorItem) => {
            dispatch(updateSectionAvailability(item));
        },
        [dispatch]
    );

    const setUpdateSectionBehaviour = useCallback(
        (item: FloorItem) => {
            dispatch(actionCreator.setUpdateSectionBehaviour(item));
        },
        [dispatch]
    );

    const updateSelectedKeys = (expanded: boolean, record: FloorItem) => {
        setExpandedKeys(expanded ? [...expandedKeys, record.id] : expandedKeys.filter((x) => x !== record.id));
    };

    useDeepCompareEffect(() => {
        setExpandedKeys(expandedMatchingSections);
    }, [expandedMatchingSections]);

    return (
        <div className="floor-manager">
            <FloorItemSearch />
            <Table
                className="floor-manager__table"
                loading={sectionLoading}
                columns={getFloorManagerColumns(
                    floorItemSearchTerm || "",
                    shouldShowDeliveryOptions,
                    hasSectionEditPermissions ? setUpdateSectionBehaviour : undefined,
                    hasSectionEditPermissions ? updateAvailability : undefined
                )}
                dataSource={floorItems}
                rowKey="key"
                tableLayout="auto"
                expandedRowKeys={expandedKeys}
                onExpand={updateSelectedKeys}
                expandRowByClick
                pagination={false}
                showHeader={false}
            />
            <FloorManagerFooter />
            <UpdateSectionBehaviourModal />
            <AvailabilityStatusModal />
            <DeliveryOptionModal />
            <DisableSectionModal />
        </div>
    );
};
