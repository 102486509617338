import { MessageType } from "../types";

export type FooterStates = FooterState[];

export interface FooterState {
    message?: string;
    emphasis?: string;
    messageType: MessageType;
    onClick?: () => void;
    path?: string;
}

enum TypeKeys {
    ADD_FOOTER_MESSAGE = "FOOTER/ADD_MESSAGE",
    REMOVE_FOOTER_MESSAGE = "FOOTER/REMOVE_MESSAGE",
}

export const createAction = {
    show: (footer: FooterState) => ({ type: TypeKeys.ADD_FOOTER_MESSAGE, footer }),
    reset: (messageType: MessageType) => ({ type: TypeKeys.REMOVE_FOOTER_MESSAGE, messageType }),
};

export type FooterShowAction = { type: TypeKeys.ADD_FOOTER_MESSAGE; footer: FooterState };
export type FooterResetAction = { type: TypeKeys.REMOVE_FOOTER_MESSAGE; messageType: MessageType };

export type FooterAction = FooterShowAction | FooterResetAction;

const addFooterState = (states: FooterStates, state: FooterState) => {
    const copyStates = [...states];
    const existedType = copyStates.findIndex((s) => s.messageType === state.messageType);
    if (existedType > -1) {
        copyStates.splice(existedType, 1, state);
        return copyStates;
    } else {
        copyStates.push(state);
        return copyStates.sort((m1, m2) => m2.messageType - m1.messageType);
    }
};

const removeFooterMessage = (states: FooterStates, messageType: MessageType) => {
    return states.filter((s) => s.messageType !== messageType);
};

const initialState: FooterState = {
    messageType: MessageType.NORMAL,
    message: "",
};

export const reducer = (state: FooterStates = [initialState], action: FooterAction): FooterStates => {
    switch (action.type) {
        case TypeKeys.ADD_FOOTER_MESSAGE:
            return addFooterState(state, action.footer);
        case TypeKeys.REMOVE_FOOTER_MESSAGE:
            return removeFooterMessage(state, action.messageType);
        default:
            return state;
    }
};
