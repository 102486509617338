import "./AvailabilityStatusModal.scss";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { AppDispatch } from "features";
import { ConfirmModal } from "components/confirmModal/ConfirmModal";
import { Indicator } from "components/indicator/Indicator";
import { ViewIcon } from "common/icons";
import { Text } from "components/text";
import { Availability, FloorItemType, FloorItem, TableAvailabilityStatus } from "../types";
import { getManageAvailability } from "../selectors/getManangeSection";
import { actionCreator } from "../reducers/manageSection";
import { update } from "../actions";
import { updateTable } from "../actions/updateTable";
import { ALL_SECTION_ID } from "../selectors/getFloorItems";

export const UNAVAILABILITY_DURATION_OPTIONS = [
    {
        value: 1,
        duration: 15,
        label: "15 min",
    },
    {
        value: 2,
        duration: 30,
        label: "30 min",
    },
    {
        value: 3,
        duration: 45,
        label: "45 min",
    },
    {
        value: 4,
        duration: 60,
        label: "1 h",
    },
    {
        value: 5,
        duration: 120,
        label: "2 h",
    },
    {
        value: 6,
        duration: 180,
        label: "3 h",
    },
    {
        value: 7,
        duration: 240,
        label: "4 h",
    },
    {
        value: 8,
        duration: undefined,
        label: "Until tomorrow",
    },
];
interface OfflineDuration {
    value: number;
    minutes?: number;
}

function getAvailabilityFromAvailabilityStatus(
    availabilityStatus: TableAvailabilityStatus | null,
    duration?: OfflineDuration
) {
    if (!availabilityStatus) {
        return Availability.NOW;
    }
    if (!duration?.minutes) {
        return Availability.DAY;
    }
    return Availability.LATER;
}

export const AvailabilityStatusModal = () => {
    const manageAvailability = useSelector(getManageAvailability);
    const [availabilityStatus, setAvailabilityStatus] = useState<TableAvailabilityStatus | undefined | null>();
    const [offlineDuration, setOfflineDuration] = useState<OfflineDuration | undefined>();
    const { id, displayName, type } = manageAvailability || {};

    const dispatch = useDispatch<AppDispatch>();

    const setManageAvailability = useCallback(
        (floorItem?: FloorItem) => {
            dispatch(actionCreator.setManageAvailability(floorItem));
        },
        [dispatch]
    );

    const canApplySelections = () => {
        if (availabilityStatus === undefined) return false;
        if (availabilityStatus && !offlineDuration) return false;
        return true;
    };

    const onConfirm = () => {
        if (id && availabilityStatus !== undefined) {
            if (type === FloorItemType.SECTION) {
                dispatch(
                    update(
                        id === ALL_SECTION_ID ? null : id,
                        getAvailabilityFromAvailabilityStatus(availabilityStatus, offlineDuration),
                        undefined,
                        offlineDuration?.minutes
                    )
                );
            } else if (type === FloorItemType.TABLE && manageAvailability?.parent?.id) {
                dispatch(updateTable(manageAvailability.parent.id, id, availabilityStatus, offlineDuration?.minutes));
            }
        }
    };

    const resetState = () => {
        setManageAvailability();
        setAvailabilityStatus(undefined);
        setOfflineDuration(undefined);
    };

    useEffect(
        () => () => {
            setManageAvailability();
        },
        [setManageAvailability]
    );

    if (manageAvailability) {
        return (
            <ConfirmModal
                title={`Updating status for ${displayName}`}
                showModal={!!manageAvailability}
                onCloseModal={() => {
                    resetState();
                }}
                className="availability-status-modal"
                onConfirm={canApplySelections() ? onConfirm : undefined}
            >
                <div>
                    <Text preset="g-14" mode="bold">
                        Status
                    </Text>
                    <div className="availability-status-modal__availability-options">
                        <Indicator
                            primary={availabilityStatus === null}
                            onClick={() => setAvailabilityStatus(null)}
                            icon={ViewIcon}
                            textSize="large"
                        >
                            Available
                        </Indicator>
                        <Indicator
                            primary={availabilityStatus === TableAvailabilityStatus.UNAVAILABLE}
                            onClick={() => setAvailabilityStatus(TableAvailabilityStatus.UNAVAILABLE)}
                            icon={ViewIcon}
                            textSize="large"
                        >
                            Unavailable
                        </Indicator>
                    </div>
                    {availabilityStatus && (
                        <div className="availability-status-modal__unavailable-options-container">
                            <Text preset="g-14" mode="bold">
                                For how long?
                            </Text>
                            <div className="availability-status-modal__unavailable-options-container__options">
                                {UNAVAILABILITY_DURATION_OPTIONS.map((option) => (
                                    <Indicator
                                        key={option.value}
                                        primary={offlineDuration?.value === option.value}
                                        onClick={() => {
                                            setOfflineDuration({
                                                minutes: option.duration,
                                                value: option.value,
                                            });
                                        }}
                                        shape="joined"
                                        textWeight="medium"
                                    >
                                        {option.label}
                                    </Indicator>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </ConfirmModal>
        );
    }
    return null;
};
