import { Availability, DeliveryOptionType, DisableReason, MenuItemType } from "./Sections";

export interface DisableSection {
    sectionId: string;
    sectionName: string;
    availability: Availability;
    disableReason?: DisableReason;
    disableSubReason?: string;
    offlineDurationMinutes?: number | null;
}

export interface EditDeliveryOptionType {
    sectionId: string;
    sectionName: string;
    menuItemType: MenuItemType;
    deliveryOptionType: DeliveryOptionType;
}

export enum SectionBehaviour {
    ORDER_AND_PAY = "ORDERANDPAY",
    PAY_ONLY = "PAYONLY",
}

export interface OpenTableOrderType {
    label: string;
    value: SectionBehaviour;
}
