import { FeatureConfiguration } from "../index";
import { reducer } from "./reducers";
import { RegionHelper } from "./regionHelper";
import { regionMiddleware } from "./middleware/regionMiddleware";

export const regionHelper = new RegionHelper();

export default function (config: FeatureConfiguration) {
    config.reducers.region = reducer;
    config.middleware.push(regionMiddleware());
}
