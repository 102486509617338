import { getLocationPosSupportsPosMenu } from "features/location/selectors/getLocationConfig";
import { AppState } from "features/state";
import { AppDispatch } from "../../index";
import { fetchCategoriesApi } from "../api";
import { createActionDisableCategories } from "../reducers";

export const fetchCategories = (locationId: string) => {
    return async function (dispatch: AppDispatch, getState: () => AppState) {
        dispatch(createActionDisableCategories.categoriesLoading());

        const locationSupportsPosMenu = getLocationPosSupportsPosMenu(getState());

        try {
            const categories = await fetchCategoriesApi(locationId, locationSupportsPosMenu);

            dispatch(createActionDisableCategories.categoriesLoaded(categories));
        } catch (e) {
            dispatch(createActionDisableCategories.categoriesFailed(e));
        }
    };
};
