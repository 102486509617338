import { fetchAsStaff } from "../../staffLogin/selectors/getAccessToken";
import moment from "moment";
import { opsApi } from "../../../common/api";
import { Order } from "../types";
import { getOrderDisplayStatus, toGroupTabsData } from "../utils";
import { OrderItem, OrderWithoutPayment, ActiveParty } from "common/types/party";
import { getGroupTabFields, getPartyFields } from "./commonGraphFields";
import { mapOrder } from "./commonGraphFunctions";

export interface FetchActiveOrderOptions {
    diningDate: string | Date;
    skip: number;
    limit: number;
    sectionId: string | null;
    ascending: boolean;
    statusFilter?: string[];
}

export async function fetchActiveOrdersApi(
    locationId: string,
    { diningDate, skip, limit, sectionId, ascending, statusFilter }: FetchActiveOrderOptions
): Promise<OrdersResponse> {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(
        query,
        {
            locationId,
            diningDate: moment(diningDate).format("YYYY-MM-DD HH:00:00"),
            skip: skip,
            limit: limit,
            sectionId,
            ascending,
            statusFilter,
            ongoingOnly: false,
        },
        fetchAsStaff(true)
    );
    const orders: Order[] = [];
    let spreadOrdersCount = 0;

    res.data.activeParties.data.forEach((p: ActiveParty) => {
        if (p.orderWithPayment) {
            const order = p.orderWithPayment;
            const mappedOrder = mapOrder(p, order);
            orders.push({
                ...mappedOrder,
                fullName: p.members[0].displayName,
                itemTotal: order.orderTotal,
                totalValue: order.posTotal,
                promotions: order.promotions,
                cepOffers: order.cepOffers,
                refundablePaymentId: p.refundablePaymentId,
                paymentsTotal: p.paymentsTotal,
                pointsPaymentsTotal: p.pointsPaymentsTotal,
                refundableTotal: p.refundableTotal,
                refundedTotal: p.refundedTotal,
                groupTab: p.groupTab ? toGroupTabsData([p.groupTab])[0] : undefined,
                payments: p.members[0].payments,
                dateCollected: (p.dateCollected && new Date(p.dateCollected)) || undefined,
                processingFee: p.processingFeeTotal,
                surcharge: order.surcharge,
                discount: order.discount,
                membershipDiscount: order.membershipDiscount,
                trayCharges: order.trayCharges,
                taxTotals: p.taxTotals,
                tip: p.tipsTotal,
                refunds: p.refunds,
                venueCauses: order.venueCauses,
                stampsEarned: order.stampsEarned,
            });
        }
        if (p.ordersWithoutPayment) {
            p.ordersWithoutPayment.forEach((order: OrderWithoutPayment, index: number) => {
                if (statusFilter && !statusFilter.includes(getOrderDisplayStatus(order.confirmationStatus))) {
                    return;
                }

                ++spreadOrdersCount;
                const mappedOrder = mapOrder(p, order);
                orders.push({
                    ...mappedOrder,
                    itemTotal: order.orderTotal,
                });
            });
            --spreadOrdersCount;
        }
    });

    return {
        orders,
        activeOrdersTotal: res.data.activeParties.totalCount + spreadOrdersCount,
    };
}

export const toOrderItems = (orders: OrderItem[]) => {
    return orders.map((orderItem) => ({
        ...orderItem,
        mappedModifiers: orderItem.modifiers && orderItem.modifiers,
    }));
};
type GraphQLPayload = { activeParties: { data: any[]; totalCount: number } };
type OrdersResponse = { orders: Order[]; activeOrdersTotal: number };

const query: string = `
query (
    $locationId: ID!, 
    $diningDate: String!, 
    $skip: Int!, 
    $limit: Int!, 
    $sectionId: String, 
    $ascending: Boolean!,
    $statusFilter: [String!]) 
{  
    activeParties(locationId: $locationId, diningDate: $diningDate, skip: $skip, limit: $limit, section: $sectionId, ascending: $ascending, statusFilter: $statusFilter) 
    {    
        totalCount    
        data {      
            ${getPartyFields}
            groupTab {
                ${getGroupTabFields}
            }
        }
    }
}
`;
