import { LocationLocale } from "../types/LocationLocale";

export const getProcessingFeeText = (locationLocale: LocationLocale) => {
    switch (locationLocale.locale) {
        case "en-GB":
            return "Administration fee";
        default:
            return "Card processing fee";
    }
};
