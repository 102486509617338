import { NavType } from "../types";

export interface NavState {
    navType?: NavType;
    forceShow?: boolean;
}

export enum TypeKeys {
    ACTIVE_TYPE = "NAV/ACTIVE_TYPE",
    FORCE_SHOW = "NAV/FORCE_SHOW",
}

export const actionCreator = {
    activeType: (navType: NavType) => ({ type: TypeKeys.ACTIVE_TYPE, navType }),
    forceShow: (forceShow: boolean) => ({ type: TypeKeys.FORCE_SHOW, forceShow }),
};

export type ActiveTypeAction = { type: TypeKeys.ACTIVE_TYPE; navType: NavType };
type ForceShowAction = { type: TypeKeys.FORCE_SHOW; forceShow: boolean };

type NavActions = ActiveTypeAction | ForceShowAction;

const initialState: NavState = {};

export const reducer = (state: NavState = initialState, action: NavActions) => {
    if (action.type === TypeKeys.ACTIVE_TYPE) {
        return {
            navType: action.navType,
            forceShow: false,
        };
    }
    if (action.type === TypeKeys.FORCE_SHOW) {
        return {
            ...state,
            forceShow: action.forceShow,
        };
    }
    return state;
};
