import { AnyAction, Middleware, MiddlewareAPI, ReducersMapObject } from "redux";
import { History } from "history";

import initEnrolment from "features/enrolment";
import initStaffLogin from "features/staffLogin";
import initBumpScreen from "features/bumpScreen";
import initFlowManager from "features/floorManager";
import initHelp from "features/help";
import initSoldOutSetting from "features/soldOut";
import initLocation from "features/location";
import initNav from "features/nav";
import initFooter from "features/footer";
import initSales from "features/sales";
import initOrders from "features/orders";
import initOrderHub from "features/orderHub";
import initRegion from "features/region";

import { AppState } from "./state";
import { ThunkDispatch } from "redux-thunk";
import { ReactNode } from "react";

export * from "./state";

export type AppDispatch = ThunkDispatch<AppState, void, AnyAction>;

export type AppMiddleware = MiddlewareAPI<AppDispatch, AppState>;

export interface FeatureConfiguration {
    history: History;
    middleware: Middleware<{}, AppState, AppDispatch>[];
    reducers: Partial<ReducersMapObject<AppState, any>>;
    routes: RoutesConfiguration;

    addRoutes(target: ReactNode[], routes: ReactNode[]): void;
}

export interface RoutesConfiguration {
    locationRoutes: ReactNode[];
}

type InitFeatureFn = (config: FeatureConfiguration) => void;

const initFeatureFunctions: InitFeatureFn[] = [
    initEnrolment,
    initLocation,
    initBumpScreen,
    initFlowManager,
    initHelp,
    initSoldOutSetting,
    initNav,
    initStaffLogin,
    initFooter,
    initSales,
    initOrders,
    initOrderHub,
    initRegion,
];

export default function (history: History) {
    const config: FeatureConfiguration = {
        history,
        middleware: [],
        reducers: {},
        routes: {
            locationRoutes: [],
        },
        addRoutes: (target: ReactNode[], routes: ReactNode[]) => {
            routes.forEach((r) => {
                target.push(r);
            });
        },
    };

    for (const initFeature of initFeatureFunctions) {
        initFeature(config);
    }

    return config;
}
