export enum NavType {
    AVAILABILITY = 0,
    FLOOR_MANAGER = 1,
    HELP = 2,
    BUMP_SCREEN_BUZZER = 3,
    BUMP_SCREEN_TAKEAWAY = 4,
    SALES = 5,
    ORDERS = 6,
}

export function navTypeFromName(name: string) {
    switch (name) {
        case "sold-out":
        case "sold-out/categories":
        case "sold-out/menu-items":
            return NavType.AVAILABILITY;
        case "floor-mananger":
            return NavType.FLOOR_MANAGER;
        case "support":
            return NavType.HELP;
        case "bump-screen/buzzer":
            return NavType.BUMP_SCREEN_BUZZER;
        case "bump-screen/takeaway":
            return NavType.BUMP_SCREEN_TAKEAWAY;
        case "sales":
            return NavType.SALES;
        case "orders":
        case "orders/":
        case "orders/grouptabs":
        case "orders/history":
        case "orders/payments":
            return NavType.ORDERS;
        default:
            return NavType.SALES;
    }
}
