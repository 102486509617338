import { AppDispatch } from "../../index";
import { fetchUnconfirmedOrders } from "../api/fetchUnconfirmedOrders";

import { actionCreators } from "../reducers";
import { unconfirmedOrdersPageSize } from "../reducers/orders";

export const reloadUnconfirmedOrders = (locationId: string, diningDate: Date, sectionId: string | null) => {
    return async (dispatch: AppDispatch) => {
        dispatch(actionCreators.unconfirmedOrdersLoading());
        try {
            const response = await fetchUnconfirmedOrders(
                locationId,
                diningDate,
                0,
                unconfirmedOrdersPageSize + 10,
                sectionId
            );

            dispatch(actionCreators.unconfirmedOrdersLoaded(response.orders, response.unconfirmedOrdersTotal));
        } catch (e) {
            dispatch(actionCreators.unconfirmedOrdersFailed(e));
        }
    };
};
