import { ReactElement, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../index";
import { controlActions } from "../reducers/orderHubConnection";
import { getConnectionStatus } from "../selectors";

interface Props {
    children: ReactElement;
}

export const ActiveHubConnection = (props: Props) => {
    const dispatch = useDispatch<AppDispatch>();

    const status = useSelector(getConnectionStatus);

    const connect = useCallback(() => dispatch(controlActions.connect()), [dispatch]);
    const disconnect = useCallback(() => dispatch(controlActions.disconnect()), [dispatch]);

    useEffect(() => {
        if (status === "disconnected") {
            connect();
        }
    }, [connect, status]);

    useEffect(() => {
        return () => {
            disconnect();
        };
    }, [disconnect]);

    return props.children;
};
