import { fetchAsStaff } from "../../staffLogin/selectors/getAccessToken";
import { opsApi } from "../../../common/api";
import { Party } from "../types";
import { mapActivePartyToParty } from "./commonGraphFunctions";
import { ActivePartyApiResponse, getActivePartyApiQuery } from "./commonGraphQueries";

export async function fetchParty(locationId: string, partyId: string): Promise<Party> {
    const res = await opsApi.graphQLQuery<ActivePartyApiResponse>(
        getActivePartyApiQuery,
        {
            partyId,
            locationId,
        },
        fetchAsStaff(true)
    );
    return mapActivePartyToParty(res.data.party);
}
