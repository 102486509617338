import * as React from "react";
import "./index.scss";
import { createRoot } from "react-dom/client";
import App from "./App";
import { applyMiddleware, createStore, combineReducers } from "redux";
import { Provider } from "react-redux";
import configureFeatures, { AppState } from "features";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { BrowserRouter as Router } from "react-router-dom";
import { AppInsightsContext, AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { browserHistory, reactPlugin, initAppInsights } from "common/AppInsights/AppInsights";

const featureConfig = configureFeatures(browserHistory);

const reducer = combineReducers({
    ...featureConfig.reducers,
});

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunkMiddleware, ...featureConfig.middleware)));

const appElement = document.getElementById("root") as HTMLElement;

const RenderApp = () => {
    const region = useSelector((state: AppState) => state.region);
    useEffect(() => {
        initAppInsights(store.getState, region);
    }, [region]);

    return (
        <AppInsightsContext.Provider value={reactPlugin}>
            <AppInsightsErrorBoundary onError={() => <h1>I believe something went wrong</h1>} appInsights={reactPlugin}>
                <App routes={featureConfig.routes} />
            </AppInsightsErrorBoundary>
        </AppInsightsContext.Provider>
    );
};

const root = createRoot(appElement);

root.render(
    <Provider store={store}>
        <Router>
            <RenderApp />
        </Router>
    </Provider>
);
