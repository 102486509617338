import { connect } from "react-redux";
import { AppDispatch, AppState } from "features/index";
import { DataProps, FunctionProps } from "types/utils";
import { Props, BumpScreenPage } from "../components/BumpScreenPage";
import { BumpScreenType } from "../../shared/types";
import { createAction } from "../../shared/reducers";
import { actionCreator as navBarActions } from "../../../nav/reducers";
import { Section } from "features/floorManager/types";
import { withDisabledSessionTimeout } from "features/staffLogin/containers/withDisabledSessionTimeout";
import { getSections } from "features/floorManager/selectors/getSections";
import { getActivePartyCounts } from "../selectors";

const mapStateToProps = (state: AppState): DataProps<Props> => {
    const {
        bumpScreen: { mode, selectedSection },
        nav: { forceShow },
        orderHub: { status },
    } = state;
    const sections = getSections(state);
    const activePartyCounts = getActivePartyCounts(state);
    return {
        mode,
        isLive: status === "connected",
        forceShow,
        selectedSection,
        sections,
        activePartyCounts,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch): FunctionProps<Props> => {
    return {
        changeView: (mode: BumpScreenType) => dispatch(createAction.changeView(mode)),
        showNavBar: (show: boolean) => dispatch(navBarActions.forceShow(show)),
        selectSection: (selectedSection: Section | undefined) => dispatch(createAction.selectSection(selectedSection)),
    };
};

export const BumpScreenContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withDisabledSessionTimeout(BumpScreenPage));
