import { AppDispatch, AppState } from "../../index";
import { getOfflineSectionDisplayNames } from "../selectors/getOfflineSectionDisplayNames";
import { createAction as FooterActions } from "../../footer/reducers";
import { actionCreator as NavActions } from "../../nav/reducers";
import { NavType } from "../../nav/types";
import { MessageType } from "../../footer/types";

export const updateSectionNotification = () => {
    return function (dispatch: AppDispatch, getState: () => AppState) {
        const state = getState();
        const offlineSectionDisplayNames = getOfflineSectionDisplayNames(state);
        if (offlineSectionDisplayNames.length > 0) {
            dispatch(
                FooterActions.show({
                    message: `${
                        offlineSectionDisplayNames.length > 1
                            ? "Multiple sections are"
                            : offlineSectionDisplayNames[0] + " is"
                    } offline.`,
                    emphasis: " Review?",
                    onClick: () => dispatch(NavActions.activeType(NavType.FLOOR_MANAGER)),
                    messageType: MessageType.ALERT,
                    path: "/floor-manager",
                })
            );
        } else {
            dispatch(FooterActions.reset(MessageType.ALERT));
        }
    };
};
