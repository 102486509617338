import { History } from "history";
import { AppDispatch, AppMiddleware } from "features";
import { StaffLoginAction, TypeKeys } from "../reducers/staffLogin";
import { extend } from "../actions/extend";
import { fetchAsStaff } from "../selectors/getAccessToken";
import { SECOND } from "common/utility";

const EXTEND_TIMEOUT_MS = 10 * SECOND; // extend session 10 seconds after access token is last accessed

export const staffLoginMiddleware = (history: History) => {
    return (store: AppMiddleware) => {
        fetchAsStaff.store = store;

        history.listen((location, action) => {
            if (action !== "REPLACE") {
                extendStaffLoginSession();
            }
        });

        let expiryTimeout: number = 0;
        let extendTimeout: number = 0;

        function autoExtendStaffLoginSession(expiresAt: number) {
            const halfSessionTime = (expiresAt - new Date().getTime()) / 2;
            extendStaffLoginSession(halfSessionTime);
        }

        function extendStaffLoginSession(delay = EXTEND_TIMEOUT_MS) {
            const { staffLogin } = store.getState();

            if (staffLogin.status !== "authenticated") {
                return;
            }

            if (staffLogin.extending) {
                // Extend in progress
                return;
            }
            window.clearTimeout(extendTimeout);
            extendTimeout = window.setTimeout(
                () => store.dispatch(extend()),
                getExtendDelay(delay, staffLogin.expiresAt)
            );
        }

        return (next: AppDispatch) => {
            return (action: StaffLoginAction) => {
                next(action);

                if (action.type === TypeKeys.SUCCESS || action.type === TypeKeys.EXTENDED) {
                    window.clearTimeout(expiryTimeout);
                    expiryTimeout = window.setTimeout(() => {
                        store.dispatch({ type: TypeKeys.RESET });
                    }, action.expiresAt - new Date().getTime());

                    if (store.getState().staffLogin.autoExtend) {
                        autoExtendStaffLoginSession(action.expiresAt);
                    }
                }

                if (action.type === TypeKeys.ACCESSED) {
                    extendStaffLoginSession();
                }

                if (action.type === TypeKeys.AUTOEXTEND_CHANGED && action.enabled) {
                    const staffLoginState = store.getState().staffLogin;
                    if (staffLoginState.status === "authenticated") {
                        autoExtendStaffLoginSession(staffLoginState.expiresAt);
                    }
                }
            };
        };
    };
};

function getExtendDelay(delay = EXTEND_TIMEOUT_MS, tokenExpiresAt: number) {
    const defaultExtendAt = new Date().getTime() + delay;

    const EXTEND_BUFFER_MS = 10 * SECOND;

    if (defaultExtendAt > tokenExpiresAt - EXTEND_BUFFER_MS) {
        // Trying to extend right before the token expires risks the authentication failing due to
        // server/client time drift, so just do it now if it's too close
        return 0;
    }

    return delay;
}
