import React, { useEffect } from "react";
import { EnrolmentStatus } from "../reducers/enrolment";
import { Spin } from "antd";
import { config } from "common/config";

export interface Props {
    restore: () => void;
    enrolmentStatus: EnrolmentStatus;
    children: () => React.ReactElement | null;
}

export const RequireEnrolment = ({ enrolmentStatus, children, restore }: Props) => {
    useEffect(() => {
        restore();
    }, [restore]);

    if (enrolmentStatus === "unenroled") {
        if (process.env.NODE_ENV === "development") {
            return <p>Not enrolled. Change the URL to /(region)/enrol/(location) to enrol.</p>;
        }

        window.location.href = config.VITE_MANAGE_APP_LINK!;
    }

    if (enrolmentStatus !== "enroled") {
        return (
            <div className="loader__container loader__container--overlay">
                <Spin />
            </div>
        );
    }

    return children();
};
