import { SECOND } from "common/utility";

interface JsonWebToken {
    sub: string;
    exp: number;
    aud: string;
}

function parseJwt(token: string): JsonWebToken {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
        atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );

    return JSON.parse(jsonPayload);
}

export function getJwtExpiry(token: string): number {
    const jwt = parseJwt(token);
    return jwt.exp * SECOND;
}
