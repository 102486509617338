import { AppDispatch, AppState } from "features";
import { useSelector, useDispatch } from "react-redux";
import { restoreEnrolment } from "../actions/restoreEnrolment";
import { useCallback } from "react";
import { RequireEnrolment } from "../components/RequireEnrolment";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router";

interface OwnProps {
    children: () => React.ReactElement | null;
}

export const RequireEnrolmentContainer = withRouter(({ children, history }: OwnProps & RouteComponentProps<any>) => {
    const enrolmentStatus = useSelector((state: AppState) => state.enrolment.status);
    const dispatch = useDispatch<AppDispatch>();
    const restore = useCallback(() => dispatch(restoreEnrolment(history)), [dispatch, history]);

    return (
        <RequireEnrolment enrolmentStatus={enrolmentStatus} restore={restore}>
            {children}
        </RequireEnrolment>
    );
});
