import { Loaded } from "common/loader";
import { Section } from "features/floorManager/types";
import { OrderBase, ActiveParty } from "../../../../common/types";

export type BumpScreenState = {
    mode: BumpScreenType;
    selectedSection?: Section;
    closedSearchValue?: string;
    closedParty?: ClosedParty;
    closedParties: Loaded<ClosedParties>;
    activeParties: BumpActiveParty[];
};

export type ActivePartyStatus = "preparing" | "ready";

export interface BumpActiveParty extends Omit<ActiveParty, "dateSubmitted" | "status"> {
    status: ActivePartyStatus;
    overdue: boolean;
    statusChanging: boolean;
    dateStatusChanged: number;
    dateSubmitted: number;
    dateScheduled?: number;
    originalDateScheduled?: number;
    sectionName?: string;
}

export interface ActivePartyCounts {
    total: number;
    preparing: number;
    ready: number;
}

export type ClosedParties = {
    totalCount: number;
    data: ClosedParty[];
};

export type ClosedParty = {
    id: string;
    tableNumber: string;
    sectionName: string | null;
    memberName?: string;
    quantity: number;
    dateSubmitted: string;
    dateClosed: string;
    timePast?: string;
    order?: OrderBase;
};

export enum BumpScreenType {
    ACTIVE,
    READY,
    PREPARING,
    CLOSE,
}

export type GroupedClosedParty = {
    displayName: string;
    data: ClosedParty[];
};
