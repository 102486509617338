export const hasHeader = (init: RequestInit | undefined, header: string) => {
    if (!init || !init.headers) return false;

    if (init.headers instanceof Headers) {
        return init.headers.has(header);
    }

    if (Array.isArray(init.headers)) {
        return init.headers.some((h) => h[0] === header);
    }

    return false;
};

export const setHeader = (init: RequestInit | undefined, header: string, value: string) => {
    if (!init) throw Error("Can't set header as none have been configured");

    if (!init.headers) init.headers = new Headers();

    if (init.headers instanceof Headers) {
        init.headers.set(header, value);
        return;
    }

    if (Array.isArray(init.headers)) {
        const h = init.headers.find((h) => h[0] === header);

        if (!h) {
            init.headers.push([header, value]);
        } else {
            h[1] = value;
        }
        return;
    }

    if (typeof init.headers === "object") {
        init.headers[header] = value;
    }
};
