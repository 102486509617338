import React from "react";
import { Text } from "components/text";
import { CheckedIcon } from "common/icons";
import "./Notifications.scss";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { CSSTransition } from "react-transition-group";
import classNames from "classnames";

interface Props {
    notificationText: string;
    isVisible?: boolean;
}

export const SuccessNotification = ({ notificationText, isVisible = false }: Props) => {
    return (
        <div className={classNames("message-saved", isVisible && "active")}>
            <Text preset="g-16" mode="bold" className="message-saved__text">
                <CheckedIcon />
                {notificationText}
            </Text>
        </div>
    );
};

export const InlineErrorNotification = ({ notificationText, isVisible = false }: Props) => {
    return (
        <div className={classNames("message-inline-error", isVisible && "active")}>
            <Text preset="g-16" mode="bold" className="message-error__text">
                <ExclamationCircleFilled />
                {notificationText}
            </Text>
        </div>
    );
};

export const OverlayErrorNotification = ({ notificationText, isVisible = false }: Props) => {
    return (
        <CSSTransition classNames="loading" timeout={300} unmountOnExit in={isVisible}>
            <div className="message-error">
                <Text preset="g-16" mode="bold" className="message-error__text">
                    <ExclamationCircleFilled />
                    {notificationText}
                </Text>
            </div>
        </CSSTransition>
    );
};
