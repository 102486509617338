import { connect } from "react-redux";
import { AppDispatch, AppState } from "features/index";
import { DataProps, FunctionProps } from "types/utils";
import { Props, ClosedPartiesContent } from "../components/ClosedPartiesContent";
import { getClosedParties, getClosedParty } from "../../shared/actions";
import { getClosedPartiesTable } from "../selectors";
import { createAction } from "../../shared/reducers";
import { ClosedParty } from "../../shared/types";

const mapStateToProps = (state: AppState): DataProps<Props> => {
    const {
        bumpScreen: { closedParties, closedSearchValue, selectedSection },
        staffLogin,
    } = state;
    return {
        loading: closedParties.status === "loading",
        locationId: staffLogin.status === "authenticated" ? staffLogin.locationId : undefined,
        searchValue: closedSearchValue,
        sectionId: selectedSection ? selectedSection.id : null,
        parties: getClosedPartiesTable(state),
        totalCount: closedParties.status === "loaded" ? closedParties.data.totalCount : 0,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch): FunctionProps<Props> => {
    return {
        loadParties: (
            locationId: string,
            skip: number,
            limit: number,
            sectionId: string | null,
            searchOrder?: string
        ) => dispatch(getClosedParties(locationId, skip, limit, sectionId, searchOrder)),
        onSearch: (value: string) => dispatch(createAction.closePartiesSearch(value)),
        showParty: (locationId: string, closedParty: ClosedParty) => dispatch(getClosedParty(locationId, closedParty)),
    };
};

export const ClosedPartiesContainer = connect(mapStateToProps, mapDispatchToProps)(ClosedPartiesContent);
