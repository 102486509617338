import { AppState } from "features";

import { RequireStaffLogin, Props } from "../components/RequireStaffLogin";
import { connect } from "react-redux";
import { getLocationLocale } from "features/location/selectors/getLocationLocale";

interface OwnProps {
    children: () => React.ReactNode;
}

function mapStateToProps(state: AppState, { children }: OwnProps): Props {
    const isLoggedIn = state.staffLogin.status === "authenticated" && state.staffLogin.expiresAt > Date.now();

    const locationLocale = getLocationLocale(state);

    return {
        isLoggedIn,
        locationLocale,
        children,
    };
}

export const RequireStaffLoginContainer = connect(mapStateToProps, () => ({}))(RequireStaffLogin);
