import { Region } from "../types/region";

export enum TypeKeys {
    CHANGED = "REGION/CHANGED",
}

export type State = {
    region: Region | null;
};

export const actionCreators = {
    changed: (region: Region): RegionChangedAction => ({ type: TypeKeys.CHANGED, region }),
};

export type RegionChangedAction = { type: TypeKeys.CHANGED; region: Region };

export default function reducer(state: Region | null = null, action: RegionChangedAction): Region | null {
    if (action.type === TypeKeys.CHANGED) {
        return action.region;
    }
    return state;
}
