import { Loaded } from "common/loader";
import { Section } from "features/floorManager/types";
import {
    OrderItem,
    OrderPromotion,
    OrderTrayCharge,
    OrderVenueCause,
    PartyMember,
    PartyRefund,
    PartyType,
    Surcharge,
    UnconfirmedOrder,
} from "../../../common/types";

export type OrdersState = {
    selectedSection?: Section;
    historyOrders?: Loaded<Order[]>;
    historyOrdersTotal?: number;
    historyOrdersFetchedCount?: number;
    activeOrders: Loaded<Order[]>;
    activeOrdersTotal?: number;
    activeOrdersFetchedCount?: number;
    historyOrdersFilter: OrderStatus[];
    historyOrdersSearch: string;
    historyOrdersPage: number;
    historyOrdersCountChange?: number;
    unconfirmedOrders: Loaded<Order[]>;
    unconfirmedOrdersTotal: number;
    unconfirmedLiveOrders: Order[];
    unconfirmedLiveOrderIds: string[];
    resolvedOrder?: Loaded<Order>;
    refundedOrder?: Loaded<Order>;
    refundedAmount: number;
    groupTabs?: Loaded<GroupTabsData[]>;
    groupTabsTotal?: number;
    groupTabsFilter: string[];
    groupTabsPage: number;
    activeGroupTabsTotal: number;
    activeGroupTabsCount: Loaded<GroupTab[]>;
    closeGroupTabResponse: Loaded<boolean>;
    fetchTabOrderResponse: Loaded<boolean>;
    fetchTabByIdResponse: Loaded<boolean>;

    activePaymentParties: Loaded<Party[]>;
    activePaymentPartiesTotal?: number;
    closedPaymentParties?: Loaded<Party[]>;
    closedPaymentPartiesTotal?: number;
    paymentPartiesFilter: PartyActivityStatus[];
    paymentPartiesSearch: string;
    closedPaymentPartiesPage: number;
    resubmitUnconfirmedOrder?: Order;

    forceChargePartyResponse?: Loaded<true>;
};

export type OrderSearchOptions = {
    filters?: OrderStatus[] | null;
    partyFilters?: PartyActivityStatus[];
    search: string | null;
    page?: number;
    pageSize?: number;
    diningDate?: string;
    sectionId: string | null;
    ascending: boolean | null;
};

export type ItemWithTime = {
    time: Date;
};

export type Party = ItemWithTime & {
    partyId: string;
    partyType?: PartyType;
    fullName: string;
    phone?: string;
    displayId: string;
    table: string;
    section?: string;
    dateClosed?: Date;
    customerSavedPayment?: PartyMember;
    status: PartyActivityStatus;
    totalPaymentsTotal?: number;
    pointsPaymentsTotal?: number;
    totalPaymentsAmount?: number;
    totalRefundableAmount?: number;
    totalItemAmount?: number;
    totalRefundedAmount?: number;
    totalPaid: number;
    totalTip?: number;
    totalGratuity?: number;
    totalProcessingFee?: number;
    paymentsData?: Order[];
    processingFeeAbsorbed?: boolean;
    canForceCharge: boolean;
    wasForceCharged: boolean;
    isFlexTab: boolean;
};

export type PartyActivityStatus = "Closed" | "Ongoing";

export type Order = ItemWithTime & {
    orderId: string;
    partyId: string;
    partyType: PartyType;
    fullName?: string;
    phone?: string;
    displayOrderId?: string;
    table: string;
    section?: string;
    isTakeaway: boolean;
    dateSubmitted?: Date;
    dateCollected?: Date;
    totalValue?: number;
    status: OrderStatus;
    posReferenceId?: string;
    isMerged?: boolean;
    items?: OrderItem[];
    paymentsTotal?: number;
    pointsPaymentsTotal?: number;
    refundableTotal?: number;
    itemTotal?: number;
    refundablePaymentId?: string;
    refundedTotal?: number;
    groupTab?: GroupTabsData;
    payments?: Payment[];
    promotions?: OrderPromotion[] | null;
    cepOffers?: OrderPromotion[] | null;
    type?: string;
    transactionId?: string | null;
    tip?: number;
    gratuity?: number;
    processingFee?: number;
    processingFeeAbsorbed?: boolean;
    unconfirmedOrders?: UnconfirmedOrder[];
    orderSubmittedCount?: number;
    surcharge?: Surcharge;
    discount?: number;
    membershipDiscount?: number;
    trayCharges?: OrderTrayCharge[] | null;
    taxTotals?: TaxTotal[];
    refunds?: PartyRefund[];
    isForceCharge?: boolean;
    venueCauses?: OrderVenueCause[] | null;
    isFlexTab: boolean;
    stampsEarned?: number | null;
    failedReason?: string;
};

export type OrderStatus =
    | "Confirmed"
    | "Unconfirmed"
    | "Resolved"
    | "Submitting"
    | "Refunded"
    | "Refund failed"
    | "Resubmitting"
    | "Resubmitted"
    | "Resubmit pending"
    | "Pending"
    | "Failed";

export type GroupTab = {
    id: string;
    type: GroupTabType;
    tabName: string;
    packageName?: string;
    limit: number;
    status: string;
    locationId: string;
    owner: string;
    ownerName: string;
    creditsTotal?: number;
    locale: string;
    currency: string;
    dateOpened: string;
    dateClosed: string;
    members: GroupTabMember[];
    orders: GroupTabOrderItem[];
    paymentTotals: PaymentTotal;
};

export type PaymentTotal = {
    processingFee?: number;
    totalCharged?: number;
    totalRefunded?: number;
    total: number;
    taxSummary?: TaxTotal[];
    surcharges: number;
};

export type TaxTotal = {
    taxClass: string;
    total: number;
    exclusive: boolean;
};

export type GroupTabMember = {
    memberId: string;
    firstName: string;
    lastName: string;
    displayName: string;
    phone: string;
};

export type GroupTabsData = {
    id: string;
    type: GroupTabType;
    owner: string;
    packageName?: string;
    limit: number;
    ownerName: string;
    fullName: string;
    ownerPhone: string;
    status: string;
    locationId: string;
    locale: string;
    currency: string;
    duration: string;
    members: number;
    tabName: string;
    numberOfOrders: number;
    paymentTotals: PaymentTotal;
    totalSpend: number;
    orders: GroupTabOrderItem[];
};

export type GroupTabSearchOptions = {
    statusFilters: string[] | null;
    ownerNameSearch: string | null;
    page?: number;
    pageSize?: number;
    diningDate: string;
    activeTabs: boolean;
};

export type GroupTabOrderItem = {
    partyId: string;
    id: string;
    memberId: string;
    memberName: string;
    amount: number;
    dateCreated: string;
    orderDetails: Order;
};

export type Payment = {
    paymentMethodId?: string;
    status: string;
    paymentMethod: string;
};

export enum GroupTabType {
    PayOnCreate = "PAYONCREATE",
    PayOnOrder = "PAYONORDER",
}

export enum TablePage {
    ALERTS = "Alerts",
    ORDERS = "Orders",
}

export enum OrderTypes {
    ORDER = "Order",
    PAYMENT = "Payment",
}
