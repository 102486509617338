import "./Alerts.scss";
import React, { useEffect, useRef } from "react";
import { AlertsTable } from "./VirtualiseAlertsTable";
import { useDispatch } from "react-redux";
import { AppDispatch } from "features";
import { trackPageView } from "common/AppInsights/AppInsights";
import { ResubmitOrderModal } from "./ResubmitOrderModal";

export const Alerts = () => {
    const tableRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch<AppDispatch>();

    const scrollRef = useRef<HTMLElement | Window | null>();

    useEffect(() => {
        dispatch(trackPageView(window.location.href));
    }, [dispatch]);

    return (
        <div className="alerts">
            <div className="alerts__table" ref={tableRef}>
                <AlertsTable scrollRef={scrollRef} />
                <ResubmitOrderModal />
            </div>
        </div>
    );
};
