import { Table } from "antd";
import React from "react";
import { Text } from "components/text";
import classNames from "classnames";
import { ColumnProps } from "antd/lib/table";
import "./AvailabilityTable.scss";
import { EditCategoryAvailabilityInfo } from "features/soldOut/types";
interface Props {
    items: AvailabilityRecord[];
    columns: ColumnProps<any>[];
    loading?: boolean;
    title?: string;
}

export interface AvailabilityRecord {
    object: AvailabilityRecordObject;
    modified?: any;
    disabled: boolean;
    editAvailability: (info: EditCategoryAvailabilityInfo | null) => void;
}

export interface AvailabilityRecordObject {
    id: string;
}

export const AvailabilityTable = ({ items, columns, loading, title }: Props) => (
    <Table
        className="availability-table"
        dataSource={items}
        columns={columns}
        showHeader={false}
        pagination={false}
        loading={loading}
        rowKey={(record: AvailabilityRecord) => "disable-cat-row-" + record.object.id}
        rowClassName={(record: AvailabilityRecord) =>
            classNames("availability-table__row", !!record.modified && "unsaved")
        }
        locale={{ emptyText: " " }}
        title={
            !!title
                ? () => (
                      <Text className="availability-table__title" mode={["block", "bold"]} preset="title-24">
                          {title}
                      </Text>
                  )
                : undefined
        }
    />
);
