import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getSections } from "features/floorManager/selectors/getSections";
import { BumpActiveParty } from "../../shared/types";
import { getFilteredAndSortedTakeawayActiveParties } from "../selectors";
import { NoTakeawayOrderListItem } from "./NoTakeawayOrderListItem";
import { TakeawayOrderDrawer } from "./TakeawayOrderDrawer";
import "./TakeawayOrderList.scss";
import { TakeawayOrderListItem } from "./TakeawayOrderListItem";

export const TakeawayOrderList = () => {
    const parties = useSelector(getFilteredAndSortedTakeawayActiveParties);
    const sections = useSelector(getSections);
    const [selectedParty, setSelectedParty] = useState<BumpActiveParty>();

    useEffect(() => {
        setSelectedParty((prev) => {
            const latest = parties.find((p) => p.id === prev?.id);
            if (latest) return latest;
            if (!latest && prev?.status === "preparing") return prev; // keep showing previous one until the new signalR "ready" party comes through
            return undefined; // the order is marked as collected, so we want to close the drawer
        });
    }, [parties, sections]);

    const closeDrawer = () => {
        setSelectedParty(undefined);
    };

    return (
        <div className="takeaway-order__list">
            {/*TODO: 4/10/2022 - ben.priebe - add fetching/error states*/}
            {!parties.length ? (
                <NoTakeawayOrderListItem />
            ) : (
                parties.map((party) => (
                    <TakeawayOrderListItem key={party.id} party={party} onClick={setSelectedParty} />
                ))
            )}
            <TakeawayOrderDrawer party={selectedParty} onClose={closeDrawer} visible={!!selectedParty} />
        </div>
    );
};
