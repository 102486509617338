import { Spin } from "antd";
import * as React from "react";
import { CSSTransition } from "react-transition-group";

import meanduLogo from "../../assets/img/meandu-logo.svg";

import "./LoadingSpinner.scss";
import "./PageLoading.scss";

export const LoadingSpinner = () => <img src={meanduLogo} alt="Loading" className="loading-spinner" />;

interface Props {
    visible: boolean;
}
export const LoadingOverlayWithSpin = ({ visible }: Props) => (
    <CSSTransition classNames="loading" timeout={300} unmountOnExit in={visible}>
        <div className="loader__container loader__container--overlay">
            <Spin />
        </div>
    </CSSTransition>
);
