import React, { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import ReactModal from "react-modal";
import { OfflineIcon } from "common/icons";
import { Text } from "../../../components/text";

import "./OfflineModal.scss";

export const OfflineModal = () => {
    const [offline, setOffline] = useState(false);

    const updateOfflineStatus = useCallback(() => {
        setOffline(!navigator.onLine);
    }, []);

    useEffect(() => {
        ReactModal.setAppElement("#root");
        window.addEventListener("online", updateOfflineStatus);
        window.addEventListener("offline", updateOfflineStatus);

        return () => {
            window.removeEventListener("online", updateOfflineStatus);
            window.removeEventListener("offline", updateOfflineStatus);
        };
    }, [updateOfflineStatus]);

    return (
        <ReactModal
            isOpen={offline}
            className="offline-modal"
            overlayClassName="offline-modal__overlay"
            closeTimeoutMS={250}
        >
            <OfflineIcon />
            <Text className="offline-modal__title" preset="title-24" mode="bold">
                Connection lost
            </Text>
            <Text preset="g-14" mode="bold">
                An internet connection cannot be established.
            </Text>
            <Text preset="g-14">Venue Manager will be available when a connection is found.</Text>
        </ReactModal>
    );
};
