import "./ResubmitOrderModal.scss";
import React, { useCallback, useEffect, useRef } from "react";
import { ConfirmModal } from "components/confirmModal/ConfirmModal";
import { useDispatch, useSelector } from "react-redux";
import { getResubmitOrder } from "../selectors";
import { AppDispatch } from "features";
import { actionCreators } from "../reducers";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Text } from "components/text";
import { ExternalLinkIcon } from "common/icons";
import { useTrackEvent } from "common/AppInsights/AppInsights";
import { getPartyTypeForTracking } from "../types/orderTracking";

export const ResubmitOrderModal = () => {
    const orderToResubmit = useSelector(getResubmitOrder);
    const dispatch = useDispatch<AppDispatch>();
    const trackLearnMoreClicked = useTrackEvent("ORDER/RESUBMIT_LEARN_CLICKED", {});
    const trackOrderResubmitted = useTrackEvent("ORDER/RESUBMITTED", {});
    const submitOrderClicked = useRef(false);

    const resetState = useCallback(() => {
        dispatch(actionCreators.setOrderToResubmit());
        submitOrderClicked.current = false;
    }, [dispatch]);

    useEffect(
        () => () => {
            resetState();
        },
        [resetState]
    );

    const onConfirm = () => {
        submitOrderClicked.current = true;
        dispatch(actionCreators.resubmitUnconfirmedOrder(orderToResubmit!));
    };
    return (
        <ConfirmModal
            title="Resubmit order to POS"
            showModal={!!orderToResubmit}
            onCloseModal={() => {
                trackOrderResubmitted({
                    order_resubmitted: submitOrderClicked.current,
                    "party.type": getPartyTypeForTracking(orderToResubmit),
                });
                resetState();
            }}
            className="resubmit-order-modal"
            confirmText="Resubmit order"
            onConfirm={onConfirm}
        >
            <>
                <div className="resubmit-order-modal__banner">
                    <ExclamationCircleFilled className="resubmit-order-modal__banner__icon" />
                    <div className="resubmit-order-modal__banner__text">
                        <Text preset="g-16" mode="bold" className="resubmit-order-modal__banner__text-heading">
                            First confirm the order isn’t within POS
                        </Text>
                        <Text preset="g-14" className="resubmit-order-modal__banner__text-subheading">
                            Transfers between me&u and POS can take several minutes.
                        </Text>
                    </div>
                    <button
                        type="button"
                        className="disable-section__live-chat__button"
                        onClick={() => {
                            trackLearnMoreClicked({
                                "party.type": getPartyTypeForTracking(orderToResubmit),
                            });
                            window.open("https://meandu.helpjuice.com/en_US/resubmit-an-order", "_blank");
                        }}
                    >
                        <Text preset="g-14" mode="bold">
                            <ExternalLinkIcon /> Learn more
                        </Text>
                    </button>
                </div>
                <Text preset="g-14" className="resubmit-order-modal__body">
                    Resubmitting will send a copy of the failed portion of the order to POS. The customer won’t be
                    charged again. It is possible that the original order may still reach the POS terminal, resulting in
                    a duplicate order that will affect reconciliation.
                </Text>
            </>
        </ConfirmModal>
    );
};
