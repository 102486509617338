import { fetchAsStaff } from "../../staffLogin/selectors/getAccessToken";
import { opsApi } from "../../../common/api";
import { Order } from "../types";
import { getPaymentsTotal, mapOrder } from "./commonGraphFunctions";
import { toGroupTabsData } from "../utils";
import { ActivePartyApiResponse, getActivePartyApiQuery } from "./commonGraphQueries";

export async function fetchOrder(locationId: string, partyId: string, orderId: string): Promise<Order | null> {
    const res = await opsApi.graphQLQuery<ActivePartyApiResponse>(
        getActivePartyApiQuery,
        {
            partyId,
            locationId,
        },
        fetchAsStaff(true)
    );

    const party = res.data.party;
    if (party?.orderWithPayment) {
        const order = party.orderWithPayment;
        const mappedOrder = mapOrder(party, order);
        const partyRefund = party.refunds?.[0];
        return {
            ...mappedOrder,
            fullName: party.members[0].displayName,
            itemTotal: order.orderTotal,
            totalValue: order.posTotal,
            promotions: order.promotions,
            cepOffers: order.cepOffers,
            refundablePaymentId: party.refundablePaymentId,
            paymentsTotal: getPaymentsTotal(party, partyRefund),
            refundableTotal: party.refundableTotal,
            refundedTotal: partyRefund?.transactionTotal,
            groupTab: party.groupTab ? toGroupTabsData([party.groupTab])[0] : undefined,
            payments: party.members[0].payments,
            dateCollected: (party.dateCollected && new Date(party.dateCollected)) || undefined,
            stampsEarned: order.stampsEarned,
        };
    } else {
        const order = party?.ordersWithoutPayment?.find((o) => o.id === orderId);
        if (order) {
            const mappedOrder = mapOrder(party!, order);

            return {
                ...mappedOrder,
                itemTotal: order.orderTotal,
            };
        }
    }
    return null;
}
