import { AddCircleIcon, BugIcon, DeskBellIcon, DrinkIcon } from "common/icons";
import { OpenTableOrderType, SectionBehaviour } from "./ManageSection";

export type Section = {
    id: string;
    displayName: string;
    availability: Availability;
    availabilityDate: string | null;
    deliveryOptions?: DeliveryOption[];
    tables?: Table[];
    sectionBehaviour?: SectionBehaviour;
    orderFlow: SectionOrderFlowTypes;
};

export type Table = {
    id: string;
    name: string;
    availabilityStatus?: TableAvailabilityStatus;
    statusExpiryUtc?: string;
};

export type FloorItem = {
    id: string;
    key: string;
    type: FloorItemType;
    parent?: FloorItem;
    children?: FloorItem[];
    displayName: string;
    availabilityStatus?: TableAvailabilityStatus;
    statusExpiryUtc?: string | null;
    deliveryOptions?: DeliveryOption[];
    hasMatch?: boolean;
    sectionOrderTypes?: OpenTableOrderType[];
    sectionBehaviour?: SectionBehaviour;
    orderFlow: SectionOrderFlowTypes;
};

export type AggregateSectionAvailability = Pick<Section, "availability" | "availabilityDate">;

export enum FloorItemType {
    SECTION = 0,
    TABLE = 1,
}
export enum TableAvailabilityStatus {
    UNAVAILABLE = "UNAVAILABLE",
    //| removeAlcoholic,
}

export enum Availability {
    NOW = "NOW",
    DAY = "DAY",
    LATER = "LATER",
}

export enum DisableReason {
    OTHER = "OTHER",
    POS = "POS",
    VENUE_OPERATIONS = "VENUEOPERATIONS",
    RSA = "RSA",
}

export type MenuItemType = "food" | "drink";

export type DeliveryOption = {
    menuItemType: MenuItemType;
    type: DeliveryOptionType;
};

export enum DeliveryOptionType {
    TABLE = "TABLE",
    PICKUP = "PICKUP",
    TAKEAWAY = "TAKEAWAY",
}

export interface SubReason {
    label: string;
    value: string;
    defaultDuration?: number | undefined;
}

export const DISABLE_PARENT_REASONS = [
    {
        label: "POS errors",
        value: DisableReason.POS,
        icon: BugIcon,
    },
    {
        label: "Venue operations",
        value: DisableReason.VENUE_OPERATIONS,
        icon: DeskBellIcon,
    },
    {
        label: "Alcohol concerns",
        value: DisableReason.RSA,
        icon: DrinkIcon,
    },
    {
        label: "Other",
        value: DisableReason.OTHER,
        icon: AddCircleIcon,
    },
];

export const POS_SECONDARY_REASONS = [
    {
        label: "Orders not printing",
        value: "OrdersNotPrinting",
    },
    {
        label: "POS rejecting order",
        value: "POSRejectingOrder",
    },
    {
        label: "Connecting to POS",
        value: "ConnectingToPOS",
    },
    {
        label: "Network issues",
        value: "NetworkIssues",
    },
    {
        label: "Customer can't tap on",
        value: "CustomerCantTapOn",
    },
    {
        label: "Incorrect menu showing",
        value: "IncorrectMenuShowing",
    },
    {
        label: "Other",
        value: "Other",
    },
];

export const OPERATIONAL_REASONS = [
    {
        label: "We can’t run drinks",
        value: "CantRunDrinks",
        defaultDuration: 15,
    },
    {
        label: "The kitchen is too busy",
        value: "KitchenTooBusy",
        defaultDuration: 15,
    },
    {
        label: "Venue traffic is slow",
        value: "VenueTrafficSlow",
        defaultDuration: undefined,
    },
    {
        label: "Weather conditions",
        value: "WeatherConditions",
        defaultDuration: undefined,
    },
    {
        label: "Function or event",
        value: "FunctionOrEvent",
        defaultDuration: undefined,
    },
    {
        label: "Other",
        value: "Other",
        defaultDuration: 15,
    },
];

export const SECONDARY_REASONS = {
    [DisableReason.POS]: POS_SECONDARY_REASONS,
    [DisableReason.VENUE_OPERATIONS]: OPERATIONAL_REASONS,
};

export enum SectionOrderFlowTypes {
    OPEN_TABLE = "OPENTABLE",
    PAY_AND_ORDER = "PAYANDORDER",
}
