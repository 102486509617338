import "./MemberDetails.scss";

import { ActiveParty } from "common/types";
import { getCustomerName } from "common/utility/memberUtils";
import React from "react";
import { PhoneNumber } from "sharedComponents/controls/phoneNumber";

interface Props {
    party: ActiveParty;
    variant?: "light" | "dark";
}

export const MemberDetails = ({ party, variant = "light" }: Props) => {
    const member = party.members[0];
    const isTakeaway = isTakeawayOrder(party);
    const showPhoneNumber = isTakeaway && member.phoneNumber;
    return (
        <>
            {showPhoneNumber ? (
                <PhoneNumber phoneNumber={member.phoneNumber!} className={`phone-${variant}`} mask={true} />
            ) : (
                getCustomerName(party.tableNumber)
            )}
        </>
    );
};

const isTakeawayOrder = (party: ActiveParty) => {
    return party.deliveryOptions?.find((deliveryOption) => deliveryOption.type === "takeaway");
};
