import { useEffect } from "react";
import { useDispatch } from "react-redux";
import React from "react";
import { AppDispatch } from "../../index";
import { actionCreators } from "../reducers/staffLogin";

export const withDisabledSessionTimeout =
    <P extends {}>(Component: React.ComponentType<P>): React.FC<P> =>
    ({ ...props }: P) => {
        const dispatch = useDispatch<AppDispatch>();

        useEffect(() => {
            dispatch(actionCreators.autoExtend(true));

            return () => {
                dispatch(actionCreators.autoExtend(false));
            };
        }, [dispatch]);

        return <Component {...props} />;
    };
