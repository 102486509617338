import "./UpdateSectionBehaviourModal.scss";
import React, { useCallback, useEffect, useState } from "react";
import { ConfirmModal } from "components/confirmModal/ConfirmModal";
import { useSelector } from "react-redux";
import { getUpdateSectionBehaviour } from "../selectors/getManangeSection";
import { actionCreator } from "../reducers/manageSection";
import { DeliveryOptionType, FloorItem, OpenTableOrderType, SectionBehaviour, SectionOrderFlowTypes } from "../types";
import { useDispatch } from "react-redux";
import { Indicator } from "components/indicator/Indicator";
import { Text } from "components/text";
import { updateSectionBehaviour } from "../actions/updateSectionBehaviour";
import { AppDispatch } from "features";
import { ALL_SECTION_ID, OPEN_TABLE_ORDER_TYPES } from "../selectors/getFloorItems";
import { useTrackEvent } from "common/AppInsights/AppInsights";

export const SECTION_BEHAVIOUR_CHANGE_TRACKING_EVENT = "SECTION/BEHAVIOUR_CHANGED";

export const UpdateSectionBehaviourModal = () => {
    const sectionToUpdate = useSelector(getUpdateSectionBehaviour);
    const [newSectionBehaviour, setNewSectionBehaviour] = useState<OpenTableOrderType | undefined>();
    const dispatch = useDispatch<AppDispatch>();
    const trackSectionBehaviourChanged = useTrackEvent(SECTION_BEHAVIOUR_CHANGE_TRACKING_EVENT, {});

    const setUpdateSectionBehaviour = useCallback(
        (floorItem?: FloorItem) => {
            dispatch(actionCreator.setUpdateSectionBehaviour(floorItem));
        },
        [dispatch]
    );

    const resetState = () => {
        setUpdateSectionBehaviour(undefined);
        setNewSectionBehaviour(undefined);
    };

    useEffect(
        () => () => {
            setUpdateSectionBehaviour(undefined);
        },
        [setUpdateSectionBehaviour]
    );

    const onConfirm = () => {
        if (sectionToUpdate !== undefined && newSectionBehaviour) {
            dispatch(
                updateSectionBehaviour(
                    sectionToUpdate?.id === ALL_SECTION_ID ? null : sectionToUpdate?.id,
                    newSectionBehaviour.value
                )
            );
            trackSectionBehaviourChanged({
                section_id: sectionToUpdate.id,
                section_name: sectionToUpdate.displayName,
                all_sections: sectionToUpdate.id === ALL_SECTION_ID,
                order_flow: getSectionOrderFlowForTracking(sectionToUpdate),
                initial_order_sub_flow: getSectionOrderSubFlowForTracking(sectionToUpdate.sectionBehaviour),
                final_order_sub_flow: getSectionOrderSubFlowForTracking(newSectionBehaviour.value),
            });
        }
    };

    return (
        <ConfirmModal
            title={`Update behaviour for ${sectionToUpdate?.displayName}`}
            showModal={!!sectionToUpdate}
            onCloseModal={resetState}
            className="update-section-behaviour-modal"
            onConfirm={newSectionBehaviour ? onConfirm : undefined}
        >
            <>
                <Text preset="g-14" mode="bold" className="update-section-behaviour-modal__select-behaviour">
                    Select behaviour
                </Text>
                <div className="update-section-behaviour-modal__options-container">
                    {OPEN_TABLE_ORDER_TYPES.map((type) => (
                        <Indicator
                            key={type.value}
                            onClick={() => setNewSectionBehaviour(type)}
                            primary={newSectionBehaviour?.value === type.value}
                        >
                            {type.label}
                        </Indicator>
                    ))}
                </div>
                {newSectionBehaviour?.value && (
                    <Text preset="g-14" mode="block" className="update-section-behaviour-modal__option-description">
                        {getSectionBehaviourDescription(newSectionBehaviour.value)}
                    </Text>
                )}
            </>
        </ConfirmModal>
    );
};

const getSectionBehaviourDescription = (sectionBehaviour: SectionBehaviour) =>
    sectionBehaviour === SectionBehaviour.ORDER_AND_PAY
        ? "Allows customers to order and pay with me&u."
        : "Restricts orders from being placed with me&u. Only payments are available.";

export const getSectionOrderFlowForTracking = (record: FloorItem) => {
    if (record.orderFlow === SectionOrderFlowTypes.OPEN_TABLE) {
        return "flex";
    }

    if (record.deliveryOptions?.some((option) => option.type === DeliveryOptionType.TAKEAWAY)) {
        return "takeaway";
    }

    return "order&pay";
};

const getSectionOrderSubFlowForTracking = (sectionBehaviour: SectionBehaviour | undefined) => {
    switch (sectionBehaviour) {
        case SectionBehaviour.PAY_ONLY: {
            return "payments_only";
        }
        default: {
            return "full_service";
        }
    }
};
