import { Modal, Radio } from "antd";
import { Text } from "components/text";
import React, { useState } from "react";
import "./TakeawayOrderCancelModal.scss";

interface Props {
    visible: boolean;
    onSubmit: (cancelReason?: string) => void;
    onCancel?: (e: React.MouseEvent<HTMLElement>) => void;
}

export const TakeawayOrderCancelModal = ({ visible, onSubmit, onCancel }: Props) => {
    const [cancelReason, setCancelReason] = useState<string>();

    const cancelOrder = () => {
        setCancelReason((prev) => {
            onSubmit(prev);
            return undefined;
        });
    };

    const close = (e: React.MouseEvent<HTMLElement>) => {
        setCancelReason(undefined);
        onCancel && onCancel(e);
    };

    return (
        <Modal
            width={640}
            title={
                <>
                    <Text preset="title-24" mode={["bold", "block"]} value="Cancel order" />
                    <Text
                        preset="g-14"
                        mode={["block"]}
                        className="takeaway-order__modal-subtitle"
                        value="On cancelling this order, a refund will be issued and an SMS will be sent to the customer. We won’t share the reason for cancelling with the customer."
                    />
                </>
            }
            className="takeaway-order__modal"
            centered
            closable={false}
            open={visible}
            okText="Cancel order"
            cancelText="Dismiss"
            onOk={cancelOrder}
            okButtonProps={{ disabled: !cancelReason }}
            onCancel={close}
        >
            <Text preset="title-20" mode={["bold", "block"]} value="Why are you cancelling this order?" />
            <Radio.Group
                value={cancelReason}
                onChange={(e) => setCancelReason(e.target.value)}
                className="takeaway-order__modal-radio-list"
            >
                <Radio value="Sold out item/s">
                    <Text preset="g-16">Sold out item/s</Text>
                </Radio>
                <Radio value="Kitchen is busy">
                    <Text preset="g-16">Kitchen is busy</Text>
                </Radio>
                <Radio value="Venue is busy">
                    <Text preset="g-16">Venue is busy</Text>
                </Radio>
                <Radio value="Customer asked to cancel">
                    <Text preset="g-16">Customer asked to cancel</Text>
                </Radio>
                <Radio value="Other">
                    <Text preset="g-16">Other</Text>
                </Radio>
            </Radio.Group>
        </Modal>
    );
};
