import { PhoneIcon } from "common/icons";
import React from "react";
import { Text } from "../../../../components/text";

export interface NameRowProps {
    fullName: string;
    phoneNumber?: string;
}

export const OrderCardNameRow = ({ fullName, phoneNumber }: NameRowProps) => {
    return (
        <div className="details-card__detail-row">
            <Text preset="g-14" mode={["bold", "block"]} value="Customer" />
            <span className="details-card__detail-row-phone">
                <Text preset="g-14" mode="block" value={fullName} />
                <Text preset="g-14">
                    {phoneNumber && <PhoneIcon />} {phoneNumber}
                </Text>
            </span>
        </div>
    );
};
