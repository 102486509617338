import { parsePhoneNumber } from "awesome-phonenumber";
import classNames from "classnames";
import { PhoneToggleIcon } from "common/icons";
import React, { useEffect, useState } from "react";
import "./PhoneNumber.scss";

const regex = /([\d-]{3,7}|[(\d)\s]{4,11})\s(.*)/gm;

interface Props {
    phoneNumber: string;
    mask?: boolean;
    className?: string;
}

export const PhoneNumber = ({ phoneNumber, className, mask = false }: Props) => {
    const [masked, toggleMask] = useState(mask);
    useEffect(() => {
        toggleMask(mask);
    }, [mask]);

    const parsed = parsePhoneNumber(phoneNumber);
    let displayPhoneNumber = parsed.getNumber("national");
    displayPhoneNumber = !masked ? displayPhoneNumber : maskPhoneNumber(displayPhoneNumber);

    const onClick = () => {
        if (!mask) return;
        toggleMask((prev) => !prev);
    };
    return (
        <div className={classNames("phoneNumber", { clickable: mask }, className)} onClick={onClick}>
            <PhoneToggleIcon muted={!masked} />
            {displayPhoneNumber}
        </div>
    );
};

const maskPhoneNumber = (phoneNumber: string) => {
    return phoneNumber.replace(regex, (match, g1, g2) => `${g1} ${g2.replace(/\d/g, ".")}`);
};
