import { fetchAsStaff } from "../../staffLogin/selectors/getAccessToken";
import moment from "moment";
import { opsApi } from "../../../common/api";
import { Party } from "../types";
import { OrderItem, PartyType } from "common/types/party";
import { getPartyFields } from "./commonGraphFields";
import { FetchActiveOrderOptions } from "./fetchActiveOrdersApi";
import { mapActivePartyToPartyPayment, sortItemWithTime } from "../utils";

export async function fetchActivePaymentsApi(
    locationId: string,
    { diningDate, skip, limit, sectionId, ascending }: FetchActiveOrderOptions,
    typeFilter?: PartyType[]
): Promise<PartiesResponse> {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(
        query,
        {
            locationId,
            diningDate: moment(diningDate).format("YYYY-MM-DD HH:00:00"),
            skip: skip,
            limit: limit,
            sectionId,
            ascending,
            ongoingOnly: false,
            typeFilter: typeFilter,
        },
        fetchAsStaff(true)
    );
    const parties: Party[] = mapActivePartyToPartyPayment(res.data.activeParties.data, true);

    return {
        parties: parties.sort(sortItemWithTime),
        partiesTotal: Math.max(
            res.data.activeParties.totalCount - (res.data.activeParties.data.length - parties.length),
            0
        ),
    };
}

export const toOrderItems = (orders: OrderItem[]) => {
    return orders.map((orderItem) => ({
        ...orderItem,
        mappedModifiers: orderItem.modifiers && orderItem.modifiers,
    }));
};
type GraphQLPayload = { activeParties: { data: any[]; totalCount: number } };
export type PartiesResponse = { parties: Party[]; partiesTotal: number };

const query: string = `
query (
    $locationId: ID!, 
    $diningDate: String!, 
    $skip: Int!, 
    $limit: Int!, 
    $sectionId: String, 
    $ascending: Boolean!,
    $statusFilter: [String!],
    $paymentStatusFilter: [String!],
    $ongoingOnly: Boolean,
    $typeFilter: [PartyType!]) 
{  
    activeParties(locationId: $locationId, diningDate: $diningDate, skip: $skip, limit: $limit, section: $sectionId, ascending: $ascending, statusFilter: $statusFilter, paymentStatusFilter: $paymentStatusFilter, ongoingOnly: $ongoingOnly, typeFilter: $typeFilter) 
    {    
        totalCount    
        data {      
            ${getPartyFields}
        }
    }
}
`;
