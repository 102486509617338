import { LocationSummary } from "../types/LocationSummary";
import { opsApi } from "common/api";
import { regionHelper } from "../../region";

const query: string = `query ($locationId: ID!) {
    location(locationId: $locationId) {
        id,
        displayName
        authInfo {
            permissions
            userRoles
        }
        isBuzzer
        locale
        currency
        timeZone
        isLiveOrdersEnabled
        showDisableCategory
        supportedOrderFlows
        enableRefunds
        enableResubmitOrders
        enableWaitTimes
        posOperations {
            name
            supported
        },
        takeawayOptions {
            enabled,
            sectionId
        },
        manageMigrationUrl,
        migrationSlug,
    }
}`;

export async function fetchLocation(locationId: string, token: string): Promise<LocationSummary> {
    const result = await opsApi.graphQLQuery<{ location: LocationSummary }>(query, { locationId }, async (request) => {
        request.headers.append("Authorization", `Bearer ${token}`);
        await regionHelper.addRegionHeaders(request);
        return fetch(request);
    });

    return result.data.location;
}
