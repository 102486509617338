import { FeatureConfiguration } from "..";
import { Route } from "react-router";
import * as React from "react";
import { HelpPageContainer } from "./containers/HelpPageContainer";

export default function (config: FeatureConfiguration) {
    config.addRoutes(config.routes.locationRoutes, [
        <Route key="help" path={`/help`} render={() => <HelpPageContainer />} />,
    ]);
}
