import { Tabs as AntsTabs } from "antd";
import classNames from "classnames";
import React, { ReactNode } from "react";
import { Text } from "components/text";
import "./Tabs.scss";

interface Props {
    rightContent?: ReactNode;
    tabs: TabProperties[];
    className?: string;
    activeKey?: string;
    destroyInactiveTabPane?: boolean;
    onTabClick?: (key: string) => void;
    mode: "page" | "content";
}

interface TabProperties {
    totalCounter?: number;
    title: string;
    key: string;
    className?: string;
    content: ReactNode;
    icon?: ReactNode;
}

export const Tabs = ({ tabs, mode, className, activeKey, destroyInactiveTabPane, rightContent, onTabClick }: Props) => {
    const controlledProps = {};
    if (activeKey) {
        controlledProps["activeKey"] = activeKey;
    }
    return (
        <AntsTabs
            className={classNames("tabs-element", "tabs-element--" + mode, className)}
            animated={false}
            tabBarExtraContent={rightContent}
            destroyInactiveTabPane={destroyInactiveTabPane}
            {...controlledProps}
            onTabClick={onTabClick}
            defaultActiveKey={tabs[0].key}
            items={tabs.map((tab) => ({
                className: classNames(`tabs-element__tab`, tab.className),
                children: tab.content,
                key: tab.key,
                label: (
                    <span className="tabs-element__tab-header">
                        {tab.icon}
                        {mode === "content" ? (
                            <Text preset="g-16" mode="bold" className="tab-title">
                                {tab.title}
                            </Text>
                        ) : (
                            <Text preset="g-14" mode="bold" className="tab-title">
                                {tab.title}
                            </Text>
                        )}
                        {!!tab.totalCounter && (
                            <Text preset="g-12" mode={["bold"]} className="total-count">
                                {tab.totalCounter}
                            </Text>
                        )}
                    </span>
                ),
            }))}
        />
    );
};
