import { actionCreators } from "features/orders/reducers";
import { getSelectedHistoryFilters } from "features/orders/selectors";
import { Order, OrderStatus, TablePage } from "features/orders/types";
import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VirtualTableColumnFilter, VirtualTableColumnType } from "../virtualTable/types";
import { VirtualTableHeader } from "../virtualTable/VirtualTableHeader";

export const getTableColumns = (page: TablePage): VirtualTableColumnType<Order, OrderStatus>[] => {
    const allColumns: VirtualTableColumnType<Order, OrderStatus>[] = [
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
            visibleOnPage: [TablePage.ALERTS],
        },
        {
            title: "Party ID",
            dataIndex: "displayOrderId",
            key: "displayOrderId",
            visibleOnPage: [TablePage.ALERTS, TablePage.ORDERS],
        },
        {
            title: "Order flow",
            dataIndex: "orderFlow",
            key: "orderFlow",
            visibleOnPage: [TablePage.ALERTS, TablePage.ORDERS],
        },
        {
            title: "",
            dataIndex: "isMerged",
            key: "isMerged",
            visibleOnPage: [TablePage.ALERTS, TablePage.ORDERS],
        },
        {
            title: "Table",
            dataIndex: "table",
            key: "table",
            visibleOnPage: [TablePage.ALERTS, TablePage.ORDERS],
        },
        {
            title: "Section",
            dataIndex: "section",
            key: "section",
            visibleOnPage: [TablePage.ALERTS, TablePage.ORDERS],
        },
        {
            title: "Total value",
            dataIndex: "itemTotal",
            key: "itemTotal",
            align: "right",
            visibleOnPage: [TablePage.ALERTS, TablePage.ORDERS],
        },
        {
            title: "Time",
            dataIndex: "time",
            key: "time",
            visibleOnPage: [TablePage.ALERTS, TablePage.ORDERS],
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            width: 160,
            minWidth: 120,
            filters:
                page === TablePage.ORDERS
                    ? ([
                          {
                              text: "Confirmed",
                              value: "Confirmed",
                          },
                          {
                              text: "Unconfirmed",
                              value: "Unconfirmed",
                          },
                          {
                              text: "Resolved",
                              value: "Resolved",
                          },
                          {
                              text: "Refunded",
                              value: "Refunded",
                          },
                          {
                              text: "Refund failed",
                              value: "Refund failed",
                          },
                      ] as VirtualTableColumnFilter<OrderStatus>[])
                    : undefined,
            visibleOnPage: [TablePage.ALERTS, TablePage.ORDERS],
        },
    ];
    return allColumns.filter((col) => !col.visibleOnPage || col.visibleOnPage.includes(page));
};

interface Props {
    page: TablePage;
}

export const OrdersHeader = ({ page }: Props) => {
    const historyFilters = useSelector(getSelectedHistoryFilters);
    const orderColumns = useMemo(() => getTableColumns(page), [page]);

    const dispatch = useDispatch();

    const applyFilters = useCallback(
        (selected: OrderStatus[]) => {
            dispatch(actionCreators.selectHistoryOrdersPage(1));
            dispatch(actionCreators.selectHistoryOrdersFilter(selected));
        },
        [dispatch]
    );

    return (
        <VirtualTableHeader<Order, OrderStatus>
            columns={orderColumns}
            applyFilters={applyFilters}
            defaultSelectedValue={historyFilters}
        />
    );
};
