import * as React from "react";
import { useCallback, useEffect, useState } from "react";

import "./Nav.scss";
import { NavIcon } from "./NavIcon";
import { NavType } from "../types";
import { LockIcon, MeAndUIcon } from "common/icons";
import classNames from "classnames";
import { DoubleLeftOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "features";
import { actionCreators as StaffLoginAction } from "../../staffLogin/reducers/staffLogin";
import { useTrackEvent } from "common/AppInsights/AppInsights";
import { Text } from "components/text";
import { usePreparingTakeaways } from "../hooks";
import { getIsMigratedLocation } from "../selectors/isMigratedLocation";

export interface Props {
    navType: NavType;
    stateNavType?: NavType;
    activeType: (type: NavType) => void;
    hideNavBar?: boolean;
    collapseBar?: boolean;
    canViewSalesReport: boolean;
    isBuzzerBumpScreenEnabled: boolean;
    isTakeawayBumpScreenEnabled: boolean;
    isLiveOrdersEnabled: boolean;
    canViewFloorMananger: boolean;
    locationName?: string;
}

export const Nav = ({
    navType,
    stateNavType,
    activeType,
    hideNavBar,
    collapseBar,
    canViewSalesReport,
    isBuzzerBumpScreenEnabled,
    isTakeawayBumpScreenEnabled,
    isLiveOrdersEnabled,
    canViewFloorMananger,
    locationName,
}: Props) => {
    const [showNav, setShowNav] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const trackLockButton = useTrackEvent("LockScreen", {});
    const preparingTakeaways = usePreparingTakeaways();
    const isMigrated = useSelector(getIsMigratedLocation);

    const reset = useCallback(() => {
        trackLockButton({});
        dispatch(StaffLoginAction.reset());
    }, [dispatch, trackLockButton]);

    useEffect(() => {
        if ((navType && stateNavType === undefined) || navType !== stateNavType) {
            activeType(navType);
        }
        setShowNav(false);
    }, [stateNavType, navType, activeType]);

    return (
        <>
            {!collapseBar && !hideNavBar && <div className="navbar__space-saver" />}
            <nav className={classNames("navbar", collapseBar && "collapse", hideNavBar && "hide", showNav && "show")}>
                <DoubleLeftOutlined className="menu-fold" onClick={() => setShowNav(!showNav)} />
                <div className="navbar__top">
                    <MeAndUIcon />
                    {locationName && (
                        <Text preset="g-12" mode="block" className="navbar__venue-name text">
                            {locationName}
                        </Text>
                    )}
                    <div className="navbar__option">
                        {canViewSalesReport && <NavIcon type={NavType.SALES} onClick={activeType} />}
                        {isLiveOrdersEnabled && <NavIcon type={NavType.ORDERS} onClick={activeType} />}
                        {isTakeawayBumpScreenEnabled && !isMigrated && (
                            <NavIcon
                                type={NavType.BUMP_SCREEN_TAKEAWAY}
                                count={preparingTakeaways}
                                onClick={activeType}
                            />
                        )}
                        {isBuzzerBumpScreenEnabled && !isMigrated && (
                            <NavIcon type={NavType.BUMP_SCREEN_BUZZER} onClick={activeType} />
                        )}
                        {!isMigrated && (
                            <NavIcon type={NavType.AVAILABILITY} onClick={activeType} />
                        )}
                        {canViewFloorMananger && !isMigrated && <NavIcon type={NavType.FLOOR_MANAGER} onClick={activeType} />}
                        <NavIcon type={NavType.HELP} onClick={activeType} />
                    </div>
                </div>
                <div className="navbar__lock" onClick={reset}>
                    <LockIcon />
                </div>
            </nav>
        </>
    );
};
