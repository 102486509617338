import React from "react";
import { Text } from "components/text";

interface FooterProps {
    visible: boolean;
    onClick: () => void;
    loading: boolean;
    buttonText?: string;
}

export const VirtualTableFooter = ({ onClick, loading, visible, buttonText = "Show more" }: FooterProps) => {
    return (
        <tr>
            <td colSpan={10}>
                <div className="virtual-table__footer">
                    {visible && (
                        <button disabled={loading} onClick={onClick}>
                            <Text mode="bold">{buttonText}</Text>
                        </button>
                    )}
                </div>
            </td>
        </tr>
    );
};
