import { opsApi } from "common/api";
import { Table, TableAvailabilityStatus } from "../types";
import { fetchAsStaff } from "features/staffLogin/selectors/getAccessToken";
import { tableQuery } from "./fetchSections";

export async function updateTableMutation(locationId: string, updateTableInput: UpdateTableInput): Promise<Table> {
    const res = (
        await opsApi.graphQLQuery<{ updateTableAvailability: Table }>(
            updateQuery,
            {
                locationId,
                updateTableInput,
            },
            fetchAsStaff(true)
        )
    ).data;

    return res.updateTableAvailability;
}

const updateQuery: string = `mutation ($locationId: ID!, $updateTableInput: UpdateTableInput!) {
    updateTableAvailability(locationId: $locationId, updateTableInput: $updateTableInput) {
        ${tableQuery}
    }
}`;

export interface UpdateTableInput {
    sectionId: string;
    tableId: string;
    availabilityStatus?: TableAvailabilityStatus;
    availableInMinutes?: number;
}
