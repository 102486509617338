import { AppDispatch } from "features";
import { AppState } from "features/state";
import { actionCreators } from "../reducers/staffLogin";
import * as staffLoginApi from "../api";
import { loadLocation } from "features/location/actions/load";
import { extendEnrolment } from "features/enrolment/actions/extendEnrolment";
import { SECOND } from "common/utility";

export function login(pin: string) {
    return async (dispatch: AppDispatch, getState: () => AppState) => {
        const { enrolment } = getState();

        if (enrolment.status !== "enroled") {
            dispatch(actionCreators.failed(Error("Cannot login as device not enroled")));
            return;
        }

        dispatch(actionCreators.processing());

        try {
            const result = await staffLoginApi.login(enrolment.access_token, pin);

            await dispatch(loadLocation(result.location_id, result.access_token));

            const expiresAt = new Date().getTime() + result.expires_in * SECOND;
            dispatch(
                actionCreators.success(
                    result.access_token,
                    result.location_id,
                    expiresAt,
                    result.name,
                    result.user_id,
                    result.access_level_name
                )
            );
            dispatch(extendEnrolment());
        } catch (e) {
            dispatch(actionCreators.failed(e));
        }
    };
}
