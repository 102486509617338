import "./DisableSectionModal.scss";

import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getDisableSection } from "../selectors/getManangeSection";
import { actionCreator } from "../reducers/manageSection";
import { Text } from "../../../components/text";
import { Availability, DisableReason, DisableSection, DISABLE_PARENT_REASONS } from "../types";
import { update } from "../actions";
import { AppDispatch } from "../..";
import { ALL_SECTION_ID } from "../selectors/getFloorItems";
import { ConfirmModal } from "components/confirmModal/ConfirmModal";
import { Indicator } from "components/indicator/Indicator";
import { UNAVAILABILITY_DURATION_OPTIONS } from "./AvailabilityStatusModal";
import { ExternalLinkIcon } from "common/icons";
import { DisableSectionSubReason } from "./DisableSectionSubReason";
import { useTrackEvent } from "common/AppInsights/AppInsights";

export const DisableSectionModal = () => {
    const editAvailability = useSelector(getDisableSection);
    const { disableReason, offlineDurationMinutes, disableSubReason } = editAvailability || {};

    const [openModal, setOpenModal] = useState(!!editAvailability);
    const [timeManuallySelected, setTimeManuallySelected] = useState(false);
    const [otherReasonText, setOtherReasonText] = useState("");
    const dispatch = useDispatch<AppDispatch>();
    const trackLearnMoreClicked = useTrackEvent("SECTION/AVAILABILITY_LEARN_CLICKED", {});

    const trackLiveChatClicked = useTrackEvent("SECTION/AVAILABILITY_CHAT_REQUESTED", {});

    useEffect(() => {
        if (editAvailability) {
            setOpenModal(true);
        }
    }, [editAvailability]);

    const updateSectionStatus = useCallback(
        (
            sectionId: string | null,
            status: Availability,
            disableReason?: DisableReason,
            offlineDurationMinutes?: number | null,
            disableSubReason?: string
        ) => {
            dispatch(update(sectionId, status, disableReason, offlineDurationMinutes, disableSubReason));
        },
        [dispatch]
    );

    const setDisableSection = useCallback(
        (editAvailability?: DisableSection) => {
            dispatch(actionCreator.setDisableSection(editAvailability));
        },
        [dispatch]
    );

    const closeModal = useCallback(() => {
        setOpenModal(false);
        setOtherReasonText("");
    }, [setOpenModal]);

    useEffect(
        () => () => {
            setDisableSection();
        },
        [setDisableSection]
    );

    const canApplySelections = () => {
        if (!disableReason || offlineDurationMinutes === null) return false;
        if ([DisableReason.POS].includes(disableReason) && !disableSubReason) return false;
        return true;
    };

    return (
        <ConfirmModal
            title={`Disable ${
                editAvailability
                    ? editAvailability.sectionId === ALL_SECTION_ID
                        ? "all sections"
                        : `section: ${editAvailability.sectionName}`
                    : ""
            }`}
            className="disable-section__modal"
            showModal={!!openModal}
            onCloseModal={closeModal}
            confirmText={editAvailability?.sectionId === ALL_SECTION_ID ? "Disable all sections" : "Disable section"}
            onConfirm={
                canApplySelections()
                    ? () => {
                          const { sectionId, availability, disableReason, offlineDurationMinutes } = editAvailability!;
                          const updatedSubReason =
                              disableReason === DisableReason.OTHER ? otherReasonText : disableSubReason;

                          updateSectionStatus(
                              sectionId === ALL_SECTION_ID ? null : sectionId,
                              availability,
                              disableReason,
                              offlineDurationMinutes,
                              updatedSubReason
                          );
                      }
                    : undefined
            }
        >
            <div>
                <Text preset="g-14" mode="bold" className="disable-section__field-title-required">
                    Reason
                </Text>
                <div className="disable-section__reason">
                    {DISABLE_PARENT_REASONS.map((reason) => (
                        <Indicator
                            key={reason.value}
                            className="disable-section__reason__indicator"
                            icon={reason.icon}
                            primary={disableReason === reason.value}
                            onClick={() => {
                                setTimeManuallySelected(false);
                                const offlineMinutes = reason.value === DisableReason.OTHER ? 15 : null;
                                setDisableSection({
                                    ...editAvailability!,
                                    offlineDurationMinutes: offlineMinutes,
                                    disableSubReason: "",
                                    disableReason: reason.value,
                                });
                            }}
                        >
                            {reason.label}
                        </Indicator>
                    ))}
                </div>
                <DisableSectionSubReason timeManuallySelected={timeManuallySelected} />
                {disableReason && (
                    <>
                        <Text preset="g-14" mode="bold" className="disable-section__field-title-required">
                            For how long?
                        </Text>
                        <div className="disable-section__duration-container">
                            {UNAVAILABILITY_DURATION_OPTIONS.map((option) => (
                                <Indicator
                                    key={option.value}
                                    primary={option.duration === offlineDurationMinutes}
                                    onClick={() => {
                                        setTimeManuallySelected(true);
                                        const availability =
                                            option.duration === undefined ? Availability.DAY : Availability.LATER;
                                        setDisableSection({
                                            ...editAvailability!,
                                            availability,
                                            offlineDurationMinutes: option.duration,
                                        });
                                    }}
                                    shape="joined"
                                    textWeight="medium"
                                >
                                    {option.label}
                                </Indicator>
                            ))}
                        </div>
                    </>
                )}
                {disableReason === DisableReason.OTHER && (
                    <div className="disable-section__other">
                        <Text preset="g-14" mode="bold" className="disable-section__field-title">
                            Can we help?
                        </Text>
                        <textarea
                            placeholder="Write your comments here"
                            value={otherReasonText}
                            onChange={(e) => setOtherReasonText(e.target.value)}
                        />
                    </div>
                )}
                {disableReason && disableReason !== DisableReason.RSA ? (
                    <HelpBanner
                        buttonAction={() => {
                            trackLiveChatClicked({});
                            window.zE("messenger", "open");
                        }}
                    />
                ) : disableReason ? (
                    <HelpBanner
                        title="Disable single table instead?"
                        subtitle="Target specific tables instead of disabling the entire section."
                        buttonText="Learn more"
                        buttonAction={() => {
                            trackLearnMoreClicked({});
                            window.open(
                                "https://meandu.helpjuice.com/en_US/disabling-a-beacontable-temporarily",
                                "_blank"
                            );
                        }}
                        buttonIcon={ExternalLinkIcon}
                    />
                ) : null}
            </div>
        </ConfirmModal>
    );
};

export interface HelpBannerProps {
    title?: string;
    subtitle?: string;
    buttonText?: string;
    buttonAction?: () => void;
    buttonIcon?: () => JSX.Element;
}

const HelpBanner = ({
    title = "Need help fixing this issue?",
    subtitle = "Connect to the me&u support team via our 24h live chat.",
    buttonText = "Start live chat",
    buttonAction,
    buttonIcon,
}: HelpBannerProps) => {
    return (
        <div className="disable-section__live-chat__container">
            <div className="disable-section__live-chat__text">
                <Text preset="g-16" mode="bold">
                    {title}
                </Text>
                <Text preset="g-14">{subtitle}</Text>
            </div>
            <button type="button" className="disable-section__live-chat__button" onClick={buttonAction}>
                <Text preset="g-14" mode="bold">
                    {buttonIcon?.()} {buttonText}
                </Text>
            </button>
        </div>
    );
};
