import { AppDispatch, AppState } from "../../index";
import { Section, TableAvailabilityStatus } from "../types";
import { createAction } from "../reducers";
import { actionCreator } from "../reducers/manageSection";
import { trackEvent } from "common/AppInsights/AppInsights";
import { getSections } from "../selectors/getSections";
import { updateTableMutation } from "../api/updateTableMutation";
import { TableAvailabilityTrackingData } from "../types/sectionTracking";
import { getLocationLocale } from "features/location/selectors/getLocationLocale";
import { LocationLocale } from "features/location/types/LocationLocale";

export const updateTable = (
    sectionId: string,
    tableId: string,
    availabilityStatus?: TableAvailabilityStatus | null,
    availableInMinutes?: number
) => {
    return async function (dispatch: AppDispatch, getState: () => AppState) {
        const state = getState();
        const { location } = state;
        const sectionsData = getSections(state);
        const locale = getLocationLocale(state);

        if (location.locationId === null) {
            return;
        }

        dispatch(actionCreator.addUpdatingId(tableId));

        try {
            const updatedTable = await updateTableMutation(location.locationId, {
                sectionId,
                tableId,
                availabilityStatus,
                availableInMinutes,
            });
            const section = sectionsData.find((s) => s.id === sectionId);

            if (section) {
                dispatch(
                    createAction.updated({
                        ...section,
                        tables: section.tables?.map((t) => (t.id === tableId ? updatedTable : t)),
                    })
                );
            }
            trackEvent(
                {
                    name: "TABLE/AVAILABILITY_CHANGED",
                },
                getTrackingData(locale, sectionId, tableId, section, availabilityStatus, availableInMinutes)
            );
        } catch (e) {
            console.warn(e);
        } finally {
            dispatch(actionCreator.removeUpdatingId(tableId));
        }
    };
};

const getTrackingData = (
    locale: LocationLocale,
    sectionId: string,
    tableId: string,
    section?: Section,
    availabilityStatus?: TableAvailabilityStatus,
    availableInMinutes?: number
) => {
    const sectionName = section?.displayName ?? "";
    const tableName = section?.tables?.find((t) => t.id === tableId)?.name ?? "";

    const trackingData: TableAvailabilityTrackingData = {
        section_id: sectionId,
        table_id: tableId,
        section_name: sectionName,
        table_name: tableName,
        action: availabilityStatus ? "deactivate" : "activate",
        duration:
            availabilityStatus !== null
                ? availableInMinutes
                    ? `${availableInMinutes} min`
                    : "Until tomorrow"
                : "Taking orders",
        section_tables_count: (section?.tables?.length ?? 0).toString(),
        section_disabled_tables_count: (
            section?.tables?.filter(
                (t) =>
                    t.availabilityStatus === TableAvailabilityStatus.UNAVAILABLE &&
                    (!t.statusExpiryUtc || locale.isInSameDiningDate(t.statusExpiryUtc))
            ).length ?? 0
        ).toString(),
    };

    return trackingData;
};
