import { Order } from "../../types";
import { LocationLocaleContext } from "features/location/context/LocationLocaleContext";
import React, { useContext, useEffect, useState } from "react";
import { OrderCardRow } from "./OrderCardRow";
import { OrderCardTitleRow } from "./OrderCardTitleRow";
import { useSelector } from "react-redux";
import { getRefundAmount } from "features/orders/selectors";
import classNames from "classnames";
import { getProcessingFeeText } from "features/location/utils/getProcessingFeeText";
import { PaymentStatus } from "common/types";
import { MembershipIcon } from "common/icons";

export interface Props {
    order: Order;
}

export const OrderCardPaymentSection = (props: Props) => {
    const { order } = props;
    const refundedAmount = useSelector(getRefundAmount);
    const locationLocale = useContext(LocationLocaleContext);

    const [isRefunded, setIsRefunded] = useState(getIsRefunded(order));
    const isRefundFailed = order.status === "Refund failed";

    const paidAmount = (order.paymentsTotal || 0) - refundedAmount - (order.pointsPaymentsTotal || 0);

    const showRefundRow = (isRefunded || isRefundFailed) && !!(order.refundedTotal || refundedAmount);

    const promotionsTotal = order.promotions?.reduce((accumulator, promotion) => {
        return accumulator + promotion.amount;
    }, 0);

    const offersTotal = order.cepOffers?.reduce((accumulator, offer) => {
        return accumulator + offer.amount;
    }, 0);

    const trayChargeTotal = order.trayCharges?.reduce((accumulator, trayCharge) => {
        return accumulator + trayCharge.amount;
    }, 0);

    const exclusiveTaxTotal = order.taxTotals?.reduce((accumulator, taxClass) => {
        if (taxClass.exclusive) {
            return accumulator + taxClass.total;
        }
        return accumulator;
    }, 0);

    const venueCauseTotal = order.venueCauses?.reduce((accumulator, venueCause) => {
        return accumulator + venueCause.amount;
    }, 0);

    const totalAmount =
        (order.itemTotal || 0) +
        (order.surcharge?.total || 0) +
        (order.discount || 0) +
        (promotionsTotal || 0) +
        (offersTotal || 0) +
        (trayChargeTotal || 0) +
        (exclusiveTaxTotal || 0) +
        (venueCauseTotal || 0);

    useEffect(() => {
        setIsRefunded(getIsRefunded(order));
    }, [order]);

    return (
        <div className="details-card__payment">
            <OrderCardTitleRow title="Payment" />
            <OrderCardRow
                label="Amount"
                labelMode="block"
                value={locationLocale.formatCurrency(totalAmount)}
                className="payment-row"
            />
            {!!order.pointsPaymentsTotal && (
                <OrderCardRow
                    label="Points"
                    labelMode="block"
                    value={locationLocale.formatCurrency(0 - order.pointsPaymentsTotal)}
                    className="payment-row"
                    icon={<MembershipIcon />}
                />
            )}
            {!!order.tip && (
                <OrderCardRow
                    label="Tip"
                    labelMode="block"
                    value={locationLocale.formatCurrency(order.tip)}
                    className="payment-row"
                />
            )}
            {!!order.gratuity && (
                <OrderCardRow
                    label="Gratuity"
                    labelMode="block"
                    value={locationLocale.formatCurrency(order.gratuity)}
                    className="payment-row"
                />
            )}
            {!order.processingFeeAbsorbed && !!order.processingFee && (
                <OrderCardRow
                    label={getProcessingFeeText(locationLocale)}
                    labelMode="block"
                    value={locationLocale.formatCurrency(order.processingFee)}
                    className="payment-row"
                />
            )}
            {showRefundRow && (
                <OrderCardRow
                    label={isRefunded ? "Refund" : "Refund failed"}
                    labelMode="block"
                    value={`- ${locationLocale.formatCurrency(order.refundedTotal || refundedAmount)}`}
                    className={classNames(
                        "payment-row",
                        isRefunded ? "payment-row--refunded" : "payment-row--refund-failed"
                    )}
                />
            )}
            <OrderCardRow
                label="Paid"
                value={locationLocale.formatCurrency(paidAmount)}
                valueMode={["block", "bold"]}
                className="payment-row"
            />
        </div>
    );
};

const getIsRefunded = (order: Order) => {
    return (
        order.status === "Refunded" ||
        (order.type !== "Payment" &&
            !!order.refunds?.length &&
            (order.refunds[0].status === "Pending" || order.refunds[0].status === PaymentStatus.PENDING))
    );
};
