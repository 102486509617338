import { Modal, Radio } from "antd";
import { Text } from "components/text";
import React, { useState } from "react";
import "./TakeawayOrderScheduledTimeModal.scss";

interface Props {
    visible: boolean;
    onSubmit: (increaseMins?: number) => void;
    onCancel?: (e: React.MouseEvent<HTMLElement>) => void;
}
export const TakeawayOrderScheduledTimeModal = ({ visible, onSubmit, onCancel }: Props) => {
    const [increaseValue, setIncreaseValue] = useState<string>();
    const increaseScheduleTimeButtonDisabled = !increaseValue;

    const increaseScheduleTime = () => {
        setIncreaseValue((prev) => {
            const increaseMins = (prev && parseInt(prev)) || undefined;
            onSubmit(increaseMins);
            return undefined;
        });
    };

    const close = (e: React.MouseEvent<HTMLElement>) => {
        setIncreaseValue(undefined);
        onCancel && onCancel(e);
    };

    return (
        <Modal
            width={640}
            title={
                <>
                    <Text preset="title-24" mode={["bold", "block"]} value="Increase order pickup time" />
                    <Text
                        preset="g-14"
                        mode={["block"]}
                        className="takeaway-order__modal-subtitle"
                        value="On increasing the order pickup time, customers will be notified via SMS."
                    />
                </>
            }
            className="takeaway-order__modal"
            centered
            closable={false}
            open={visible}
            okText="Increase time"
            cancelText="Dismiss"
            onOk={increaseScheduleTime}
            okButtonProps={{ disabled: increaseScheduleTimeButtonDisabled }}
            onCancel={close}
        >
            <Text preset="title-20" mode={["bold", "block"]} value="How much time should be added?" />
            <Radio.Group
                value={increaseValue}
                onChange={(e) => setIncreaseValue(e.target.value)}
                className="takeaway-order__modal-button-strip"
            >
                <Radio.Button value="5">5 min</Radio.Button>
                <Radio.Button value="10">10 min</Radio.Button>
                <Radio.Button value="15">15 min</Radio.Button>
                <Radio.Button value="30">30 min</Radio.Button>
                <Radio.Button value="45">45 min</Radio.Button>
                <Radio.Button value="60">60 min</Radio.Button>
            </Radio.Group>
        </Modal>
    );
};
