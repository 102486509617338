import { AppDispatch, AppState } from "../../index";
import { Availability, DisableReason, Section } from "../types";
import { updateSection } from "../api/updateSection";
import { createAction as sectionsActions } from "../reducers";
import { actionCreator as manageSectionAction } from "../reducers/manageSection";
import { updateAllSections } from "../api/updateAllSections";
import { updateSectionNotification } from "./updateSectionNotification";
import { SectionAvailabilityTrackingData } from "../types/sectionTracking";
import { trackEvent } from "common/AppInsights/AppInsights";
import { getSections } from "../selectors/getSections";
import { ALL_SECTION_ID } from "../selectors/getFloorItems";

export const update = (
    sectionId: string | null,
    availability: Availability,
    disableReason?: DisableReason,
    offlineDurationMinutes?: number | null,
    disableSubReason?: string
) => {
    return async function (dispatch: AppDispatch, getState: () => AppState) {
        const state = getState();
        const { location } = state;
        const sectionsData = getSections(state);

        if (location.locationId === null) {
            return;
        }

        dispatch(manageSectionAction.addUpdatingId(sectionId ?? ALL_SECTION_ID));

        try {
            if (sectionId === null) {
                const result = await updateAllSections(
                    location.locationId,
                    availability,
                    disableReason,
                    offlineDurationMinutes,
                    disableSubReason
                );
                dispatch(sectionsActions.loaded(result));
            } else {
                const updatedSection = await updateSection(
                    location.locationId,
                    sectionId,
                    availability,
                    disableReason,
                    offlineDurationMinutes,
                    disableSubReason
                );
                dispatch(sectionsActions.updated(updatedSection));
            }
            dispatch(updateSectionNotification());
            trackEvent(
                {
                    name: "SECTION/AVAILABILITY_CHANGED",
                },
                getTrackingData(
                    sectionId,
                    sectionsData,
                    availability,
                    disableReason,
                    offlineDurationMinutes,
                    disableSubReason
                )
            );
        } catch (e) {
            console.warn(e);
        } finally {
            dispatch(manageSectionAction.removeUpdatingId(sectionId ?? ALL_SECTION_ID));
        }
    };
};

const getTrackingData = (
    sectionId: string | null,
    sections: Section[],
    availability: Availability,
    disableReason?: DisableReason,
    offlineDurationMinutes?: number | null,
    disableSubReason?: string
) => {
    let sectionName;
    if (sectionId) {
        const sectionIndex = sections.findIndex((s) => s.id === sectionId);
        sectionName = sectionIndex !== -1 ? sections[sectionIndex].displayName : "";
    } else {
        sectionName = sections.map((s) => s.displayName).join(",");
    }

    const trackingData: SectionAvailabilityTrackingData = {
        all_sections: !sectionId,
        data: {
            section_id: sectionId || sections.map((s) => s.id).join(","),
            section_name: sectionName,
            duration:
                availability === Availability.LATER
                    ? `${offlineDurationMinutes} min`
                    : availability === Availability.DAY
                    ? "Until tomorrow"
                    : "Taking orders",
            action: !disableReason ? "activate" : "deactivate",
            reason: disableReason,
            issue: disableReason !== DisableReason.OTHER ? disableSubReason : undefined,
            other_reason: disableReason === DisableReason.OTHER ? disableSubReason : undefined,
        },
    };

    return trackingData;
};
