import { AppDispatch, AppState } from "../../index";
import { updateCategoriesApi } from "../api";
import { createActionDisableCategories } from "../reducers";
import { CategoryAvailabilityUpdateInput } from "../types";
import { fetchCategories } from "./fetchCategories";

export const updateCategories = (locationId: string, categoryUpdates: CategoryAvailabilityUpdateInput[]) => {
    return async function (dispatch: AppDispatch, getState: () => AppState) {
        const { location } = getState();
        if (location.locationId === null) {
            return;
        }

        try {
            dispatch(createActionDisableCategories.categoriesLoading());
            const categoriesUpdated = await updateCategoriesApi(locationId, categoryUpdates);
            if (categoriesUpdated) {
                dispatch(fetchCategories(locationId));
            }
        } catch (e) {
            console.warn(e);
        }
    };
};
