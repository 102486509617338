import { createAction as SectionActions } from "../reducers";
import { fetchSections } from "../api/fetchSections";
import { updateSectionNotification } from "./updateSectionNotification";
import { AppDispatch } from "../../index";

export const load = async (dispatch: AppDispatch, locationId: string, skipLoading?: boolean) => {
    !skipLoading && dispatch(SectionActions.loading());
    try {
        const sections = await fetchSections(locationId);
        dispatch(SectionActions.loaded(sections));
        dispatch(updateSectionNotification());
    } catch (e) {
        dispatch(SectionActions.failed(e));
    }
};
