import { AppDispatch, AppState } from "../../index";
import { updateSectionBehaviour as updateSectionBehaviourApi } from "../api/updateSectionBehaviour";
import { createAction as sectionsActions } from "../reducers";
import { SectionBehaviour } from "../types";

export const updateSectionBehaviour = (sectionId: string | null, sectionBehavior: SectionBehaviour) => {
    return async function (dispatch: AppDispatch, getState: () => AppState) {
        const state = getState();
        const { location } = state;

        if (location.locationId === null) {
            return;
        }

        const sectionBehaviorInput = {
            sectionId,
            sectionBehavior,
        };

        try {
            const result = await updateSectionBehaviourApi(location.locationId, sectionBehaviorInput);
            if (sectionId === null) {
                dispatch(sectionsActions.loaded(result));
            } else {
                dispatch(sectionsActions.updated(result[0]));
            }
        } catch (e) {
            console.warn(e);
        }
    };
};
