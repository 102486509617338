import classNames from "classnames";
import { ReloadIcon } from "common/icons";
import React from "react";
import "./ReloadData.scss";

interface Props {
    fetch: () => void;
    className?: string;
}

export const ReloadData = ({ fetch, className }: Props) => (
    <div className={classNames("refresh-data-button", className)} onClick={fetch}>
        <ReloadIcon />
    </div>
);
