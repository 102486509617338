import React, { useCallback, useEffect } from "react";
import { AppDispatch } from "../../index";
import { ProductDetails, Availability, SoldOutTrackingData } from "../types";
import { DisconnectOutlined, LinkOutlined } from "@ant-design/icons";
import { Table, Tag } from "antd";
import { ColumnProps } from "antd/lib/table";
import { getStatusDisplay } from "../utils";
import { LocationLocaleContext } from "features/location/context/LocationLocaleContext";
import { removeAccent } from "common/utility";
import { AvailabilityOptionProps, AvailabilityPicker } from "components/availabilityPicker/AvailabilityPicker";
import { useDispatch, useSelector } from "react-redux";
import { updateProduct } from "../actions";
import { getExpandedProductKeys, getFilteredProducts, getSearch, isLoadingProducts } from "../selectors";
import { getLocationId } from "features/staffLogin/selectors/getLocationId";
import { useTrackEvent } from "common/AppInsights/AppInsights";
import { useState } from "react";

const searchResult = (searchVal: string, name: string) => {
    const index = removeAccent(name.toLocaleLowerCase()).indexOf(removeAccent(searchVal.toLocaleLowerCase()));
    if (index === -1) return { start: name, bold: "", end: "" };
    const endIndex = index + searchVal.length;
    return {
        start: name.substring(0, index),
        bold: name.substring(index, endIndex),
        end: endIndex > name.length ? "" : name.substring(endIndex),
    };
};

export const productColumns = (
    search: string,
    locationId?: string,
    update?: (locationId: string, item: any, status: Availability) => void
): ColumnProps<ProductDetails>[] => [
    {
        title: "Display name",
        dataIndex: "displayName",
        key: "displayName",
        width: "50%",
        className: "soldout-items__content__table__row__display-name",
        render: (displayName: string, item: ProductDetails) => {
            const { start, bold, end } = searchResult(search || "", displayName);
            const {
                start: internalStart,
                bold: internalBold,
                end: internalEnd,
            } = searchResult(search || "", item.internalName || "");
            const content = (
                <span>
                    {start}
                    <b>{bold}</b>
                    {end}
                </span>
            );
            const internalName =
                item.internalName &&
                (internalBold ? (
                    <span>
                        {" "}
                        ({internalStart}
                        <b>{internalBold}</b>
                        {internalEnd})
                    </span>
                ) : (
                    <span> ({item.internalName})</span>
                ));

            if (item.isVariant)
                return (
                    <span className="soldout-items__content__table__name">
                        {content}
                        {!!internalName && <span className="soldout-table__row__internal"> {internalName}</span>}
                    </span>
                );
            if (item.global !== undefined) {
                return (
                    <span className="soldout-items__content__table__name">
                        {item.global ? <LinkOutlined /> : <DisconnectOutlined />}
                        {content}
                        {!!internalName && <span className="soldout-table__row__internal">{internalName}</span>}
                    </span>
                );
            }
            return (
                <span>
                    {content}
                    {!!internalName && <span className="soldout-table__row__internal"> {internalName}</span>}
                </span>
            );
        },
    },
    {
        title: "Price",
        dataIndex: "price",
        key: "price",
        width: 100,
        render: (price: number) => (
            <LocationLocaleContext.Consumer>
                {(locale) => (
                    <span className="soldout-items__content__table__price">{locale.formatCurrency(price)}</span>
                )}
            </LocationLocaleContext.Consumer>
        ),
    },
    {
        title: "Category",
        dataIndex: "categories",
        key: "categories",
        render: (categories: string | string[]) => (
            <span
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                }}
            >
                {!!categories &&
                    (Array.isArray(categories) ? categories : categories.split(",")).map((category) => {
                        return <Tag key={category}>{category}</Tag>;
                    })}
            </span>
        ),
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        align: "right",
        className: "soldout-items__content__table__row__status",
        width: 190,
        render: update
            ? (status: Availability, item: ProductDetails) => (
                  <AvailabilityPicker
                      id={item.sku}
                      onAvailable={() => update(locationId!, item, Availability.AVAILABLE)}
                      onDisable={(sku: string, status: Availability) => update(locationId!, item, status)}
                      availability={null}
                      status={item.status}
                      availabilityText={getStatusDisplay(item.status || Availability.AVAILABLE)}
                      availabilityOptions={availabilityOptions}
                  />
              )
            : () => null,
    },
];
export const availabilityOptions: AvailabilityOptionProps[] = [
    { displayElement: "Available", status: Availability.AVAILABLE },
    { displayElement: "Sold out today", status: Availability.SOLD_OUT },
    { displayElement: "Unavailable", status: Availability.UNAVAILABLE },
];

export const ProductsTab = () => {
    const search = useSelector(getSearch);
    const products = useSelector(getFilteredProducts);
    const locationId = useSelector(getLocationId);
    const loadingProducts = useSelector(isLoadingProducts);
    const searchKeys = useSelector(getExpandedProductKeys);
    const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

    const dispatch = useDispatch<AppDispatch>();
    const trackEvent = useTrackEvent("SOLD_OUT/PRODUCT", {} as SoldOutTrackingData);
    const updateProductAvailability = useCallback(
        (locationId: string, item: any, status: Availability) => {
            dispatch(updateProduct(locationId, item, status));
            trackEvent({
                sku: item.sku,
                displayName: item.displayName,
                status,
                children: item.variants,
            });
        },
        [dispatch, trackEvent]
    );

    const updateSelectedKeys = (expanded: boolean, record: ProductDetails) => {
        setExpandedKeys(expanded ? [...expandedKeys, record.key!] : expandedKeys.filter((x) => x !== record.key));
    };

    useEffect(() => {
        !!search && setExpandedKeys(searchKeys);
    }, [searchKeys, search]);

    if (!locationId) return null;

    return (
        <Table
            className="soldout-items__content__table product_table"
            loading={loadingProducts}
            columns={productColumns(search!, locationId, updateProductAvailability)}
            dataSource={products}
            rowKey="key"
            tableLayout="auto"
            expandedRowKeys={expandedKeys}
            onExpand={updateSelectedKeys}
            expandRowByClick
            pagination={{
                hideOnSinglePage: true,
                pageSize: 15,
                onChange: () => {
                    const scrollElement = document.querySelector(".soldout-items__content .ant-tabs-tabpane-active");
                    if (scrollElement) scrollElement.scrollTo({ top: 0 });
                },
                showSizeChanger: false,
            }}
            showHeader={false}
            rowClassName={(item) =>
                item.isVariant ? "soldout-items__content__table__row-variant" : "soldout-items__content__table__row"
            }
        />
    );
};
