import classNames from "classnames";
import { TextModes, TextPreset } from "components/text";
import React, { ReactElement } from "react";
import { Text } from "../../../../components/text";

export interface Props {
    label: string;
    value: string | ReactElement;
    subText?: string;
    icon?: ReactElement;
    labelMode?: TextModes | TextModes[];
    valueMode?: TextModes | TextModes[];
    preset?: TextPreset;
    className?: string;
}

export interface LabelWithIconProps {
    label: string;
    icon?: ReactElement;
    labelMode?: TextModes | TextModes[];
    preset?: TextPreset;
}

export const OrderCardRow = (props: Props) => {
    const {
        label,
        value,
        subText,
        labelMode = ["bold", "block"],
        valueMode = "block",
        preset = "g-14",
        className,
        icon,
    } = props;
    return (
        <div className={classNames("details-card__detail-row", className)}>
            {subText ? (
                <span>
                    <LabelWithIcon label={label} labelMode={labelMode} preset={preset} icon={icon} />
                    <Text preset="g-12" className="details-card__order-item__subText" mode="block" value={subText} />
                </span>
            ) : (
                <LabelWithIcon label={label} labelMode={labelMode} preset={preset} icon={icon} />
            )}
            {typeof value === "string" ? <Text preset={preset} mode={valueMode} value={value} /> : value}
        </div>
    );
};

const LabelWithIcon = ({ label, labelMode, preset, icon }: LabelWithIconProps) => (
    <Text preset={preset} mode={labelMode}>
        {label}
        {icon && <span className="details-card__detail-row__icon">{icon}</span>}
    </Text>
);
