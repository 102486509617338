import "./TakeawayPage.scss";
import React from "react";

import classNames from "classnames";
import { useSelector } from "react-redux";
import { getOrderHubConnected } from "../../shared/selectors";
import { getActivePartyCounts } from "../selectors";

export const TakeawayPageHeader = () => {
    const connected = useSelector(getOrderHubConnected);
    const { total } = useSelector(getActivePartyCounts);

    return (
        <div className="takeaway__header">
            <div className="takeaway__header-left">
                <div className={classNames("takeaway__header-left--cnx-status", { offline: !connected })}>
                    {connected ? "Live" : "Offline"}
                </div>
                <div className="takeaway__header-left--active-orders">
                    Takeaway {total > 0 && <span className="count">{total}</span>}
                </div>
            </div>
        </div>
    );
};
