import { Region } from "./types/region";
import { opsApi } from "../../common/api";
import { getEnrolmentInfo } from "../enrolment/api/persistence";
import { getLegacyRegion } from "./utils/getLegacyRegion";
import { setHeader } from "../../common/api/util/headers";

const REGION_KEY = "MEANDU_REGION";

export type RegionChangedListener = (region: Region) => void;

export class RegionHelper {
    private _region: Region | null = null;
    private resolve = () => {};
    private regionPromise: Promise<void> | null = new Promise<void>((resolve) => (this.resolve = resolve));
    private regionChangedListeners: RegionChangedListener[] = [];
    private regionIdRegExp = new RegExp("^/([^/]+)/enrol/", "i");

    onRegionChanged(listener: RegionChangedListener) {
        this.regionChangedListeners.push(listener);
    }

    get region() {
        return this._region!;
    }

    init = async () => {
        let regionId = localStorage.getItem(REGION_KEY);

        const match = window.location.pathname.match(this.regionIdRegExp);
        if (match) {
            regionId = match[1];
        }

        if (!regionId && getEnrolmentInfo() !== null) {
            regionId = getLegacyRegion();
        }

        if (this._region && this._region.id === regionId) {
            return;
        }

        let init: RequestInit | undefined;

        if (regionId) {
            init = {
                headers: {
                    "x-meandu-region": regionId,
                },
            };
        }

        const response = await opsApi.fetch(`/region`, init);

        const region = (await response.json()) as Region;

        this._region = region;

        this.setLastRegion(region.id);

        for (const listener of this.regionChangedListeners) {
            listener(region);
        }

        this.resolve();
        this.regionPromise = null;
    };

    setLastRegion(regionId: string) {
        localStorage.setItem(REGION_KEY, regionId);
    }

    addRegionHeaders = async (init?: RequestInit) => {
        if (this.regionPromise) {
            await this.regionPromise;
        }

        init = init || {};

        setHeader(init, "x-meandu-region", this.region.id);

        return init;
    };
}
