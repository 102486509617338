import { Drawer } from "antd";
import classNames from "classnames";
import React from "react";
import { BumpActiveParty } from "../../shared/types";
import { TakeawayOrderCard } from "./TakeawayOrderCard";
import "./TakeawayOrderDrawer.scss";

export interface Props {
    party?: BumpActiveParty;
    className?: string;
    onClose: () => void;
    visible: boolean;
}

export const TakeawayOrderDrawer = ({ party, className, onClose, visible }: Props) => {
    return (
        <Drawer open={visible} className={classNames("takeaway-order-modal", className)} width={420} onClose={onClose}>
            <TakeawayOrderCard party={party} onClose={onClose} />
        </Drawer>
    );
};
