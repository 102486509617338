export interface LocationSummary {
    id: string;
    displayName: string;
    authInfo: AuthInfo;
    isBuzzer: boolean;
    locale: string;
    currency: string;
    timeZone: string;
    isLiveOrdersEnabled: boolean;
    showDisableCategory: boolean;
    supportedOrderFlows: SupportedOrderFlows[];
    enableRefunds: boolean;
    enableResubmitOrders: boolean;
    enableWaitTimes: boolean;
    posOperations: PosOperation[];
    takeawayOptions: TakeawayOptions;
    manageMigrationUrl: string | null;
    migrationSlug: string | null;
}

export interface AuthInfo {
    permissions: string[];
    userRoles?: string[];
}

export interface PosOperation {
    name: string;
    supported: boolean;
}

export interface TakeawayOptions {
    enabled: boolean;
    sectionId?: string;
}

export enum SupportedOrderFlows {
    PAYANDORDER = "PAYANDORDER",
    PAYONLY = "PAYONLY",
    OPENTABLE = "OPENTABLE",
}
