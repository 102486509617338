import { LocationSummary } from "../types/LocationSummary";
import { Loaded } from "common/loader";

export type State = Loaded<LocationSummary, LocationCommonState>;

export type LocationCommonState = { locationId: string | null };

const initialState: State = {
    status: "unloaded",
    locationId: null,
};

export enum TypeKeys {
    IDENTIFIED = "LOCATION/IDENTIFIED",
    LOADING = "LOCATION/LOADING",
    LOADED = "LOCATION/LOADED",
    FAILED = "LOCATION/LOAD_FAILED",
}

export const actionCreators = {
    identify: (locationId: string) => ({ type: TypeKeys.IDENTIFIED, locationId }),
    loading: () => ({ type: TypeKeys.LOADING }),
    loaded: (data: LocationSummary) => ({ type: TypeKeys.LOADED, data }),
    failed: (e: any) => ({ type: TypeKeys.FAILED, e }),
};

type LocationIdentifiedAction = { type: TypeKeys.IDENTIFIED; locationId: string };
type LocationLoadingAction = { type: TypeKeys.LOADING };
type LocationLoadedAction = { type: TypeKeys.LOADED; data: LocationSummary };
type LocationFailedAction = { type: TypeKeys.FAILED; error: any };

export type LocationAction =
    | LocationLoadingAction
    | LocationLoadedAction
    | LocationFailedAction
    | LocationIdentifiedAction;

export function reducer(state: State = initialState, action: LocationAction): State {
    const { locationId } = state;

    if (action.type === TypeKeys.IDENTIFIED) {
        return {
            status: "unloaded",
            locationId: action.locationId,
        };
    }

    if (action.type === TypeKeys.LOADING) {
        return {
            status: "loading",
            locationId,
        };
    }

    if (action.type === TypeKeys.LOADED) {
        const { data } = action;
        return {
            status: "loaded",
            data,
            locationId: data.id,
        };
    }

    if (action.type === TypeKeys.FAILED) {
        const { error } = action;
        return {
            status: "failed",
            error,
            locationId,
        };
    }

    return state;
}
