import { ServerOrderApi } from "common/api/orderApi";
import { OrderItem, OrderStatus, ActiveParty } from "common/types";
import { parseDate } from "common/utility";
import { AppDispatch, AppMiddleware } from "features/index";
import { NavType } from "features/nav/types";
import { isTakeawayEnabled } from "features/nav/selectors";
import { OrderHubStatusActions, StatusTypeKeys } from "features/orderHub/reducers/orderHubConnection";
import { mapModifiers } from "features/orders/utils";
import { createAction } from "../reducers";
import {
    BumpScreenActivePartyChangeOrderScheduledTimeAction,
    BumpScreenActivePartyCancelAndRefundAction,
    BumpScreenActivePartyChangeOrderStatusAction,
    TypeKeys,
} from "../reducers/bumpScreen";
import { BumpActiveParty } from "../types";

let orderHub: ServerOrderApi | null = null;

export const openPartiesMiddleware = () => (store: AppMiddleware) => (next: AppDispatch) => {
    return async (
        action:
            | OrderHubStatusActions
            | BumpScreenActivePartyChangeOrderStatusAction
            | BumpScreenActivePartyChangeOrderScheduledTimeAction
            | BumpScreenActivePartyCancelAndRefundAction
    ) => {
        next(action);

        if (action.type === StatusTypeKeys.INITIALIZED) {
            orderHub = action.api;
            orderHub.addMessageHandler("partyUpdated", onPartyUpdated);
            orderHub.addMessageHandler("partyClosed", onPartyClosed);
            return;
        }

        if (action.type === StatusTypeKeys.CONNECTED && orderHub !== null) {
            if (!supportsOpenParties()) return;
            const parties = await orderHub.invoke<ActiveParty[]>("getPendingCloseParties");
            const activeParties = parties.map(toBumpActiveParty).filter((p) => p !== null) as BumpActiveParty[];
            next(createAction.activePartiesLoaded(activeParties));
            return;
        }

        if (action.type === TypeKeys.ACTIVE_PARTY_CHANGE_ORDER_SCHEDULED_TIME) {
            const { party, dateScheduled } = action;
            try {
                await orderHub!.invoke("scheduleOrderForParty", party.id, { scheduleAt: dateScheduled });
            } catch (err) {
                next(createAction.activePartyUpdated(party));
            }
        }

        if (action.type === TypeKeys.ACTIVE_PARTY_CHANGE_ORDER_STATUS) {
            let { partyId, orderIds } = action;
            orderIds = orderIds && !Array.isArray(orderIds) ? [orderIds] : orderIds;

            const {
                bumpScreen: { activeParties },
            } = store.getState();

            const party = activeParties.find((p) => p.id === partyId);
            if (!party) return;

            try {
                if (party.status === "preparing") {
                    await orderHub!.invoke("ordersReady", partyId, orderIds);
                } else if (party.status === "ready") {
                    await orderHub!.invoke("ordersCollected", partyId, orderIds);
                }
            } catch (err) {
                party.statusChanging = false;
                next(createAction.activePartyUpdated(party));
            }
        }

        if (action.type === TypeKeys.ACTIVE_PARTY_CANCEL_AND_REFUND) {
            let { party, orderIds } = action;
            orderIds = orderIds && !Array.isArray(orderIds) ? [orderIds] : orderIds;

            try {
                await orderHub!.invoke("cancelAndRefundOrders", party.id, { orderIds });
            } catch (err) {
                next(createAction.activePartyUpdated(party));
            }
        }

        function onPartyUpdated(party: ActiveParty) {
            if (!supportsOpenParties()) return;

            const activeParty = toBumpActiveParty(party);

            if (activeParty === null) {
                // Just in case something changed that no longer makes this valid for the bump screen
                next(createAction.activePartyClosed(party));
            } else {
                next(createAction.activePartyUpdated(activeParty));
            }
        }

        function onPartyClosed(party: ActiveParty) {
            if (!supportsOpenParties()) return;
            next(createAction.activePartyClosed(party));
        }

        function supportsOpenParties() {
            const state = store.getState();
            const navType = state.nav.navType;
            return navType === NavType.BUMP_SCREEN_BUZZER || isTakeawayEnabled(state);
        }
    };
};

export const toBumpActiveParty = (party: ActiveParty): BumpActiveParty | null => {
    if (!party.deliveryOptions || !party.submittedOrders) return null;

    const pickupMenuItemTypes = party.deliveryOptions
        .filter((d) => d.type === "pickup" || d.type === "takeaway")
        .map((d) => d.menuItemType);

    if (!pickupMenuItemTypes.length) return null;

    const ordersToPickup = party.submittedOrders.filter(
        (o) =>
            isActiveOrderStatus(o.status) &&
            o.items.some((i) => i.menuItemType && pickupMenuItemTypes.indexOf(i.menuItemType) >= 0)
    );

    if (!ordersToPickup.length) return null;

    const [{ status, orderStatusHistory, dateSubmitted, dateScheduled, originalDateScheduled }] = ordersToPickup;

    const statusChange = orderStatusHistory.find(
        (h) => h.to === (isPreparingStatus(status) ? OrderStatus.ACCEPTED : OrderStatus.READY)
    );

    for (let i = 0; i < ordersToPickup.length; i++) {
        ordersToPickup[i].items = ordersToPickup[i].items.map((orderItem: OrderItem) => ({
            ...orderItem,
            mappedModifiers: orderItem.modifiers && mapModifiers(orderItem.modifiers),
        }));
    }
    return {
        ...party,
        submittedOrders: ordersToPickup,
        status: isPreparingStatus(status) ? "preparing" : "ready",
        overdue: isOverdueStatus(status),
        dateStatusChanged: parseDate(statusChange && statusChange.timestamp) || Date.now(),
        dateSubmitted: parseDate(dateSubmitted),
        dateScheduled: parseDate(dateScheduled),
        originalDateScheduled: parseDate(originalDateScheduled),
    } as BumpActiveParty;
};

const isActiveOrderStatus = (status: OrderStatus) =>
    status === OrderStatus.ACCEPTED || status === OrderStatus.READY || isOverdueStatus(status);

const isOverdueStatus = (status: OrderStatus) =>
    status === OrderStatus.ACCEPTEDOVERDUE || status === OrderStatus.READYOVERDUE;

const isPreparingStatus = (status: OrderStatus) =>
    status === OrderStatus.ACCEPTED || status === OrderStatus.ACCEPTEDOVERDUE;
