import React, { useCallback, useEffect, useRef, useState } from "react";
import "./OrderCard.scss";
import { formatTime } from "../../../../common/utility";
import { AppDispatch } from "../../../index";
import { ActivePartyStatus } from "../../shared/types";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { createAction } from "../../shared/reducers";
import { OrderBase, OrderItem } from "common/types";

export interface Props {
    partyId: string;
    memberDetails: React.ReactNode | string | null;
    tableNumber: string;
    sectionName: string | null;
    submittedOrders: OrderBase[];
    dateStatusChanged?: number;
    dateClosed?: number;
    status?: ActivePartyStatus;
    overdue?: boolean;
    buttonAction?: () => void;
    connected?: boolean;
}

type OrderCardState = "Initial" | "OnScreen" | "StatusWillChange" | "StatusChanging" | "Closing";

export const OrderCard = ({
    partyId,
    memberDetails,
    tableNumber,
    sectionName,
    submittedOrders,
    dateStatusChanged,
    dateClosed,
    status,
    overdue,
    buttonAction,
    connected,
}: Props) => {
    // Everything for V1 revolves around a single pickup order.
    const [{ id: orderId }] = submittedOrders;

    const [state, setState] = useState(status ? "Initial" : ("OnScreen" as OrderCardState));

    const dispatch = useDispatch<AppDispatch>();

    const setActivePartyOrderStatusChanging = useCallback(
        () => orderId && dispatch(createAction.changeOrderStatus(partyId, orderId)),
        [dispatch, partyId, orderId]
    );

    const sendOnUnmount = useCallback(() => {
        if (state === "StatusWillChange") {
            setActivePartyOrderStatusChanging();
        }
    }, [state, setActivePartyOrderStatusChanging]);

    const sendOnUnmountRef = useRef(sendOnUnmount);

    useEffect(() => {
        const timeout = setTimeout(() => setState("OnScreen"), 0);
        const sendOnUnmount = () => sendOnUnmountRef.current();

        return () => {
            clearTimeout(timeout);
            sendOnUnmount();
        };
    }, []);

    useEffect(() => {
        sendOnUnmountRef.current = sendOnUnmount;

        let timeout: number | undefined;

        switch (state) {
            case "StatusWillChange":
                timeout = window.setTimeout(() => {
                    timeout = undefined;
                    setState("StatusChanging");
                }, 5000);
                break;
            case "StatusChanging":
                timeout = window.setTimeout(() => {
                    timeout = undefined;
                    setActivePartyOrderStatusChanging();
                }, 300);
                break;
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout);
                if (state === "StatusChanging") {
                    setActivePartyOrderStatusChanging();
                }
            }
        };
    }, [state, setActivePartyOrderStatusChanging, sendOnUnmount]);

    const onButtonClick = (event: React.MouseEvent | React.TouchEvent) => {
        event.stopPropagation();
        if (status && connected) {
            setState("StatusWillChange");
        }
        if (buttonAction) {
            setState("Closing");
            window.setTimeout(() => buttonAction(), 200);
        }
    };

    const onOrderCardClick = () => setState("OnScreen");

    const onActionImmediatelyClick = (event: React.MouseEvent | React.TouchEvent) => {
        event.stopPropagation();
        setState("StatusChanging");
    };

    const onTouchEnd = (event: React.TouchEvent) => event.preventDefault();

    const getButtonText = () => {
        switch (status) {
            case "preparing":
                return "READY";
            case "ready":
                return "COLLECTED";
            default:
                return "CLOSE";
        }
    };

    const getOrderTime = () => (!dateClosed || !dateStatusChanged ? "" : formatTime(dateClosed - dateStatusChanged));

    return (
        <div
            onTouchStart={onOrderCardClick}
            onClick={onOrderCardClick}
            onTouchEnd={onTouchEnd}
            data-party-id={partyId}
            className={classNames(
                "order-card",
                { "order-card--ready": connected && status === "ready" },
                { "order-card--preparing": connected && status === "preparing" },
                { "order-card--closing": state === "Closing" },
                { "order-card--on-screen": state !== "Initial" },
                { "order-card--status-will-change": state === "StatusWillChange" || state === "StatusChanging" },
                { "order-card--status-changing": state === "StatusChanging" },
                { "order-card--overdue": overdue }
            )}
        >
            {status && (
                <div className="order-card__undo">
                    <button
                        className="order-card__undo__action-immediately"
                        onTouchStart={onActionImmediatelyClick}
                        onClick={onActionImmediatelyClick}
                        onTouchEnd={onTouchEnd}
                    >
                        SET {getButtonText()}
                    </button>
                    <svg width="24" height="30" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M24 12.0007L12 0L0 12.0007L3.1815 15.1823L9.75075 8.613V30H14.2507V8.6145L20.8185 15.1823L24 12.0007Z"
                            fill="white"
                        />
                    </svg>
                    Pull up to undo
                </div>
            )}
            <div className="order-card__header">
                <div className="order-card__header__top">
                    <div className="order-card__name">{memberDetails}</div>
                    <div className="order-card__prep-time" data-time-submitted={dateStatusChanged}>
                        {getOrderTime()}
                    </div>
                </div>
                <div className="order-card__header__bottom">
                    <div className="order-card__table-number">{tableNumber}</div>
                    {sectionName && <div className="order-card__section-name">{sectionName}</div>}
                </div>
            </div>
            <div className="order-card__orders">{submittedOrders.map(renderOrder)}</div>
            <div className="order-card__footer">
                <button
                    className="order-card__update-status-button"
                    onTouchStart={onButtonClick}
                    onClick={onButtonClick}
                    onTouchEnd={onTouchEnd}
                >
                    {getButtonText()}
                </button>
            </div>
        </div>
    );
};

const renderOrder = ({ id, items }: OrderBase, index: number) => (
    <div key={id || `order-${index}`} className="order-card__order">
        {items.map(renderOrderItem)}
    </div>
);

const renderOrderItem = ({ id, quantity, displayName, mappedModifiers, notes, memberNotes }: OrderItem) => (
    <div key={id} className="order-card__order-item">
        <div className="order-card__order-item__qty">{quantity} x</div>
        <div className="order-card__order-item__details">
            <div className="order-card__order-item__name">{displayName}</div>
            {!!mappedModifiers && !!mappedModifiers.length && (
                <div className="order-card__order-item__modifiers">
                    {mappedModifiers.map((m, i) => (
                        <div key={`item_modifier_${i}_${m.displayName}_${m.optionIndex}`}>
                            <div key={`modifier_${i}_${m.displayName}`} className="order-card__order-item__modifier">
                                {m.displayName}
                            </div>
                            {m.nestedModifiers &&
                                m.nestedModifiers.map((m) => (
                                    <div
                                        key={`nested_modifier_${i}_${m.displayName}`}
                                        className="order-card__order-item__modifier"
                                    >
                                        {"- ".repeat(m.nestingLevel || 1)}
                                        {m.displayName}
                                    </div>
                                ))}
                        </div>
                    ))}
                </div>
            )}
            {(!!notes || !!memberNotes) && (
                <div className="order-card__order-item__modifiers">
                    <div className="order-card__order-item__modifier">Note: {notes || memberNotes}</div>
                </div>
            )}
        </div>
    </div>
);
