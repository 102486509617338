import { Order } from ".";

export type OrderRefundTrackingData = {
    partyId: string;
    refundAmount: number;
    wasSuccessful: boolean;
    partyType: string;
};

export const getPartyTypeForTracking = (order: Order | undefined): string => {
    return order?.partyType ?? "";
};
