import { PartyType } from "common/types/party";

export const partyTypeMap = {
    MultiUser: PartyType.MULTIUSER,
    SingleUser: PartyType.SINGLEUSER,
    PayOnly: PartyType.PAYONLY,
};

export const getGroupTabFields = `
        id
        type
        tabName
        limit
        packageName
        locationId
        owner
        ownerName
        creditsTotal
        locale
        currency
        dateOpened
        dateClosed
        status
        members {
            memberId
            phone
            firstName
            lastName
        }
        orders {
            id
            partyId
            memberId
            amount
            dateCreated
        }
        paymentTotals {
            processingFee
            totalCharged
            totalRefunded
            total
        }
`;

const itemsQuery = `
    items {
        displayName
        variantName
        quantity
        modifiers {
            displayName
            nestedModifiers {
                displayName
                nestingLevel
            }
        }
        memberNotes
        price
        lineTotal
        priceIncludingTaxes
},`;

const baseOrderQuery = `
    id
    posReferenceId
    isMerged
    confirmationStatus
    orderSubmittedCount
    dateSubmitted
    orderTotal
    orderSubmittedCount
    ${itemsQuery}
`;

export const getPartyFields = `
        id      
        members {
            id
            displayName
            phoneNumber
            hasSecuredPayment
            payments {
                paymentMethod
                paymentMethodId
                status
            }
        }
        tableNumber
        closeReason
        dateOpened
        dateClosed
        isTakeaway
        dateSubmitted
        originalDateScheduled
        dateScheduled
        dateCollected
        sortDate
        type
        orderWithPayment {
            ${baseOrderQuery}
            posTotal
            subtotal
            totalIncludingTaxes
            unconfirmedOrders{
                orderId
            }
            promotions {
                name
                amount
            }
            cepOffers {
                name
                amount
            }
            unconfirmedOrders {
                orderId
            }
            discount
            membershipDiscount
            trayCharges {
                name
                amount
            }
            surcharge {
                percentage
                total
            }
            venueCauses {
                name
                amount
            }
            stampsEarned
        }
        taxTotals {
            taxClass
            total
            exclusive
        }
        ordersWithoutPayment {
            ${baseOrderQuery}
        }
        payments {
            id
            memberId
            status
            posStatus
            transactionId
            subtotal
            tip
            gratuity
            date
            membershipDiscount
            surchargeAmount
            venueServiceFeeAmount
            venueServiceFeePercentage
            processingFee
            transactionTotal
            processingFeeAbsorbed
            isForceCharge
            error {
                code
            }
        }
        refunds {
            paymentId
            refundId
            transactionId
            date
            transactionTotal
            status
        }
        sectionName
        paymentsAmount
        paymentsTotal
        pointsPaymentsTotal
        tipsTotal
        gratuityTotal
        processingFeeTotal
        refundableTotal
        refundablePaymentId
        refundedTotal
        securedPaymentState
        status
        isFlexTab
`;
