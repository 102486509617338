import { Loaded } from "common/loader";
import { BaseAvailabilityUpdateInput } from ".";
import { Availability } from "./availability";

export type DisableCategories = {
    categories: Loaded<CategoryDetails[]>;
};

export type CategoryTypes = "food" | "drink";
export type CategoryTypesDisplay = "Food" | "Drinks";

export type CategoryDetails = {
    id: string;
    displayName: string;
    internalName: string;
    availability: number | null;
    waitTime?: number | null;
    inServiceNow: boolean;
    inServiceToday: boolean;
    type: CategoryTypes;
};

export type EditCategoryAvailabilityInfo = Pick<CategoryDetails, "id" | "displayName" | "waitTime" | "availability">;

export type CategoryDetailsRes = CategoryDetails & {
    availability: string | null;
};

export type CategoryDisplay = {
    inServiceNow: CategoryDetails[];
    inServiceToday: CategoryDetails[];
};

export type CategoryAvailabilityUpdateInput = BaseAvailabilityUpdateInput & {
    categoryId: string;
};

export type CategoryAvailabilityChange = {
    type: CategoryTypesDisplay;
    all: boolean;
    status: Availability;
    waitTime?: number | undefined;
    duration?: number | undefined;
    singleCategory: boolean;
};

export type CategoryAvailabilityChangeHandler = (payload: CategoryAvailabilityChange) => void;

export enum CategoryDialogMode {
    ALL_AVAILABILITY = "ALL_AVAILABILITY",
    ALL_WAIT_TIMES = "ALL_WAIT_TIMES",
    SINGLE_CATEGORY = "SINGLE_CATEGORY",
}

export enum AvailabilityStatus {
    AVAILABLE = "AVAILABLE",
    WAIT_TIME = "WAIT_TIME",
    UNAVAILABLE = "UNAVAILABLE",
}

export interface WaitTimeOption {
    value: number;
    label: string;
    duration: number;
}

export interface StatusIndicatorProps {
    onClick: () => void;
    isSelected: boolean;
}

export function getDurationOptions() {
    return baseDurationOptions;
}

export const baseDurationOptions = [
    {
        value: 1,
        duration: 15,
        label: "15 min",
        status: Availability.FIXED_TIME,
    },
    {
        value: 2,
        duration: 30,
        label: "30 min",
        status: Availability.FIXED_TIME,
    },
    {
        value: 3,
        duration: 45,
        label: "45 min",
        status: Availability.FIXED_TIME,
    },
    {
        value: 4,
        duration: 60,
        label: "60 min",
        status: Availability.FIXED_TIME,
    },
    {
        value: 5,
        duration: 90,
        label: "90 min",
        status: Availability.FIXED_TIME,
    },
    {
        value: 6,
        duration: undefined,
        label: "Today",
        status: Availability.SOLD_OUT,
    },
];

export const waitTimeOptions: WaitTimeOption[] = [
    {
        value: 0,
        duration: 0,
        label: "None",
    },
    {
        value: 1,
        duration: 10,
        label: "10 min",
    },
    {
        value: 2,
        duration: 15,
        label: "15 min",
    },
    {
        value: 3,
        duration: 20,
        label: "20 min",
    },
    {
        value: 4,
        duration: 25,
        label: "25 min",
    },
    {
        value: 5,
        duration: 30,
        label: "30 min",
    },
    {
        value: 6,
        duration: 35,
        label: "35 min",
    },
    {
        value: 7,
        duration: 40,
        label: "40 min",
    },
    {
        value: 8,
        duration: 45,
        label: "45 min",
    },
    {
        value: 9,
        duration: 60,
        label: "60 min",
    },
    {
        value: 10,
        duration: 90,
        label: "90 min",
    },
];

export const extendedDurationOptions = [
    ...baseDurationOptions,
    {
        value: 7,
        duration: undefined,
        label: "Never",
        status: Availability.UNAVAILABLE,
    },
];
