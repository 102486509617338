import { opsApi } from "common/api";
import { fetchAsStaff } from "features/staffLogin/selectors/getAccessToken";
import { Section, Table } from "../types";

export async function fetchSections(locationId: string): Promise<Section[]> {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { locationId }, fetchAsStaff(false));
    return res.data.sections.map((section) => ({
        ...section,
        tables: section.tables ? [...section.tables].sort((a: Table, b: Table) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())) : undefined
    }));
}

type GraphQLPayload = { sections: Section[] };

export const tableQuery = `
    id,
    name,
    availabilityStatus,
    statusExpiryUtc
`;
export const sectionQuery = `
    id
    displayName
    availability
    availabilityDate
    sectionBehaviour
    deliveryOptions {
        type
        menuItemType
    }
    tables {
        ${tableQuery}
    }
    orderFlow
`;

const query: string = `query ($locationId: ID!) {
    sections(locationId: $locationId) {
        ${sectionQuery}
    }
}`;
