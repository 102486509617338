import { opsApi } from "common/api";
import { SalesSummary } from "../types";
import { fetchAsStaff } from "features/staffLogin/selectors/getAccessToken";

export async function fetchSalesSummary(locationId: string, date: string, today: boolean): Promise<SalesSummary> {
    const query = getQuery(today);
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { locationId, date }, fetchAsStaff(false));
    let salesSummary = res.data.salesSummary;

    salesSummary = {
        ...salesSummary,
        tipPercentageFactor:
            salesSummary.tips > 0 && salesSummary.posTotalValue > 0
                ? salesSummary.tips / salesSummary.posTotalValue
                : 0,
    };

    return salesSummary;
}

type GraphQLPayload = { salesSummary: SalesSummary };

const getQuery = (today: boolean) => `query ($locationId: ID!, $date: String!) {
    salesSummary(locationId: $locationId, diningDate: $date) {
        posTotalValue,
        tips,
        orderCount,
        drinksPercentage,
        foodPercentage,
        averageTransactionValue,
        orderHeadCount,
        uniqueUsers${
            !today
                ? ""
                : `,
        activeOrderCount`
        }
    }
}`;
