import { createSelector } from "reselect";
import { PosOperation } from "../types/LocationSummary";
import { getActiveLocation } from "./getLocationLocale";

export const isRefundsEnabled = createSelector(getActiveLocation, (location) => !!location?.enableRefunds);

export const isResubmitOrdersEnabled = createSelector(
    getActiveLocation,
    (location) => !!location?.enableResubmitOrders
);

export const getLocationPosOperations = createSelector(getActiveLocation, (location) => location?.posOperations);

export const getLocationPosSupportsCatalogueAvailabilityEvent = createSelector(getLocationPosOperations, (operations) =>
    operationIsSupported("CatalogueAvailabilityEvent", operations)
);

export const getLocationPosSupportsPosMenu = createSelector(getLocationPosOperations, (operations) =>
    operationIsSupported("PosMenu", operations)
);

function operationIsSupported(operationToFind: string, operations?: PosOperation[]) {
    const operation = operations?.find((x) => x.name === operationToFind);

    return !!operation?.supported;
}
