import { DeviceEnrolmentResult } from "../reducers/enrolment";

const KEY = "MEANDU_DEVICE";
const LOCATION_ID_KEY = "MEANDU_LOCATION_ID";

export function getEnrolmentInfo(): DeviceEnrolmentResult | null {
    const rawValue = window.localStorage.getItem(KEY);

    if (!rawValue) {
        return null;
    }

    try {
        const result = JSON.parse(rawValue) as DeviceEnrolmentResult;

        return result;
    } catch (e) {
        return null;
    }
}

export function saveEnrolmentInfo(result: DeviceEnrolmentResult) {
    window.localStorage.setItem(KEY, JSON.stringify(result));
    setLastLocationId(result.location_id);
}

export function clearEnrolmentInfo() {
    window.localStorage.removeItem(KEY);
}

export function setLastLocationId(locationId: string) {
    return window.localStorage.setItem(LOCATION_ID_KEY, locationId);
}

export function getLastLocationId(): string | null {
    return window.localStorage.getItem(LOCATION_ID_KEY);
}
