import { opsApi } from "common/api";
import { fetchAsStaff } from "../../staffLogin/selectors/getAccessToken";
import { GroupTab, GroupTabsData } from "../types";
import { toGroupTabsData } from "../utils";
import { getGroupTabFields } from "./commonGraphFields";

type GraphQLPayload = { closeGroupTab: GroupTab };
type GroupTabResponse = { groupTab: GroupTabsData };

const closeGroupTabQuery = `mutation($groupTabId: ID!, $locationId: ID!) {
    closeGroupTab(groupTabId: $groupTabId, locationId: $locationId){
        ${getGroupTabFields}
    }
}`;

export async function closeGroupTabApi(groupTabId: string, locationId: string): Promise<GroupTabResponse> {
    try {
        const res = await opsApi.graphQLQuery<GraphQLPayload>(
            closeGroupTabQuery,
            { locationId, groupTabId },
            fetchAsStaff(true)
        );
        var groupTabArray = [res.data.closeGroupTab];
        const groupTab = toGroupTabsData(groupTabArray);
        return { groupTab: groupTab[0] };
    } catch (e) {
        throw e;
    }
}
