import React from "react";
import { OrderCardList } from "./OrderCardList";
import { BumpActiveParty, BumpScreenType } from "../../shared/types";
import { NoOrders } from "./NoOrders";
import { Section } from "features/floorManager/types";

export interface Props {
    parties: BumpActiveParty[];
    connected: boolean;
    mode: BumpScreenType;
    sections: Section[];
}

export const ActivePartiesContent = ({ parties, connected, mode, sections }: Props) =>
    parties.length ? (
        <OrderCardList sections={sections} parties={parties} connected={connected} mode={mode} />
    ) : (
        <NoOrders />
    );
