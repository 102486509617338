import { AppDispatch, AppState } from "features";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "antd";
import { actionCreators } from "../reducers";
import { GroupTabSearchOptions } from "../types";
import { Text } from "../../../components/text";
import classNames from "classnames";
import { Moment } from "moment";
import "../assets/GroupTabs.scss";
import { ReloadData } from "common/loader/ReloadData";
import { GroupTabsTable } from "./VirtualiseGroupTabsTable";
import { fetchGroupTabs } from "../actions/groupTabs";
import { groupTabsFilterKey } from "../reducers/orders";
import { getOrdersState, getDisplayGroupTabFilter } from "../selectors";
import { FetchOptions, PAGE_SIZE } from "./HistoryOrders";

interface Props {
    today: Moment;
    date: Moment;
    setDate: (date: Moment) => void;
}

export const GroupTabs = ({ date, setDate, today }: Props) => {
    const dispatch = useDispatch<AppDispatch>();
    const { groupTabsFilter, groupTabsPage } = useSelector(getOrdersState);
    const { staffLogin } = useSelector((state: AppState) => state);
    const locationId = staffLogin.status === "authenticated" ? staffLogin.locationId : undefined;
    const displayGroupTabFilters = useSelector(getDisplayGroupTabFilter);

    const [search, setSearch] = useState("");
    const scrollRef = useRef<HTMLElement | Window | null>();

    const onUnload = useCallback(() => {
        if (groupTabsFilter && groupTabsFilter.length) {
            sessionStorage.setItem(groupTabsFilterKey, JSON.stringify(groupTabsFilter));
        } else {
            sessionStorage.removeItem(groupTabsFilterKey);
        }
    }, [groupTabsFilter]);

    useEffect(() => {
        window.addEventListener("unload", onUnload);
        return () => {
            window.removeEventListener("unload", onUnload);
        };
    }, [onUnload]);

    const clearFilter = useCallback(() => {
        dispatch(actionCreators.selectGroupTabsFilter([]));
    }, [dispatch]);

    useEffect(() => {
        return () => {
            clearFilter();
        };
    }, [clearFilter]);

    const setPage = useCallback(
        (page: number) => {
            dispatch(actionCreators.selectGroupTabsPage(page));
        },
        [dispatch]
    );

    useEffect(() => {
        return () => {
            setPage(1);
        };
    }, [setPage]);

    useEffect(() => {
        return () => {
            setDate(today);
        };
    }, [setDate, today]);

    const fetch = useCallback(
        (options?: FetchOptions) => {
            const searchOptions: GroupTabSearchOptions = {
                page: groupTabsPage,
                diningDate: date.format("YYYY-MM-DD"),
                statusFilters: groupTabsFilter,
                ownerNameSearch: search || null,
                pageSize: PAGE_SIZE,
                activeTabs: true,
            };

            locationId && dispatch(fetchGroupTabs(locationId, searchOptions, options?.forceReLoad, options?.loadMore));
        },
        [groupTabsFilter, groupTabsPage, search, locationId, date, dispatch]
    );

    useEffect(() => {
        fetch();
    }, [fetch]);

    return (
        <div className="groupTabs">
            <Input.Search
                allowClear
                onBlur={(e) => {
                    setPage(1);
                    setSearch(e.target.value.trim());
                }}
                onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                        e.preventDefault();
                        setPage(1);
                    }
                }}
                onSearch={setSearch}
                placeholder="Search Group Tab owner name"
            />
            <div
                className={classNames("groupTabs__filter-message", !!groupTabsFilter.length && "show")}
                onClick={() => {
                    setPage(1);
                    clearFilter();
                }}
            >
                <Text preset="g-14">
                    {" "}
                    Showing only {displayGroupTabFilters} Group Tabs.&nbsp;
                    <Text preset="g-14" mode="bold">
                        {" "}
                        Clear filter
                    </Text>
                </Text>
            </div>
            <div className="groupTabs__table">
                <GroupTabsTable fetch={fetch} scrollRef={scrollRef} />
            </div>
            <ReloadData
                fetch={() => {
                    scrollRef.current?.scrollTo({ top: 0, behavior: "smooth" });

                    setPage(1);
                    fetch();
                }}
            />
        </div>
    );
};
