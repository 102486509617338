import { LocationLocaleContext } from "features/location/context/LocationLocaleContext";
import { Party } from "../../types";
import React, { useContext, useMemo } from "react";
import { RowData, VirtualTableRowContent } from "../virtualTable/VirtualTableRowContent";
import { getPartyOrderFlow } from "features/orders/utils";
import { PartyType } from "common/types";

interface PartyRowProps {
    party: Party;
}
export const PartyRowContent = ({ party }: PartyRowProps) => {
    const locationLocale = useContext(LocationLocaleContext);
    const rowsData: RowData[] = useMemo(
        () => [
            { value: party.displayId, key: "displayId" },
            {
                value: getPartyOrderFlow(party.partyType ?? PartyType.SINGLEUSER, party.isFlexTab),
                key: "orderFlow",
            },
            { value: party.table, key: "table" },
            { value: party.section, key: "section" },
            {
                value: !party.totalPaymentsTotal ? "—" : locationLocale.formatCurrency(party.totalPaid),
                className: "virtual-table__cell--align-right",
                key: "totalPaymentsAmount",
            },
            { value: !party.dateClosed ? "—" : locationLocale.formatTime(party.dateClosed), key: "dateClosed" },
            { value: party.status, key: "status" },
        ],
        [party, locationLocale]
    );
    return <VirtualTableRowContent rows={rowsData} />;
};
