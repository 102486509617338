import { FeatureConfiguration } from "..";
import { reducer, disableCategoriesReducer } from "./reducers";
import { Route } from "react-router";
import * as React from "react";
import { SoldOutPage } from "./components/SoldOutPage";

export default function (config: FeatureConfiguration) {
    config.reducers.soldOut = reducer;
    config.reducers.disableCategory = disableCategoriesReducer;
    config.addRoutes(config.routes.locationRoutes, [
        <Route key="sold-out" path="/sold-out" render={() => <SoldOutPage />} />,
    ]);
}
