import {
    BaseTelemetryPlugin,
    IAppInsightsCore,
    IConfiguration,
    IPlugin,
    IProcessTelemetryContext,
    ITelemetryItem,
    ITelemetryPluginChain,
} from "@microsoft/applicationinsights-web";
import { AppState } from "features";

export class StateEnrichDataPlugin extends BaseTelemetryPlugin {
    public identifier = "MeanduStateEnrichDataPlugin";

    private getState: (() => AppState) | null = null;

    initialize(
        config: IConfiguration,
        core: IAppInsightsCore,
        extensions: IPlugin[],
        pluginChain?: ITelemetryPluginChain
    ) {
        super.initialize(config, core, extensions, pluginChain);

        if (config.extensionConfig) {
            this.getState = config.extensionConfig[this.identifier]["getState"];
        }
    }

    processTelemetry(event: ITelemetryItem, itemCtx?: IProcessTelemetryContext) {
        if (this.getState) {
            const state = this.getState();

            if (!!state.location) {
                event.data = {
                    ...event.data,
                    ...{
                        "member.authenticated": state.staffLogin.status === "authenticated",
                        "staff.name": state.staffLogin.status === "authenticated" ? state.staffLogin.name : "",
                        "location.id": state.location.locationId,
                    },
                };
            }
        }

        this.processNext(event, itemCtx!);
    }
}
