import React from "react";
import { Modal } from "antd";
import { LocationLocaleContext } from "features/location/context/LocationLocaleContext";
import { LocationLocale } from "features/location/types/LocationLocale";
import { StaffLoginContainer } from "../containers/StaffLoginContainer";
import { ActiveHubConnection } from "features/orderHub/containers/ActiveHubConnection";
import "./StaffLogin.scss";

export interface Props {
    children: () => React.ReactNode;
    isLoggedIn: boolean;
    locationLocale: LocationLocale;
}

export const RequireStaffLogin = ({ isLoggedIn, locationLocale, children }: Props) => {
    return (
        <>
            <Modal closable={false} className="staff-login-modal" open={!isLoggedIn} footer={null}>
                <StaffLoginContainer />
            </Modal>
            {isLoggedIn && (
                <ActiveHubConnection>
                    <LocationLocaleContext.Provider value={locationLocale}>{children()}</LocationLocaleContext.Provider>
                </ActiveHubConnection>
            )}
        </>
    );
};
