import { LocationLocaleContext } from "features/location/context/LocationLocaleContext";
import { GroupTabsData } from "../../types";
import React, { useContext, useMemo } from "react";
import { RowData, VirtualTableRowContent } from "../virtualTable/VirtualTableRowContent";

interface OrderRowProps {
    groupTab: GroupTabsData;
}
export const GroupTabsRowContent = ({ groupTab }: OrderRowProps) => {
    const locationLocale = useContext(LocationLocaleContext);

    const rowsData: RowData[] = useMemo(
        () => [
            {
                value: groupTab.ownerName,
                key: "owner",
            },
            { value: groupTab.members, key: "members" },
            { value: groupTab.duration, key: "duration" },
            {
                value: <span>{locationLocale.formatCurrency(groupTab.totalSpend)}</span>,
                key: "totalValue",
            },
            { value: groupTab.status, key: "status" },
        ],
        [groupTab, locationLocale]
    );
    return <VirtualTableRowContent rows={rowsData} />;
};
