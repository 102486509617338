import { AppDispatch, AppMiddleware } from "../../index";
import { actionCreators, OrdersAction, TypeKeys, unconfirmedOrdersPageSize } from "../reducers/orders";
import { SectionsAction, TypeKeys as SectionsTypeKeys } from "../../floorManager/reducers/sections";
import { OrderHubStatusActions, StatusTypeKeys } from "../../orderHub/reducers/orderHubConnection";
import { ServerOrderApi } from "../../../common/api/orderApi";
import { NavType } from "../../nav/types";
import { ActiveParty, PartyType } from "../../../common/types";
import { isUnconfirmedOrder, isUnconfirmedPayment, mapSubmittedOrders, toLiveOrder } from "../utils";
import { getLocationId } from "../../staffLogin/selectors/getLocationId";
import { fetchUnconfirmedOrders } from "../api/fetchUnconfirmedOrders";
import { getOrdersState, getUnconfirmedOrders as getUnconfirmedOrdersState } from "../selectors";
import { getSections } from "../../floorManager/selectors/getSections";
import { Order, OrderTypes } from "../types";
import { resolveUnconfirmedOrder } from "../api/resolveUnconfirmedOrder";
import { ActiveTypeAction, TypeKeys as NavTypeKeys } from "../../nav/reducers/nav";
import { getLocationLocale } from "features/location/selectors/getLocationLocale";
import { fetchActiveGroupTabsCountApi } from "../api/fetchActiveGroupTabsCount";

const sectionKey = "MEANDU_ORDERS_SECTION";

let orderHub: ServerOrderApi | null = null;
let sectionsLoaded = false;
let orderHubConnected = false;

export const ordersMiddleware = () => (store: AppMiddleware) => (next: AppDispatch) => {
    function onPartyUpdated(party: ActiveParty) {
        const state = store.getState();
        const {
            nav: { navType },
        } = state;
        if (navType !== NavType.ORDERS) return;
        const unconfirmedOrders = getUnconfirmedOrdersState(state);
        const updatedOrders = mapSubmittedOrders(party.submittedOrders, unconfirmedOrders);
        if (updatedOrders) {
            next(actionCreators.updateResubmittedUnconfirmedOrders(updatedOrders));
        }

        if (party.submittedOrders.some(isUnconfirmedOrder) || party.payments.some(isUnconfirmedPayment)) {
            const sections = getSections(state);
            next(actionCreators.unconfirmedLiveOrderReceived(toLiveOrder(party, sections)));
        }
    }

    async function getUnconfirmedOrders(skip: number, take: number) {
        next(actionCreators.unconfirmedOrdersLoading());

        try {
            const state = store.getState();
            const locationId = getLocationId(state)!;
            const { selectedSection } = getOrdersState(state);

            const locale = getLocationLocale(state);

            const response = await fetchUnconfirmedOrders(
                locationId,
                locale.getLocalTime(),
                skip,
                take,
                selectedSection?.id ?? null
            );

            next(actionCreators.unconfirmedOrdersLoaded(response.orders, response.unconfirmedOrdersTotal));
        } catch (err) {
            next(actionCreators.unconfirmedOrdersFailed(err));
        }
    }

    async function resolveOrder(order: Order) {
        const state = store.getState();
        const locationId = getLocationId(state)!;

        try {
            if (order.partyType !== PartyType.MULTIUSER && order.partyType !== PartyType.PAYONLY) {
                await resolveUnconfirmedOrder(locationId, order.partyId);
            } else {
                if (order.type === OrderTypes.PAYMENT) {
                    await orderHub!.invoke("paymentResolved", order.partyId, order.orderId);
                }
                if (order.type === OrderTypes.ORDER) {
                    await orderHub!.invoke("ordersResolved", order.partyId, [order.orderId]);
                }
            }
            next(actionCreators.unconfirmedOrderResolved(order));
        } catch (err) {
            next(actionCreators.unconfirmedOrderResolveFailed(order, err));
        }
    }

    async function resubmitOrder(order: Order) {
        const unconfirmedOrderIds = order.unconfirmedOrders?.map(({ orderId }) => orderId);
        try {
            await orderHub!.invoke("ordersResubmit", order.partyId, unconfirmedOrderIds);
        } catch (e) {
            next(actionCreators.resubmitOrderFailed(order));
        }
    }

    async function getActiveGroupTabsCount() {
        next(actionCreators.activeGroupTabsLoading());

        try {
            const state = store.getState();
            const locationId = getLocationId(state)!;

            const locale = getLocationLocale(state);

            const result = await fetchActiveGroupTabsCountApi(locationId, locale.getLocalTime());

            next(actionCreators.activeGroupTabsLoaded(result.activeGroupTabsCount));
        } catch (err) {
            next(actionCreators.activeGroupTabsFailed(err));
        }
    }

    return async (action: OrdersAction | SectionsAction | OrderHubStatusActions | ActiveTypeAction) => {
        const state = store.getState();

        if (action.type === StatusTypeKeys.CONNECTED) {
            orderHubConnected = true;
        }

        if (
            action.type === StatusTypeKeys.CONNECTING ||
            action.type === StatusTypeKeys.CONNECTION_FAILED ||
            action.type === StatusTypeKeys.DISCONNECTED ||
            action.type === StatusTypeKeys.INITIALIZED
        ) {
            orderHubConnected = false;
        }

        if (action.type === TypeKeys.HISTORY_ORDERS_LOADED) {
            const {
                orders: { historyOrdersTotal, historyOrdersPage },
            } = state;
            if (historyOrdersTotal !== undefined && action.totalCount > historyOrdersTotal && historyOrdersPage > 1) {
                next(
                    actionCreators.showHistoryCountChange(
                        Math.max(0, action.totalCount - historyOrdersTotal - action.spreadOrdersCount)
                    )
                );
            }
        }

        next(action);

        if (action.type === StatusTypeKeys.INITIALIZED) {
            orderHub = action.api;
            orderHub.addMessageHandler("partyUpdated", onPartyUpdated);
            orderHub.addMessageHandler("partyClosed", onPartyUpdated);
        }

        if (
            action.type === StatusTypeKeys.CONNECTED ||
            (action.type === NavTypeKeys.ACTIVE_TYPE && action.navType === NavType.ORDERS) ||
            action.type === TypeKeys.SELECT_SECTION ||
            (action.type === SectionsTypeKeys.LOADED && !sectionsLoaded)
        ) {
            const state = store.getState();
            const {
                nav: { navType },
                floorManager: {
                    sections: { status },
                },
            } = state;

            if (navType === NavType.ORDERS && status === "loaded" && orderHubConnected) {
                await getUnconfirmedOrders(0, unconfirmedOrdersPageSize + 10);
            }
        }

        if (action.type === TypeKeys.UNCONFIRMED_ORDER_RESOLVING) {
            await resolveOrder(action.order);
        }

        if (action.type === TypeKeys.RESUBMIT_UNCONFIRMED_ORDER) {
            await resubmitOrder(action.order);
        }

        if (action.type === TypeKeys.SELECT_SECTION) {
            try {
                if (action.selectedSection) {
                    localStorage.setItem(sectionKey, action.selectedSection.id);
                } else {
                    localStorage.removeItem(sectionKey);
                }
            } catch (e) {
                console.error(e);
            }
        }

        if (action.type === SectionsTypeKeys.LOADED && !sectionsLoaded) {
            try {
                const selectedSectionId = localStorage.getItem(sectionKey);
                if (selectedSectionId) {
                    const selectedSection = action.data.find((s) => s.id === selectedSectionId);
                    if (selectedSection) {
                        store.dispatch(actionCreators.selectSection(selectedSection));
                    }
                }
            } catch (e) {
                console.error(e);
            }
        }

        if (action.type === SectionsTypeKeys.LOADED) {
            sectionsLoaded = true;
        }

        if (
            action.type === StatusTypeKeys.CONNECTED ||
            (action.type === NavTypeKeys.ACTIVE_TYPE && action.navType === NavType.ORDERS)
        ) {
            const state = store.getState();
            const {
                nav: { navType },
            } = state;

            if (navType === NavType.ORDERS && orderHubConnected) {
                await getActiveGroupTabsCount();
            }
        }
    };
};
