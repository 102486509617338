import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { AppDispatch } from "../../index";
import { actionCreators } from "../reducers";
import { Text } from "../../../components/text";
import { SectionSelector } from "../../floorManager/components/sections/SectionSelector";
import { NavLink, Route } from "react-router-dom";
import { HistoryOrders } from "./HistoryOrders";
import { getActiveGroupTabsTotal, getAlertsTotal, getSelectedSection } from "../selectors";
import { SingleDatePicker } from "components/singleDatePicker";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router";
import { Alerts } from "./Alerts";
import { Moment } from "moment";
import "./OrdersPage.scss";
import { withDisabledSessionTimeout } from "../../staffLogin/containers/withDisabledSessionTimeout";
import alertMp3 from "../../../assets/sounds/alert.mp3";
import { SECOND } from "common/utility";
import { getMomentDiningDate } from "features/location/selectors/getDiningDate";
import { getActiveLocationArePaymentsSupported } from "features/location/selectors/getLocationLocale";
import { GroupTabs } from "./GroupTabs";
import { getLocationPermissions } from "features/staffLogin/selectors/getPermissions";
import { PaymentsPage } from "./PaymentsPage";
import { clearMergedColors } from "../utils";

export const OrdersPage = () => {
    const dispatch = useDispatch<AppDispatch>();

    const today = useSelector(getMomentDiningDate);
    const permissions = useSelector(getLocationPermissions);
    const selectedSection = useSelector(getSelectedSection);
    const alertsTotal = useSelector(getAlertsTotal);
    const [date, setDate] = useState(today);
    const [groupTabFilterDate, setGroupTabFilterDate] = useState(today);
    const [paymentsFilterDate, setPaymentsFilterDate] = useState(today);
    const { pathname } = useLocation();

    const alert = useMemo(() => new Audio(alertMp3), []);
    const playAlert = useCallback(() => alert.play(), [alert]);
    const alertIntervalRef = useRef(0);
    const alertsTotalRef = useRef(0);
    const groupTabsEnabled = permissions.has("grouptabs:orders:read");
    const activeGroupTabsTotal = useSelector(getActiveGroupTabsTotal);
    const arePaymentsSupported = useSelector(getActiveLocationArePaymentsSupported);

    const historySetDate = useCallback(
        (newDate: Moment) => {
            setDate((date) => {
                if (!date.isSame(newDate, "day")) {
                    dispatch(actionCreators.selectHistoryOrdersPage(1));
                    dispatch(actionCreators.activeOrdersReset());
                    clearMergedColors();
                }
                return newDate;
            });
        },
        [dispatch]
    );

    const paymentsSetDate = useCallback(
        (newDate: Moment) => {
            setPaymentsFilterDate((date) => {
                if (!date.isSame(newDate, "day")) {
                    dispatch(actionCreators.paymentPartiesReset());
                }
                return newDate;
            });
        },
        [dispatch]
    );

    const groupTabSetDate = useCallback(
        (newDate: Moment) => {
            setGroupTabFilterDate((date) => {
                if (!date.isSame(newDate, "day")) {
                    dispatch(actionCreators.selectGroupTabsPage(1));
                }
                return newDate;
            });
        },
        [dispatch]
    );

    useEffect(() => {
        return () => {
            clearMergedColors();
            clearInterval(alertIntervalRef.current);
        };
    }, []);

    useEffect(() => {
        if (alertsTotal === 0 || alertsTotal > alertsTotalRef.current) {
            clearInterval(alertIntervalRef.current);
        }
        if (alertsTotal > alertsTotalRef.current) {
            playAlert();
            alertIntervalRef.current = window.setInterval(playAlert, 10 * SECOND);
        }
        alertsTotalRef.current = alertsTotal;
    }, [alertsTotal, alertsTotalRef, playAlert]);

    return (
        <div className="orders-page">
            <div className="orders-page__header">
                {!pathname.includes("grouptabs") && (
                    <div className="orders-page__section-selector">
                        <SectionSelector
                            selectedSection={selectedSection}
                            onSelectSection={(section) => dispatch(actionCreators.selectSection(section))}
                            overlayClassName="orders-page__section-selector__overlay"
                        />
                    </div>
                )}
                {pathname.includes("history") ? (
                    <SingleDatePicker date={date} setDate={historySetDate} today={today} />
                ) : pathname.includes("payments") ? (
                    <SingleDatePicker date={paymentsFilterDate} setDate={paymentsSetDate} today={today} />
                ) : (
                    pathname.includes("grouptabs") && (
                        <>
                            <div className="orders-page__section-selector" />
                            <SingleDatePicker date={groupTabFilterDate} setDate={groupTabSetDate} today={today} />
                        </>
                    )
                )}
                <div className="orders-page__navigation">
                    <NavLink
                        exact
                        className={classNames("orders-page__navigation-tab", "orders-page__navigation-tab__alerts")}
                        to={"/orders"}
                    >
                        <Text preset="g-14" mode="bold">
                            Alerts
                        </Text>
                        {alertsTotal > 0 && (
                            <Text className="count" preset="g-12" mode={["medium", "block"]}>
                                {alertsTotal}
                            </Text>
                        )}
                    </NavLink>
                    <NavLink
                        exact
                        className={classNames("orders-page__navigation-tab", "orders-page__navigation-tab__history")}
                        to={"/orders/history"}
                    >
                        <Text preset="g-14" mode="bold" value="Orders" />
                    </NavLink>
                    {arePaymentsSupported && (
                        <NavLink
                            exact
                            className={classNames(
                                "orders-page__navigation-tab",
                                "orders-page__navigation-tab__payments"
                            )}
                            to={"/orders/payments"}
                            isActive={() => pathname.includes("payments")}
                        >
                            <Text preset="g-14" mode="bold" value="Payments" />
                        </NavLink>
                    )}
                    {groupTabsEnabled && (
                        <NavLink
                            exact
                            className={classNames(
                                "orders-page__navigation-tab",
                                "orders-page__navigation-tab__group-tab"
                            )}
                            to={"/orders/grouptabs"}
                            isActive={() => pathname.includes("grouptabs")}
                        >
                            <Text preset="g-14" mode="bold" value="Group Tabs" />
                            {!!activeGroupTabsTotal && (
                                <Text className="count" preset="g-12" mode={["medium", "block"]}>
                                    {activeGroupTabsTotal}
                                </Text>
                            )}
                        </NavLink>
                    )}
                </div>
            </div>
            <div className="orders-page__content">
                <div className="orders-page__content-inner">
                    <Route key="orders-alerts" exact path={`/orders`}>
                        <Alerts />
                    </Route>
                    <Route key="orders" exact path={`/orders/history`}>
                        <HistoryOrders date={date} setDate={setDate} today={today} />
                    </Route>
                    {arePaymentsSupported && (
                        <Route key="payments-tabs" exact path={`/orders/payments`}>
                            <PaymentsPage date={paymentsFilterDate} setDate={setPaymentsFilterDate} today={today} />
                        </Route>
                    )}
                    {groupTabsEnabled && (
                        <Route key="group-tabs" exact path={`/orders/grouptabs`}>
                            <GroupTabs date={groupTabFilterDate} setDate={setGroupTabFilterDate} today={today} />
                        </Route>
                    )}
                </div>
            </div>
        </div>
    );
};

export const OrdersPageNoSessionTimeout = withDisabledSessionTimeout(OrdersPage);
