import { actionCreators } from "features/orders/reducers";
import { getSelectedPartyPaymentFilters } from "features/orders/selectors/getPaymentParties";
import { Party, PartyActivityStatus } from "features/orders/types";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VirtualTableColumnType } from "../virtualTable/types";
import { VirtualTableHeader } from "../virtualTable/VirtualTableHeader";

export const tableColumns: VirtualTableColumnType<Party, PartyActivityStatus>[] = [
    {
        title: "Party ID",
        dataIndex: "displayId",
        key: "displayId",
    },
    {
        title: "Order flow",
        dataIndex: "orderFlow",
        key: "orderFlow",
    },
    {
        title: "Table",
        dataIndex: "table",
        key: "table",
    },
    {
        title: "Section",
        dataIndex: "section",
        key: "section",
    },
    {
        title: "Total paid",
        dataIndex: "itemTotal",
        key: "itemTotal",
        width: 120,
        align: "right",
    },
    {
        title: "Closed time",
        dataIndex: "closedTime",
        key: "time",
        width: 120,
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: 160,
        minWidth: 120,
        filters: [
            {
                text: "Ongoing",
                value: "Ongoing",
            },
            {
                text: "Closed",
                value: "Closed",
            },
        ],
    },
];

export const PartiesHeader = () => {
    const paymentsPartiesFilters = useSelector(getSelectedPartyPaymentFilters);

    const dispatch = useDispatch();

    const applyFilters = useCallback(
        (selected: PartyActivityStatus[]) => dispatch(actionCreators.selectPaymentPartyFilter(selected)),
        [dispatch]
    );

    return (
        <VirtualTableHeader<Party, PartyActivityStatus>
            columns={tableColumns}
            applyFilters={applyFilters}
            defaultSelectedValue={paymentsPartiesFilters}
        />
    );
};
