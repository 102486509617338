import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { GroupTabsData } from "../types";
import { LocationLocaleContext } from "../../location/context/LocationLocaleContext";
import { Text } from "../../../components/text";
import { CrossIcon, PhoneIcon, WarningIcon } from "../../../common/icons";
import { useSelector } from "react-redux";
import { getLocationPermissions } from "features/staffLogin/selectors/getPermissions";
import { Button } from "antd";
import { isRefundsEnabled } from "features/location/selectors/getLocationConfig";
import "../assets/GroupTabCard.scss";
import { ConfirmModal } from "components/confirmModal/ConfirmModal";
import { OverlayErrorNotification, SuccessNotification } from "components/messages/Notifications";
import { TabOrder } from "./TabOrder";
import { config } from "common/config";
import { disableTabOrderRefundButton } from "./OrderDetails/OrderCard";

export interface Props {
    groupTab?: GroupTabsData;
    onClose: (cancel: boolean) => void;
    onCloseGroupTab: (groupTabId: string) => void;
    closeSuccess: boolean | undefined;
    actioning: boolean;
    onShowGroupTabOrder?: (groupTabId: string, partyId: string) => void;
    viaOrderCard?: boolean;
    failedToLoadTabOrder?: boolean;
}

export const GroupTabCard: FC<Props> = ({
    groupTab,
    onClose,
    onCloseGroupTab,
    closeSuccess,
    actioning,
    onShowGroupTabOrder,
    viaOrderCard,
    failedToLoadTabOrder,
}) => {
    const permissions = useSelector(getLocationPermissions);
    const refundsEnabled = useSelector(isRefundsEnabled);
    const [isActive, setIsActive] = useState(groupTab && groupTab.status === "Active");
    const canRefund = refundsEnabled && permissions.has("refunds:write");
    const canClose = permissions.has("grouptabs:close");
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const tabOrders = useMemo(() => (groupTab ? groupTab.orders : []), [groupTab]);
    const showRefundButton = groupTab && disableTabOrderRefundButton(groupTab);
    const [closing, setClosing] = useState(false);

    const handleClick = () => {
        setShowConfirmModal(true);
    };

    const closeGroupTab = () => {
        groupTab && onCloseGroupTab(groupTab.id);
    };

    const internalOnClose = useCallback(
        (cancel: boolean) => {
            setClosing(true);
            onClose(cancel);
        },
        [onClose]
    );

    useEffect(() => {
        let timer: number | undefined;
        if (closing) {
            timer = window.setTimeout(() => setClosing(false), 200);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [closing]);

    useEffect(() => {
        setIsActive(groupTab && groupTab.status === "Active");
    }, [groupTab]);

    if (!groupTab) return null;

    return (
        <div className="groupTab-card">
            <div className="groupTab-card__header">
                <div className="groupTab-card__header-top">
                    <Text preset="title-20" value={groupTab.tabName} />
                    <button className="groupTab-card__close" onClick={() => internalOnClose(true)}>
                        <CrossIcon />
                    </button>
                </div>
            </div>
            <div className="groupTab-card__scrollable">
                <div className="groupTab-card__scrollable-inner">
                    <div className="groupTab-card__info">
                        <div className="groupTab-card__title-row">
                            <Text preset="g-16" mode={["bold", "block"]} value="Details" />
                        </div>
                        <div className="groupTab-card__detail-row">
                            <Text preset="g-14" mode={["bold", "block"]} value="Customer" />
                            <span className="groupTab-card__detail-row-phone">
                                <Text preset="g-14" mode={["block"]} value={groupTab.fullName} />
                                <Text preset="g-14">
                                    {groupTab.ownerPhone && <PhoneIcon />} {groupTab.ownerPhone}
                                </Text>
                            </span>
                        </div>
                        <div className="groupTab-card__detail-row">
                            <Text preset="g-14" mode={["bold", "block"]} value="Duration" />
                            <Text preset="g-14" mode={["block"]} value={groupTab.duration} />
                        </div>
                        {!!groupTab.packageName && (
                            <div className="groupTab-card__detail-row">
                                <Text preset="g-14" mode={["bold", "block"]} value="Limited menu" />
                                <Text preset="g-14" mode={["block"]}>
                                    {groupTab.packageName}
                                </Text>
                            </div>
                        )}
                        <div className="groupTab-card__detail-row">
                            <Text preset="g-14" mode={["bold", "block"]} value="Members" />
                            <Text preset="g-14" mode={["block"]} value={groupTab.members.toString()} />
                        </div>
                        <div className="groupTab-card__detail-row">
                            <Text preset="g-14" mode={["bold", "block"]} value="Orders sent" />
                            <Text preset="g-14" mode={["block"]} value={groupTab.numberOfOrders.toString()} />
                        </div>
                    </div>

                    <div className="groupTab-card__spend">
                        <div className="groupTab-card__title-row">
                            <Text preset="g-16" mode={["bold", "block"]} value="Spend" />
                        </div>
                    </div>

                    <div className="groupTab-card__info">
                        <div className="groupTab-card__detail-row no-border greyed-out">
                            <Text preset="g-14" mode={["block"]} value="Tab limit" />
                            {groupTab.limit ? (
                                <LocationLocaleContext.Consumer>
                                    {(locale) => (
                                        <Text preset="g-14" mode={["block"]}>
                                            {locale.formatCurrency(groupTab.limit)}
                                        </Text>
                                    )}
                                </LocationLocaleContext.Consumer>
                            ) : (
                                <Text preset="g-14" mode={["block"]} value="N/A" />
                            )}
                        </div>
                        <div className="groupTab-card__detail-row no-border">
                            <Text preset="g-14" mode={["bold", "block"]} value="Spend" />
                            <LocationLocaleContext.Consumer>
                                {(locale) => (
                                    <Text preset="g-14" mode={["bold", "block"]}>
                                        {locale.formatCurrency(
                                            (groupTab.paymentTotals.total || 0) +
                                                (groupTab.paymentTotals.surcharges || 0)
                                        )}
                                    </Text>
                                )}
                            </LocationLocaleContext.Consumer>
                        </div>
                        {isActive && (
                            <>
                                <div className="groupTab-card__detail-row no-border greyed-out">
                                    <Text preset="g-14" mode={["block"]} value="Remaining amount" />
                                    {groupTab.limit ? (
                                        <LocationLocaleContext.Consumer>
                                            {(locale) => (
                                                <Text preset="g-14" mode={["block"]}>
                                                    {locale.formatCurrency(
                                                        groupTab.limit -
                                                            (groupTab.paymentTotals.total || 0) +
                                                            (groupTab.paymentTotals.surcharges || 0)
                                                    )}
                                                </Text>
                                            )}
                                        </LocationLocaleContext.Consumer>
                                    ) : (
                                        <Text preset="g-14" mode={["block"]} value="N/A" />
                                    )}
                                </div>
                            </>
                        )}
                        <div className="groupTab-card__detail-row greyed-out">
                            <Text preset="g-14" mode={["block"]} value="Refunded amount" />
                            <LocationLocaleContext.Consumer>
                                {(locale) => (
                                    <Text preset="g-14" mode={["block"]}>
                                        {locale.formatCurrency(-(groupTab.paymentTotals.totalRefunded || -0))}
                                    </Text>
                                )}
                            </LocationLocaleContext.Consumer>
                        </div>
                    </div>
                    {config.VITE_SHOW_TAB_ORDERS === "1" && tabOrders.length > 0 && (
                        <div className="groupTab-card__orders">
                            <div className="groupTab-card__title-row">
                                <Text preset="g-16" mode={["bold", "block"]} value="Tab orders" />
                            </div>

                            {tabOrders.map((order) => (
                                <TabOrder
                                    key={order.partyId}
                                    tabOrder={order}
                                    viaOrderCard={viaOrderCard}
                                    disabled={actioning}
                                    onShowGroupTabOrder={(partyId: string) => {
                                        onShowGroupTabOrder && onShowGroupTabOrder(groupTab.id, partyId);
                                    }}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>

            {showConfirmModal && (
                <ConfirmModal
                    showModal={true}
                    title={`Are you sure you want to close this Group Tab?`}
                    subTitle={"Tab members will be removed and will no longer able to order using the Group Tab."}
                    isPrompt={true}
                    confirmText={`Close Group Tab`}
                    onCloseModal={() => setShowConfirmModal(false)}
                    onConfirm={closeGroupTab}
                    closeOnSubmit={true}
                />
            )}
            {(canRefund || canClose) && (
                <div className="groupTab-card__footer">
                    {canRefund && showRefundButton && (
                        <div className="groupTab-card__warning-refund">
                            <Text className="warning-icon" preset="g-14">
                                {<WarningIcon />}
                            </Text>
                            <Text preset="g-14" mode="bold">
                                {"Group Tabs can’t be refunded."}
                            </Text>
                        </div>
                    )}

                    <SuccessNotification
                        notificationText="Successfully closed"
                        isVisible={!closing && closeSuccess === true}
                    />
                    <OverlayErrorNotification
                        notificationText="Failed to close"
                        isVisible={!closing && closeSuccess === false}
                    />

                    <div className="groupTab-card__footer-inner">
                        <OverlayErrorNotification
                            notificationText="Unable to load order."
                            isVisible={failedToLoadTabOrder}
                        />

                        {canRefund && showRefundButton && (
                            <Button className="groupTab-card__button-refund" disabled={true}>
                                <Text preset="g-16" mode="bold" value="Refund" />
                            </Button>
                        )}
                        {canClose && (
                            <Button
                                className="groupTab-card__button-action"
                                onClick={handleClick}
                                disabled={!!!isActive || closeSuccess}
                                loading={actioning}
                            >
                                <Text preset="g-16" mode="bold" value={!!isActive ? "Close Group Tab" : "Closed"} />
                            </Button>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};
