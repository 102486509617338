import {
    ActiveParty,
    OrderItem,
    OrderWithoutPayment,
    OrderWithPayment,
    PartyPayment,
    PartyRefund,
    PartyStatus,
    PartyType,
    PaymentStatus,
    PosPaymentStatus,
    SecuredPaymentStateType,
} from "common/types/party";
import { Order, OrderStatus, OrderTypes, Party } from "../types";
import { partyTypeMap } from "./commonGraphFields";
import { getOrderDisplayStatus, isValidPayment } from "../utils";

export const mapOrder = (party: ActiveParty, order: OrderWithPayment | OrderWithoutPayment): Order => {
    const isOrderWithPayment = "surcharge" in order;
    return {
        partyId: party.id,
        partyType: partyTypeMap[party.type],
        phone: party.members[0].phoneNumber,
        table: party.tableNumber.split("-")[0],
        displayOrderId: party.tableNumber,
        orderId: order.id,
        posReferenceId: order.posReferenceId,
        section: party.sectionName,
        time: new Date(order.dateSubmitted || party.sortDate || party.dateOpened),
        dateSubmitted: (order.dateSubmitted && new Date(order.dateSubmitted)) || undefined,
        isTakeaway: party.isTakeaway,
        status: getOrderDisplayStatus(order.confirmationStatus) as OrderStatus,
        isMerged: order.isMerged,
        items: toOrderItems(order.items),
        type: OrderTypes.ORDER,
        orderSubmittedCount: order?.orderSubmittedCount,
        processingFee: party.processingFeeTotal,
        surcharge: isOrderWithPayment ? (order as OrderWithPayment).surcharge : undefined,
        discount: isOrderWithPayment ? (order as OrderWithPayment).discount : undefined,
        membershipDiscount: isOrderWithPayment ? (order as OrderWithPayment).membershipDiscount : undefined,
        trayCharges: isOrderWithPayment ? (order as OrderWithPayment).trayCharges : undefined,
        taxTotals: party.taxTotals,
        tip: party.tipsTotal,
        venueCauses: isOrderWithPayment ? (order as OrderWithPayment).venueCauses : undefined,
        isFlexTab: party.isFlexTab,
    };
};

export const getPaymentStatus = ({ status, posStatus }: PartyPayment, matchedRefund?: PartyRefund) => {
    if (matchedRefund) {
        return isRefundFailed(matchedRefund) ? "Refund failed" : "Refunded";
    }
    if (status === "failed" || status === PaymentStatus.FAILED) {
        return "Failed";
    }
    if (posStatus === "resolved" || posStatus === PosPaymentStatus.RESOLVED) {
        return "Resolved";
    }
    return posStatus === "rejected" || posStatus === PosPaymentStatus.REJECTED ? "Unconfirmed" : "Confirmed";
};

export const mapPayment = (party: ActiveParty, payment: PartyPayment): Order => {
    const matchedRefund = party.refunds?.find((r) => r.paymentId === payment.id);
    const linkedMember = party.members.find((m) => m.id === payment.memberId);
    const paymentStatus = getPaymentStatus(payment, matchedRefund);
    return {
        partyId: party.id,
        partyType: partyTypeMap[party.type],
        fullName: linkedMember?.displayName ?? party.members[0].memberName ?? party.members[0].displayName,
        phone: linkedMember?.phoneNumber ?? party.members[0].phoneNumber,
        table: party.tableNumber.split("-")[0],
        displayOrderId: party.tableNumber,
        orderId: payment.id,
        section: party.sectionName,
        time: new Date(payment.date ?? payment.dateCreated ?? party.dateOpened),
        dateSubmitted: (party.dateSubmitted && new Date(party.dateSubmitted)) || undefined,
        isTakeaway: party.isTakeaway,
        status: paymentStatus,
        type: OrderTypes.PAYMENT,
        itemTotal: payment.subtotal,
        refundablePaymentId: matchedRefund ? undefined : payment.id,
        refundableTotal: matchedRefund ? 0 : payment.transactionTotal,
        refundedTotal: matchedRefund ? matchedRefund.transactionTotal : undefined,
        paymentsTotal:
            payment.transactionTotal -
            (matchedRefund && isRefundFailed(matchedRefund) ? 0 : matchedRefund?.transactionTotal ?? 0),
        transactionId: payment.transactionId,
        tip: payment.tip,
        gratuity: payment.gratuity,
        processingFee: payment.processingFee,
        processingFeeAbsorbed: payment.processingFeeAbsorbed,
        isForceCharge: payment.isForceCharge,
        isFlexTab: party.isFlexTab,
        failedReason: payment.error?.code,
    };
};

export const getPaymentsTotal = (party: ActiveParty, partyRefund: PartyRefund | undefined) => {
    return (
        (party.paymentsTotal || 0) -
        (partyRefund && isRefundFailed(partyRefund) ? 0 : partyRefund?.transactionTotal || 0)
    );
};

const isRefundFailed = (refund: PartyRefund) => refund.status === "failed" || refund.status === PaymentStatus.FAILED;

export const toOrderItems = (orders: OrderItem[]) => {
    return orders.map((orderItem) => ({
        ...orderItem,
        mappedModifiers: orderItem.modifiers && orderItem.modifiers,
    }));
};

export const mapActivePartyToParty = (party: ActiveParty): Party => {
    const customerSavedPayment = party.members.find(({ hasSecuredPayment }) => hasSecuredPayment);

    return {
        partyId: party.id,
        status: isPartyClosed(party) ? "Closed" : "Ongoing",
        partyType: partyTypeMap[party.type],
        fullName: party.members[0]?.displayName,
        phone: party.members[0]?.phoneNumber,
        table: party.tableNumber.split("-")[0],
        displayId: party.tableNumber,
        section: party.sectionName,
        customerSavedPayment,
        time: new Date(party.dateClosed || party.sortDate || party.dateOpened),
        dateClosed: party.dateClosed ? new Date(party.dateClosed) : undefined,
        totalPaymentsTotal: party.paymentsTotal,
        pointsPaymentsTotal: party.pointsPaymentsTotal,
        totalPaymentsAmount: party.paymentsAmount,
        totalRefundableAmount: party.refundableTotal,
        totalPaid: (party.paymentsTotal ?? 0) - (party.refundedTotal ?? 0),
        totalItemAmount:
            party.orderWithPayment?.subtotal ??
            party.ordersWithoutPayment?.reduce((total, order) => total + order.orderTotal, 0),
        totalRefundedAmount: party.refundedTotal,
        totalTip: party.tipsTotal,
        totalGratuity: party.gratuityTotal,
        totalProcessingFee: party.processingFeeTotal,
        paymentsData: party.payments
            .filter((payment) => isValidPayment(payment))
            .map((payment) => mapPayment(party, payment)),
        canForceCharge: canForceCharge(party),
        wasForceCharged: hasForceChargedPayment(party),
        isFlexTab: party.isFlexTab,
    };
};

export const isPartyClosed = (party: ActiveParty): boolean => !!party.dateClosed || party.status !== PartyStatus.OPEN;
export const hasForceChargedPayment = (party: ActiveParty): boolean =>
    party.payments.some((p) => (p.status === PaymentStatus.COMPLETE || p.status === "complete") && p.isForceCharge);
const hasSecuredPayment = (party: ActiveParty): boolean =>
    party.securedPaymentState === SecuredPaymentStateType.SATISFIED && party.members.some((m) => m.hasSecuredPayment);
const hasUnpaidItems = (party: ActiveParty): boolean =>
    !!party.ordersWithoutPayment &&
    party.ordersWithoutPayment.reduce((total, order) => total + order.orderTotal, 0) > 0;

export const canForceCharge = (party: ActiveParty): boolean => {
    return (
        partyTypeMap[party.type] === PartyType.MULTIUSER &&
        !isPartyClosed(party) &&
        hasSecuredPayment(party) &&
        !hasForceChargedPayment(party) &&
        hasUnpaidItems(party)
    );
};
