import { opsApi } from "common/api";
import { CategoryDetails, CategoryDetailsRes } from "../types";
import { fetchAsStaff } from "../../staffLogin/selectors/getAccessToken";

export async function fetchCategoriesApi(
    locationId: string,
    locationSupportsPosMenu: boolean
): Promise<CategoryDetails[]> {
    const query = buildQuery(locationSupportsPosMenu);

    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { locationId }, fetchAsStaff(false));
    return res.data.categories.map((category) => ({
        ...category,
        availability: !!category.availability ? Date.parse(category.availability!) : null,
    }));
}

type GraphQLPayload = { categories: CategoryDetailsRes[] };

function buildQuery(locationSupportsPosMenu: boolean) {
    let queryType: string;
    if (locationSupportsPosMenu) {
        queryType = "categories: publishedCategories";
    } else {
        queryType = "categories";
    }

    return `query ($locationId: ID!) { 
        ${queryType} (locationId: $locationId) {
            id,
            internalName,
            displayName,
            type,
            availability,
            inServiceNow,
            inServiceToday,
            waitTime
        }
    }`;
}
