import { AppState } from "features/state";
import { createSelector } from "reselect";
import { LocationLocale } from "../types/LocationLocale";
import { LocationSummary, SupportedOrderFlows } from "../types/LocationSummary";

export const getActiveLocation = (state: AppState) => (state.location.status === "loaded" ? state.location.data : null);

export const getLocationLocale = createSelector(getActiveLocation, (location) => getLocationLocaleByLocation(location));

export const getLocationLocaleByLocation = (location: LocationSummary | null) => {
    return location
        ? new LocationLocale(location.locale, location.currency, location.timeZone)
        : new LocationLocale("en-AU", "AUD", "Australia/Sydney");
};

export const getActiveLocationName = createSelector(getActiveLocation, (activeLocation) => activeLocation?.displayName);

export const getActiveLocationSupportedOrderFlows = createSelector(
    getActiveLocation,
    (activeLocation) => activeLocation?.supportedOrderFlows ?? []
);

export const getActiveLocationArePaymentsSupported = createSelector(
    getActiveLocationSupportedOrderFlows,
    (supportedOrderFlows) => supportedOrderFlows.includes(SupportedOrderFlows.OPENTABLE) ||
                             supportedOrderFlows.includes(SupportedOrderFlows.PAYONLY)
);
