import { batchGraphQLQuery } from "./batching";
import { regionHelper } from "../features/region";

export async function graphQLQuery<T = any>(
    url: string,
    query: string,
    variables: {} | undefined,
    gateway: APIGateway
): Promise<GraphQLResult<T>> {
    const result = await batchGraphQLQuery(query, variables, (q, v) => invokeQuery(url, gateway, q, v));

    return result as GraphQLResult<T>;
}

async function invokeQuery(url: string, gateway: APIGateway, query: string, variables?: {}) {
    const init = await regionHelper.addRegionHeaders({
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ query, variables }),
    });

    const request = new Request(url, init);

    const response = await gateway(request);

    if (!response.ok) {
        throw response;
    }

    const result = await response.json();

    if (result.errors && result.errors.length) {
        throw result;
    }

    return result;
}

export interface GraphQLResult<T> {
    data: T;
    errors?: GraphQLError[];
}

export interface GraphQLError {
    message: string;
}

export type APIGateway = (request: Request) => Promise<Response>;
