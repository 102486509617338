import { AppDispatch, AppState } from "features";
import { actionCreator as navActions } from "features/nav/reducers";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { DataProps, FunctionProps } from "types/utils";
import { Nav, Props } from "../components/Nav";
import { isBuzzerEnabled, isTakeawayEnabled } from "../selectors";
import { NavType, navTypeFromName } from "../types";
import { getLocationPermissions } from "features/staffLogin/selectors/getPermissions";
import { getActiveLocationName } from "features/location/selectors/getLocationLocale";

const mapStateToProps = (state: AppState, route: RouteComponentProps<any>): DataProps<Props> => {
    const {
        nav: { forceShow, navType },
        staffLogin,
    } = state;
    const actualNavType = navTypeFromName(route.location.pathname.replace(route.match.path, ""));
    const permissions = getLocationPermissions(state);
    const canViewSalesReport = permissions.has("reports:sales:read");
    const isBuzzerBumpScreenEnabled = isBuzzerEnabled(state);
    const isTakeawayBumpScreenEnabled = isTakeawayEnabled(state);
    const isLiveOrdersEnabled = state.location.status === "loaded" ? state.location.data.isLiveOrdersEnabled : false;
    const canViewFloorMananger = permissions.has("section:read");
    const isFullScreenPage = actualNavType === NavType.BUMP_SCREEN_BUZZER;

    const locationName = getActiveLocationName(state);

    return {
        navType: actualNavType,
        stateNavType: navType,
        hideNavBar: isFullScreenPage && !forceShow,
        collapseBar: staffLogin.status === "authenticated" && isFullScreenPage,
        canViewSalesReport,
        isBuzzerBumpScreenEnabled,
        isTakeawayBumpScreenEnabled,
        isLiveOrdersEnabled,
        canViewFloorMananger,
        locationName,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch): FunctionProps<Props> => {
    return {
        activeType: (navType: NavType) => dispatch(navActions.activeType(navType)),
    };
};

export const NavContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(Nav));
