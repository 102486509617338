import { fetchAsStaff } from "../../staffLogin/selectors/getAccessToken";
import moment from "moment";
import { opsApi } from "../../../common/api";
import { Order } from "../types";
import { isValidPayment, toGroupTabsData } from "../utils";
import { ActiveParty, OrderWithoutPayment, PartyPayment, PartyType, PosPaymentStatus } from "common/types/party";
import { getGroupTabFields, getPartyFields, partyTypeMap } from "./commonGraphFields";
import { mapOrder, mapPayment } from "./commonGraphFunctions";

export async function fetchUnconfirmedOrders(
    locationId: string,
    diningDate: Date,
    skip: number,
    limit: number,
    sectionId: string | null
): Promise<OrdersResponse> {
    try {
        const res = await opsApi.graphQLQuery<GraphQLPayload>(
            query,
            {
                locationId,
                diningDate: moment(diningDate).format("YYYY-MM-DD HH:00:00"),
                skip: skip,
                limit: limit,
                sectionId,
                ascending: true,
                statusFilter: ["Unconfirmed"],
                paymentStatusFilter: ["Unconfirmed"],
            },
            fetchAsStaff(true)
        );
        const orders: Order[] = [];
        res.data.activeParties.data.forEach((p: ActiveParty) => {
            if (p.orderWithPayment) {
                const order = p.orderWithPayment;
                const mappedOrder = mapOrder(p, order);
                orders.push({
                    ...mappedOrder,
                    itemTotal: order.orderTotal,
                    totalValue: order.posTotal,
                    promotions: order.promotions,
                    cepOffers: order.cepOffers,
                    refundablePaymentId: p.refundablePaymentId,
                    paymentsTotal: p.paymentsTotal,
                    refundableTotal: p.refundableTotal,
                    refundedTotal: p.refundedTotal,
                    groupTab: !!p.groupTab ? toGroupTabsData([p.groupTab])[0] : undefined,
                    payments: p.members[0].payments,
                    dateCollected: (p.dateCollected && new Date(p.dateCollected)) || undefined,
                    unconfirmedOrders: order.unconfirmedOrders,
                    stampsEarned: order.stampsEarned,
                });
            }
            if (p.ordersWithoutPayment) {
                p.ordersWithoutPayment.forEach((order: OrderWithoutPayment, index: number) => {
                    if (index > 0) {
                        ++res.data.activeParties.totalCount;
                    }
                    const mappedOrder = mapOrder(p, order);
                    orders.push({
                        ...mappedOrder,
                        paymentsTotal: order.orderTotal,
                        itemTotal: order.orderTotal,
                        unconfirmedOrders: [{ orderId: order.id }],
                    });
                });
            }
            const partyType = partyTypeMap[p.type];
            if (partyType === PartyType.MULTIUSER || partyType === PartyType.PAYONLY) {
                p.payments.forEach((payment: PartyPayment) => {
                    if (isValidPayment(payment) && payment.posStatus !== PosPaymentStatus.SUBMITTING) {
                        const mappedPayment = mapPayment(p, payment);
                        orders.push(mappedPayment);
                    }
                });
            }
        });

        return {
            orders,
            unconfirmedOrdersTotal: orders.length,
        };
    } catch (e) {
        throw e;
    }
}

type GraphQLPayload = { activeParties: { data: any[]; totalCount: number } };
type OrdersResponse = { orders: Order[]; unconfirmedOrdersTotal: number };

const query: string = `
query (
    $locationId: ID!, 
    $diningDate: String!, 
    $skip: Int!, 
    $limit: Int!, 
    $sectionId: String, 
    $ascending: Boolean!,
    $statusFilter: [String!],
    $paymentStatusFilter: [String!],
    $ongoingOnly: Boolean) 
{  
    activeParties(locationId: $locationId, diningDate: $diningDate, skip: $skip, limit: $limit, section: $sectionId, ascending: $ascending, statusFilter: $statusFilter, paymentStatusFilter: $paymentStatusFilter, ongoingOnly: $ongoingOnly) 
    {    
        totalCount    
        data {      
            ${getPartyFields}
            groupTab {
                ${getGroupTabFields}
            }
        }
    }
}
`;
