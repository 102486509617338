import { Order, OrderTypes } from "../../types";
import { LocationLocaleContext } from "features/location/context/LocationLocaleContext";
import React, { useContext } from "react";
import { OrderCardRow } from "./OrderCardRow";
import classNames from "classnames";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { OrderCardNameRow } from "./OrderCardNameRow";
import { Text } from "../../../../components/text";
import { OrderCardLinkRow } from "./OrderCardLinkRow";
import { getPartyOrderFlow } from "features/orders/utils";

export interface Props {
    order: Order;
    buttonDisabled: boolean | undefined;
    viaTabsCard: boolean | undefined;
    onOrderGroupTab: (order: Order) => void;
}

export const OrderCardInfoSection = (props: Props) => {
    const { order, buttonDisabled, viaTabsCard, onOrderGroupTab } = props;
    const locationLocale = useContext(LocationLocaleContext);
    const isUnconfirmedOrder = order.status === "Unconfirmed";
    const isResubmittingOrder = order.status === "Resubmit pending";
    const isGroupTabOrder = !!order.groupTab;
    const isPaymentType = order.type === OrderTypes.PAYMENT;

    const showUnconfirmedPosBanner = order.type && isUnconfirmedOrder && !buttonDisabled && !isResubmittingOrder;

    return (
        <div className="details-card__info">
            {showUnconfirmedPosBanner && (
                <div
                    className={classNames(
                        "details-card__confirm-order-message",
                        "details-card__confirm-order-message--warning"
                    )}
                >
                    <ExclamationCircleFilled />
                    <Text preset="g-14">
                        This {order.type!.toLowerCase()} was not confirmed by your POS and needs to be manually reviewed
                    </Text>
                </div>
            )}
            {!order.isTakeaway && (
                <OrderCardRow
                    label="Date"
                    value={`${locationLocale.formatOrderDate(order.time)} ${locationLocale.formatTime(order.time)}`}
                />
            )}
            {isPaymentType && (
                <>
                    {order.transactionId && <OrderCardRow label="Transaction ID" value={order.transactionId} />}

                    {order.displayOrderId && <OrderCardRow label="Party ID" value={order.displayOrderId} />}
                </>
            )}
            {!isPaymentType && order.displayOrderId && <OrderCardRow label="Order ID" value={order.displayOrderId} />}
            <OrderCardRow
                label="Status"
                className={classNames(order.status === "Failed" && "failed")}
                value={order.status}
            />
            {order.failedReason && <OrderCardRow label="Reason" className="failed" value={order.failedReason} />}
            <OrderCardRow label="Order flow" value={getPartyOrderFlow(order.partyType, order.isFlexTab)} />
            {order.fullName && <OrderCardNameRow fullName={order.fullName} phoneNumber={order.phone} />}
            {!isPaymentType && (
                <OrderCardRow
                    label="Section / Table"
                    value={order.section ? `${order.section} / ${order.table}` : order.table}
                />
            )}
            {!!order.orderSubmittedCount && (
                <OrderCardRow label="Times submitted to POS" value={`${order.orderSubmittedCount}`} />
            )}
            {!!order.stampsEarned && <OrderCardRow label="Stamps earned" value={`${order.stampsEarned}`} />}
            {isGroupTabOrder && (
                <OrderCardLinkRow
                    labelName="Tab"
                    linkName={order.groupTab!.tabName}
                    displayAsText={viaTabsCard}
                    onLinkClick={() => onOrderGroupTab(order)}
                    className="tab-link"
                />
            )}
            {order.isTakeaway && (
                <>
                    <OrderCardRow
                        label="Submitted"
                        value={`${locationLocale.formatOrderDate(order.dateSubmitted!)} 
                        ${locationLocale.formatTime(order.dateSubmitted!)}`}
                    />
                    <OrderCardRow
                        label="Collection"
                        value={
                            !order.dateCollected
                                ? "-"
                                : `${locationLocale.formatOrderDate(order.dateCollected)} 
                            ${locationLocale.formatTime(order.dateCollected)}`
                        }
                    />
                </>
            )}
        </div>
    );
};
