import { MINUTE } from ".";

class Polling {
    private registeredFunctions: Map<string, Function> = new Map();
    private interval = -1;

    public start = () => {
        if (this.interval > -1) return;
        this.on();
        this.interval = window.setInterval(this.on, MINUTE);
    };

    private stop = () => {
        window.clearInterval(this.interval);
        this.interval = -1;
    };

    private on = () => {
        this.registeredFunctions.forEach((value) => {
            value();
        });
    };

    reset = () => {
        this.registeredFunctions.clear();
        this.stop();
    };

    register = (name: string, callback: Function) => {
        this.registeredFunctions.set(name, callback);
    };

    unregister = (name: string) => {
        this.registeredFunctions.delete(name);
    };
}

export const polling = new Polling();
