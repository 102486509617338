import { AppDispatch, AppState } from "../../index";
import { updateApi } from "../api";
import { Availability, ModifierDetails } from "../types";
import { createAction } from "../reducers";

export const updateModifier = (locationId: string, modifier: ModifierDetails, status: Availability) => {
    return async function (dispatch: AppDispatch, getState: () => AppState) {
        const { location } = getState();
        if (location.locationId === null) {
            return;
        }

        try {
            const updatedModifier = await updateApi(locationId, [{ sku: modifier.sku, status }]);
            if (updatedModifier !== false) {
                dispatch(createAction.modifierUpdated(modifier.sku, status));
            }
        } catch (e) {
            console.warn(e);
        }
    };
};
