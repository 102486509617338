import { FeatureConfiguration } from "..";
import { reducer } from "./reducers";
import { staffLoginMiddleware } from "./middleware/staffLoginMiddleware";
import { Redirect } from "react-router";
import * as React from "react";

export { getAccessToken } from "./selectors/getAccessToken";

const PAGE_KEY = "MEANDU_VENUE_PAGE_KEY";

export default function (config: FeatureConfiguration) {
    config.reducers.staffLogin = reducer;
    config.middleware.push(staffLoginMiddleware(config.history));
    config.addRoutes(config.routes.locationRoutes, [
        <Redirect path="/" key="default" to={sessionStorage.getItem(PAGE_KEY) || "/sales"} />,
    ]);
}

window.addEventListener("unload", () => {
    if (window.location.pathname.indexOf("/enrol/") === -1) {
        sessionStorage.setItem(PAGE_KEY, window.location.pathname);
    }
});
