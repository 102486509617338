import React, { FC, useCallback, useEffect } from "react";
import "./OrderRefund.scss";
import { Order } from "../types";
import { Numpad, NumpadPreset, NumpadValidator } from "./Numpad";
import { ConfirmModal } from "components/confirmModal/ConfirmModal";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getOrderRefundState } from "../selectors";
import memoizeOne from "memoize-one";
import { useTrackEvent } from "common/AppInsights/AppInsights";
import { OrderRefundTrackingData } from "../types/orderTracking";
import { LocationLocaleContext } from "features/location/context/LocationLocaleContext";
import { LocationLocale } from "features/location/types/LocationLocale";
import { partyTypeMap } from "../api/commonGraphFields";

export interface Props {
    order: Order | null;
    onRefundOrder: (order: Order, amount: number) => void;
}

export const OrderRefund: FC<Props> = ({ order, onRefundOrder }) => {
    const [confirmRefund, setConfirmRefund] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [displayAmount, setDisplayAmount] = useState("");
    const [refundAmount, setRefundAmount] = useState(0);
    const { status: refundedOrderStatus } = useSelector(getOrderRefundState) ?? {};

    const trackEvent = useTrackEvent("ORDER/REFUNDED", {} as OrderRefundTrackingData);

    const trackRefund = useCallback(
        (success: boolean) => {
            order &&
                trackEvent({
                    partyId: order.partyId,
                    refundAmount: refundAmount,
                    wasSuccessful: success,
                    partyType: Object.keys(partyTypeMap).find((type) => partyTypeMap[type] === order.partyType) || "",
                });
        },
        [trackEvent, order, refundAmount]
    );

    useEffect(() => {
        if (refundedOrderStatus === "failed") {
            setConfirmRefund(false);
            setHasError(true);
            trackRefund(false);
        }
    }, [refundedOrderStatus, trackRefund]);

    useEffect(() => {
        if (refundedOrderStatus === "loaded") {
            setConfirmRefund(false);
            setHasError(false);
            trackRefund(true);
        }
    }, [refundedOrderStatus, trackRefund]);

    if (!order) return null;

    const refundPresets = memoizeOne((order: Order): NumpadPreset[] => [
        {
            text: "Item total",
            value: order.itemTotal || 0,
            hidden:
                (!!order.promotions && order.promotions.length > 0) ||
                (order.refundableTotal || 0) <= (order.itemTotal || 0),
        },
        {
            text: "Refundable amount",
            value: order.refundableTotal || 0,
            hidden: false,
        },
    ]);

    const refundValidators = memoizeOne((order: Order, locale: LocationLocale): NumpadValidator[] => [
        {
            validate: memoizeOne((value: number) => {
                // Cases where the refundable amount is less than the order item total (maybe due to a member discount)
                return !!order &&
                    !!value &&
                    !!order.refundableTotal &&
                    !!order.itemTotal &&
                    order.refundableTotal <= order.itemTotal
                    ? value > order.refundableTotal
                        ? {
                              passed: false,
                              error: `Amount exceeds refundable amount of ${locale.formatCurrency(
                                  order.refundableTotal || 0
                              )}`,
                          }
                        : {
                              passed: true,
                              error: "",
                          }
                    : {
                          passed: true,
                          error: "",
                      };
            }),
        },
        {
            validate: memoizeOne((value: number) => {
                // Cases where the refundable amount is greater than the order item total
                return !!order && !!value && !!order.refundableTotal && !!order.itemTotal && value > order.itemTotal
                    ? value < order.refundableTotal
                        ? {
                              passed: false,
                              error: `Amount exceeds item total. Enter a value lower than the item total or input the full refundable amount.`,
                          }
                        : value > order.refundableTotal
                        ? {
                              passed: false,
                              error: `Amount exceeds refundable amount of ${locale.formatCurrency(
                                  order.refundableTotal || 0
                              )}`,
                          }
                        : {
                              passed: true,
                              error: "",
                          }
                    : value >= 0
                    ? {
                          passed: true,
                          error: "",
                      }
                    : {
                          passed: false,
                          error: `Amount must be greater than ${locale.formatCurrency(0)}`,
                      };
            }),
        },
    ]);

    const promptConfirmation = (amount: string, displayAmount: string) => {
        setRefundAmount(parseFloat(amount) / 100);
        setDisplayAmount(displayAmount);
        setConfirmRefund(true);
    };

    const processRefund = () => {
        onRefundOrder(order, refundAmount);
    };

    return (
        <div className="order-refund">
            <div className="order-refund__body">
                <LocationLocaleContext.Consumer>
                    {(locale) => (
                        <Numpad
                            validationSteps={refundValidators(order, locale)}
                            submitText="Refund"
                            onSubmit={promptConfirmation}
                            presets={refundPresets(order)}
                        />
                    )}
                </LocationLocaleContext.Consumer>
                <ConfirmModal
                    showModal={confirmRefund}
                    title={`Refund ${displayAmount} to this customer?`}
                    subTitle={"Only a single refund can be processed for this order. This action cannot be undone."}
                    confirmText={`Refund ${displayAmount}`}
                    onConfirm={processRefund}
                    onCloseModal={() => setConfirmRefund(false)}
                    closeOnSubmit={false}
                    loading={refundedOrderStatus === "loading"}
                />
                <ConfirmModal
                    showModal={hasError}
                    title={`Unable to process refund.`}
                    subTitle={
                        "Your refund could not be processed at this time. Please try again, or if the issue continues, contact me&u Support."
                    }
                    confirmText={`Try again`}
                    onConfirm={processRefund}
                    onCloseModal={() => setHasError(false)}
                    closeOnSubmit={false}
                    loading={refundedOrderStatus === "loading"}
                />
            </div>
        </div>
    );
};
