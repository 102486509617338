import { Order, TaxTotal } from "../../types";
import { LocationLocaleContext } from "features/location/context/LocationLocaleContext";
import React, { useContext } from "react";
import { OrderCardRow } from "./OrderCardRow";
import { OrderCardTitleRow } from "./OrderCardTitleRow";
import classNames from "classnames";
import { Text } from "../../../../components/text";
import { getSurchargeText } from "features/location/utils/getSurchargeText";
import { MembershipIcon, PromotionIcon, WhiteInfoIcon } from "common/icons";
import { OrderItem, PartyType } from "common/types";
import { LocationLocale } from "features/location/types/LocationLocale";

export interface Props {
    order: Order;
}

export interface TaxItemProps {
    taxTotals?: TaxTotal[];
    locationLocale: LocationLocale;
}

export const OrderCardOrderSection = (props: Props) => {
    const { order } = props;
    const locationLocale = useContext(LocationLocaleContext);
    const isOpenTable = order.partyType === PartyType.MULTIUSER;

    return (
        <div className="details-card__order">
            <OrderCardTitleRow title="Order" />
            {order.items?.map((item, index) =>
                renderOrderItem(item, index, index + 1 === order.items?.length, locationLocale)
            )}
            {!isOpenTable && (
                <>
                    <OrderCardRow
                        label="Subtotal"
                        labelMode="block"
                        value={locationLocale.formatCurrency(order.itemTotal || 0)}
                        className="order-row"
                    />
                    {!!order.membershipDiscount && (
                        <OrderCardRow
                            label="Members discount"
                            labelMode="block"
                            icon={<MembershipIcon />}
                            value={`(${locationLocale.formatCurrency(order.membershipDiscount)})`}
                            className="order-row"
                        />
                    )}
                    {!!order.discount && (
                        <OrderCardRow
                            label="Venue discount"
                            labelMode="block"
                            value={locationLocale.formatCurrency(order.discount)}
                            className="order-row"
                        />
                    )}
                    {!!order.promotions &&
                        order.promotions.length > 0 &&
                        order.promotions.map((orderPromotion, index) => (
                            <OrderCardRow
                                key={"promotion_" + index}
                                label={orderPromotion.name}
                                labelMode="block"
                                icon={<PromotionIcon />}
                                value={locationLocale.formatCurrency(orderPromotion.amount)}
                                className="order-row"
                            />
                        ))}
                    {!!order.cepOffers &&
                        order.cepOffers.length > 0 &&
                        order.cepOffers.map((orderPromotion, index) => (
                            <OrderCardRow
                                key={"offer_" + index}
                                label={orderPromotion.name}
                                labelMode="block"
                                icon={<PromotionIcon />}
                                value={locationLocale.formatCurrency(orderPromotion.amount)}
                                className="order-row"
                            />
                        ))}
                    {order.surcharge && (
                        <OrderCardRow
                            label={getSurchargeText(locationLocale, order.surcharge.percentage)}
                            labelMode="block"
                            value={locationLocale.formatCurrency(order.surcharge.total)}
                            className="order-row"
                        />
                    )}
                    {order.venueCauses?.map((venueCause) => (
                        <OrderCardRow
                            key={venueCause.name}
                            label="Venue cause"
                            labelMode="block"
                            value={locationLocale.formatCurrency(venueCause.amount)}
                            className="order-row"
                        />
                    ))}
                    {order.trayCharges?.map((trayCharge) => (
                        <OrderCardRow
                            key={trayCharge.name}
                            label={`Venue ${trayCharge.name}`}
                            labelMode="block"
                            value={locationLocale.formatCurrency(trayCharge.amount)}
                            className="order-row"
                        />
                    ))}
                    {order.taxTotals && <TaxItems taxTotals={order.taxTotals} locationLocale={locationLocale} />}
                </>
            )}
        </div>
    );
};

const renderOrderItem = (
    { quantity, displayName, mappedModifiers, notes, memberNotes, lineTotal, price }: OrderItem,
    index: number,
    isLastItem: boolean,
    locationLocale: LocationLocale
) => (
    <div
        key={`item_${index}_${displayName}_${price}`}
        className={classNames("details-card__order-item", isLastItem && "details-card__order-item--last-item")}
    >
        <div className="details-card__order-item__details">
            <div className="details-card__order-item__qty">
                <Text preset="g-14" mode="medium">
                    {quantity}
                </Text>
            </div>
            <div className="details-card__order-item__description">
                <Text preset="g-14" mode="bold">
                    {displayName}
                </Text>
                {mappedModifiers &&
                    mappedModifiers.map((m, i) => (
                        <div key={`item_modifier_${i}_${m.displayName}_${m.optionIndex}`}>
                            <Text
                                preset="g-14"
                                mode="block"
                                className="details-card__order-item__modifier"
                                value={`${m.displayName}`}
                            />
                            {m.nestedModifiers &&
                                m.nestedModifiers.map((m, j) => (
                                    <Text
                                        preset="g-14"
                                        mode="block"
                                        key={`nested_modifier_${i}_${j}_${m.displayName}_${m.nestingLevel}`}
                                        className="details-card__order-item__modifier"
                                        value={`${"- ".repeat(m.nestingLevel || 1)}${m.displayName}`}
                                    />
                                ))}
                        </div>
                    ))}
                {(notes || memberNotes) && (
                    <Text
                        preset="g-14"
                        mode="block"
                        className="details-card__order-item__modifier"
                        value={`Note: ${notes || memberNotes}`}
                    />
                )}
            </div>
        </div>
        <div className="details-card__order-item__price">
            <Text preset="g-14" mode={["bold", "block"]}>
                {locationLocale.formatCurrency(price || lineTotal)}
            </Text>
        </div>
    </div>
);

const TaxItems = ({ taxTotals, locationLocale }: TaxItemProps) => {
    const exclusiveTaxTotal = taxTotals?.reduce((accumulator, taxClass) => {
        if (taxClass.exclusive) {
            return accumulator + taxClass.total;
        }
        return accumulator;
    }, 0);
    return (
        <>
            {!!exclusiveTaxTotal && (
                <OrderCardRow
                    label="Taxes"
                    labelMode="block"
                    icon={<WhiteInfoIcon />}
                    value={locationLocale.formatCurrency(exclusiveTaxTotal)}
                    className="order-row order-row"
                />
            )}
            {taxTotals?.map((t) =>
                t.exclusive ? (
                    <OrderCardRow
                        key={t.taxClass}
                        label={`Tax: ${t.taxClass}`}
                        labelMode="block"
                        value={`(${locationLocale.formatCurrency(t.total)})`}
                        className="order-row order-row--tax-Item"
                    />
                ) : (
                    <OrderCardRow
                        key={t.taxClass}
                        label={`Included ${t.taxClass}`}
                        labelMode="block"
                        value={`(${locationLocale.formatCurrency(t.total)})`}
                        className="order-row"
                    />
                )
            )}
        </>
    );
};
