import { Drawer } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Party } from "../types";
import { PaymentsCard } from "./PaymentsCard";

interface Props {
    onClose: () => void;
    party: Party | null;
}

export const PaymentsDrawer = ({ party, onClose }: Props) => {
    const [localParty, setLocalParty] = useState(party);

    const afterOpenChange = useCallback((open: boolean) => {
        if (!open) {
            setLocalParty(null);
        }
    }, []);

    useEffect(() => {
        if (party) {
            setLocalParty(party);
        }
    }, [party]);

    return (
        <Drawer
            className="live-orders-modal"
            contentWrapperStyle={{
                width: 420,
            }}
            placement="right"
            afterOpenChange={afterOpenChange}
            onClose={onClose}
            closable={false}
            open={!!party}
        >
            {localParty && <PaymentsCard onClose={onClose} party={localParty} />}
        </Drawer>
    );
};
