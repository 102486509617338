import _ from "lodash";
import { createSelector } from "reselect";
import { getOrdersState } from "./getGlobalState";

export const getGroupTabsData = createSelector(getOrdersState, (orders) =>
    orders.groupTabs?.status === "loaded" ? orders.groupTabs.data : []
);

export const getActiveGroupTabOrders = createSelector(getGroupTabsData, (groupTabsData) =>
    groupTabsData.filter((data) => data.status === "Active")
);

export const getGroupTabPageOrders = createSelector(
    getActiveGroupTabOrders,
    getGroupTabsData,
    (activeGroupTabs, groupTabsData) => {
        return _.unionBy(activeGroupTabs, groupTabsData, "id");
    }
);

export const getGroupTabsLoading = createSelector(getOrdersState, (orders) => orders.groupTabs?.status === "loading");

export const getGroupTabsTotal = createSelector(getOrdersState, (orders) => orders.groupTabsTotal || 0);
