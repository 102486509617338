import classNames from "classnames";
import { GroupTabsData, Order, OrderStatus, Party, PartyActivityStatus } from "features/orders/types";
import React from "react";
import { DropdownFilter } from "./DropdownFilter";
import { VirtualTableColumnType } from "./types";

interface Props<
    TData extends Order | Party | GroupTabsData,
    TStatus extends OrderStatus | PartyActivityStatus | string
> {
    columns: VirtualTableColumnType<TData, TStatus>[];
    applyFilters?: (selected: TStatus[]) => void;
    defaultSelectedValue?: TStatus[];
}

export const VirtualTableHeader = <
    TData extends Order | Party | GroupTabsData,
    TStatus extends OrderStatus | PartyActivityStatus | string
>({
    columns,
    applyFilters,
    defaultSelectedValue,
}: Props<TData, TStatus>) => (
    <tr>
        {columns.map((column) => (
            <th
                key={column.key}
                className={classNames(
                    "ant-table-cell",
                    "ant-table-column-title",
                    `virtual-table__cell--align-${column.align}`
                )}
                style={{ width: column.width, minWidth: column.minWidth }}
            >
                <div className={column.filters?.length ? "virtual-table__cell__data" : undefined}>
                    <>
                        {column.title}
                        {!!column.filters?.length && applyFilters && (
                            <DropdownFilter<TStatus>
                                onApply={applyFilters}
                                items={column.filters}
                                defaultSelectedValue={defaultSelectedValue}
                            />
                        )}
                    </>
                </div>
            </th>
        ))}
    </tr>
);
