import { createSelector } from "reselect";
import { isBuzzerEnabled } from "./isBuzzerEnabled";
import { isTakeawayEnabled } from "./isTakeawayEnabled";

export * from "./isBuzzerEnabled";
export * from "./isTakeawayEnabled";

export const isBuzzerOrTakeawayEnabled = createSelector(
    isBuzzerEnabled,
    isTakeawayEnabled,
    (buzzerEnabled, takeawayEnabled) => buzzerEnabled || takeawayEnabled
);
