// An accurate timer that avoids browser drift.
//
// https://gist.github.com/jakearchibald/cb03f15670817001b1157e62a076fe95
// https://www.youtube.com/watch?v=MCi6AZMkxcU&list=PLNYkxOF6rcIAKIQFsNbV0JDws_G_bnNo9&index=2

import { useCallback, useEffect, useState } from "react";
import { usePrevious } from "react-use";

export function exactTimeInterval(callback: (ticks: number) => void, ms: number, signal: AbortSignal) {
    // Prefer currentTime, as it'll better sync animations queued in the same frame, but if it isn't supported, performance.now() is fine.
    const start = document.timeline?.currentTime ?? performance.now();
    let tick = 0;

    function frame(time: number) {
        if (signal.aborted) return;
        tick++;
        callback(tick);
        scheduleFrame(time);
    }

    function scheduleFrame(time: number) {
        const elapsed = time - start;
        const roundedElapsed = Math.round(elapsed / ms) * ms;
        const targetNext = start + roundedElapsed + ms;
        const delay = targetNext - performance.now();
        setTimeout(() => requestAnimationFrame(frame), delay);
    }

    scheduleFrame(start);
}

export function useInterval(callback: (ticks: number) => void, intervalMS: number) {
    const [canRun, setCanRun] = useState(false);
    const prevCallbackInstance = usePrevious(callback);

    const start = useCallback(() => {
        setCanRun(true);
    }, []);

    const cancel = useCallback(() => {
        setCanRun(false);
    }, []);

    useEffect(() => {
        if (!canRun) return;
        const controller = new AbortController();
        exactTimeInterval(prevCallbackInstance!, intervalMS, controller.signal);
        return () => controller.abort();
    }, [intervalMS, canRun, prevCallbackInstance]);

    return [start, cancel];
}
