import "./virtualTable/VirtualiseTable.scss";

import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { ItemProps, TableVirtuoso } from "react-virtuoso";
import {
    getOrderResolvedState,
    getResubmitOrder,
    getSelectedSection,
    getUnconfirmedOrders,
    getUnconfirmedOrdersCount,
    getUnconfirmedOrdersLoading,
} from "../selectors";
import { useDispatch, useSelector } from "react-redux";
import { Order, TablePage } from "../types";
import { LocationLocaleContext } from "features/location/context/LocationLocaleContext";
import classNames from "classnames";
import { OrdersDrawer } from "./OrderDetails/OrdersDrawer";
import { refundOrder } from "../actions";
import { getLocationId } from "features/staffLogin/selectors/getLocationId";
import { AppDispatch } from "features";
import { closeGroupTab } from "../actions/closeGroupTab";
import { reloadUnconfirmedOrders } from "../actions/reloadUnconfirmedOrders";
import { Spin } from "antd";
import { VirtualTableComponents } from "./virtualTable/VirtualTableComponents";
import { useHistory } from "react-router-dom";
import { useTrackEvent } from "common/AppInsights/AppInsights";
import { actionCreators } from "../reducers";
import { VirtualTableFooter } from "./virtualTable/VirtualTableFooter";
import { AlertsRowContent } from "./virtualAlertsTable/AlertsRowContent";
import { AlertsHeader } from "./virtualAlertsTable/AlertsHeader";
import { getPartyTypeForTracking } from "../types/orderTracking";

interface Props {
    scrollRef?: React.MutableRefObject<HTMLElement | Window | null | undefined>;
}

export const AlertsTable = ({ scrollRef }: Props) => {
    const [order, setOrder] = useState<Order | null>(null);
    const [closing, setClosing] = useState(false);
    const [actioning, setActioning] = useState(false);
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [actionNextOrder, setActionNextOrder] = useState(false);
    const [groupTabModalclosing, setGroupTabModalclosing] = useState(false);
    const [actioningGroupTab, setActioningGroupTab] = useState(false);
    const [isGroupTabOrder, setIsGroupTabOrder] = useState(false);
    const [tapToConfirm, setTapToConfirm] = useState(false);

    const locationId = useSelector(getLocationId);
    const partyCount = useSelector(getUnconfirmedOrdersCount);
    const selectedSection = useSelector(getSelectedSection);
    const unconfirmedOrders = useSelector(getUnconfirmedOrders);
    const unconfirmedOrdersLoading = useSelector(getUnconfirmedOrdersLoading);
    const { status: orderStatus } = useSelector(getOrderResolvedState) ?? {};
    const orderToResubmit = useSelector(getResubmitOrder);

    const closeModalTimerRef = useRef(0);
    const closeGroupTabModalTimerRef = useRef(0);
    const showNextOrderTimerRef = useRef(0);
    const showSavedTimerRef = useRef(0);
    const loadNextTimerRef = useRef(0);
    const locationLocale = useContext(LocationLocaleContext);

    const trackMarkAsResolvedClicked = useTrackEvent("ORDER/RESOLVED_CLICKED", {});
    const trackMarkAsResolvedConfirmed = useTrackEvent("ORDER/RESOLVING", {});
    const trackEvent = useTrackEvent("ORDER/RESOLVED", {});

    const dispatch = useDispatch<AppDispatch>();
    const history = useHistory();

    const onClose = useCallback((cancel: boolean) => {
        if (cancel) {
            setActionNextOrder(false);
            setActioning(false);
        }
        setTapToConfirm(false);
        setClosing(true);
        closeModalTimerRef.current = window.setTimeout(() => {
            setOrder(null);
            setClosing(false);
            setSaveSuccess(false);
        }, 200);
    }, []);

    const oncloseGroupTab = useCallback(
        (groupTabId: string) => {
            locationId && dispatch(closeGroupTab(groupTabId, locationId, true));
        },
        [locationId, dispatch]
    );

    const onCloseGroupTabModal = useCallback((cancel: boolean) => {
        setGroupTabModalclosing(true);
        setActioningGroupTab(false);
        closeGroupTabModalTimerRef.current = window.setTimeout(() => {
            setGroupTabModalclosing(false);
        }, 200);
    }, []);

    const onOrderRowClick = useCallback(
        (selectedOrder: Order) => {
            const groupTabPayment = selectedOrder?.payments?.find(
                (payment) => payment.status === "complete" && payment.paymentMethod === "GroupTab"
            );
            setIsGroupTabOrder(!!groupTabPayment);
            if (groupTabPayment && !selectedOrder?.groupTab) {
                locationId &&
                    groupTabPayment.paymentMethodId &&
                    dispatch(
                        reloadUnconfirmedOrders(locationId, locationLocale.getLocalTime(), selectedSection?.id ?? null)
                    );
            } else {
                setOrder(selectedOrder);
            }
        },
        [locationLocale, dispatch, locationId, selectedSection]
    );

    const components = useMemo(
        () => ({
            ...VirtualTableComponents({
                loading: unconfirmedOrdersLoading,
                itemType: "orders",
                emptyText: "Looking good! No new unconfirmed orders.",
                colSpan: 9,
            }),
            TableRow: (props: ItemProps<Order>) => (
                <tr
                    onClick={() => {
                        onOrderRowClick(props.item);
                    }}
                    className={classNames(
                        "ant-table-row",
                        `orders-table_row_status-${props.item.status}`,
                        "actionable-row"
                    )}
                    data-row-key={`orders_${props.item.orderId}`}
                    data-row-party={`party_${props.item.partyId}`}
                    {...props}
                />
            ),
        }),
        [unconfirmedOrdersLoading, onOrderRowClick]
    );

    useEffect(() => {
        return () => {
            window.clearTimeout(closeModalTimerRef.current);
            window.clearTimeout(closeGroupTabModalTimerRef.current);
        };
    }, []);

    const resolve = useCallback(
        (order: Order) => {
            trackMarkAsResolvedConfirmed({
                "party.type": getPartyTypeForTracking(order),
                "order.type": order.type,
            });
            dispatch(actionCreators.unconfirmedOrderResolving(order));
            setTapToConfirm(false);
            return true;
        },
        [dispatch, trackMarkAsResolvedConfirmed]
    );

    const showSecondaryCta = useCallback(
        (order: Order) => {
            trackMarkAsResolvedClicked({
                "party.type": getPartyTypeForTracking(order),
                "order.type": order.type,
            });
            setTapToConfirm(true);
            return false;
        },
        [trackMarkAsResolvedClicked]
    );

    const internalOnAction = useCallback(
        (order: Order) => {
            const result = tapToConfirm ? resolve(order) : showSecondaryCta(order);
            if (result) {
                setActioning(true);
                if (unconfirmedOrders && unconfirmedOrders.indexOf(order) === 0) {
                    setActionNextOrder(true);
                }
            }
            return result;
        },
        [unconfirmedOrders, tapToConfirm, resolve, showSecondaryCta]
    );

    useEffect(() => {
        if (unconfirmedOrders) {
            if (!unconfirmedOrders.length) {
                setActionNextOrder(false);
            } else if (actionNextOrder && !actioning && order !== unconfirmedOrders[0]) {
                showNextOrderTimerRef.current = window.setTimeout(() => {
                    setOrder(unconfirmedOrders[0]);
                }, 200);
            }
        }

        return () => {
            window.clearTimeout(showNextOrderTimerRef.current);
        };
    }, [actionNextOrder, order, unconfirmedOrders, setOrder, setClosing, setActioning, setActionNextOrder, actioning]);

    const trackResolved = useCallback(() => {
        trackEvent({
            partyId: order ? order.partyId : "",
            "party.type": getPartyTypeForTracking(order ?? undefined),
        });
    }, [order, trackEvent]);

    const loadNextOrder = useCallback(() => {
        setActioning(true);
        setClosing(true);
        loadNextTimerRef.current = window.setTimeout(() => {
            dispatch(actionCreators.orderRefundReset());
            setOrder(null);
            setClosing(false);
            setSaveSuccess(false);
            setActioning(false);
        }, 300);
    }, [dispatch]);

    useEffect(() => {
        if (unconfirmedOrders && orderStatus === "loaded" && order !== unconfirmedOrders[0]) {
            setSaveSuccess(true);
            trackResolved();
            showSavedTimerRef.current = window.setTimeout(() => {
                loadNextOrder();
            }, 1000);
        }

        return () => {
            window.clearTimeout(showSavedTimerRef.current);
            window.clearTimeout(loadNextTimerRef.current);
        };
    }, [orderStatus, trackResolved, loadNextOrder, order, unconfirmedOrders]);

    useEffect(() => {
        if (!orderToResubmit) {
            onClose(false);
        }
    }, [orderToResubmit, onClose]);

    const getActionText = useCallback(() => {
        if (tapToConfirm) {
            return "Tap to confirm";
        }
        if (order?.status === "Resubmit pending") {
            return "Order resubmitting to POS";
        }
        return "Mark resolved";
    }, [tapToConfirm, order?.status]);

    return (
        <>
            <div className="virtual-table__container">
                <TableVirtuoso
                    data={unconfirmedOrders}
                    scrollerRef={scrollRef ? (r) => (scrollRef.current = r) : undefined}
                    initialTopMostItemIndex={0}
                    style={{ height: "100%" }}
                    totalCount={partyCount}
                    className={classNames("orders-table", "ant-table", "virtual-table")}
                    components={components}
                    fixedHeaderContent={() => <AlertsHeader page={TablePage.ALERTS} />}
                    itemContent={(_, order) => <AlertsRowContent order={order} />}
                    fixedFooterContent={() => (
                        <VirtualTableFooter
                            visible
                            onClick={() => history.push("/orders/history")}
                            loading={false}
                            buttonText={"View history"}
                        />
                    )}
                />

                {unconfirmedOrdersLoading && !!unconfirmedOrders.length && (
                    <div className="virtual-table__loader">
                        <Spin />
                    </div>
                )}
                <OrdersDrawer
                    order={order}
                    onAction={internalOnAction}
                    getActionText={getActionText}
                    getActionBgColor={() => (tapToConfirm ? "#b6321f" : "#181819")}
                    onClose={onClose}
                    visible={!!order && !closing}
                    className={classNames(
                        "live-orders-modal",
                        actioning && "actioning",
                        !actioning && actionNextOrder && "next"
                    )}
                    onRefundOrder={(order: Order, amount: number) =>
                        locationId && dispatch(refundOrder(locationId, order, amount, true))
                    }
                    saveSuccess={saveSuccess}
                    loading={actioning}
                    groupTabModalClosing={groupTabModalclosing}
                    oncloseGroupTab={(groupTabId: string) => {
                        setActioningGroupTab(true);
                        oncloseGroupTab(groupTabId);
                    }}
                    onCloseGroupTabModal={onCloseGroupTabModal}
                    actioningGroupTab={actioningGroupTab}
                    isGroupTabOrder={isGroupTabOrder}
                    buttonDisabled={order?.status === "Resubmit pending"}
                />
            </div>
        </>
    );
};
