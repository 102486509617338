import { ActiveParty } from "common/types/party";
import { getPartyFields } from "./commonGraphFields";

// Active Party
export type ActivePartyApiResponse = { party: ActiveParty };

export const getActivePartyApiQuery = `
query ($partyId: ID!, $locationId: ID!) {  
    party(partyId: $partyId, locationId: $locationId) {       
        ${getPartyFields}
    }
}
`;
