import { LoadingOutlined } from "@ant-design/icons";
import { Button } from "antd";
import classNames from "classnames";
import { Order } from "common/types";
import { Text } from "components/text";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useUnmount } from "react-use";
import { useTrackEvent } from "common/AppInsights/AppInsights";
import { AppDispatch } from "../../../index";
import { createAction } from "../../shared/reducers";
import { ActivePartyStatus } from "../../shared/types";
import "./TakeawayOrderStatusButton.scss";

const undoDelayMs = 3000;

interface Props {
    partyId: string;
    status: ActivePartyStatus;
    orders: Order[];
}

export const TakeawayOrderStatusButton = ({ partyId, status, orders }: Props) => {
    const dispatch = useDispatch<AppDispatch>();
    const timeoutRef = useRef<number | undefined>();
    const [isDelaying, setIsDelaying] = useState(false);
    const trackEvent = useTrackEvent("TAKEAWAY/UNDO_CHANGE_ORDER_STATUS", {});

    const changeOrderStatus = useCallback(
        (e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation();
            setIsDelaying((isDelaying) => {
                if (isDelaying) {
                    window.clearTimeout(timeoutRef.current);
                    trackEvent({
                        "party.id": partyId,
                        change_order_status: status === "preparing" ? "ready" : "collected",
                    });
                    return false;
                } else {
                    timeoutRef.current = window.setTimeout(() => {
                        const orderIds = orders.map((so) => so.id!);
                        setIsDelaying(false);
                        dispatch(createAction.changeOrderStatus(partyId, orderIds));
                    }, undoDelayMs);
                    return true;
                }
            });
        },
        [dispatch, partyId, orders, status, trackEvent]
    );

    useEffect(() => {
        window.clearTimeout(timeoutRef.current);
        setIsDelaying(false);
    }, [status]);

    useUnmount(() => {
        if (isDelaying) {
            // TODO: 19/10/2022 - ben.priebe - The orderHub is disconnecting before middleware picks up this redux action
            const orderIds = orders.map((so) => so.id!);
            dispatch(createAction.changeOrderStatus(partyId, orderIds));
        }
        window.clearTimeout(timeoutRef.current);
    });

    const label = isDelaying ? "Tap to undo" : status === "preparing" ? "Mark ready" : "Mark collected";

    return (
        <Button
            className={classNames("takeaway-order__button", `takeaway-order__button--${status}`, {
                "takeaway-order__button--delaying": isDelaying,
            })}
            onClick={changeOrderStatus}
        >
            <Text preset="g-16" mode="bold">
                {isDelaying && <LoadingOutlined spin />}
                {label}
            </Text>
        </Button>
    );
};
