import React from "react";

import "./Screen.scss";
import { NavContainer } from "../../nav/containers/NavContainer";
import { Footer } from "../../footer/components/Footer";

export interface Props {
    children: React.ReactNode;
}

export const Screen = ({ children }: Props) => (
    <div className="screen">
        <NavContainer />
        <div className="screen__container">
            <Footer />
            {children}
        </div>
    </div>
);
