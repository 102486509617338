import { AppDispatch } from "../../index";
import { createAction } from "../reducers";
import { fetchProductsApi } from "../api";

export const fetchProducts = (locationId: string) => {
    return async function (dispatch: AppDispatch) {
        dispatch(createAction.productsLoading());
        try {
            const Products = await fetchProductsApi(locationId);
            dispatch(createAction.productsLoaded(Products));
        } catch (e) {
            dispatch(createAction.productsFailed(e));
        }
    };
};
