import "./DropdownFilter.scss";

import { Checkbox, Dropdown } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import classNames from "classnames";
import { FilterIcon } from "common/icons";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { OrderStatus, PartyActivityStatus } from "features/orders/types";

interface DropdownFilterProps<TStatus> {
    icon?: ReactNode;
    items: FilterItem<TStatus>[];
    defaultSelectedValue?: TStatus[];
    onApply: (selected: TStatus[]) => void;
}

interface FilterItem<TStatus> {
    value: TStatus;
    text?: ReactNode;
}

export const DropdownFilter = <TStatus extends OrderStatus | PartyActivityStatus | string>({
    items,
    defaultSelectedValue,
    onApply,
}: DropdownFilterProps<TStatus>) => {
    const [selected, setSelected] = useState<TStatus[]>(defaultSelectedValue ?? []);

    const toggleSelected = useCallback((value: TStatus, added: boolean) => {
        setSelected((selected) => {
            const res = selected.filter((s) => s !== value);
            if (added) {
                res.push(value);
            }
            return res;
        });
    }, []);

    useEffect(() => {
        setSelected(defaultSelectedValue ?? []);
    }, [defaultSelectedValue]);

    return (
        <Dropdown
            overlayClassName={classNames("ant-table-filter-dropdown", "virtual-table__filter-dropdown")}
            overlayStyle={{ minWidth: 180, minHeight: "initial !important" }}
            menu={{
                items: items.reduce((mapped, item, index) => {
                    const itemSelected = selected.some((s) => s === item.value);
                    mapped.push({
                        className: classNames(
                            "ant-dropdown-menu-item",
                            itemSelected && "ant-dropdown-menu-item-selected"
                        ),
                        label: (
                            <div>
                                <Checkbox
                                    defaultChecked={itemSelected}
                                    onChange={(el) => toggleSelected(item.value, el.target.checked)}
                                >
                                    <span>{item.value}</span>
                                </Checkbox>
                            </div>
                        ),
                        disabled: true,
                        key: item.value,
                    });

                    if (index === items.length - 1) {
                        mapped.push({
                            className: "virtual-table__filter-dropdown__button",
                            label: "Apply filters",
                            key: "apply",
                            onClick: () => {
                                onApply(selected);
                            },
                        });
                    }
                    return mapped;
                }, [] as ItemType[]),
            }}
            placement="bottomRight"
            trigger={["click"]}
        >
            <span
                className={classNames(
                    "virtual-table__filter__icon",
                    defaultSelectedValue?.length && "virtual-table__filter__icon--active"
                )}
            >
                <FilterIcon />
            </span>
        </Dropdown>
    );
};
