import { Input } from "antd";
import { HiddenIcon } from "common/icons";
import { ReloadData } from "common/loader/ReloadData";
import { Indicator } from "components/indicator/Indicator";
import { Tabs } from "components/tabs/Tabs";
import { getLocationId } from "features/staffLogin/selectors/getLocationId";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../index";
import { fetchModifiers, fetchProducts } from "../actions";
import { createAction } from "../reducers";
import { getFilter, modifiersCount, productsCount } from "../selectors";
import { FilterTypes } from "../types";
import "./MenuItemsPage.scss";
import { ProductsTab } from "./ProductsTab";
import { ModifiersTab } from "./ModifiersTab";

export const MenuItemsPage = () => {
    const locationId = useSelector(getLocationId);
    const totalProducts = useSelector(productsCount);
    const totalModifiers = useSelector(modifiersCount);
    const filter = useSelector(getFilter) || FilterTypes.VIEW_ALL;

    const dispatch = useDispatch<AppDispatch>();
    const setSearchVal = useCallback(
        (search: string) => dispatch(createAction.setSearch(search.toLocaleLowerCase())),
        [dispatch]
    );
    const setFilter = useCallback((selected: FilterTypes) => dispatch(createAction.applyFilter(selected)), [dispatch]);
    const fetch = useCallback(() => {
        if (locationId) {
            dispatch(fetchProducts(locationId));
            dispatch(fetchModifiers(locationId));
        }
    }, [dispatch, locationId]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    useEffect(() => {
        return () => {
            setSearchVal("");
            setFilter(FilterTypes.VIEW_ALL);
        };
    }, [setSearchVal, setFilter]);

    return (
        <div className="soldout-items-page">
            <div className="soldout-items__content">
                <div className="soldout-items__content__searchBar">
                    <Input.Search
                        allowClear
                        onBlur={(e) => setSearchVal(e.target.value.trim())}
                        onSearch={setSearchVal}
                        placeholder={`Search for products or modifiers`}
                    />
                </div>
                <Tabs
                    mode="content"
                    rightContent={
                        <Indicator
                            onClick={() =>
                                setFilter(
                                    filter === FilterTypes.VIEW_ALL ? FilterTypes.UNAVAILABLE : FilterTypes.VIEW_ALL
                                )
                            }
                            className="soldout__show-unavailable"
                            primary={filter === FilterTypes.UNAVAILABLE}
                            unique
                            icon={HiddenIcon}
                            textSize="large"
                        >
                            Show unavailable
                        </Indicator>
                    }
                    tabs={[
                        {
                            title: "Products",
                            totalCounter: totalProducts,
                            key: "production-tab",
                            content: <ProductsTab />,
                        },
                        {
                            title: "Modifiers",
                            totalCounter: totalModifiers,
                            key: "modifiers-tab",
                            content: <ModifiersTab />,
                        },
                    ]}
                />
                <ReloadData fetch={fetch} />
            </div>
        </div>
    );
};
