import * as React from "react";
import "./NavIcon.scss";
import {
    BumpScreenNavIcon,
    OrdersNavIcon,
    SalesNavIcon,
    SectionsNavIcon,
    SettingsNavIcon,
    SoldOutNavIcon,
    TakeawayNavIcon,
} from "common/icons";
import { NavType } from "../types";
import { NavLink } from "react-router-dom";
import { Text } from "../../../components/text";
import classNames from "classnames";

interface Props {
    type: NavType;
    count?: number;
    onClick: (type: NavType) => void;
}

export const NavIcon = ({ type, count, onClick }: Props) => {
    const handleOnClick = () => {
        onClick(type);
    };

    return (
        <NavLink className="nav-icon" to={switchRoutes(type)} onClick={handleOnClick}>
            <div className="nav-icon__icon">
                {renderNavIcon(type)}
                {!!count && (
                    <div className="nav-icon__icon__counter">
                        <Text mode="bold" preset="g-14">
                            {count}
                        </Text>
                    </div>
                )}
            </div>
            <Text mode={["bold", "emphasis"]} preset="g-10" className={classNames("nav-icon__title")}>
                {getNavTitle(type)}
            </Text>
        </NavLink>
    );
};

const renderNavIcon = (navType: NavType) => {
    switch (navType) {
        case NavType.AVAILABILITY:
            return <SoldOutNavIcon />;
        case NavType.FLOOR_MANAGER:
            return <SectionsNavIcon />;
        case NavType.HELP:
            return <SettingsNavIcon />;
        case NavType.BUMP_SCREEN_BUZZER:
            return <BumpScreenNavIcon />;
        case NavType.BUMP_SCREEN_TAKEAWAY:
            return <TakeawayNavIcon />;
        case NavType.SALES:
            return <SalesNavIcon />;
        case NavType.ORDERS:
            return <OrdersNavIcon />;
        default:
            return <div>default</div>;
    }
};

const getNavTitle = (navType: NavType) => {
    switch (navType) {
        case NavType.AVAILABILITY:
            return "Availability";
        case NavType.FLOOR_MANAGER:
            return "Floor Manager";
        case NavType.HELP:
            return "Support";
        case NavType.BUMP_SCREEN_BUZZER:
            return "Bump";
        case NavType.BUMP_SCREEN_TAKEAWAY:
            return "Takeaway";
        case NavType.SALES:
            return "Sales";
        case NavType.ORDERS:
            return "Orders";
        default:
            return "";
    }
};

const switchRoutes = (type: NavType) => {
    switch (type) {
        case NavType.AVAILABILITY:
            return "/sold-out";
        case NavType.FLOOR_MANAGER:
            return "/floor-manager";
        case NavType.HELP:
            return "/help";
        case NavType.BUMP_SCREEN_BUZZER:
            return "/bump-screen/buzzer";
        case NavType.BUMP_SCREEN_TAKEAWAY:
            return "/bump-screen/takeaway";
        case NavType.SALES:
            return "/sales";
        case NavType.ORDERS:
            return "/orders";
    }
};
