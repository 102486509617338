const DINING_DATE_OFFSET_HOURS = 5;
const DINING_DATE_OFFSET_MS = DINING_DATE_OFFSET_HOURS * 60 * 60 * 1000;

export class LocationLocale {
    private shortDateFormat: Intl.DateTimeFormat;
    private shortTimeFormat: Intl.DateTimeFormat;
    private orderDateFormat: Intl.DateTimeFormat;
    private longDateFormat: Intl.DateTimeFormat;
    private currencyFormat: Intl.NumberFormat;
    private countFormat: Intl.NumberFormat;
    private percentageFormat: Intl.NumberFormat;

    public shortDateFormatString: string;
    public currencySymbol: string;
    constructor(public locale: string, public currency: string, public timeZone: string) {
        this.shortDateFormat = new Intl.DateTimeFormat(locale);

        // Translate the locale format into something suitable for moment
        this.shortDateFormatString = this.shortDateFormat
            .format(new Date(2021, 11, 13))
            .replace("2021", "YYYY")
            .replace("21", "YY")
            .replace("12", "MM")
            .replace("13", "DD");

        this.shortTimeFormat = new Intl.DateTimeFormat(locale, {
            hour: "numeric",
            minute: "numeric",
        });

        this.longDateFormat = new Intl.DateTimeFormat(locale, {
            year: "numeric",
            month: "long",
            day: "numeric",
        });

        this.orderDateFormat = new Intl.DateTimeFormat(locale, {
            month: "short",
            day: "numeric",
        });

        this.currencyFormat = new Intl.NumberFormat(locale, {
            style: "currency",
            currency: currency,
        });

        this.currencyFormat = new Intl.NumberFormat(locale, {
            style: "currency",
            currency: currency,
        });

        this.countFormat = new Intl.NumberFormat(locale, {
            style: "decimal",
        });

        this.percentageFormat = new Intl.NumberFormat(locale, {
            style: "percent",
            maximumFractionDigits: 2,
        });

        // Surely there's a better way of doing this...
        this.currencySymbol = this.currencyFormat.format(undefined!).replace("NaN", "");
    }

    formatDate(date: Date | string) {
        return this.shortDateFormat.format(getDate(date));
    }

    formatOrderDate(date: Date | string) {
        return this.orderDateFormat.format(getDate(date));
    }

    formatTime(date: Date | string) {
        return this.shortTimeFormat.format(getDate(date));
    }

    formatLongDate(date: Date | string) {
        return this.longDateFormat.format(getDate(date));
    }

    formatCurrency(value: number) {
        return this.currencyFormat.format(value);
    }

    formatCount(value: number) {
        return this.countFormat.format(value);
    }

    formatPercentage(value: number) {
        return this.percentageFormat.format(value);
    }

    getLocalTime(date?: Date) {
        const formattedLocalTime = (date || new Date()).toLocaleString("en-US", { timeZone: this.timeZone });

        return new Date(formattedLocalTime);
    }

    getDiningDate(date?: Date | number) {
        const ms = getTime(date ?? this.getLocalTime());

        const diningDate = new Date(ms - DINING_DATE_OFFSET_MS);
        diningDate.setHours(0);
        diningDate.setMinutes(0);
        diningDate.setSeconds(0);
        diningDate.setMilliseconds(0);

        return diningDate;
    }

    isInSameDiningDate(dateToTest: Date | string) {
        const testDiningDate = this.getDiningDate(getDate(dateToTest));
        const todayDiningDate = this.getDiningDate();
        return testDiningDate.getTime() === todayDiningDate.getTime();
    }
}
const getDate = (date: Date | string) => (typeof date === "string" ? Date.parse(date) : date);
const getTime = (date: Date | number) => (typeof date === "number" ? date : date.getTime());
