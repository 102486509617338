import { opsApi } from "common/api";
import { fetchAsStaff } from "features/staffLogin/selectors/getAccessToken";
import { sectionQuery } from "./fetchSections";
import { GraphQLSection } from "./updateSection";
import { SectionBehaviour } from "../types";

export async function updateSectionBehaviour(
    locationId: string,
    sectionBehaviorInput: {
        sectionId: string | null;
        sectionBehavior: SectionBehaviour;
    }
): Promise<GraphQLSection[]> {
    const res = (
        await opsApi.graphQLQuery<{ updateSectionsBehavior: GraphQLSection[] }>(
            updateQuery,
            {
                locationId,
                sectionBehaviorInput,
            },
            fetchAsStaff(true)
        )
    ).data.updateSectionsBehavior;

    return res;
}

const updateQuery: string = `mutation ($locationId: ID!, $sectionBehaviorInput: UpdateSectionBehaviorInput!) {
    updateSectionsBehavior(locationId: $locationId, sectionBehaviorInput: $sectionBehaviorInput) {
        ${sectionQuery}
    }
}`;
