import { DatePicker } from "antd";
import { DownArrow } from "common/icons";
import { LocationLocaleContext } from "features/location/context/LocationLocaleContext";
import { Moment } from "moment";
import React, { useCallback, useContext, useMemo } from "react";
import "./SingleDatePicker.scss";

const disabledDate = (date: Moment | undefined | null, today: Moment) => {
    if (!date) return false;
    if (today.isBefore(date, "day")) return true;
    return today.clone().subtract(90, "days").isAfter(date, "day");
};

interface Props {
    today: Moment;
    date: Moment;
    setDate: (date: Moment) => void;
}

export const SingleDatePicker = ({ date, setDate, today }: Props) => {
    const onChange = useCallback(
        (date: Moment | null) => {
            if (!date) return;
            setDate(date);
        },
        [setDate]
    );

    const locationLocale = useContext(LocationLocaleContext);

    const dateFormat = useMemo(() => {
        if (
            locationLocale &&
            locationLocale.shortDateFormatString.indexOf("M") < locationLocale.shortDateFormatString.indexOf("D")
        ) {
            return "ddd, MMM DD";
        }

        return "ddd, DD MMM";
    }, [locationLocale]);

    return (
        <span className={"single-date-picker"}>
            <DatePicker
                value={date}
                inputReadOnly={true}
                disabledDate={(d) => disabledDate(d, today)}
                format={today.isSame(date, "day") ? "[Today]" : dateFormat}
                suffixIcon={<DownArrow />}
                onChange={onChange}
                allowClear={false}
                bordered={false}
                popupClassName="single-date-picker__dropdown"
            />
        </span>
    );
};
