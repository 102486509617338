import { EnrolDevice, Props } from "../components/EnrolDevice";
import { enrol } from "../actions/enrol";
import { AppState } from "features/state";
import { DataProps, FunctionProps } from "types/utils";
import { AppDispatch } from "features";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";

type RouteParams = { regionId: string; locationId: string };

type OwnProps = RouteComponentProps<RouteParams>;

function mapStateToProps(state: AppState): DataProps<Props> {
    return {
        status: state.enrolment.status,
    };
}

function mapDispatchToProps(dispatch: AppDispatch, { match }: OwnProps): FunctionProps<Props> {
    return {
        enrol: () => dispatch(enrol(match.params.regionId, match.params.locationId, match.url)),
    };
}

export const EnrolDeviceContainer = connect(mapStateToProps, mapDispatchToProps)(EnrolDevice);
