import { AppMiddleware, AppDispatch } from "../../index";
import { OrderHubControlActions, ControlTypeKeys, statusActions } from "../reducers/orderHubConnection";
import { ServerOrderApi } from "common/api/orderApi";

let orderHub = new ServerOrderApi();

export const orderHubConnectionMiddleware = () => (store: AppMiddleware) => (next: AppDispatch) => {
    function getAccessToken() {
        const { staffLogin } = store.getState();

        return staffLogin.status === "authenticated" ? staffLogin.token : null!;
    }

    next(statusActions.initialized(orderHub));

    return async (action: OrderHubControlActions) => {
        next(action);

        if (action.type === ControlTypeKeys.CONNECT) {
            try {
                orderHub.setInitializer(async (connectionPromise) => {
                    store.dispatch(statusActions.connecting());
                    await connectionPromise;
                    store.dispatch(statusActions.connected());
                    return true;
                });

                await orderHub.connect("/orderhub", getAccessToken);
            } catch (e) {
                store.dispatch(statusActions.connectionFailed(e));
            }
        }

        if (action.type === ControlTypeKeys.DISCONNECT) {
            try {
                await orderHub.disconnect();
            } catch (e) {
                // ?
            } finally {
                store.dispatch(statusActions.disconnected());
            }
        }
    };
};
