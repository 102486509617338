import { opsApi } from "common/api";
import { fetchAsStaff } from "../../staffLogin/selectors/getAccessToken";
import { Order } from "../types";
import { mapPartyToOrderWithPayment } from "./fetchClosedOrdersApi";
import { getPartyFields } from "./commonGraphFields";

type GraphQLPayload = { party: any };
type PartyResponse = Order;

const getPartyQuery: string = `query ($partyId: ID!, $locationId: ID!) {
    party(partyId: $partyId, locationId: $locationId) {
        ${getPartyFields}
    }
}`;

export async function fetchOrderByPartyId(partyId: string, locationId: string): Promise<PartyResponse> {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(
        getPartyQuery,
        {
            partyId,
            locationId,
        },
        fetchAsStaff(true)
    );

    const mappedOrder: Order = mapPartyToOrderWithPayment(res.data.party, res.data.party.orderWithPayment);

    return mappedOrder;
}
