import { formatTime, SECOND } from "common/utility";

interface ObserveredOrderCard {
    prepTime: Element;
    timeSubmitted: number;
}

const observedOrderCards = new Map<string, ObserveredOrderCard>();

const addObservedOrderCard = (node: Node) => {
    const element = node as Element;

    const orderCard = element.querySelector(".order-card");
    if (!orderCard) return;

    const partyId = orderCard.getAttribute("data-party-id");
    const prepTime = element.querySelector("[data-time-submitted]");
    if (!partyId || !prepTime) return;

    const timeSubmitted = parseInt(prepTime.getAttribute("data-time-submitted")!);

    observedOrderCards.set(partyId, {
        prepTime,
        timeSubmitted,
    });
};

const removeObservedOrderCard = (node: Node) => {
    const element = node as Element;

    const orderCard = element.querySelector(".order-card");
    if (!orderCard) return;

    const partyId = orderCard.getAttribute("data-party-id");
    if (!partyId) return;

    observedOrderCards.delete(partyId);
};

const activePartyObserver = new MutationObserver((mutations) => {
    mutations
        .filter((mutation) => mutation.addedNodes || mutation.removedNodes)
        .forEach((mutation) => {
            console.log(`${mutation.addedNodes.length} nodes added, ${mutation.removedNodes.length} removed`);
            mutation.addedNodes.forEach(addObservedOrderCard);
            mutation.removedNodes.forEach(removeObservedOrderCard);
        });
});

const activePartyObserverOptions = {
    childList: true,
    subtree: false,
    attributes: false,
    characterData: false,
};

class ActivePartyMonitor {
    private interval?: number;

    public start = () => {
        const cardList = document.body.querySelector(".order-card-list");
        if (!cardList) return;

        const swiperWrapper = cardList.firstElementChild;
        if (!swiperWrapper) return;

        swiperWrapper.querySelectorAll(".order-card-list__list-item").forEach(addObservedOrderCard);

        this.updatePrepTimes();

        this.interval = window.setInterval(this.updatePrepTimes, SECOND);

        activePartyObserver.observe(swiperWrapper, activePartyObserverOptions);
    };

    public stop = () => {
        if (this.interval) {
            window.clearInterval(this.interval);
        }
        observedOrderCards.clear();
        activePartyObserver.disconnect();
    };

    private updatePrepTimes = () => {
        const now = Date.now();
        observedOrderCards.forEach(({ prepTime, timeSubmitted }) => {
            const millisecondsElapsed = now - timeSubmitted;
            prepTime.innerHTML = formatTime(millisecondsElapsed);
        });
    };
}

export const activePartyMonitor = new ActivePartyMonitor();
