import { ReloadData } from "common/loader/ReloadData";
import { Moment } from "moment";
import React, { useCallback, useEffect, useRef } from "react";
import { Text } from "../../../components/text";
import { getSelectedSection } from "../selectors";
import { OrderSearchOptions } from "../types";
import { FetchOptions, PAGE_SIZE } from "./HistoryOrders";
import { useDispatch, useSelector } from "react-redux";
import { getLocationId } from "features/staffLogin/selectors/getLocationId";
import { fetchPaymentParties } from "../actions/fetchPaymentParties";
import { AppDispatch } from "features";
import { getSectionsLoaded } from "features/floorManager/selectors/getSections";
import { getSelectedPartyPaymentFilters, getSelectedPartyPaymentSearch } from "../selectors/getPaymentParties";
import { actionCreators, partyFilterKey } from "../reducers/orders";
import Input from "antd/lib/input";
import { PartiesTable } from "./VirtualisePartiesTable";
import classNames from "classnames";

interface Props {
    today: Moment;
    date: Moment;
    setDate: (date: Moment) => void;
}

export const PaymentsPage = ({ date, today, setDate }: Props) => {
    const sectionLoaded = useSelector(getSectionsLoaded);
    const selectedSection = useSelector(getSelectedSection);
    const locationId = useSelector(getLocationId);
    const paymentPartiesFilter = useSelector(getSelectedPartyPaymentFilters);
    const search = useSelector(getSelectedPartyPaymentSearch);

    const scrollRef = useRef<HTMLElement | Window | null>();

    const dispatch = useDispatch<AppDispatch>();

    const fetch = useCallback(
        (options?: FetchOptions) => {
            const searchOptions: OrderSearchOptions = {
                diningDate: date.format("YYYY-MM-DD"),
                filters: null,
                partyFilters: paymentPartiesFilter,
                search: search || null,
                pageSize: PAGE_SIZE,
                ascending: false,
                sectionId: selectedSection?.id ?? null,
            };

            sectionLoaded &&
                locationId &&
                dispatch(fetchPaymentParties(locationId, searchOptions, options?.forceReLoad, options?.loadMore));
        },
        [sectionLoaded, search, paymentPartiesFilter, locationId, selectedSection, date, dispatch]
    );

    const onUnload = useCallback(() => {
        if (paymentPartiesFilter && paymentPartiesFilter.length) {
            sessionStorage.setItem(partyFilterKey, JSON.stringify(paymentPartiesFilter));
        } else {
            sessionStorage.removeItem(partyFilterKey);
        }
    }, [paymentPartiesFilter]);

    const resetFilter = useCallback(() => {
        dispatch(actionCreators.selectPaymentPartyFilter([]));
    }, [dispatch]);

    const onSearch = useCallback(
        (value: string) => {
            dispatch(actionCreators.sePaymentPartiesSearch(value));
        },
        [dispatch]
    );

    useEffect(() => {
        window.addEventListener("unload", onUnload);
        return () => {
            window.removeEventListener("unload", onUnload);
        };
    }, [onUnload]);

    useEffect(() => {
        fetch({ forceReLoad: true });
    }, [fetch]);

    useEffect(() => {
        return () => {
            dispatch(actionCreators.paymentPartiesReset());
            dispatch(actionCreators.selectPaymentPartyFilter([]));
            dispatch(actionCreators.sePaymentPartiesSearch(""));
        };
    }, [dispatch]);

    useEffect(() => {
        return () => {
            setDate(today);
        };
    }, [setDate, today]);

    return (
        <div className="history-orders">
            <Input.Search allowClear onSearch={onSearch} placeholder="Search table number" />
            <div
                className={classNames("history-orders__filter-message", !!paymentPartiesFilter.length && "show")}
                onClick={resetFilter}
            >
                <Text preset="g-14">
                    {" "}
                    Showing only {paymentPartiesFilter.join(" & ")} parties.&nbsp;
                    <Text preset="g-14" mode="bold">
                        {" "}
                        Clear filter
                    </Text>
                </Text>
            </div>
            <div className="history-orders__table">
                <PartiesTable fetch={fetch} scrollRef={scrollRef} />
            </div>
            <ReloadData
                fetch={() => {
                    scrollRef.current?.scrollTo({ top: 0, behavior: "smooth" });

                    dispatch(actionCreators.paymentPartiesReset());
                    fetch({ forceReLoad: true });
                }}
            />
        </div>
    );
};
