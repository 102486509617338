import "./virtualTable/VirtualiseTable.scss";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ItemProps, TableVirtuoso } from "react-virtuoso";
import { getHistoryPageOrdersFetchedPartyCount } from "../selectors";
import { useDispatch, useSelector } from "react-redux";
import { Party } from "../types";
import classNames from "classnames";
import { FetchOptions } from "./HistoryOrders";
import { AppDispatch } from "features";
import { Spin } from "antd";
import { VirtualTableFooter } from "./virtualTable/VirtualTableFooter";
import {
    getPaymentPartiesLoading,
    getPaymentPartiesOrders,
    getPaymentPartiesTotal,
} from "../selectors/getPaymentParties";
import { PartyRowContent } from "./virtualPartiesTable/PartyRowContent";
import { PartiesHeader } from "./virtualPartiesTable/PartiesHeader";
import { showMorePaymentParties } from "../actions/showMorePaymentParties";
import { VirtualTableComponents } from "./virtualTable/VirtualTableComponents";
import { PaymentsDrawer } from "./PaymentsDrawer";
import { getLocationId } from "features/staffLogin/selectors/getLocationId";
import { fetchPaymentParty } from "../actions/fetchPaymentParty";

interface Props {
    fetch: (options?: FetchOptions) => void;
    scrollRef?: React.MutableRefObject<HTMLElement | Window | null | undefined>;
}

export const PartiesTable = ({ fetch, scrollRef }: Props) => {
    const [parties, setParties] = useState<Party[]>([]);
    const [selectedParty, setSelectedParty] = useState<Party | null>(null);
    const paymentPartiesLoading = useSelector(getPaymentPartiesLoading);
    const paymentParties = useSelector(getPaymentPartiesOrders);
    const partyCount = useSelector(getHistoryPageOrdersFetchedPartyCount);
    const paymentPartiesTotal = useSelector(getPaymentPartiesTotal);
    const locationId = useSelector(getLocationId);

    const dispatch = useDispatch<AppDispatch>();

    const loadMore = useCallback(() => {
        dispatch(showMorePaymentParties());
        fetch({ forceReLoad: false, loadMore: true });
    }, [fetch, dispatch]);

    useEffect(() => {
        if (!paymentPartiesLoading) {
            setParties((parties) => {
                if (scrollRef?.current && paymentParties[0]?.partyId !== parties[0]?.partyId) {
                    scrollRef.current.scrollTo({ top: 0 });
                }
                return paymentParties;
            });
            setSelectedParty((selected) =>
                selected ? paymentParties.find((party) => party.partyId === selected.partyId) ?? null : null
            );
        }
    }, [paymentPartiesLoading, paymentParties, scrollRef]);

    const components = useMemo(
        () => ({
            ...VirtualTableComponents({ loading: paymentPartiesLoading, itemType: "parties" }),
            TableRow: (props: ItemProps<Party>) => (
                <tr
                    className={classNames("ant-table-row", "actionable-row")}
                    data-row-key={`party_${props.item.partyId}`}
                    {...props}
                    onClick={() => setSelectedParty(props.item)}
                />
            ),
        }),
        [paymentPartiesLoading]
    );

    useEffect(() => {
        return () => {
            setParties([]);
            setSelectedParty(null);
        };
    }, []);

    return (
        <>
            <div className="virtual-table__container">
                <TableVirtuoso
                    data={parties}
                    scrollerRef={scrollRef ? (r) => (scrollRef.current = r) : undefined}
                    initialTopMostItemIndex={0}
                    style={{ height: "100%" }}
                    totalCount={partyCount}
                    className={classNames("orders-table", "ant-table", "virtual-table")}
                    components={components}
                    fixedHeaderContent={PartiesHeader}
                    itemContent={(_, party) => <PartyRowContent party={party} />}
                    fixedFooterContent={
                        parties.length
                            ? () => (
                                  <VirtualTableFooter
                                      visible={parties.length < paymentPartiesTotal}
                                      onClick={loadMore}
                                      loading={paymentPartiesLoading}
                                  />
                              )
                            : null
                    }
                />

                {paymentPartiesLoading && !!parties.length && (
                    <div className="virtual-table__loader">
                        <Spin />
                    </div>
                )}
            </div>
            <PaymentsDrawer
                party={selectedParty}
                onClose={() => {
                    selectedParty && locationId && dispatch(fetchPaymentParty(locationId, selectedParty.partyId));
                    setSelectedParty(null);
                }}
            />
        </>
    );
};
