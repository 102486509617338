import { AppDispatch } from "features";
import { AppState } from "features/state";
import { actionCreators, DeviceEnrolmentResult } from "../reducers/enrolment";
import { getEnrolmentInfo, saveEnrolmentInfo, setLastLocationId } from "../api/persistence";
import { auth } from "common/auth";
import { opsApi } from "common/api";
import { SECOND } from "common/utility";
import { regionHelper } from "features/region";

export function enrol(regionId: string, locationId: string, enrolUrl: string) {
    return async function (dispatch: AppDispatch, getState: () => AppState) {
        const status = getState().enrolment.status;

        if (status !== "failed" && status !== "initial") {
            return;
        }

        dispatch(actionCreators.processing());

        const savedState = getEnrolmentInfo();

        if (
            savedState !== null &&
            savedState.location_id === locationId &&
            savedState.expires_at > new Date().getTime()
        ) {
            dispatch(actionCreators.restore(savedState));
            return;
        }

        regionHelper.setLastRegion(regionId);

        setLastLocationId(locationId);

        if (!auth.isAuthenticated()) {
            auth.login(window.location.pathname);
            return;
        }

        const result = await enrolDevice(locationId, auth.getAccessToken()!);

        saveEnrolmentInfo(result);
        dispatch(actionCreators.success(result));
    };
}

async function enrolDevice(locationId: string, accessToken: string): Promise<DeviceEnrolmentResult> {
    const response = await opsApi.fetch(`/staff/auth/device?locationId=${locationId}`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });

    if (!response.ok) {
        throw response;
    }

    const { id, access_token, location_id, expires_in, can_bypass_staff_login } = await response.json();

    return {
        id,
        access_token,
        location_id,
        expires_at: new Date().getTime() + expires_in * SECOND,
        can_bypass_staff_login,
    };
}
