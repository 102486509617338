import { opsApi } from "common/api";
import { ProductDetails, Availability } from "../types";
import { fetchAsStaff } from "../../staffLogin/selectors/getAccessToken";

export const productItems = () => [
    {
        id: "1",
        sku: "sk1",
        displayName: "Avocado test" + Math.round(Math.random() * 10),
        price: 31,
        categories: ["Breakfast", "Grab", "Breakfast", "Grab", "Breakfast", "Grab", "Breakfast", "Grab"],
        status: Availability.AVAILABLE,
    },
    {
        id: "2",
        sku: "sk2",
        displayName: "Avocado1 test",
        price: 32,
        categories: ["Grab"],
        status: Availability.UNAVAILABLE,
        variants: [
            {
                id: "22",
                sku: "sk2",
                displayName: "Avocado1 data variant",
                isVariant: true,
                price: 32,
                categories: ["Grab"],
                status: Availability.AVAILABLE,
            },
            {
                id: "23",
                sku: "sk2",
                displayName: "Avocado1 -3 variant",
                isVariant: true,
                price: 32,
                categories: ["Grab"],
                status: Availability.AVAILABLE,
            },
        ],
    },
    {
        id: "3",
        sku: "sk3",
        displayName: "Avocado2 some",
        price: 33,
        categories: ["Breakfast", "Grab"],
        status: Availability.SOLD_OUT,
    },
    {
        id: "4",
        sku: "sk4",
        displayName: "Avocado3 some",
        price: 34,
        categories: ["Breakfast"],
        status: Availability.AVAILABLE,
    },
];
export const MoreItems = () => [
    {
        id: "11",
        sku: "sk1",
        displayName: "Avocado test" + Math.round(Math.random() * 10),
        price: 31,
        categories: ["Breakfast", "Grab"],
        status: Availability.UNAVAILABLE,
    },
    {
        id: "12",
        sku: "sk2",
        displayName: "Avocado1 test",
        price: 32,
        categories: ["Grab"],
        status: Availability.UNAVAILABLE,
    },
    {
        id: "13",
        sku: "sk3",
        displayName: "Avocado2 ava",
        price: 33,
        categories: ["Breakfast", "Grab"],
        status: Availability.SOLD_OUT,
        variants: [
            {
                id: "22",
                sku: "sk2",
                displayName: "Avocado1 variant",
                isVariant: true,
                price: 32,
                categories: ["Grab"],
                status: Availability.AVAILABLE,
            },
            {
                id: "23",
                sku: "sk2",
                displayName: "Avocado1 -3 variant",
                isVariant: true,
                price: 32,
                categories: ["Grab"],
                status: Availability.AVAILABLE,
            },
        ],
    },
    {
        id: "14",
        sku: "sk4",
        displayName: "Avocado3 last",
        price: 34,
        categories: ["Breakfast"],
        status: Availability.AVAILABLE,
    },
];

const mapProduct = (product: ProductDetails, index: number) => {
    return {
        ...product,
        key: `products_${index}_${product.sku}`,
        children:
            product.variants &&
            product.variants.map((v, i) => {
                return {
                    ...v,
                    isVariant: true,
                    key: `products_${index}_${product.sku}_${i}`,
                };
            }),
    };
};

export async function fetchProductsApi(locationId: string): Promise<ProductDetails[]> {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { locationId }, fetchAsStaff(false));
    return res.data.productAvailability.map(mapProduct);
}

type GraphQLPayload = { productAvailability: ProductDetails[] };

const query: string = `query ($locationId: ID!) {
    productAvailability(locationId: $locationId) {
        sku
        displayName
        price
        categories
        status
        internalName
        variants {
            sku
            displayName
            price
            categories
            status
        }
    }
}`;
