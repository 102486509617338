import { AppDispatch } from "features";
import { getSectionsState } from "features/floorManager/selectors/getSections";
import { AppState } from "features/state";
import { actionCreators } from "../reducers";
import { fetchPaymentParty } from "../actions/fetchPaymentParty";
import { forceChargePartyApi } from "../api/sendForceCharge";
import { Party } from "../types";

export const chargeParty = (party: Party, locationId: string, onFailed?: (error: any) => void) => {
    return async function (dispatch: AppDispatch, getState: () => AppState) {
        const state = getState();
        const { status } = getSectionsState(state);
        if (status === "unloaded" || status === "loading") return;

        dispatch(actionCreators.chargePartyProcessing());
        try {
            const newParty = await forceChargePartyApi(party.partyId, locationId);
            if (!newParty) {
                throw "Could not charge party";
            }

            dispatch(actionCreators.chargePartyProcessed(newParty));
        } catch (e) {
            onFailed?.(e);
            dispatch(fetchPaymentParty(locationId, party.partyId));
            dispatch(actionCreators.chargePartyFailed(e));
        }
    };
};
