import { AppDispatch, AppState } from "features/index";
import { normalizeError } from "../../../../common/error";
import { fetchClosedParties, fetchClosedParty } from "../api";
import { createAction } from "../reducers";
import { ClosedParties, ClosedParty } from "../types";

export function getClosedParties(
    locationId: string,
    skip: number,
    limit: number,
    sectionId: string | null,
    searchOrder?: string
) {
    return async function (dispatch: AppDispatch, getState: () => AppState) {
        dispatch(createAction.closedPartiesLoading());

        try {
            const result: ClosedParties = await fetchClosedParties(
                locationId,
                skip,
                limit,
                sectionId,
                searchOrder || null
            );
            dispatch(createAction.closedPartiesLoaded(result));
        } catch (e) {
            dispatch(createAction.closedPartiesFailed(normalizeError(e)));
        }
        return true;
    };
}

export function getClosedParty(locationId: string, closedParty: ClosedParty) {
    return async function (dispatch: AppDispatch, getState: () => AppState) {
        try {
            closedParty.order = await fetchClosedParty(locationId, closedParty.id);
            dispatch(createAction.showClosedParty(closedParty));
        } catch (e) {
            dispatch(createAction.showClosedParty(undefined));
        }
        return true;
    };
}
