export type State = { autoExtend: boolean; token: string } & (
    | { status: "unauthenticated" }
    | { status: "processing" }
    | { status: "failed"; error: any }
    | ({ status: "authenticated" } & AuthenticatedState)
);

type AuthenticatedState = {
    token: string;
    locationId: string;
    expiresAt: number;
    lastAccessed: number;
    extending: boolean;
    name: string;
    userId: string;
    accessLevelName: string;
};

export type StaffLoginStatus = State["status"];

const initialState: State = { status: "unauthenticated", token: "", autoExtend: false };

export enum TypeKeys {
    PROCESSING = "STAFF_LOGIN/PROCESSING",
    RESTORE = "STAFF_LOGIN/RESTORE",
    SUCCESS = "STAFF_LOGIN/SUCCESS",
    FAILED = "STAFF_LOGIN/FAILED",
    RESET = "STAFF_LOGIN/RESET",
    ACCESSED = "STAFF_LOGIN/ACCESSED",
    EXTENDING = "STAFF_LOGIN/EXTENDING",
    EXTENDED = "STAFF_LOGIN/EXTENDED",
    AUTOEXTEND_CHANGED = "STAFF_LOGIN/AUTOEXTEND_CHANGED",
}

export const actionCreators = {
    autoExtend: (enabled: boolean) => ({ type: TypeKeys.AUTOEXTEND_CHANGED, enabled }),
    processing: () => ({ type: TypeKeys.PROCESSING }),
    failed: (error: any) => ({ type: TypeKeys.FAILED, error }),
    success: (
        token: string,
        locationId: string,
        expiresAt: number,
        name: string,
        userId: string,
        accessLevelName: string
    ) => ({
        type: TypeKeys.SUCCESS,
        token,
        locationId,
        expiresAt,
        name,
        userId,
        accessLevelName,
    }),
    reset: () => ({ type: TypeKeys.RESET }),
    accessed: () => ({ type: TypeKeys.ACCESSED }),
    extending: () => ({ type: TypeKeys.EXTENDING }),
    extended: (
        token: string,
        locationId: string,
        expiresAt: number,
        name: string,
        userId: string,
        accessLevelName: string
    ) => ({
        type: TypeKeys.EXTENDED,
        token,
        locationId,
        expiresAt,
        name,
        userId,
        accessLevelName,
    }),
};

export type StaffLoginAction =
    | ({ type: TypeKeys.SUCCESS } & AuthenticatedState)
    | ({ type: TypeKeys.RESTORE } & AuthenticatedState)
    | { type: TypeKeys.FAILED; error: any }
    | { type: TypeKeys.PROCESSING }
    | { type: TypeKeys.RESET }
    | { type: TypeKeys.ACCESSED }
    | { type: TypeKeys.EXTENDING }
    | ({ type: TypeKeys.EXTENDED } & AuthenticatedState)
    | { type: TypeKeys.AUTOEXTEND_CHANGED; enabled: boolean };

export function reducer(state = initialState, action: StaffLoginAction): State {
    if (action.type === TypeKeys.SUCCESS || action.type === TypeKeys.RESTORE || action.type === TypeKeys.EXTENDED) {
        const { token, locationId, expiresAt, name, userId, accessLevelName } = action;
        return {
            ...state,
            status: "authenticated",
            token,
            locationId,
            expiresAt,
            lastAccessed: new Date().getTime(),
            extending: false,
            name,
            userId,
            accessLevelName,
        };
    }

    if (action.type === TypeKeys.EXTENDING && state.status === "authenticated") {
        return {
            ...state,
            extending: true,
        };
    }

    if (action.type === TypeKeys.ACCESSED && state.status === "authenticated") {
        return {
            ...state,
            lastAccessed: new Date().getTime(),
        };
    }

    if (action.type === TypeKeys.RESET) {
        return initialState;
    }

    if (action.type === TypeKeys.FAILED) {
        return { autoExtend: state.autoExtend, token: "", status: "failed", error: action.error };
    }

    if (action.type === TypeKeys.PROCESSING) {
        return { autoExtend: state.autoExtend, token: "", status: "processing" };
    }

    if (action.type === TypeKeys.AUTOEXTEND_CHANGED) {
        return { ...state, autoExtend: action.enabled };
    }

    return state;
}
