import { AppDispatch } from "../../index";
import { closeGroupTabApi } from "../api/sendCloseGroupTabRequest";
import { actionCreators } from "../reducers";
import { trackEvent } from "common/AppInsights/AppInsights";

export const closeGroupTab = (groupTabId: string, locationId: string, updateGroupTabInOrder: boolean) => {
    return async (dispatch: AppDispatch) => {
        dispatch(actionCreators.closeGroupTabProcessing());
        try {
            const groupTabResponse = await closeGroupTabApi(groupTabId, locationId);
            dispatch(actionCreators.closeGroupTabProcessed(groupTabResponse.groupTab, updateGroupTabInOrder));

            trackEvent({
                name: "GROUP_TAB/TAB_CLOSED",
                properties: {
                    grouptab_id: groupTabId,
                },
            });
        } catch (e) {
            dispatch(actionCreators.closeGroupTabFailed(e));
        }
    };
};
