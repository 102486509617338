import { Indicator } from "components/indicator/Indicator";
import { Text } from "components/text";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { actionCreator } from "../reducers/manageSection";
import { getDisableSection } from "../selectors/getManangeSection";
import { Availability, DisableReason, DisableSection, SECONDARY_REASONS, SubReason } from "../types";

export const DisableSectionSubReason = ({ timeManuallySelected }: { timeManuallySelected: boolean }) => {
    const editAvailability = useSelector(getDisableSection);
    const dispatch = useDispatch();
    const { disableReason, disableSubReason } = editAvailability || {};
    const selectedSubReasons = disableReason ? SECONDARY_REASONS[disableReason] : undefined;
    const offlineDurationSelected = timeManuallySelected && editAvailability?.offlineDurationMinutes !== null;

    const setDisableSection = useCallback(
        (editAvailability?: DisableSection) => {
            dispatch(actionCreator.setDisableSection(editAvailability));
        },
        [dispatch]
    );

    const getDefaultDuration = (reason: SubReason) => {
        if (offlineDurationSelected) return editAvailability!.offlineDurationMinutes;
        if (disableReason === DisableReason.POS) return 15;
        if (reason.hasOwnProperty("defaultDuration")) return reason.defaultDuration;
        return null;
    };

    const getAvailability = (reason: SubReason) => {
        if (offlineDurationSelected) return editAvailability!.availability;
        if (reason.hasOwnProperty("defaultDuration") && reason.defaultDuration === undefined) return Availability.DAY;
        else return Availability.LATER;
    };
    if (selectedSubReasons) {
        return (
            <>
                <Text preset="g-14" mode="bold" className="disable-section__field-title-required">
                    Issue
                </Text>
                <div className="disable-section__sub-reason">
                    {selectedSubReasons.map((reason: SubReason) => (
                        <Indicator
                            key={reason.value}
                            className="disable-section__sub-reason__indicator"
                            primary={disableSubReason === reason.value}
                            onClick={() =>
                                setDisableSection({
                                    ...editAvailability!,
                                    disableSubReason: reason.value,
                                    offlineDurationMinutes: getDefaultDuration(reason),
                                    availability: getAvailability(reason),
                                })
                            }
                        >
                            {reason.label}
                        </Indicator>
                    ))}
                </div>
            </>
        );
    }
    return null;
};
