import { ActiveParty } from "common/types";
import { Section } from "features/floorManager/types";
import { BumpActiveParty, BumpScreenState, BumpScreenType, ClosedParties, ClosedParty } from "../types";

export type State = BumpScreenState;

export enum TypeKeys {
    ACTIVE_PARTIES_LOADED = "BUMP_SCREEN/ACTIVE_PARTIES/LOADED",
    ACTIVE_PARTY_UPDATED = "BUMP_SCREEN/ACTIVE_PARTY/UPDATED",
    ACTIVE_PARTY_CLOSED = "BUMP_SCREEN/ACTIVE_PARTY/CLOSED",
    ACTIVE_PARTY_CHANGE_ORDER_STATUS = "BUMP_SCREEN/ACTIVE_PARTY/CHANGE_ORDER_STATUS",
    ACTIVE_PARTY_CHANGE_ORDER_SCHEDULED_TIME = "BUMP_SCREEN/ACTIVE_PARTY/CHANGE_ORDER_SCHEDULED_TIME",
    ACTIVE_PARTY_CANCEL_AND_REFUND = "BUMP_SCREEN/ACTIVE_PARTY/CANCEL_AND_REFUND",
    CLOSED_LOADING = "BUMP_SCREEN/CLOSED/LOADING",
    CLOSED_LOADED = "BUMP_SCREEN/CLOSED/LOADED",
    CLOSED_UPDATED = "BUMP_SCREEN/CLOSED/UPDATED",
    CLOSED_FAILED = "BUMP_SCREEN/CLOSED/FAILED",
    CHANGE_VIEW = "BUMP_SCREEN/CHANGE_VIEW",
    SELECT_SECTION = "BUMP_SCREEN/SELECT_SECTION",
    CLOSED_SEARCH = "BUMP_SCREEN/CLOSED_SEARCH",
    SHOW_CLOSED_PARTY = "BUMP_SCREEN/SHOW_CLOSED_PARTY",
}

export const createAction = {
    activePartiesLoaded: (parties: BumpActiveParty[]) => ({ type: TypeKeys.ACTIVE_PARTIES_LOADED, parties }),
    activePartyUpdated: (party: BumpActiveParty) => ({ type: TypeKeys.ACTIVE_PARTY_UPDATED, party }),
    activePartyClosed: (party: ActiveParty) => ({ type: TypeKeys.ACTIVE_PARTY_CLOSED, party }),
    changeOrderStatus: (partyId: string, orderIds: string | string[]) => ({
        type: TypeKeys.ACTIVE_PARTY_CHANGE_ORDER_STATUS,
        partyId,
        orderIds,
    }),
    changeOrderScheduledTime: (party: ActiveParty, dateScheduled: string) => ({
        type: TypeKeys.ACTIVE_PARTY_CHANGE_ORDER_SCHEDULED_TIME,
        party,
        dateScheduled,
    }),
    cancelAndRefund: (party: ActiveParty, orderIds: string | string[]) => ({
        type: TypeKeys.ACTIVE_PARTY_CANCEL_AND_REFUND,
        party,
        orderIds,
    }),
    closedPartiesLoading: () => ({ type: TypeKeys.CLOSED_LOADING }),
    closedPartiesLoaded: (data: ClosedParties) => ({ type: TypeKeys.CLOSED_LOADED, data }),
    closedPartiesFailed: (error: any) => ({ type: TypeKeys.CLOSED_FAILED, error }),
    selectSection: (selectedSection: Section | undefined) => ({ type: TypeKeys.SELECT_SECTION, selectedSection }),
    changeView: (mode: BumpScreenType) => ({ type: TypeKeys.CHANGE_VIEW, mode }),
    closePartiesSearch: (searchValue?: string) => ({ type: TypeKeys.CLOSED_SEARCH, searchValue }),
    showClosedParty: (closedParty?: ClosedParty) => ({ type: TypeKeys.SHOW_CLOSED_PARTY, closedParty }),
};

type BumpScreenActivePartiesLoadedAction = { type: TypeKeys.ACTIVE_PARTIES_LOADED; parties: BumpActiveParty[] };
type BumpScreenActivePartyUpdatedAction = { type: TypeKeys.ACTIVE_PARTY_UPDATED; party: BumpActiveParty };
type BumpScreenActivePartyClosedAction = { type: TypeKeys.ACTIVE_PARTY_CLOSED; party: ActiveParty };
export type BumpScreenActivePartyChangeOrderStatusAction = {
    type: TypeKeys.ACTIVE_PARTY_CHANGE_ORDER_STATUS;
    partyId: string;
    orderIds: string | string[];
};
export type BumpScreenActivePartyChangeOrderScheduledTimeAction = {
    type: TypeKeys.ACTIVE_PARTY_CHANGE_ORDER_SCHEDULED_TIME;
    party: BumpActiveParty;
    dateScheduled: string;
};
export type BumpScreenActivePartyCancelAndRefundAction = {
    type: TypeKeys.ACTIVE_PARTY_CANCEL_AND_REFUND;
    party: BumpActiveParty;
    orderIds: string | string[];
};
type BumpScreenClosedLoadingAction = { type: TypeKeys.CLOSED_LOADING };
type BumpScreenClosedLoadedAction = { type: TypeKeys.CLOSED_LOADED; data: ClosedParties };
type BumpScreenClosedUpdatedAction = { type: TypeKeys.CLOSED_UPDATED; sku: string };
type BumpScreenClosedFailedAction = { type: TypeKeys.CLOSED_FAILED; error: any };
type BumpScreenSelectSectionAction = { type: TypeKeys.SELECT_SECTION; selectedSection: Section | undefined };
type BumpScreenChangeViewAction = { type: TypeKeys.CHANGE_VIEW; mode: BumpScreenType };
type BumpScreenCloseSearchAction = { type: TypeKeys.CLOSED_SEARCH; searchValue?: string };
type BumpScreenShowClosedPartyAction = { type: TypeKeys.SHOW_CLOSED_PARTY; closedParty?: ClosedParty };

export type BumpScreenAction =
    | BumpScreenActivePartiesLoadedAction
    | BumpScreenActivePartyUpdatedAction
    | BumpScreenActivePartyClosedAction
    | BumpScreenActivePartyChangeOrderStatusAction
    | BumpScreenActivePartyChangeOrderScheduledTimeAction
    | BumpScreenClosedLoadingAction
    | BumpScreenClosedLoadedAction
    | BumpScreenClosedUpdatedAction
    | BumpScreenClosedFailedAction
    | BumpScreenSelectSectionAction
    | BumpScreenCloseSearchAction
    | BumpScreenShowClosedPartyAction
    | BumpScreenActivePartyCancelAndRefundAction
    | BumpScreenChangeViewAction;

const initialState: State = {
    mode: BumpScreenType.ACTIVE,
    closedParties: { status: "unloaded" },
    activeParties: [],
};

export const reducer = (state: State = initialState, action: BumpScreenAction): State => {
    if (action.type === TypeKeys.ACTIVE_PARTIES_LOADED) {
        const { parties } = action;

        return {
            ...state,
            activeParties: parties,
        };
    }

    if (action.type === TypeKeys.ACTIVE_PARTY_UPDATED) {
        const { party } = action;

        const partyIdx = state.activeParties.findIndex((p) => p.id === party.id);

        if (partyIdx === -1) {
            state.activeParties.push(party);
        } else {
            state.activeParties[partyIdx] = party;
        }

        return {
            ...state,
            activeParties: [...state.activeParties],
        };
    }

    if (action.type === TypeKeys.ACTIVE_PARTY_CLOSED) {
        const { party } = action;

        const activeParty = state.activeParties.find((p) => p.id === party.id);
        if (!activeParty) return state;

        const parties = state.activeParties.filter((p) => p !== activeParty);

        return {
            ...state,
            activeParties: parties,
        };
    }

    if (action.type === TypeKeys.ACTIVE_PARTY_CHANGE_ORDER_SCHEDULED_TIME) {
        const { party, dateScheduled } = action;

        const partyIdx = state.activeParties.findIndex((p) => p.id === party.id);
        if (partyIdx === -1) return state;

        state.activeParties[partyIdx] = {
            ...state.activeParties[partyIdx],
            dateScheduled: new Date(dateScheduled).getTime(),
        };

        return {
            ...state,
            activeParties: [...state.activeParties],
        };
    }

    if (action.type === TypeKeys.ACTIVE_PARTY_CANCEL_AND_REFUND) {
        const { party } = action;

        return {
            ...state,
            activeParties: state.activeParties.filter((p) => p.id !== party.id),
        };
    }

    if (action.type === TypeKeys.ACTIVE_PARTY_CHANGE_ORDER_STATUS) {
        const { partyId } = action;

        const partyIdx = state.activeParties.findIndex((p) => p.id === partyId);
        if (partyIdx === -1) return state;

        state.activeParties[partyIdx] = {
            ...state.activeParties[partyIdx],
            statusChanging: true,
        };

        return {
            ...state,
            activeParties: [...state.activeParties],
        };
    }

    if (action.type === TypeKeys.CLOSED_LOADING) {
        return {
            ...state,
            closedParties: { status: "loading" },
        };
    }

    if (action.type === TypeKeys.CLOSED_LOADED) {
        const { data } = action;
        return {
            ...state,
            closedParties: {
                status: "loaded",
                data,
            },
        };
    }

    if (action.type === TypeKeys.CLOSED_FAILED) {
        const { error } = action;
        return {
            ...state,
            closedParties: {
                status: "failed",
                error,
            },
        };
    }

    if (action.type === TypeKeys.CHANGE_VIEW) {
        const { mode } = action;
        return {
            ...state,
            mode,
        };
    }

    if (action.type === TypeKeys.SELECT_SECTION) {
        const { selectedSection } = action;
        return {
            ...state,
            selectedSection,
        };
    }

    if (action.type === TypeKeys.CLOSED_SEARCH) {
        return {
            ...state,
            closedSearchValue: action.searchValue,
        };
    }

    if (action.type === TypeKeys.SHOW_CLOSED_PARTY) {
        return {
            ...state,
            closedParty: action.closedParty,
        };
    }

    return state;
};
