import { GroupTab, OrderStatus as ConfirmedOrderStatus, Payment, TaxTotal } from "features/orders/types";

export interface ActiveParty {
    id: string;
    tableNumber: string;
    deliveryOptions?: DeliveryOption[];
    submittedOrders: Order[];
    sectionId: string | null;
    members: PartyMember[];
    dateClosed: string | null;
    sortDate: string | null;
    payments: PartyPayment[];
    refunds?: PartyRefund[];
    type: OrderHubPartyType;
    orderWithPayment?: OrderWithPayment | null;
    ordersWithoutPayment?: OrderWithoutPayment[] | null;
    paymentsAmount?: number;
    paymentsTotal?: number;
    pointsPaymentsTotal?: number;
    refundableTotal?: number;
    refundedTotal?: number;
    tipsTotal?: number;
    gratuityTotal?: number;
    processingFeeTotal?: number;
    refundablePaymentId?: string;
    groupTab?: GroupTab;
    dateCollected?: string;
    dateSubmitted?: string;
    sectionName?: string;
    dateOpened: string;
    isTakeaway: boolean;
    taxTotals?: TaxTotal[];
    securedPaymentState?: SecuredPaymentStateType | null;
    status: PartyStatus;
    isFlexTab: boolean;
    openTableOrderFlowBehaviorFlags?: OrderHubOrderFlowBehaviorFlags;
}

export enum OrderHubOrderFlowBehaviorFlags {
    Ordering = 1,
    Paying = 2,
    Tab = 4,
}

export enum PartyStatus {
    OPEN = "OPEN",
    CLOSED = "CLOSED",
    PENDING_CLOSE = "PENDINGCLOSE",
}

export enum SecuredPaymentStateType {
    NOT_REQUIRED = "NOTREQUIRED",
    REQUIRED = "REQUIRED",
    SATISFIED = "SATISFIED",
}

export enum PartyType {
    MULTIUSER = "MULTIUSER",
    SINGLEUSER = "SINGLEUSER",
    PAYONLY = "PAYONLY",
}

export enum OrderHubPartyType {
    MULTIUSER = 0,
    SINGLEUSER = 1,
    PAYONLY = 2,
}

export interface Bill {
    adjustments: BillAdjustment[];
}
export interface BillAdjustment {
    value: number;
    name: string;
    promotionId?: string | null;
    type: string;
}

export interface DeliveryOption {
    type: "table" | "pickup" | "takeaway";
    name: string;
    menuItemType: string;
}

export interface OrderBase {
    id?: string;
    items: OrderItem[];
}

export interface Order extends OrderBase {
    status: OrderStatus;
    orderStatusHistory: OrderStatusChange[];
    dateSubmitted: string;
    dateScheduled?: string;
    originalDateScheduled?: string;
    dateCollected?: string;
    isMerged: boolean | null;
    posReferenceId: string | null;
    bill: Bill;
    unconfirmedOrders?: UnconfirmedOrder[];
    orderSubmittedCount?: number;
}

export interface OrderPromotion {
    name: string;
    amount: number;
}

export enum OrderStatus {
    ACCEPTED = 2,
    SUBMITFAILED = 4,
    REJECTED = 11,
    ORPHANED = 14,
    READY = 15,
    COLLECTED = 16,
    ACCEPTEDOVERDUE = 17,
    READYOVERDUE = 18,
    RESUBMITTING = 21,
    RESUBMITTED = 22,
}
export enum PaymentStatus {
    PENDING = 0,
    COMPLETE = 1,
    FAILED = 2,
}

export enum PosPaymentStatus {
    SUBMITTING = 0,
    ACCEPTED = 1,
    REJECTED = 2,
    RESOLVED = 3,
}

export interface OrderStatusChange {
    from: OrderStatus;
    to: OrderStatus;
    timestamp: string;
}

export interface OrderItem {
    id: string;
    quantity: number;
    displayName: string;
    modifiers: OrderItemModifier[] | null;
    mappedModifiers: ItemModifierOption[] | null;
    menuItemType?: string;
    notes?: string;
    memberNotes?: string;
    price?: number;
    lineTotal: number;
    priceIncludingTaxes?: number;
}

export interface OrderItemModifier {
    selectedOptions?: OrderItemModifierOption[];
    displayName?: string;
    optionIndex?: number;
    optionNestedModifiers?: OrderItemModifier[];
    modifiers?: OrderItemModifier[];
    nestedModifiers?: OrderItemNestedModifier[];
}

export interface ItemModifierOption {
    displayName?: string;
    nestedModifiers?: OrderItemNestedModifier[];
    optionIndex?: number;
}

export interface OrderItemNestedModifier {
    displayName?: string;
    nestingLevel?: number;
}

export interface OrderItemModifierOption {
    optionIndex?: number;
    displayName: string;
}

export interface PartyMember {
    memberName: string;
    displayName: string;
    phoneNumber?: string;
    payments?: Payment[];
    memberId?: string;
    hasSecuredPayment?: boolean;
    id?: string;
}

export interface PartyPayment extends IPayment {
    processingFeeAbsorbed: boolean;
}

export interface PartyRefund extends IPayment {
    refundId: string;
    paymentId: string;
}

export interface PaymentError {
    code: string;
}

export interface IPayment {
    transactionTotal: number;
    transactionId?: string;
    date: string;
    id: string;
    memberId: string;
    membershipDiscount?: number;
    memberDiscount?: number; // used for parties pushed by HUB, for OPS-API we are using membershipDiscount
    subtotal: number;
    surchargeAmount: number;
    discountAmount: number;
    amount: number;
    orderAmount: number;
    tip: number;
    gratuity?: number;
    processingFee: number;
    attemptedPaymentMethods: string;
    paymentMethodId?: string;
    status: PaymentStatus | string | null; // Enum is from HUB (cosmos) string is from OPS-API (reporting)
    posStatus: PosPaymentStatus | string | null; // Enum is from HUB (cosmos) string is from OPS-API (reporting)
    venueServiceFeeAmount?: number;
    venueServiceFeePercentage?: number;
    refundableTotal?: number;
    owner?: string;
    ownerName: string;
    dateCreated?: string;
    taxSummary?: TaxTotal[];
    isForceCharge: boolean;
    error: PaymentError | null;
}

export interface OrderWithoutPayment {
    confirmationStatus: ConfirmedOrderStatus;
    dateSubmitted?: string;
    isMerged?: boolean;
    items: OrderItem[];
    orderTotal: number;
    id: string;
    posReferenceId?: string;
    orderSubmittedCount: number;
}

export type Surcharge = {
    percentage?: number;
    total: number;
};

export interface OrderTrayCharge {
    name: string;
    amount: number;
}

export interface OrderWithPayment extends OrderWithoutPayment {
    posTotal?: number;
    subtotal?: number;
    totalIncludingTaxes?: number;
    promotions?: OrderPromotion[] | null;
    cepOffers?: OrderPromotion[] | null;
    unconfirmedOrders?: UnconfirmedOrder[];
    surcharge?: Surcharge;
    discount?: number;
    membershipDiscount?: number;
    trayCharges?: OrderTrayCharge[] | null;
    venueCauses?: OrderVenueCause[] | null;
    stampsEarned?: number | null;
}

export interface UnconfirmedOrder {
    orderId: string;
}

export interface OrderVenueCause {
    name: string;
    amount: number;
}
