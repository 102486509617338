import React, { useEffect } from "react";
import { GroupedClosedParty, ClosedParty } from "../../shared/types";
import { Table, Input } from "antd";
import "./ClosedPartiesContent.scss";
import { ColumnProps } from "antd/lib/table";
import { OrderCardModalContainer } from "../containers/OrderCardModalContainer";

const dateFormatter = new Intl.DateTimeFormat("default", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
});
const tableHeight = window.innerHeight - 234;
const ROWS = Math.floor(tableHeight / 50 - 3);
export interface Props {
    parties?: GroupedClosedParty[];
    locationId?: string;
    searchValue?: string;
    loading?: boolean;
    totalCount: number;
    sectionId: string | null;
    loadParties: (
        locationId: string,
        skip: number,
        limit: number,
        section: string | null,
        searchOrder?: string
    ) => void;
    onSearch: (value: string) => void;
    showParty: (locationId: string, closedParty: ClosedParty) => void;
}

export const ClosedPartiesContent = ({
    loading,
    parties,
    locationId,
    loadParties,
    sectionId,
    searchValue,
    onSearch,
    showParty,
    totalCount,
}: Props) => {
    useEffect(() => {
        locationId && loadParties(locationId, 0, ROWS, sectionId, searchValue);
    }, [loadParties, locationId, searchValue, sectionId]);

    const onSearchTarget = (e: React.ChangeEvent<HTMLInputElement>) => {
        onSearch && onSearch(e.target.value);
    };
    const expandedRowRender = (record: GroupedClosedParty, index: number) => {
        const onRowClick = (item: ClosedParty) => showParty(locationId!, item);
        const innerColumns: ColumnProps<ClosedParty>[] = [
            {
                dataIndex: "tableNumber",
                key: "tableNumber",
                width: columns[0].width,
            },
            {
                dataIndex: "memberName",
                width: columns[1].width,
            },
            {
                dataIndex: "quantity",
                key: "quantity",
                className: "closed-parties--secondary-column",
                align: columns[2].align,
                width: columns[2].width,
                render: (quantity) => {
                    return `QTY ${quantity}`;
                },
            },
            {
                dataIndex: "dateSubmitted",
                key: "dateSubmitted",
                className: "closed-parties--secondary-column",
                align: columns[3].align,
                width: columns[3].width,
                render: (date) => {
                    return dateFormatter.format(new Date(date));
                },
            },
            {
                dataIndex: "timePast",
                key: "timePast",
                className: "closed-parties--secondary-column",
                align: columns[4].align,
                width: columns[4].width,
            },
            {
                key: "View",
                align: columns[5].align,
                width: columns[5].width,
                render: () => <b>View order</b>,
            },
        ];
        return (
            <Table
                className="closed-parties--inner-table"
                key={`closed-parties--inner-table${index}_${record.displayName}`}
                rowKey={(item) => `"closed-parties--inner_${index}_${item.tableNumber}_${item.dateSubmitted}`}
                onRow={(record) => ({ onClick: () => onRowClick(record) })}
                columns={innerColumns}
                showHeader={false}
                dataSource={record.data}
                pagination={false}
            />
        );
    };

    return (
        <div className="closed-parties">
            <Input.Search
                placeholder="Search by order ID"
                className="closed-parties--search"
                allowClear
                onChange={onSearchTarget}
                value={searchValue}
            />
            <Table
                className="closed-parties--outer-table"
                columns={columns}
                scroll={{ y: window.innerHeight - 234 }}
                expandedRowKeys={["closed-parties--outer_0", "closed-parties--outer_1", "closed-parties--outer_2"]}
                expandRowByClick
                expandIcon={() => null}
                rowKey={(item, index) => `closed-parties--outer_${index}`}
                expandedRowRender={expandedRowRender}
                dataSource={parties}
                loading={loading}
                pagination={{
                    total: totalCount,
                    pageSize: ROWS,
                    onChange: (page: number) => loadParties(locationId!, --page * ROWS, ROWS, sectionId, searchValue),
                }}
            />
            <OrderCardModalContainer />
        </div>
    );
};

const columns: ColumnProps<GroupedClosedParty>[] = [
    {
        title: "Order",
        dataIndex: "displayName",
        key: "displayName",
        className: "closed-parties--time-range",
        align: "left",
        width: "28vw",
    },
    {
        title: "Name",
        key: "Name",
        width: "18vw",
    },
    {
        title: "Items ordered",
        key: "Items ordered",
        className: "closed-parties--secondary-column",
        align: "right",
        width: 130,
    },
    {
        title: "Order placed",
        key: "Order placed",
        className: "closed-parties--secondary-column",
        align: "right",
        width: 125,
    },
    {
        title: "Time since closed",
        key: "Time since closed",
        className: "closed-parties--secondary-column",
        align: "right",
        width: 170,
    },
    {
        title: "",
        key: "view",
        align: "right",
        width: 120,
    },
];
