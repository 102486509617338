import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { StaffLoginStatus } from "../reducers/staffLogin";
import "./StaffLogin.scss";
import { MonitorEnrolment } from "features/enrolment/containers/MonitorEnrolment";

export interface Props {
    status: StaffLoginStatus;
    login: (pin: string) => void;
    reset: () => void;
    bypassLogin?: () => void;
}

export const StaffLogin = ({ status, login, bypassLogin, reset }: Props) => {
    const [pin, setPin] = useState("");
    useEffect(() => {
        let timer: any;
        if (status === "failed") {
            console.log("failed");
            timer = window.setTimeout(() => {
                setPin("");
                reset();
            }, 350);
        }

        if (status === "authenticated") {
            setPin("");
        }

        return () => clearTimeout(timer);
    }, [status, reset]);

    useEffect(() => {
        if (pin.length === 4) {
            login(pin);
        }
    }, [pin, login]);

    return (
        <form className="staff-login">
            {status !== "authenticated" && <MonitorEnrolment />}
            <div className="staff-login__title-container">
                <h3 className="staff-login__title">Enter your code to start</h3>
                {bypassLogin && (
                    <button className={`staff-login__bypass-button`} onClick={bypassLogin} type="button">
                        Bypass
                    </button>
                )}
            </div>
            <div className={`staff-login__cipher ${status === "failed" && "error"}`}>
                <span className={`${pin.length >= 1 && "active"}`} />
                <span className={`${pin.length >= 2 && "active"}`} />
                <span className={`${pin.length >= 3 && "active"}`} />
                <span className={`${pin.length === 4 && "active"}`} />
            </div>
            <input className="staff-login__input" onChange={(e) => setPin(e.target.value)} value={pin} readOnly />
            <div className="staff-login__keypad">
                <div className="staff-login__keypad__row">
                    {[1, 2, 3].map((d, i) => renderDigits(d, pin, setPin, i))}
                </div>
                <div className="staff-login__keypad__row">
                    {[4, 5, 6].map((d, i) => renderDigits(d, pin, setPin, i))}
                </div>
                <div className="staff-login__keypad__row">
                    {[7, 8, 9].map((d, i) => renderDigits(d, pin, setPin, i))}
                </div>
                <div className="staff-login__keypad__row">
                    {[null, 0, null].map((d, i) => renderDigits(d, pin, setPin, i))}
                </div>
            </div>
        </form>
    );
};

const renderDigits = (digit: number | null, pin: string, setPin: Dispatch<SetStateAction<string>>, index: number) => {
    return (
        <button
            className={`staff-login__keypad__row__number ${digit === null && "empty"}`}
            onClick={() => digit !== null && setPin(pin + digit.toString())}
            type="button"
            disabled={pin.length === 4}
            key={digit ? "digit" + digit : "null" + index}
        >
            {digit}
        </button>
    );
};
