import * as React from "react";
import { FeatureConfiguration } from "..";
import { Route } from "react-router";
import { sectionMiddleware } from "./middleware/sectionMiddleware";
import { FloorManagerPage } from "./components/FloorManagerPage";
import { reducer } from "./reducers";

export default function (config: FeatureConfiguration) {
    config.reducers.floorManager = reducer;
    config.middleware.push(sectionMiddleware());
    config.addRoutes(config.routes.locationRoutes, [
        <Route key="floor-manager" path={`/floor-manager`} render={() => <FloorManagerPage />} />,
    ]);
}
