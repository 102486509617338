import { Text } from "../../../../components/text";
import { BackIcon, CrossIcon } from "common/icons";
import React from "react";

export interface Props {
    displayOrderId?: string;
    onClose: (cancel: boolean) => void;
    onCloseRefund?: () => void;
    viaTabsCard: boolean | undefined;
    isRefundOpen?: boolean;
}

export const OrderCardHeader = (props: Props) => {
    const { displayOrderId, viaTabsCard, isRefundOpen, onClose, onCloseRefund } = props;

    return (
        <div className="details-card__header">
            <div className="details-card__header-top">
                {isRefundOpen && onCloseRefund && (
                    <button className="details-card__back" onClick={() => onCloseRefund()}>
                        <BackIcon />
                    </button>
                )}
                <Text preset="title-20" value={displayOrderId || ""} />
                {!isRefundOpen &&
                    (viaTabsCard ? (
                        <button className="details-card__back" onClick={() => onClose(true)}>
                            <BackIcon />
                        </button>
                    ) : (
                        <button className="details-card__close" onClick={() => onClose(true)}>
                            <CrossIcon />
                        </button>
                    ))}
            </div>
        </div>
    );
};
