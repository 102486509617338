import { Text } from "components/text";
import React from "react";
import { StatusBadge, StatusBadgeMode } from "sharedComponents/controls/statusBadge";
import { PickupStatus } from "../utils/getPickupStatus";

interface Props {
    pickupStatus: PickupStatus;
    className?: string;
}

export const TakeawayOrderStatusBadge = ({ pickupStatus, className }: Props) => {
    let mode: StatusBadgeMode = "error";

    switch (pickupStatus.status) {
        case "Overdue":
            mode = "error";
            break;
        case "Uncollected":
            mode = "warning";
            break;
        case "Ready":
            mode = "success";
            break;
        case "Preparing":
            mode = "info";
            break;
    }
    return (
        <div className={className}>
            <StatusBadge mode={mode}>
                <Text preset="g-14" mode="bold">
                    {pickupStatus.status}
                </Text>
            </StatusBadge>
        </div>
    );
};
