import _ from "lodash";
import { createSelector } from "reselect";
import { getOrdersState, getSelectedSection } from "./getGlobalState";

export const getActivePaymentPartiesState = createSelector(getOrdersState, (orders) => orders.activePaymentParties);
export const getClosedPaymentPartiesState = createSelector(getOrdersState, (orders) => orders.closedPaymentParties);
export const getSelectedPartyPaymentFilters = createSelector(getOrdersState, (orders) => orders.paymentPartiesFilter);
export const getSelectedPartyPaymentSearch = createSelector(getOrdersState, (orders) => orders.paymentPartiesSearch);

export const getActivePaymentPartiesData = createSelector(getActivePaymentPartiesState, (activePaymentParties) =>
    activePaymentParties?.status === "loaded" ? activePaymentParties.data : []
);

export const getActivePaymentParties = createSelector(
    getActivePaymentPartiesData,
    getSelectedPartyPaymentFilters,
    getSelectedPartyPaymentSearch,
    getSelectedSection,
    (activePaymentParties, filters, search, section) =>
        filters.length || search || section
            ? activePaymentParties?.filter((party) => {
                  let match = !section || party.section === section.displayName;
                  match = match && party.table.includes(search);
                  return (!filters.length || filters.includes("Ongoing")) && match;
              })
            : activePaymentParties
);

export const getClosedPaymentParties = createSelector(getClosedPaymentPartiesState, (closedPaymentParties) =>
    closedPaymentParties?.status === "loaded" ? closedPaymentParties.data : []
);

export const getPaymentPartiesOrders = createSelector(
    getActivePaymentParties,
    getClosedPaymentParties,
    (activePaymentParties, closedPaymentParties) => {
        return _.unionBy(activePaymentParties, closedPaymentParties, "partyId");
    }
);
export const getPaymentPartiesLoading = createSelector(
    getActivePaymentPartiesState,
    getClosedPaymentPartiesState,
    (activePaymentParties, closedPaymentParties) =>
        activePaymentParties.status === "unloaded" ||
        activePaymentParties.status === "loading" ||
        !closedPaymentParties ||
        closedPaymentParties.status === "loading"
);

export const getActivePaymentPartiesCount = createSelector(getActivePaymentParties, (parties) => parties.length);
export const getClosedPaymentPartiessCount = createSelector(getOrdersState, (orders) =>
    orders.closedPaymentParties?.status !== "failed" ? orders.closedPaymentPartiesTotal : undefined
);

export const getPaymentPartiesTotal = createSelector(
    getActivePaymentPartiesCount,
    getClosedPaymentPartiessCount,
    (activePaymentPartiesTotal = 0, closedPaymentPartiesTotal = 0) =>
        activePaymentPartiesTotal + closedPaymentPartiesTotal
);
