import { AppDispatch } from "../../index";
import { getTabById } from "../api/getTabById";
import { actionCreators } from "../reducers";

export const fetchTabById = (groupTabId: string, locationId: string) => {
    return async (dispatch: AppDispatch) => {
        dispatch(actionCreators.getTabByIdLoading());
        try {
            const groupTabResponse = await getTabById(groupTabId, locationId);
            dispatch(actionCreators.getTabByIdLoaded(groupTabResponse.groupTab));
        } catch (e) {
            dispatch(actionCreators.getTabByIdFailed(e));
        }
    };
};
