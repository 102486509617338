import { AppDispatch } from "../../index";
import { createAction } from "../reducers";
import { fetchModifiersApi } from "../api";

export const fetchModifiers = (locationId: string) => {
    return async function (dispatch: AppDispatch) {
        dispatch(createAction.modifiersLoading());
        try {
            const modifiers = await fetchModifiersApi(locationId);
            dispatch(createAction.modifiersLoaded(modifiers));
        } catch (e) {
            dispatch(createAction.modifiersFailed(e));
        }
    };
};
