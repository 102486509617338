import { BellIcon, WarningIcon } from "common/icons";
import { OrderStatus as OrderHistoryStatus } from "common/types";
import { LocationLocale } from "features/location/types/LocationLocale";
import React from "react";
import { BumpActiveParty } from "../../shared/types";

export type OrderStatus = "Preparing" | "Ready" | "Overdue" | "Uncollected";

export interface PickupStatus {
    status: OrderStatus;
    msg: string;
    icon?: JSX.Element;
}

export const getPickupStatus = (party: BumpActiveParty, locale: LocationLocale): PickupStatus => {
    const now = Date.now();
    const scheduled = new Date(party.dateScheduled!);

    if (!party.overdue) {
        const orderTimeStatusMins = Math.round((scheduled.getTime() - now) / 1000 / 60);
        return {
            status: party.status === "preparing" ? "Preparing" : "Ready",
            msg:
                orderTimeStatusMins <= 0
                    ? "Picking up now"
                    : orderTimeStatusMins < 60
                    ? `Pick up in ${orderTimeStatusMins} min`
                    : `Pick up at ${locale.formatTime(scheduled)}`,
        };
    } else {
        const orderTimeStatusMins = Math.round((now - scheduled.getTime()) / 1000 / 60);
        if (party.status === "preparing") {
            return {
                status: "Overdue",
                msg: orderTimeStatusMins > 1 ? `Due ${orderTimeStatusMins} min ago` : "Due now",
                icon: <WarningIcon />,
            };
        } else {
            const readyTimestamp = party.submittedOrders[0]?.orderStatusHistory.find(
                (osh) => osh.to === OrderHistoryStatus.READY
            )?.timestamp;
            const readyTime = new Date(Date.parse(readyTimestamp!)).getTime();
            const uncollectedDurationMins = Math.round((now - readyTime) / 1000 / 60);
            return {
                status: "Uncollected",
                msg: `Notified ${uncollectedDurationMins} min ago`,
                icon: <BellIcon />,
            };
        }
    }
};
