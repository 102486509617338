import "./OrderCardList.scss";

import React, { useEffect, useState } from "react";
import Swiper from "react-id-swiper";
import { MemberDetails } from "sharedComponents/controls/member";
import { BumpActiveParty, BumpScreenType } from "../../shared/types";
import classNames from "classnames";
import { OrderCard } from "./OrderCard";
import { SwiperInstance } from "react-id-swiper/lib/types";
import { activePartyMonitor } from "../util/activePartyMonitor";
import { Section } from "features/floorManager/types";

export interface Props {
    parties: BumpActiveParty[];
    connected: boolean;
    mode: BumpScreenType;
    sections: Section[];
}

export const OrderCardList = ({ parties, connected, mode, sections }: Props) => {
    const [initialized, setInitialized] = useState(false);

    const [swiper, setSwiper] = useState(null as SwiperInstance);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setInitialized(true);
        }, 500);
        activePartyMonitor.start();
        return () => {
            clearTimeout(timeout);
            activePartyMonitor.stop();
        };
    }, [setInitialized]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            swiper && swiper.updateSlides();
        }, 500);
        swiper && swiper.updateSlides();
        return () => {
            clearTimeout(timeout);
        };
    }, [parties, swiper]);

    useEffect(() => {
        swiper && swiper.slideTo(0);
    }, [mode, swiper]);

    return (
        <Swiper
            getSwiper={setSwiper}
            containerClass={classNames("order-card-list", { "order-card-list--initialized": initialized })}
            slidesPerView={"auto"}
            spaceBetween={24}
            slidesOffsetBefore={24}
            slidesOffsetAfter={24}
        >
            {parties.map((p) => (
                <div
                    key={p.id}
                    className={classNames(
                        "order-card-list__list-item",
                        { "order-card-list__list-item--preparing": p.status === "preparing" },
                        { "order-card-list__list-item--ready": p.status === "ready" }
                    )}
                >
                    <OrderCard
                        partyId={p.id}
                        memberDetails={<MemberDetails party={p} variant={p.overdue ? "light" : "dark"} />}
                        tableNumber={p.tableNumber}
                        sectionName={(sections.find((s) => s.id === p.sectionId) || {}).displayName || null}
                        submittedOrders={p.submittedOrders}
                        dateStatusChanged={p.dateStatusChanged}
                        status={p.status}
                        overdue={p.overdue}
                        connected={connected}
                    />
                </div>
            ))}
        </Swiper>
    );
};
