import "./DeliveryOptionIndicator.scss";

import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../..";
import { DrinkIcon, FoodIcon } from "../../../common/icons";
import { DeliveryOption, DeliveryOptionType, FloorItem, MenuItemType } from "../types";
import { Indicator } from "../../../components/indicator/Indicator";
import classNames from "classnames";
import { actionCreator } from "../reducers/manageSection";
import { getLocationPermissions } from "features/location/selectors/getPermissions";
import { useTrackEvent } from "common/AppInsights/AppInsights";
import { getSectionOrderFlowForTracking, SECTION_BEHAVIOUR_CHANGE_TRACKING_EVENT } from "./UpdateSectionBehaviourModal";
import { ALL_SECTION_ID } from "../selectors/getFloorItems";

interface Props {
    section: FloorItem;
    deliveryOption: DeliveryOption;
    disabled?: boolean;
    showIcon?: boolean;
}

export const DeliveryOptionIndicator = ({ section, deliveryOption, disabled = false, showIcon = false }: Props) => {
    const dispatch: AppDispatch = useDispatch();
    const hasSectionEditPermissions = useSelector(getLocationPermissions).has("section:update");
    const trackSectionBehaviourChanged = useTrackEvent(SECTION_BEHAVIOUR_CHANGE_TRACKING_EVENT, {});

    const setEditDeliveryOptionType = useCallback(() => {
        const newDeliveryType =
            deliveryOption.type === DeliveryOptionType.PICKUP ? DeliveryOptionType.TABLE : DeliveryOptionType.PICKUP;

        dispatch(
            actionCreator.setSectionDeliveryOption({
                sectionId: section.id,
                sectionName: section.displayName,
                menuItemType: deliveryOption.menuItemType,
                deliveryOptionType: deliveryOption.type,
            })
        );

        trackSectionBehaviourChanged({
            section_id: section.id,
            section_name: section.displayName,
            all_sections: section.id === ALL_SECTION_ID,
            order_flow: getSectionOrderFlowForTracking(section),
            initial_order_sub_flow: getDeliveryOptionTypeTextForTracking(deliveryOption.type),
            final_order_sub_flow: getDeliveryOptionTypeTextForTracking(newDeliveryType),
        });
    }, [section, deliveryOption, dispatch, trackSectionBehaviourChanged]);

    return (
        <Indicator
            key={deliveryOption.menuItemType}
            className={classNames("delivery-option")}
            disabled={!hasSectionEditPermissions || deliveryOption.type === DeliveryOptionType.TAKEAWAY || disabled}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setEditDeliveryOptionType();
            }}
        >
            {deliveryOption.type !== DeliveryOptionType.TAKEAWAY && (
                <div className="delivery-option__details">
                    {showIcon && getMenuItemTypeIcon(deliveryOption.menuItemType)}
                    {getDeliveryOptionTypeText(deliveryOption.type)}
                </div>
            )}
        </Indicator>
    );
};

export const getDeliveryOptionTypeText = (deliveryOptionType: DeliveryOptionType) => {
    switch (deliveryOptionType) {
        case DeliveryOptionType.TABLE:
            return "Table service";
        case DeliveryOptionType.PICKUP:
            return "Buzzer service";
        case DeliveryOptionType.TAKEAWAY:
            return "Takeaway";
    }
};

export const getMenuItemTypeIcon = (menuItemType: MenuItemType) => {
    switch (menuItemType) {
        case "food":
            return <FoodIcon />;
        case "drink":
            return <DrinkIcon />;
    }
};

const getDeliveryOptionTypeTextForTracking = (deliveryOptionType: DeliveryOptionType) => {
    switch (deliveryOptionType) {
        case DeliveryOptionType.TABLE:
            return "table_service";
        case DeliveryOptionType.PICKUP:
            return "buzzer_service";
        case DeliveryOptionType.TAKEAWAY:
            return "takeaway";
    }
};
