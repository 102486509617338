import { GroupTab, GroupTabsData, GroupTabSearchOptions } from "../types";
import moment from "moment";
import { isActiveDays, toGroupTabsData } from "../utils";
import { opsApi } from "common/api";
import { fetchAsStaff } from "../../staffLogin/selectors/getAccessToken";
import { getGroupTabFields } from "./commonGraphFields";

const defaultOptions: GroupTabSearchOptions = {
    page: 1,
    pageSize: 15,
    diningDate: moment().format("YYYY-MM-DD"),
    activeTabs: true,
    statusFilters: null,
    ownerNameSearch: null,
};
type GroupTabResponse = { groupTabs: GroupTabsData[]; groupTabsTotal: number };
type GraphQLPayload = { locationGroupTabs: { data: GroupTab[]; totalCount: number } };

const getGroupTabsQuery: string = `query ($locationId: ID!, $skip: Int!, $limit: Int!, $ownerNameSearch: String, $dateFrom: String, $dateTo: String, $activeTabs: Boolean!, $statusFilter: [String!]) {
    locationGroupTabs(locationId: $locationId, skip: $skip, limit: $limit, ownerNameSearch: $ownerNameSearch, dateFrom: $dateFrom, dateTo: $dateTo, activeTabs: $activeTabs, statusFilter: $statusFilter) {
        totalCount
        data {
            ${getGroupTabFields}
        }
    }
  }`;
export async function fetchGroupTabsApi(
    locationId: string,
    options: GroupTabSearchOptions = defaultOptions
): Promise<GroupTabResponse> {
    const searchOptions = { ...defaultOptions, ...options };

    const activeTabs = isActiveDays(new Date(searchOptions.diningDate));

    const res = await opsApi.graphQLQuery<GraphQLPayload>(
        getGroupTabsQuery,
        {
            locationId,
            skip: (searchOptions.page! - 1) * searchOptions.pageSize!,
            limit: searchOptions.pageSize!,
            ownerNameSearch: searchOptions.ownerNameSearch,
            dateFrom: searchOptions.diningDate,
            dateTo: searchOptions.diningDate,
            activeTabs,
            statusFilter: searchOptions.statusFilters,
        },
        fetchAsStaff(true)
    );

    const groupTabs = toGroupTabsData(res.data.locationGroupTabs.data);

    return {
        groupTabs,
        groupTabsTotal: res.data.locationGroupTabs.totalCount,
    };
}
