import { AppState } from "features";
import { createSelector } from "reselect";
import { CategoryDetails } from "../types";

export const getCategories = (state: AppState) =>
    state.disableCategory.categories.status === "loaded" ? state.disableCategory.categories.data : [];

export const getCategoriesLoading = (state: AppState) => state.disableCategory.categories.status === "loading";

export const getFoodCategories = createSelector(getCategories, (categories) =>
    categories.filter((c) => c.type === "food")
);

export const getDrinksCategories = createSelector(getCategories, (categories) =>
    categories.filter((c) => c.type === "drink")
);

export const getFoodCategoriesSplit = createSelector(getFoodCategories, (categories) => ({
    inServiceNow: categories.filter((c) => c.inServiceNow).sort(sortCategories),
    inServiceToday: categories.filter((c) => !c.inServiceNow && c.inServiceToday).sort(sortCategories),
}));

export const getDrinksCategoriesSplit = createSelector(getDrinksCategories, (categories) => ({
    inServiceNow: categories.filter((c) => c.inServiceNow).sort(sortCategories),
    inServiceToday: categories.filter((c) => !c.inServiceNow && c.inServiceToday).sort(sortCategories),
}));

function sortCategories(a: CategoryDetails, b: CategoryDetails) {
    return a.displayName.localeCompare(b.displayName);
}
