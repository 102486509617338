import * as React from "react";
import { useContext, useCallback, useEffect, useRef, useState } from "react";
import { Skeleton } from "antd";
import { DownloadIcon } from "../../../common/icons";
import { Text } from "../../../components/text";
import classNames from "classnames";
import { SalesSummary } from "../types";
import { useSelector } from "react-redux";
import { AppState } from "../../state";
import { fetchSalesCsvExport, fetchItemSalesCsvExport, fetchSalesSummary } from "../api";
import { LocationLocaleContext } from "features/location/context/LocationLocaleContext";
import { SingleDatePicker } from "components/singleDatePicker";
import "./SalesPage.scss";
import moment from "moment";
import { getMomentDiningDate } from "features/location/selectors/getDiningDate";

const TotalLoader = () => <Skeleton paragraph={false} active title={{ width: 200 }} />;

const StatLoader = () => <Skeleton paragraph={false} active title={{ width: 75 }} />;

export const SalesPage = () => {
    const timerRef = useRef(0);

    const locationLocale = useContext(LocationLocaleContext);

    const today = useSelector(getMomentDiningDate);
    const [date, setDate] = useState(today);
    const [isToday, setIsToday] = useState(true);
    const [salesSummary, setSalesSummary] = useState<SalesSummary | null>(null);
    const [fetchingSalesCsv, setFetchingSalesCsv] = useState(false);
    const [fetchingItemSalesCsv, setFetchingItemSalesCsv] = useState(false);

    const locationId = useSelector((state: AppState) =>
        state.staffLogin.status === "authenticated" ? state.staffLogin.locationId : undefined
    );

    const onDownloadSalesCsv = useCallback(() => {
        if (!locationId) return;
        setFetchingSalesCsv(true);
        fetchSalesCsvExport(locationId, date).then(() => setFetchingSalesCsv(false));
    }, [date, locationId, setFetchingSalesCsv]);

    const onDownloadItemSalesCsv = useCallback(() => {
        if (!locationId) return;
        setFetchingItemSalesCsv(true);
        fetchItemSalesCsvExport(locationId, date).then(() => setFetchingItemSalesCsv(false));
    }, [date, locationId, setFetchingItemSalesCsv]);

    useEffect(() => {
        if (!locationId) return;
        clearTimeout(timerRef.current);
        setSalesSummary(null);
        fetchSalesSummary(locationId, date.format("YYYY-MM-DD"), isToday).then((salesSummary) => {
            setSalesSummary(salesSummary);
            if (isToday) {
                timerRef.current = window.setTimeout(() => setDate(today), 60000);
            }
        });
        return () => clearTimeout(timerRef.current);
    }, [date, setSalesSummary, locationId, timerRef, isToday, today]);

    return (
        <div className="sales-page">
            <div className="sales-page__header">
                <SingleDatePicker
                    date={date}
                    setDate={(date) => {
                        setDate(date);
                        setIsToday(today.isSame(date, "day"));
                    }}
                    today={today}
                />
            </div>
            <div className={classNames("sales-page__refresh-progress", today && salesSummary && "active")}>
                <div className="sales-page__refresh-progress-inner" />
            </div>
            <div className="sales-page__content">
                <div className="sales-page__content-inner">
                    {today && (
                        <div className="sales-page__last-updated">
                            <Text preset="g-16" mode="medium">
                                Last updated {moment().format("h:mm A")}
                            </Text>
                        </div>
                    )}
                    <div className="sales-page__totals">
                        <div className="sales-page__total">
                            <Text
                                className="sales-page__total-description"
                                preset="g-16"
                                mode="medium"
                                value="POS total value"
                            />
                            <Text preset="title-36" mode="bold">
                                {salesSummary ? (
                                    locationLocale.formatCurrency(salesSummary.posTotalValue)
                                ) : (
                                    <TotalLoader />
                                )}
                            </Text>
                        </div>
                        <div className="sales-page__total">
                            <Text
                                className="sales-page__total-description"
                                preset="g-16"
                                mode="medium"
                                value="Orders"
                            />
                            <Text preset="title-36" mode="bold">
                                {salesSummary ? locationLocale.formatCount(salesSummary.orderCount) : <TotalLoader />}
                            </Text>
                        </div>
                        <div className="sales-page__total">
                            <Text className="sales-page__total-description" preset="g-16" mode="medium" value="Tips" />
                            <Text preset="title-36" mode="bold">
                                {salesSummary ? locationLocale.formatCurrency(salesSummary.tips) : <TotalLoader />}
                            </Text>
                        </div>
                    </div>
                    <div className="sales-page__stat-bar">
                        <Text preset="g-16" mode="medium" value="Average transactional value" />
                        <Text preset="g-16" mode="medium">
                            {salesSummary ? (
                                locationLocale.formatCurrency(salesSummary.averageTransactionValue)
                            ) : (
                                <StatLoader />
                            )}
                        </Text>
                    </div>
                    <div className="sales-page__stat-bar">
                        <Text preset="g-16" mode="medium" value="Tip percentage" />
                        <Text preset="g-16" mode="medium">
                            {salesSummary ? (
                                `${locationLocale.formatPercentage(salesSummary.tipPercentageFactor)}`
                            ) : (
                                <StatLoader />
                            )}
                        </Text>
                    </div>
                    <div className="sales-page__stat-bar">
                        <Text preset="g-16" mode="medium" value="Unique users" />
                        <Text preset="g-16" mode="medium">
                            {salesSummary ? locationLocale.formatCount(salesSummary.uniqueUsers) : <StatLoader />}
                        </Text>
                    </div>
                    {today && (
                        <div className="sales-page__stat-bar">
                            <Text preset="g-16" mode="medium" value="Currently ordering" />
                            <Text preset="g-16" mode="medium">
                                {salesSummary ? (
                                    locationLocale.formatCount(salesSummary.activeOrderCount ?? 0)
                                ) : (
                                    <StatLoader />
                                )}
                            </Text>
                        </div>
                    )}
                    {typeof salesSummary?.orderHeadCount === "number" && (
                        <div className="sales-page__stat-bar">
                            <Text preset="g-16" mode="medium" value="Total order head count" />
                            <Text preset="g-16" mode="medium">
                                {salesSummary.orderHeadCount}
                            </Text>
                        </div>
                    )}
                    <div className="sales-page__stat-bar">
                        <Text preset="g-16" mode="medium" value="Food & beverage split" />
                        <Text preset="g-16" mode="medium">
                            {salesSummary ? (
                                `${salesSummary.foodPercentage}% / ${salesSummary.drinksPercentage}%`
                            ) : (
                                <StatLoader />
                            )}
                        </Text>
                    </div>
                    <div className="sales-page__stat-bar sales-page__stat-bar--export">
                        <Text preset="g-16" mode="medium" value="Export sales" />
                        <div
                            className="sales-page__stat-bar__download-csv"
                            onClick={fetchingSalesCsv ? () => {} : onDownloadSalesCsv}
                        >
                            <Text preset="g-16" mode="bold">
                                Download CSV
                                <DownloadIcon />
                            </Text>
                        </div>
                    </div>
                    <div className="sales-page__stat-bar">
                        <Text preset="g-16" mode="medium" value="Export item sales" />
                        <div
                            className="sales-page__stat-bar__download-csv"
                            onClick={fetchingItemSalesCsv ? () => {} : onDownloadItemSalesCsv}
                        >
                            <Text preset="g-16" mode="bold">
                                Download CSV
                                <DownloadIcon />
                            </Text>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
