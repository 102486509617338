import { removeAccent } from "common/utility";
import { ModifierDetails, ProductDetails } from "features/soldOut/types";

export const filterSearchable = <T extends ProductDetails | ModifierDetails>(
    items: T[],
    searchTerm: string,
    childSearch: (item: T, searchRegEx: RegExp, updateChildren: Boolean) => T | undefined
) => {
    const searchRegex = new RegExp(removeAccent(searchTerm || ""), "i");

    const newItems = items.reduce((result: T[], item) => {
        if (
            searchRegex.test(removeAccent(item.displayName)) ||
            (item.internalName && searchRegex.test(removeAccent(item.internalName)))
        ) {
            const matchedItem = { ...item };
            const matchedChildren = childSearch(item, searchRegex, false);
            matchedItem.hasChildMatch = !!matchedChildren;
            result.push(matchedItem);
            return result;
        }

        const childMatches = childSearch(item, searchRegex, true);
        childMatches && result.push(childMatches);
        return result;
    }, []);

    return [...newItems];
};

export const getChildMatches = <T extends ProductDetails>(items: T[], searchRegEx: RegExp) => {
    return items.filter(
        (ch: ProductDetails) =>
            searchRegEx.test(removeAccent(ch.displayName)) ||
            (ch.internalName && searchRegEx.test(removeAccent(ch.internalName)))
    );
};
