import { GroupTabSearchOptions } from "../types";
import moment from "moment";
import { opsApi } from "common/api";
import { fetchAsStaff } from "../../staffLogin/selectors/getAccessToken";
import { isActiveDays } from "../utils";

const searchOptions: GroupTabSearchOptions = {
    page: 1,
    pageSize: 100, //assuming we will not have more than 100 Group Tabs at the same time.
    diningDate: moment().format("YYYY-MM-DD"),
    activeTabs: true,
    statusFilters: ["Open"],
    ownerNameSearch: null,
};
type GroupTabResponse = { activeGroupTabsCount: number };
type GraphQLPayload = { locationGroupTabs: { totalCount: number } };

const getGroupTabsQuery: string = `query ($locationId: ID!, $skip: Int!, $limit: Int!, $dateFrom: String, $dateTo: String, $activeTabs: Boolean!, $statusFilter: [String!]) {
    locationGroupTabs(locationId: $locationId, skip: $skip, limit: $limit,  dateFrom: $dateFrom, dateTo: $dateTo, activeTabs: $activeTabs, statusFilter: $statusFilter) {
        totalCount
    }
  }`;
export async function fetchActiveGroupTabsCountApi(locationId: string, diningDate: Date): Promise<GroupTabResponse> {
    const formattedDiningDate = moment(diningDate).format("YYYY-MM-DD");

    const activeTabs = isActiveDays(diningDate);

    const res = await opsApi.graphQLQuery<GraphQLPayload>(
        getGroupTabsQuery,
        {
            locationId,
            skip: (searchOptions.page! - 1) * searchOptions.pageSize!,
            limit: searchOptions.pageSize!,
            dateFrom: formattedDiningDate,
            dateTo: formattedDiningDate,
            activeTabs,
            statusFilter: searchOptions.statusFilters,
        },
        fetchAsStaff(true)
    );

    return {
        activeGroupTabsCount: res.data.locationGroupTabs.totalCount,
    };
}
