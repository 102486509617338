import { Order, OrderTypes } from "../../types";
import { Text } from "../../../../components/text";
import { WarningIcon } from "common/icons";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { SuccessNotification } from "components/messages/Notifications";
import { Button } from "antd";
import { LocationLocaleContext } from "features/location/context/LocationLocaleContext";
import { getLocationPermissions } from "features/location/selectors/getPermissions";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { disableTabOrderRefundButton } from "./OrderCard";
import { isRefundsEnabled, isResubmitOrdersEnabled } from "features/location/selectors/getLocationConfig";
import { getOrderIsRefunded, getRefundAmount } from "features/orders/selectors";

export interface Props {
    order: Order;
    isRefundOpen?: boolean;
    buttonDisabled?: boolean;
    loading: boolean;
    saved: boolean;
    onClose: (cancel: boolean) => void;
    onCloseRefund?: () => void;
    onShowRefund?: () => void;
    setResubmitOrder: (order: Order) => void;
    getActionText?: (order: Order) => string;
    getActionBgColor?: (order: Order) => string;
    onAction?: (order: Order) => boolean;
}

export const OrderCardFooter = (props: Props) => {
    const {
        order,
        isRefundOpen,
        buttonDisabled,
        loading,
        saved,
        onShowRefund,
        setResubmitOrder,
        getActionText,
        onClose,
        onAction,
        getActionBgColor,
    } = props;

    const locationLocale = useContext(LocationLocaleContext);
    const permissions = useSelector(getLocationPermissions);
    const refundedAmount = useSelector(getRefundAmount);
    const isUnconfirmedOrder = order.status === "Unconfirmed";
    const isResubmittingOrder = order.status === "Resubmit pending";
    const hasResubmitPermission = permissions.has("resubmitorders:write");
    const refundsEnabled = useSelector(isRefundsEnabled);
    const hasRefundPermission = permissions.has("refunds:write");
    const isRefundFailed = order.status === "Refund failed";
    const [isRefunded, setIsRefunded] = useState(order.status === "Refunded");
    const resubmitEnabled = useSelector(isResubmitOrdersEnabled);
    const isPaymentType = order.type === OrderTypes.PAYMENT;
    const [displaySaved, setDisplaySaved] = useState(false);
    const orderStatusIsRefunded = useSelector(getOrderIsRefunded);
    const [actionText, setActionText] = useState(getActionText && getActionText(order));

    const disableRefundButtonForGroupTabOrder =
        disableTabOrderRefundButton(order.groupTab) && refundsEnabled && hasRefundPermission;

    const showCantRefundGroupTabMessage =
        disableRefundButtonForGroupTabOrder || (order.groupTab && !order.refundablePaymentId);

    // Currently paymentsTotal also includes points payments,
    // but we don't need to show the refund button if paid completely using points.
    const paymentsTotalInCents = Math.round((order?.paymentsTotal || 0) * 100);
    const pointsPaymentsTotalInCents = Math.round((order?.pointsPaymentsTotal || 0) * 100);

    const canRefund =
        refundsEnabled &&
        hasRefundPermission &&
        !isRefunded &&
        order?.refundablePaymentId &&
        !isRefundFailed &&
        paymentsTotalInCents > pointsPaymentsTotalInCents;

    const showResubmitOrderButton =
        resubmitEnabled && hasResubmitPermission && isUnconfirmedOrder && !isPaymentType && !isResubmittingOrder;

    const closeNotificationTimerRef = useRef(0);

    const triggerNotification = useCallback(() => {
        setDisplaySaved(true);
        closeNotificationTimerRef.current = window.setTimeout(() => {
            setDisplaySaved(false);
        }, 3000);
    }, []);

    useEffect(() => {
        if (!isRefunded && orderStatusIsRefunded) {
            triggerNotification();
            setIsRefunded(true);
            setActionText("Order refunded");
            return;
        }
        !isRefunded && getActionText && setActionText(getActionText(order));
    }, [orderStatusIsRefunded, onClose, isRefunded, onAction, triggerNotification, getActionText, order]);

    useEffect(() => {
        return () => window.clearTimeout(closeNotificationTimerRef.current);
    }, []);

    useEffect(() => {
        setIsRefunded(order.status === "Refunded");
    }, [order.status]);

    return (
        <div className="details-card__footer">
            {showCantRefundGroupTabMessage && (
                <div className="details-card__warning-refund">
                    <Text className="warning-icon" preset="g-14">
                        {<WarningIcon />}
                    </Text>
                    <Text preset="g-14" mode="bold">
                        {"Group Tab orders can’t be refunded."}
                    </Text>
                </div>
            )}

            {!isRefundOpen && order.status !== "Failed" && (
                <div className={classNames("details-card__footer-inner")}>
                    {(disableRefundButtonForGroupTabOrder || canRefund) && !isResubmittingOrder && (
                        <Button
                            className="details-card__button-refund"
                            onClick={onShowRefund}
                            disabled={loading || disableRefundButtonForGroupTabOrder}
                        >
                            <Text preset="g-16" mode="bold" value="Refund" />
                        </Button>
                    )}
                    {showResubmitOrderButton && (
                        <Button className="details-card__button-resubmit" onClick={() => setResubmitOrder(order)}>
                            <Text preset="g-16" mode="bold">
                                Resubmit
                            </Text>
                        </Button>
                    )}
                    <Button
                        className="details-card__button-action"
                        style={
                            getActionBgColor && !isRefunded && !buttonDisabled
                                ? { backgroundColor: getActionBgColor(order) }
                                : undefined
                        }
                        onClick={onAction && !isRefunded ? () => onAction(order) : undefined}
                        disabled={buttonDisabled || isRefunded || false}
                        loading={loading}
                    >
                        <Text preset="g-16" mode="bold">
                            {actionText}
                        </Text>
                    </Button>
                </div>
            )}
            <SuccessNotification notificationText="Marked as resolved" isVisible={saved} />
            <SuccessNotification
                notificationText={`Your refund of ${locationLocale.formatCurrency(refundedAmount)} has been processed`}
                isVisible={refundedAmount > 0 && displaySaved}
            />
        </div>
    );
};
