import { AppState } from "../../state";
import { Availability, FiltersCount, FilterTypes, ModifierDetails, ProductDetails } from "../types";
import { createSelector } from "reselect";
import { filterSearchable, getChildMatches } from "common/search/filterSearchable";

export const getFilter = (state: AppState) => state.soldOut.filter;
export const getSearch = (state: AppState) => state.soldOut.search;
export const isLoadingProducts = (state: AppState) => state.soldOut.products.status === "loading";
export const isLoadingModifiers = (state: AppState) => state.soldOut.modifiers.status === "loading";

const getVariantMatches = (
    item: ProductDetails,
    searchRegEx: RegExp,
    updateChildren: Boolean = true
): ProductDetails | undefined => {
    const childMatches = item.variants && getChildMatches(item.variants, searchRegEx);
    if (childMatches && childMatches.length) {
        const matchedItem = { ...item };
        matchedItem.variants = matchedItem.variants = updateChildren ? childMatches : item.variants;
        matchedItem.hasChildMatch = true;
        return matchedItem;
    }
    return undefined;
};

const getProductMatches = (
    item: ModifierDetails,
    searchRegEx: RegExp,
    updateChildren: Boolean = true
): ModifierDetails | undefined => {
    const childMatches = item.products && getChildMatches(item.products, searchRegEx);

    if (childMatches && childMatches.length) {
        const matchedItem = { ...item };
        matchedItem.products = updateChildren ? childMatches : item.products;
        matchedItem.hasChildMatch = true;
        return matchedItem;
    }
    return undefined;
};

export const getProducts = (state: AppState) => {
    if (state.soldOut.products.status === "loaded") {
        return state.soldOut.search
            ? filterSearchable(state.soldOut.products.data, state.soldOut.search, getVariantMatches)
            : state.soldOut.products.data;
    }
    return [];
};

export const getModifiers = (state: AppState) => {
    if (state.soldOut.modifiers.status === "loaded") {
        const mods = state.soldOut.search
            ? filterSearchable(state.soldOut.modifiers.data, state.soldOut.search, getProductMatches)
            : state.soldOut.modifiers.data;
        return mods;
    }
    return [];
};

export const getExpandedProductKeys = createSelector(getProducts, (products) =>
    products.filter((x) => x.hasChildMatch).map((x) => x.key!)
);

export const getExpandedModifierKeys = createSelector(getModifiers, (modifiers) =>
    modifiers.filter((x) => x.hasChildMatch).map((x) => x.key!)
);

export const productsCount = createSelector(getProducts, (products) => products.length);

export const modifiersCount = createSelector(getModifiers, (modifiers) => modifiers.length);

export const getProductsFiltersCount = createSelector(getProducts, (products) => {
    if (products.length) {
        const filtersCount = {
            mixed: products.filter((p) => p.variants && p.variants.some((v, i, d) => v.status !== d[0].status)).length,
            tomorrow: products.filter((p) =>
                p.status
                    ? p.status === Availability.SOLD_OUT
                    : p.variants && p.variants.some((v) => v.status && v.status === Availability.SOLD_OUT)
            ).length,
            unavailable: products.filter((p) =>
                p.status
                    ? p.status === Availability.UNAVAILABLE
                    : p.variants && p.variants.some((v) => v.status && v.status === Availability.UNAVAILABLE)
            ).length,
        };
        return filtersCount as FiltersCount;
    }
    return {};
});
export const getModifiersFiltersCount = createSelector(getModifiers, (modifiers) => {
    if (modifiers.length) {
        const filtersCount = {
            mixed: modifiers.filter((m) => m.products && m.products.some((v) => v.status !== m.products![0].status))
                .length,
            tomorrow: modifiers.filter((m) => m.status === Availability.SOLD_OUT).length,
            unavailable: modifiers.filter((m) => m.status === Availability.UNAVAILABLE).length,
        };
        return filtersCount as FiltersCount;
    }
    return {};
});
const getUnFlatFilteredProducts = createSelector(getProducts, getFilter, (products, filter) => {
    if (products.length) {
        if (filter && filter === FilterTypes.UNAVAILABLE) {
            return products.filter((p) =>
                p.status
                    ? p.status === Availability.UNAVAILABLE || p.status === Availability.SOLD_OUT
                    : p.variants &&
                      p.variants.some(
                          (v) =>
                              v.status && (v.status === Availability.UNAVAILABLE || v.status === Availability.SOLD_OUT)
                      )
            );
        }
        return products;
    }
    return [];
});

function derivedStatus(item: ProductDetails) {
    if (item.status) {
        return item.status;
    }
    if (!item.variants || !item.variants.length) {
        return Availability.AVAILABLE;
    }

    const allSame = item.variants.every((variant) => variant.status === item.variants![0].status);
    if (allSame) {
        return item.variants![0].status;
    }

    if (
        item.variants.every(
            (variant) => variant.status === Availability.SOLD_OUT || variant.status === Availability.UNAVAILABLE
        )
    ) {
        return Availability.SOLD_OUT;
    }
    return Availability.MIXED_STATE;
}

export const getFilteredProducts = createSelector(getUnFlatFilteredProducts, (products) =>
    products.map(
        (item, index) =>
            ({
                ...item,
                status: derivedStatus(item),
                children:
                    item.variants &&
                    item.variants.map((variant, i) => ({
                        ...variant,
                        isVariant: true,
                        key: `products_${index}_${item.sku}_${i}`,
                    })),
            } as ProductDetails)
    )
);

export const getFilteredModifiers = createSelector(getModifiers, getFilter, (modifiers, filter) => {
    if (modifiers.length) {
        if (!!filter && filter === FilterTypes.UNAVAILABLE) {
            return modifiers.filter((p) => p.status === Availability.UNAVAILABLE || p.status === Availability.SOLD_OUT);
        }
        return modifiers;
    }
    return [];
});
