import { createSelector } from "reselect";
import { Availability, AggregateSectionAvailability } from "../types";
import { getSections } from "./getSections";

export const getAggregateSectionAvailability = createSelector(getSections, (sections): AggregateSectionAvailability => {
    const earliestAvailableSection = [...sections].sort((a, b) => {
        const aAvailabilityDate = a.availabilityDate === null ? 0 : new Date(a.availabilityDate).getTime();
        const bAvailabilityDate = b.availabilityDate === null ? 0 : new Date(b.availabilityDate).getTime();
        return aAvailabilityDate - bAvailabilityDate;
    })[0] || { availability: Availability.DAY, availabilityDate: null };
    return {
        availability: earliestAvailableSection.availability,
        availabilityDate: earliestAvailableSection.availabilityDate,
    };
});
