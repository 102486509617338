import { createSelector } from "reselect";
import { AppState } from "../../state";

export const getSectionsState = (state: AppState) => {
    return state.floorManager.sections;
};
export const getSectionsLoading = createSelector(getSectionsState, (sections) => sections.status === "loading");

export const getSectionsLoaded = createSelector(getSectionsState, (sections) => sections.status === "loaded");

export const getSections = createSelector(getSectionsState, (sections) =>
    sections.status === "loaded" ? sections.data : []
);
