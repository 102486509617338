import { OrderItem } from "common/types";
import { Text } from "components/text";
import { getLocationLocale } from "features/location/selectors/getLocationLocale";
import React from "react";
import { useSelector } from "react-redux";
import "./TakeawayOrderItem.scss";

export interface Props {
    item: OrderItem;
    className?: string;
}

export const TakeawayOrderItem = ({ item }: Props) => {
    const { quantity, displayName, mappedModifiers } = item;
    const notes = item.notes || item.memberNotes;
    const price = item.price || item.lineTotal;
    const locale = useSelector(getLocationLocale);

    return (
        <div className="takeaway-order__card-item">
            <div className="takeaway-order__card-item-details">
                <div className="takeaway-order__card-item-qty">
                    <Text preset="g-14" mode="medium">
                        {quantity}
                    </Text>
                </div>
                <div className="takeaway-order__card-item-desc">
                    <Text preset="g-14">{displayName}</Text>
                    {mappedModifiers?.map((m) => (
                        <div key={`item_modifier_${m.displayName}_${m.optionIndex}`}>
                            <Text
                                preset="g-14"
                                mode={["block"]}
                                className="takeaway-order__card-item-modifier"
                                value={`${m.displayName}`}
                            />
                            {m.nestedModifiers?.map((nm) => (
                                <Text
                                    preset="g-14"
                                    mode={["block"]}
                                    key={`nested_modifier_${nm.displayName}_${nm.nestingLevel}`}
                                    className="takeaway-order__card-item-modifier"
                                    value={`${"- ".repeat(nm.nestingLevel || 1)}${nm.displayName}`}
                                />
                            ))}
                        </div>
                    ))}
                    {notes && (
                        <Text
                            preset="g-14"
                            mode={["block"]}
                            className="takeaway-order__card-item-notes"
                            value={`Note: ${notes}`}
                        />
                    )}
                </div>
            </div>
            <div className="takeaway-order__card-item-price">
                <Text preset="g-14" mode={["block"]}>
                    {locale.formatCurrency(price)}
                </Text>
            </div>
        </div>
    );
};
