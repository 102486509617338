import React, { useState, FunctionComponent } from "react";
import { CategoryAvailability } from "./CategoryAvailability";
import { Indicator } from "components/indicator/Indicator";
import { CategoryAvailabilityProps } from "./CategoryAvailability";
import "./AllCategoriesAvailability.scss";

interface AllCategoriesAvailabilityProps {
    disabled?: boolean;
    buttonLabel: string;
    buttonIcon: FunctionComponent<any>;
}

export const AllCategoriesAvailability = (
    props: AllCategoriesAvailabilityProps & Omit<CategoryAvailabilityProps, "showModal" | "setShowModal">
) => {
    const { disabled, buttonLabel, buttonIcon, ...rest } = props;
    const [showModal, setShowModal] = useState(false);

    return (
        <div className="all-categories-availability__button">
            <Indicator
                onClick={() => setShowModal(true)}
                unique
                icon={buttonIcon}
                textSize="large"
                shape="rect"
                disabled={disabled}
            >
                {buttonLabel}
            </Indicator>
            <CategoryAvailability {...rest} showModal={showModal} setShowModal={setShowModal} />
        </div>
    );
};
