import moment from "moment";
import { createSelector } from "reselect";
import { getLocationLocale } from "./getLocationLocale";

export const getDiningDate = createSelector(getLocationLocale, (locale) => {
    return locale.getDiningDate();
});

export const getMomentDiningDate = createSelector(getDiningDate, (diningDate) => {
    return moment(diningDate);
});
