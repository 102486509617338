import { AppDispatch, AppMiddleware } from "../../index";
import { SectionsAction, TypeKeys } from "../reducers/sections";
import { polling } from "common/utility/polling";

export const sectionMiddleware = () => (store: AppMiddleware) => (next: AppDispatch) => {
    return (action: SectionsAction) => {
        if (action.type === TypeKeys.LOADED) {
            const { staffLogin } = store.getState();
            if (staffLogin.status === "unauthenticated" || staffLogin.status === "failed") {
                polling.unregister("loadSections");
            }
        }
        return next(action);
    };
};
