import React, { PropsWithChildren, FunctionComponent } from "react";
import { Text, TextPreset } from "components/text";
import classNames from "classnames";
import "./Indicator.scss";

interface Props {
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    className?: string;
    primary?: boolean;
    unique?: boolean;
    icon?: FunctionComponent<any>;
    iconClass?: string;
    textSize?: "large" | "default";
    textWeight?: "bold" | "medium" | "regular";
    shape?: "rect" | "joined";
    disabled?: boolean;
}

export const Indicator = ({
    children,
    className,
    primary,
    unique,
    onClick,
    icon: Icon,
    iconClass,
    textSize,
    textWeight = "medium",
    shape,
    disabled = false,
}: PropsWithChildren<Props>) => {
    const textPreset: TextPreset = textSize === "large" ? "g-16" : "g-14";

    const classes = classNames({
        "indicator-element": true,
        "indicator-element--disabled": disabled,
        [`indicator-element--${shape}`]: !!shape,
        [`${className}`]: !!className,
    });

    return (
        <div onClick={disabled ? undefined : onClick} className={classes}>
            <Text
                className={classNames(
                    "indicator",
                    primary && "indicator--on",
                    onClick && !disabled && "indicator--action",
                    unique && "indicator--unique",
                    disabled && "indicator--disabled"
                )}
                preset={textPreset}
                mode={textWeight === "regular" ? undefined : textWeight}
            >
                {Icon && (
                    <span className={classNames("indicator__icon", primary && "indicator__icon--on", iconClass)}>
                        <Icon />
                    </span>
                )}
                {children}
            </Text>
        </div>
    );
};
