import "../assets/DetailsCard.scss";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import classNames from "classnames";
import { CrossIcon, PhoneIcon } from "common/icons";
import { LocationLocaleContext } from "features/location/context/LocationLocaleContext";
import { Text } from "../../../components/text";
import { Order, Party } from "../types";
import { OrdersDrawer } from "./OrderDetails/OrdersDrawer";
import { useDispatch, useSelector } from "react-redux";
import { getLocationId } from "features/staffLogin/selectors/getLocationId";
import { AppDispatch, AppState } from "features";
import { refundOrder } from "../actions";
import { fetchPaymentParty } from "../actions/fetchPaymentParty";
import { OrderCardRow } from "./OrderDetails/OrderCardRow";
import { OrderCardTitleRow } from "./OrderDetails/OrderCardTitleRow";
import { Button } from "antd";
import { config } from "common/config";
import { chargeParty } from "../actions/chargeParty";
import { getLocationPermissions } from "features/staffLogin/selectors/getPermissions";
import { InlineErrorNotification, SuccessNotification } from "components/messages/Notifications";
import { chargePartyActionCreators } from "../reducers/chargePartyReducer";
import { LoadingOverlayWithSpin } from "common/loader/LoadingSpinner";
import { ConfirmModal } from "components/confirmModal/ConfirmModal";
import { useTrackEvent } from "common/AppInsights/AppInsights";
import { normalizeError } from "common/error";
import { useUnmount } from "react-use";
import { getPartyOrderFlow } from "../utils";

interface Props {
    party: Party;
    onClose: () => void;
}

export const PaymentsCard = ({ party, onClose }: Props) => {
    const [isConfirmChargeCardOpen, setIsConfirmChargeCardOpen] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState<Order | null>(null);
    const {
        orders: { forceChargePartyResponse },
    } = useSelector((state: AppState) => state);
    const [showForceCharge, setShowForceCharge] = useState(false);
    const [enableForceCharge, setEnableForceCharge] = useState(false);
    const [chargeSuccess, setChargeSuccess] = useState<boolean | undefined>();
    const [chargeInProgress, setChargeInProgress] = useState(false);
    const modalTimerRef = useRef(0);
    const locationId = useSelector(getLocationId);
    const permissions = useSelector(getLocationPermissions);
    const hasForceChargePermissions = permissions.has("party:forcecharge");

    const trackChargeCardClicked = useTrackEvent("PAYMENT/CHARGE_CARD_CLICKED", {});
    const trackForceChargeCard = useTrackEvent("PAYMENT/FORCE_CHARGE_CARD", {});
    const trackForceChargeFailed = useTrackEvent("PAYMENT/FORCE_CHARGE_FAILED", {});

    const locale = useContext(LocationLocaleContext);

    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        setShowForceCharge(
            hasForceChargePermissions &&
                (party.canForceCharge || party.wasForceCharged || config.VITE_SHOW_FORCE_CHARGE === "1")
        );
        setEnableForceCharge(hasForceChargePermissions && party.canForceCharge && !party.wasForceCharged);

        if (forceChargePartyResponse === undefined || forceChargePartyResponse?.status === "unloaded") {
            setChargeSuccess(undefined);
            setChargeInProgress(false);
        } else if (
            forceChargePartyResponse.status === "loaded" ||
            forceChargePartyResponse.status === "failed" ||
            forceChargePartyResponse.status === "loading"
        ) {
            setChargeInProgress(forceChargePartyResponse.status === "loading");
            setChargeSuccess(
                forceChargePartyResponse.status === "loaded"
                    ? true
                    : forceChargePartyResponse.status === "failed"
                    ? false
                    : undefined
            );

            if (forceChargePartyResponse.status !== "loading") {
                modalTimerRef.current = window.setTimeout(() => {
                    setChargeSuccess(undefined);
                    dispatch(chargePartyActionCreators.chargePartyReset());
                }, 5000);
            }
        }

        return () => {
            clearTimeout(modalTimerRef.current);
        };
    }, [dispatch, forceChargePartyResponse, party, hasForceChargePermissions]);

    useUnmount(() => {
        dispatch(chargePartyActionCreators.chargePartyReset());
    });

    const handleOnChargeCardClick = () => {
        trackChargeCardClicked({});
        setIsConfirmChargeCardOpen(true);
    };

    const handleOnConfirmChargeCardClick = () => {
        setIsConfirmChargeCardOpen(false);
        if (locationId) {
            trackForceChargeCard({
                number_of_payments: party.paymentsData?.length ?? 0,
            });

            dispatch(
                chargeParty(party, locationId, (error) => {
                    trackForceChargeFailed({ reason: error?.errors?.[0].message ?? normalizeError(error) });
                })
            );
        }
    };

    return (
        <>
            <div className="details-card">
                <div className="details-card__header">
                    <div className="details-card__header-top">
                        <Text preset="title-20" value={party.displayId || ""} />
                        <button className="details-card__close" onClick={onClose}>
                            <CrossIcon />
                        </button>
                    </div>
                </div>

                <div className="details-card__scrollable">
                    <div className="details-card__scrollable-inner">
                        <div className="details-card__info">
                            <OrderCardRow
                                label="Date"
                                value={`${locale.formatOrderDate(party.time)} ${locale.formatTime(party.time)}`}
                            />
                            <OrderCardRow label="Status" value={party.status} />
                            <OrderCardRow
                                label="Closed time"
                                value={party.dateClosed ? locale.formatTime(party.dateClosed ?? party.time) : "—"}
                            />
                            <OrderCardRow label="Party ID" value={party.displayId} />
                            {party.partyType && (
                                <OrderCardRow
                                    label="Order flow"
                                    value={getPartyOrderFlow(party.partyType, party.isFlexTab)}
                                />
                            )}

                            <OrderCardRow
                                label={party.section ? "Section / Table" : "Table"}
                                value={party.section ? `${party.section} / ${party.table}` : party.table}
                            />
                            {party.customerSavedPayment && (
                                <OrderCardRow
                                    label="Customer saved payment"
                                    value={
                                        <div className="details-card__info__customer-saved-payment">
                                            <Text preset="g-14" mode="block">
                                                {party.customerSavedPayment.displayName}
                                            </Text>
                                            {party.customerSavedPayment.phoneNumber && (
                                                <div className="details-card__info__customer-saved-payment__phone">
                                                    <PhoneIcon />
                                                    <Text preset="g-14" mode="block">
                                                        {party.customerSavedPayment.phoneNumber}
                                                    </Text>
                                                </div>
                                            )}
                                        </div>
                                    }
                                />
                            )}
                            <div className="details-card__confirm-order-message">
                                <ExclamationCircleFilled />
                                <Text preset="g-14">
                                    Any payments that are not made with me&u are not displayed. Refer to your POS for
                                    more information.
                                </Text>
                            </div>
                        </div>
                        {!!party.totalPaid && (
                            <div className="details-card__payment">
                                <OrderCardTitleRow title="Sum of payments" />
                                <OrderCardRow
                                    label="Amount"
                                    labelMode="block"
                                    value={locale.formatCurrency(party.totalPaymentsAmount || 0)}
                                    className="payment-row"
                                />
                                {!!party.totalTip && (
                                    <OrderCardRow
                                        label="Tip"
                                        labelMode="block"
                                        value={locale.formatCurrency(party.totalTip)}
                                        className="payment-row"
                                    />
                                )}
                                {!!party.totalGratuity && (
                                    <OrderCardRow
                                        label="Gratuity"
                                        labelMode="block"
                                        value={locale.formatCurrency(party.totalGratuity)}
                                        className="payment-row"
                                    />
                                )}
                                {!party.processingFeeAbsorbed && (
                                    <OrderCardRow
                                        label="Card processing fee"
                                        labelMode="block"
                                        value={locale.formatCurrency(party.totalProcessingFee || 0)}
                                        className="payment-row"
                                    />
                                )}
                                {!!party.totalRefundedAmount && (
                                    <OrderCardRow
                                        label="Refund"
                                        labelMode="block"
                                        value={` – ${locale.formatCurrency(party.totalRefundedAmount)}`}
                                        className="payment-row payment-row--refunded"
                                    />
                                )}
                                <OrderCardRow
                                    label="Paid"
                                    valueMode={["block", "bold"]}
                                    value={locale.formatCurrency(party.totalPaid)}
                                    className="payment-row"
                                />
                            </div>
                        )}
                        <div className="details-card__payment">
                            <OrderCardTitleRow title="Payments" />
                            {party.paymentsData?.length ? (
                                [...party.paymentsData]
                                    .sort((a, b) => b.time.getTime() - a.time.getTime())
                                    .map((payment) => (
                                        <div key={payment.orderId} className="details-card__payment">
                                            <PaymentRow payment={payment} onSelectPayment={setSelectedPayment} />
                                        </div>
                                    ))
                            ) : (
                                <div className="details-card__no-payment">
                                    <Text preset="g-14">No payments were found</Text>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="details-card__footer">
                    <div className={classNames("details-card__footer-charge")}>
                        {showForceCharge && locationId && (
                            <>
                                <Button
                                    className="details-card__button-charge"
                                    disabled={!enableForceCharge}
                                    onClick={handleOnChargeCardClick}
                                >
                                    <Text preset="g-16" mode="bold" value="Charge card" />
                                </Button>
                                <ConfirmModal
                                    closeOnSubmit
                                    showModal={isConfirmChargeCardOpen}
                                    title="Charge card"
                                    subTitle="Charging the card will auto-charge any outstanding balance left on the party. It is possible that the payment will be taken successfully but will fail to reach the POS terminal, resulting in an unconfirmed payment that will need to be resolved."
                                    confirmText="Charge card"
                                    onCloseModal={() => setIsConfirmChargeCardOpen(false)}
                                    onConfirm={handleOnConfirmChargeCardClick}
                                />
                            </>
                        )}
                        {chargeInProgress && <LoadingOverlayWithSpin visible={chargeInProgress} />}
                        <SuccessNotification
                            notificationText="Card successfully charged"
                            isVisible={chargeSuccess === true}
                        />
                        <InlineErrorNotification
                            notificationText="Failed to charge card"
                            isVisible={chargeSuccess === false}
                        />
                    </div>
                </div>
            </div>
            <OrdersDrawer
                order={selectedPayment}
                getActionText={() => "Confirmed by POS"}
                onClose={() => setSelectedPayment(null)}
                visible={!!selectedPayment}
                className={classNames("live-orders-modal")}
                onRefundOrder={(order: Order, amount: number) =>
                    locationId &&
                    dispatch(
                        refundOrder(locationId, order, amount, !party.dateClosed, () =>
                            dispatch(fetchPaymentParty(locationId, order.partyId))
                        )
                    )
                }
            />
        </>
    );
};

export interface PaymentRowProps {
    payment: Order;
    onSelectPayment?: (payment: Order) => void;
    viaOrderCard?: boolean;
    disabled?: boolean;
}

export const PaymentRow = ({ payment, onSelectPayment, viaOrderCard, disabled }: PaymentRowProps) => {
    const locationLocale = useContext(LocationLocaleContext);
    return (
        <div
            className={classNames("details-card__detail-row payment-link-row", payment.status === "Failed" && "failed")}
        >
            <Text preset="g-14" mode={["bold", "block"]}>
                {locationLocale.formatTime(payment.time)} • {locationLocale.formatCurrency(payment.paymentsTotal ?? 0)}
                <Text preset="g-12" mode="block" className="">
                    Payment ID: {payment.transactionId}
                    {payment.isForceCharge ? " • Charged by staff" : " • Paid by customer"}
                </Text>
            </Text>
            {!viaOrderCard && (
                <Text preset="g-14" mode={["medium", "bold"]}>
                    <a
                        className={classNames("order-link", disabled && "disabled")}
                        onClick={() => !disabled && onSelectPayment?.(payment)}
                    >
                        View
                    </a>
                </Text>
            )}
        </div>
    );
};
