import classNames from "classnames";
import { useAnchoredInterval } from "common/hooks/useAnchoredInterval";
import { Text } from "components/text";
import { getLocationLocale } from "features/location/selectors/getLocationLocale";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BumpActiveParty } from "../../shared/types";
import { getPickupStatus, PickupStatus } from "../utils/getPickupStatus";
import { TakeawayOrderStatusBadge } from "./TakeawayOrderStatusBadge";
import { TakeawayOrderStatusButton } from "./TakeawayOrderStatusButton";

interface Props {
    party: BumpActiveParty;
    onClick: (party: BumpActiveParty) => void;
}

export const TakeawayOrderListItem = ({ party, onClick }: Props) => {
    const locale = useSelector(getLocationLocale);
    const [pickupStatus, setPickupStatus] = useState(getPickupStatus(party, locale));
    const updateOrderStatusMsg = useCallback(() => setPickupStatus(getPickupStatus(party, locale)), [party, locale]);
    useAnchoredInterval(updateOrderStatusMsg);
    useEffect(updateOrderStatusMsg, [updateOrderStatusMsg]);

    return (
        <div onClick={() => onClick(party)}>
            <Text
                preset="g-16"
                className={classNames(
                    "takeaway-order__list-item",
                    `takeaway-order__list-item--${pickupStatus.status.toLowerCase()}`
                )}
            >
                <OrderTableNumber tableNumber={party.tableNumber} />
                <OrderTime pickupStatus={pickupStatus} />
                <TakeawayOrderStatusBadge
                    pickupStatus={pickupStatus}
                    className="takeaway-order__list-item-order-status"
                />
                <TakeawayOrderStatusButton orders={party.submittedOrders} status={party.status} partyId={party.id} />
            </Text>
        </div>
    );
};

const OrderTableNumber = ({ tableNumber }: { tableNumber: string }) => {
    return (
        <Text preset="g-16" mode="bold" className="takeaway-order__list-item-order-table-number">
            {tableNumber}
        </Text>
    );
};

const OrderTime = ({ pickupStatus }: { pickupStatus: PickupStatus }) => {
    return (
        <div className="takeaway-order__list-item-order-time">
            {pickupStatus.icon}
            <Text preset="g-16" mode="bold">
                {pickupStatus.msg}
            </Text>
        </div>
    );
};
