import { Availability } from "./types";
import { config } from "common/config";

export const getStatusDisplay = (status: Availability): StatusDisplay => {
    switch (status) {
        case Availability.AVAILABLE:
            return "Available";
        case Availability.SOLD_OUT:
            return "Sold out today";
        case Availability.UNAVAILABLE:
            return "Unavailable";
        case Availability.MIXED_STATE:
            return "Partially available";
    }
    return status;
};

export type StatusDisplay =
    | "Available"
    | "Sold out today"
    | "Unavailable"
    | "Partially available"
    | Availability.FIXED_TIME;

export const HIDE_WAIT_TIMES = config.VITE_HIDE_WAIT_TIMES === "1";
