import { Loaded } from "common/loader";
import { Section } from "../types";

export type State = Loaded<Section[]>;

export enum TypeKeys {
    LOADING = "SECTIONS/LOADING",
    LOADED = "SECTIONS/LOADED",
    UPDATED = "SECTION/UPDATED",
    FAILED = "SECTIONS/FAILED",
}

export const createAction = {
    loading: () => ({ type: TypeKeys.LOADING }),
    loaded: (data: Section[]) => ({ type: TypeKeys.LOADED, data }),
    updated: (section: Section) => ({ type: TypeKeys.UPDATED, section }),
    failed: (error: any) => ({ type: TypeKeys.FAILED, error }),
};

type SectionsLoadingAction = { type: TypeKeys.LOADING };
type SectionsLoadedAction = { type: TypeKeys.LOADED; data: Section[] };
type SectionUpdatedAction = { type: TypeKeys.UPDATED; section: Section };
type SectionsFailedAction = { type: TypeKeys.FAILED; error: any };

export type SectionsAction = SectionsLoadingAction | SectionsLoadedAction | SectionUpdatedAction | SectionsFailedAction;

const initialState: State = {
    status: "unloaded",
};

export function reducer(state: State = initialState, action: SectionsAction): State {
    if (action.type === TypeKeys.LOADING) {
        return {
            status: "loading",
        };
    }

    if (action.type === TypeKeys.LOADED) {
        const { data } = action;
        return {
            status: "loaded",
            data,
        };
    }

    if (action.type === TypeKeys.UPDATED && state.status === "loaded") {
        return {
            ...state,
            data: state.data.map((s) => (s.id === action.section.id ? action.section : s)),
        };
    }

    if (action.type === TypeKeys.FAILED) {
        const { error } = action;
        return {
            status: "failed",
            error,
        };
    }
    return state;
}
