import { Button } from "antd";
import classNames from "classnames";
import { useTrackEvent } from "common/AppInsights/AppInsights";
import { CrossIcon, PhoneIcon } from "common/icons";
import { Text } from "components/text";
import { getLocationLocale } from "features/location/selectors/getLocationLocale";
import { mapPromotionAdjustments } from "features/orders/utils";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../index";
import { createAction } from "../../shared/reducers";
import { BumpActiveParty } from "../../shared/types";
import { getPickupStatus } from "../utils/getPickupStatus";
import { TakeawayOrderCancelModal } from "./TakeawayOrderCancelModal";
import "./TakeawayOrderCard.scss";
import { TakeawayOrderItem } from "./TakeawayOrderItem";
import { TakeawayOrderScheduledTimeModal } from "./TakeawayOrderScheduledTimeModal";
import { TakeawayOrderStatusBadge } from "./TakeawayOrderStatusBadge";
import { TakeawayOrderStatusButton } from "./TakeawayOrderStatusButton";

export interface Props {
    party?: BumpActiveParty;
    className?: string;
    onClose: () => void;
}

export const TakeawayOrderCard = ({ party, onClose }: Props) => {
    const locale = useSelector(getLocationLocale);
    const dispatch = useDispatch<AppDispatch>();
    const trackOrderCancelled = useTrackEvent("TAKEAWAY/ORDER_CANCELLED", {});
    const trackOrderTimeChanged = useTrackEvent("TAKEAWAY/ORDER_TIME_CHANGED", {});

    const [changeTimeModalOpen, setChangeTimeModalOpen] = useState(false);
    const [cancelOrderModalOpen, setCancelOrderModalOpen] = useState(false);

    if (!party) return null;

    const member = party.members[0];
    const pickupStatus = getPickupStatus(party, locale);
    const dateSubmitted = locale.formatTime(new Date(party.dateSubmitted));
    const dateScheduled = locale.formatTime(new Date(party.dateScheduled!));
    const originalDateScheduled = locale.formatTime(new Date(party.originalDateScheduled!));
    const orderItems = party.submittedOrders.flatMap((so) => so.items);
    const foodItems = orderItems.filter((item) => item.menuItemType === "food");
    const drinkItems = orderItems.filter((item) => item.menuItemType === "drink");
    const orderAmount = party.payments.find((p) => !!p.transactionId)?.orderAmount || party.payments[0].orderAmount;
    const promotions = mapPromotionAdjustments(party?.submittedOrders.flatMap((o) => o.bill.adjustments) || []);
    const itemHasExclusiveTaxes = party.submittedOrders.some((order) =>
        order.items?.some(
            (item) => item.priceIncludingTaxes && item.priceIncludingTaxes > (item.price || item.lineTotal)
        )
    );
    const statusIsPreparing = party.status === "preparing";

    const increaseScheduledTime = (increaseMins?: number) => {
        if (increaseMins) {
            const updatedDateScheduled = new Date(party.dateScheduled!);
            updatedDateScheduled.setMinutes(updatedDateScheduled.getMinutes() + increaseMins);
            dispatch(createAction.changeOrderScheduledTime(party, updatedDateScheduled.toISOString()));
            trackOrderTimeChanged({
                "party.id": party.id,
                initial_time: new Date(party.originalDateScheduled!).toISOString(),
                final_time: updatedDateScheduled.toISOString(),
            });
        }
        setChangeTimeModalOpen(false);
    };

    const cancelOrder = (reason?: string) => {
        const orderIds = party.submittedOrders.map((so) => so.id!);
        dispatch(createAction.cancelAndRefund(party, orderIds));
        trackOrderCancelled({
            "party.id": party.id,
            reason,
        });
        setCancelOrderModalOpen(false);
        onClose();
    };

    return (
        <div className={"takeaway-order__card"}>
            <div className="takeaway-order__card-header">
                <div className="takeaway-order__card-header-top">
                    <Text preset="title-20" value={party.tableNumber} />
                    <button className="takeaway-order__card-close" onClick={onClose}>
                        <CrossIcon />
                    </button>
                </div>
            </div>
            <div className="takeaway-order__card-scrollable">
                <div className="takeaway-order__card-scrollable-inner">
                    <div className="takeaway-order__card-section">
                        <div className="takeaway-order__card-detail-row">
                            <Text preset="g-16" mode={["bold", "block"]} value="Details" />
                            <TakeawayOrderStatusBadge
                                pickupStatus={pickupStatus}
                                className="takeaway-order__card-order-status"
                            />
                        </div>
                        <div className="takeaway-order__card-detail-row">
                            <Text preset="g-14" mode={["bold", "block"]} value="Customer" />
                            <span className="takeaway-order__card-detail-row-phone">
                                <Text preset="g-14" mode={["block"]} value={member.memberName} />
                                {member.phoneNumber && (
                                    <Text preset="g-14">
                                        <PhoneIcon /> {member.phoneNumber}
                                    </Text>
                                )}
                            </span>
                        </div>
                        <div className="takeaway-order__card-detail-row">
                            <Text preset="g-14" mode={["bold", "block"]} value="Section" />
                            <Text preset="g-14" mode={["block"]} value={party.sectionName || "NA"} />
                        </div>
                        <div className="takeaway-order__card-detail-row">
                            <Text preset="g-14" mode={["bold", "block"]} value="ID number" />
                            <Text preset="g-14" mode={["block"]} value={party.tableNumber} />
                        </div>
                    </div>

                    <div className="takeaway-order__card-section">
                        <div className="takeaway-order__card-title-row">
                            <Text preset="g-16" mode={["bold", "block"]} value="Pickup time" />
                        </div>
                        <div className="takeaway-order__card-detail-row tight">
                            <div className="takeaway-order__card-detail-column">
                                <div className="tight">
                                    <Text preset="g-14" mode={["block"]} value="Order submitted time" />
                                    <Text preset="g-14" mode={["block"]} value={dateSubmitted} />
                                </div>
                                <div className="tight">
                                    <Text preset="g-14" mode={["block"]} value="Original pickup time" />
                                    <Text preset="g-14" mode={["block"]} value={originalDateScheduled} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="takeaway-order__card-section">
                        <div className="takeaway-order__card-detail-row">
                            <Text preset="g-16" mode={["bold", "block"]} value={`Order pickup time ${dateScheduled}`} />
                            <span onClick={statusIsPreparing ? () => setChangeTimeModalOpen(true) : undefined}>
                                <Text
                                    preset="g-14"
                                    mode={["block"]}
                                    className={classNames("takeaway-order__card-detail-row-increase-time", {
                                        disabled: !statusIsPreparing,
                                    })}
                                >
                                    Increase time
                                </Text>
                            </span>
                            <TakeawayOrderScheduledTimeModal
                                visible={changeTimeModalOpen}
                                onSubmit={increaseScheduledTime}
                                onCancel={() => setChangeTimeModalOpen(false)}
                            />
                        </div>
                    </div>

                    <div className="takeaway-order__card-section">
                        <div className="takeaway-order__card-title-row">
                            <Text preset="g-16" mode={["bold", "block"]} value="Order" />
                        </div>
                        {drinkItems.length > 0 && (
                            <div className="takeaway-order__card-detail-row tight">
                                <div className="takeaway-order__card-detail-column">
                                    {drinkItems.map((item) => (
                                        <TakeawayOrderItem key={item.id} item={item} />
                                    ))}
                                </div>
                            </div>
                        )}
                        {foodItems.length > 0 && (
                            <div className="takeaway-order__card-detail-row tight">
                                <div className="takeaway-order__card-detail-column">
                                    {foodItems.map((item) => (
                                        <TakeawayOrderItem key={item.id} item={item} />
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="takeaway-order__card-section">
                        <div className="takeaway-order__card-title-row">
                            <Text preset="g-16" mode={["bold", "block"]} value="Payment" />
                        </div>
                        {promotions.length > 0 ? (
                            promotions.map((orderPromotion, index) => (
                                <div key={index} className="takeaway-order__card-detail-row">
                                    <Text
                                        preset="g-14"
                                        mode={["bold", "block"]}
                                        value={`Promotions: ${orderPromotion.name}`}
                                    />
                                    <Text preset="g-14" mode={["block"]}>
                                        {locale.formatCurrency(orderPromotion.amount || 0)}
                                    </Text>
                                </div>
                            ))
                        ) : (
                            <div className="takeaway-order__card-detail-row">
                                <Text preset="g-14" mode={["bold", "block"]} value="Item total" />
                                {itemHasExclusiveTaxes && (
                                    <Text
                                        preset="g-12"
                                        className="takeaway-order__card-order-item__subText"
                                        mode={["block"]}
                                        value="Includes item price and taxes"
                                    />
                                )}
                                <Text preset="g-14" mode={["block"]}>
                                    {locale.formatCurrency(orderAmount)}
                                </Text>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="takeaway-order__card-footer">
                <Button
                    className="takeaway-order__button takeaway-order__button--cancel"
                    onClick={() => setCancelOrderModalOpen(true)}
                    disabled={!statusIsPreparing}
                >
                    <Text preset="g-16" mode="bold" value="Cancel order" />
                </Button>
                <TakeawayOrderStatusButton partyId={party.id} status={party.status} orders={party.submittedOrders} />
                <TakeawayOrderCancelModal
                    visible={cancelOrderModalOpen}
                    onSubmit={cancelOrder}
                    onCancel={() => setCancelOrderModalOpen(false)}
                />
            </div>
        </div>
    );
};
