import { opsApi } from "common/api";
import { Availability, DeliveryOption, DisableReason, Table } from "../types";
import { fetchAsStaff } from "features/staffLogin/selectors/getAccessToken";
import { sectionQuery } from "./fetchSections";

export async function updateSection(
    locationId: string,
    sectionId: string,
    availability: Availability,
    disableReason?: DisableReason,
    offlineDurationMinutes?: number | null,
    disableSubReason?: string
): Promise<GraphQLSection> {
    const res = (
        await opsApi.graphQLQuery<{ updateSectionAvailability: GraphQLSection }>(
            updateQuery,
            {
                locationId,
                sectionId,
                availability,
                disableReason,
                offlineDurationMinutes,
                disableSubReason,
            },
            fetchAsStaff(true)
        )
    ).data.updateSectionAvailability;

    return res;
}

const updateQuery: string = `mutation ($locationId: ID!, $sectionId: ID!, $availability: SectionAvailability!, $disableReason: SectionDisableReason, $offlineDurationMinutes: Int, $disableSubReason: String) {
    updateSectionAvailability(locationId: $locationId, sectionId: $sectionId, availability: $availability, disableReason: $disableReason, offlineDurationMinutes: $offlineDurationMinutes, disableSubReason: $disableSubReason) {
        ${sectionQuery}
    }
}`;

export interface GraphQLSection {
    displayName: string;
    id: string;
    availability: Availability;
    availabilityDate: string | null;
    deliveryOptions: DeliveryOption[];
    tables?: Table[];
}
