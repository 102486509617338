import classNames from "classnames";
import { LocationLocaleContext } from "features/location/context/LocationLocaleContext";
import { Order } from "../../types";
import React, { useContext, useMemo } from "react";
import { RowData, VirtualTableRowContent } from "../virtualTable/VirtualTableRowContent";
import { getNextColor, getPartyOrderFlow } from "features/orders/utils";

interface AlertsRowProps {
    order: Order;
}
export const AlertsRowContent = ({ order }: AlertsRowProps) => {
    const locationLocale = useContext(LocationLocaleContext);

    const rowsData: RowData[] = useMemo(
        () => [
            { value: order.type, key: "type" },
            { value: order.displayOrderId, key: "displayOrderId" },
            { value: getPartyOrderFlow(order.partyType, order.isFlexTab), key: "orderFlow" },
            {
                value: (
                    <div
                        className={classNames(
                            "grouped-docket",
                            order.isMerged && order.posReferenceId && "color-" + getNextColor(order.posReferenceId)
                        )}
                    />
                ),
                key: "isMerged",
            },
            { value: order.table, key: "table" },
            { value: order.section, key: "section" },
            {
                value:
                    !order.totalValue && !order.itemTotal && !order.paymentsTotal
                        ? null
                        : locationLocale.formatCurrency((order.paymentsTotal ?? order.totalValue ?? order.itemTotal)!),
                className: "virtual-table__cell--align-right",
                key: "totalValue",
            },
            { value: !order.time ? null : locationLocale.formatTime(order.time), key: "time" },
            { value: order.status, key: "status" },
        ],
        [order, locationLocale]
    );

    return <VirtualTableRowContent rows={rowsData} />;
};
