import { AppDispatch } from "features";
import { getSectionsState } from "features/floorManager/selectors/getSections";
import { AppState } from "features/state";
import { fetchParty } from "../api/fetchParty";
import { actionCreators } from "../reducers";

export const fetchPaymentParty = (locationId: string, partyId: string) => {
    return async function (dispatch: AppDispatch, getState: () => AppState) {
        const state = getState();
        const { status } = getSectionsState(state);
        if (status === "unloaded" || status === "loading") return;

        const party = await fetchParty(locationId, partyId);
        dispatch(actionCreators.paymentPartyUpdated(party));
    };
};
