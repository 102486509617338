import { useSelector, useDispatch } from "react-redux";
import { AppState, AppDispatch } from "features";
import { useEffect, useState } from "react";
import { useTimeout } from "common/hooks/useTimeout";
import { unenrol } from "../actions/unenrol";
import { config } from "common/config";

export const MonitorEnrolment = () => {
    const dispatch = useDispatch<AppDispatch>();

    const enrolment = useSelector((state: AppState) => state.enrolment);

    const expiresAt = enrolment.status === "enroled" ? enrolment.expires_at : null;

    const [expiresIn, setExpiresIn] = useState<number | null>(null);

    useEffect(() => {
        setExpiresIn(expiresAt === null ? null : Math.max(0, expiresAt - new Date().getTime()));
    }, [expiresAt]);

    useTimeout(() => {
        dispatch(unenrol());
        window.location.href = config.VITE_MANAGE_APP_LINK!;
    }, expiresIn);

    return null;
};
