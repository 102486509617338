import classNames from "classnames";
import React, { ReactNode } from "react";

interface VirtualTableRowContentProps {
    rows: RowData[];
}
export interface RowData {
    value?: ReactNode;
    className?: string;
    key: string;
}

export const VirtualTableRowContent = ({ rows }: VirtualTableRowContentProps) => (
    <>
        {rows.map((row) => (
            <td key={row.key} className={classNames("ant-table-cell", row.className)}>
                {row.value}
            </td>
        ))}
    </>
);
