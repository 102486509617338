import { opsApi } from "common/api";
import { fetchAsStaff } from "../../staffLogin/selectors/getAccessToken";
import { ProductAvailabilityUpdateInput } from "../types";

export async function updateApi(locationId: string, updates: ProductAvailabilityUpdateInput[]): Promise<boolean> {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(
        updateQuery,
        {
            locationId,
            updates,
        },
        fetchAsStaff(true)
    );

    return res.data.updateProductAvailability;
}

type GraphQLPayload = { updateProductAvailability: boolean };

const updateQuery: string = `mutation ($locationId: ID!, $updates: [ProductAvailabilityUpdateInput!]!) {
    updateProductAvailability(locationId: $locationId, updates: $updates)
}`;
