import React, { useEffect } from "react";

import { State as EnrolmentState } from "../reducers/enrolment";
import { Redirect } from "react-router";

export interface Props {
    status: EnrolmentState["status"];
    enrol: () => void;
}

export const EnrolDevice = ({ enrol, status }: Props) => {
    useEffect(() => {
        enrol();
    }, [enrol]);

    if (status === "enroled") {
        return <Redirect to="/" />;
    }

    if (status === "processing" || status === "initial") {
        return <p>Enrolling this device</p>;
    }

    return <p>Device enrolment failed</p>;
};
