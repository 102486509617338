import { opsApi } from "common/api";

type AuthenticationResult = {
    id: string;
    access_token: string;
    location_id: string;
    expires_in: number;
    name: string;
    user_id: string;
    access_level_name: string;
};
type BypassAuthenticationResult = Omit<AuthenticationResult, "user_id" | "access_level_name">;

export const login = async (deviceToken: string, pin: string): Promise<AuthenticationResult> => {
    const body = new FormData();
    body.append("pin", pin);

    const headers = new Headers({
        Authorization: `Bearer ${deviceToken}`,
    });

    const response = await opsApi.fetch("/staff/auth", {
        method: "POST",
        body,
        credentials: "include",
        headers,
    });

    if (!response.ok) {
        throw response;
    }

    return await response.json();
};

export const bypassLogin = async (deviceToken: string): Promise<BypassAuthenticationResult> => {
    const headers = new Headers({
        Authorization: `Bearer ${deviceToken}`,
    });

    const response = await opsApi.fetch("/staff/auth/bypass", {
        method: "POST",
        credentials: "include",
        headers,
    });

    if (!response.ok) {
        throw response;
    }

    return await response.json();
};

export const extend = async (accessToken: string): Promise<AuthenticationResult> => {
    const headers = new Headers({
        Authorization: `Bearer ${accessToken}`,
    });

    const response = await opsApi.fetch("/staff/auth/extend", {
        method: "POST",
        credentials: "include",
        headers,
    });

    if (!response.ok) {
        throw response;
    }

    return await response.json();
};
