import { GroupTabOrderItem } from "../types";
import * as React from "react";
import { useContext } from "react";
import { Text } from "../../../components/text";
import { LocationLocaleContext } from "../../location/context/LocationLocaleContext";
import classNames from "classnames";

export interface Props {
    tabOrder: GroupTabOrderItem;
    onShowGroupTabOrder?: (partyId: string) => void;
    viaOrderCard?: boolean;
    disabled?: boolean;
}

export const TabOrder = ({ tabOrder, onShowGroupTabOrder, viaOrderCard, disabled }: Props) => {
    const locationLocale = useContext(LocationLocaleContext);
    return (
        <>
            <div
                className={classNames(
                    "groupTab-card__detail-row",
                    tabOrder.memberName && "no-border no-padding-bottom"
                )}
            >
                <Text
                    preset="g-14"
                    mode={["bold", "block"]}
                    value={`Order: ${locationLocale
                        .formatTime(new Date(tabOrder.dateCreated))
                        .replace(" ", "")} • ${locationLocale.formatCurrency(tabOrder.amount)}`}
                />
                {!viaOrderCard && (
                    <Text preset="g-14" mode={["medium", "bold"]}>
                        <a
                            className={classNames("order-link", disabled && "disabled")}
                            onClick={() => !disabled && onShowGroupTabOrder && onShowGroupTabOrder(tabOrder.partyId)}
                        >
                            Go to order
                        </a>
                    </Text>
                )}
            </div>
            {tabOrder.memberName && (
                <div key={`orderId_${tabOrder.partyId}`} className="groupTab-card__detail-row no-padding-top">
                    <Text preset="g-14" mode={["block"]} value={`Name: ${tabOrder.memberName}`} />
                </div>
            )}
        </>
    );
};
