import { State as SectionState } from "../reducers/sections";
import { State as ManageSectionState } from "./manageSection";
import { reducer as sectionsReducer } from "../reducers/sections";
import { reducer as manageSectionReducer } from "../reducers/manageSection";
import { combineReducers } from "redux";
export { createAction } from "../reducers/sections";

export const reducer = combineReducers({
    sections: sectionsReducer,
    manageSection: manageSectionReducer,
});

export interface State {
    floorManager: {
        sections: SectionState;
        manageSection: ManageSectionState;
    };
}
