import { AppDispatch, AppState } from "../../index";
import { actionCreator } from "../reducers/manageSection";
import { getAggregateSectionAvailability } from "../selectors/getAggregateSectionAvailability";
import { ALL_SECTION_ID } from "../selectors/getFloorItems";
import { Availability, FloorItem, FloorItemType, TableAvailabilityStatus } from "../types";

export const updateSectionAvailability = (item: FloorItem) => async (dispatch: AppDispatch) => {
    if (item.type === FloorItemType.SECTION) {
        if (item.availabilityStatus === TableAvailabilityStatus.UNAVAILABLE) {
            dispatch(actionCreator.setManageAvailability(item));
        } else
            dispatch(
                actionCreator.setDisableSection({
                    sectionId: item.id,
                    sectionName: item.displayName,
                    availability: Availability.LATER,
                    offlineDurationMinutes: null,
                })
            );
    } else {
        dispatch(actionCreator.setManageAvailability(item));
    }
};

export const updateAllSectionsAvailability = () => async (dispatch: AppDispatch, getState: () => AppState) => {
    const state = getState();
    const aggregateSectionAvailability = getAggregateSectionAvailability(state);

    const allSectionsItem = {
        type: FloorItemType.SECTION,
        id: ALL_SECTION_ID,
        displayName: "All sections",
        key: "AllSections",
        availabilityStatus:
            aggregateSectionAvailability.availability !== Availability.NOW
                ? TableAvailabilityStatus.UNAVAILABLE
                : undefined,
        statusExpiryUtc:
            aggregateSectionAvailability.availability === Availability.LATER
                ? aggregateSectionAvailability.availabilityDate
                : undefined,
    };

    dispatch(updateSectionAvailability(allSectionsItem));
};
