import { AppDispatch, AppState } from "../../index";
import { updateApi } from "../api";
import { Availability, ProductDetails } from "../types";
import { createAction } from "../reducers";

export const updateProduct = (locationId: string, product: ProductDetails, status: Availability) => {
    return async function (dispatch: AppDispatch, getState: () => AppState) {
        const { location } = getState();
        if (location.locationId === null) {
            return;
        }

        try {
            const update = product.variants
                ? product.variants.map((v) => ({ sku: v.sku, status }))
                : [{ sku: product.sku, status }];
            const updatedProduct = await updateApi(locationId, update);
            if (updatedProduct) {
                dispatch(createAction.productUpdated(product, status));
            }
        } catch (e) {
            console.warn(e);
        }
    };
};
