import React from "react";
import { ColumnProps } from "antd/lib/table";
import { DeliveryOptionType, FloorItem, FloorItemType, SectionOrderFlowTypes } from "../types";
import { Indicator } from "../../../components/indicator/Indicator";
import { removeAccent } from "../../../common/utility";
import { Text } from "../../../components/text";
import { AvailabilityIndicator } from "./AvailabilityIndicator";
import { ALL_SECTION_ID } from "../selectors/getFloorItems";
import { DeliveryOptionIndicator } from "./DeliveryOptionIndicator";

const searchResult = (searchVal: string, name: string) => {
    const index = removeAccent(name.toLocaleLowerCase()).indexOf(removeAccent(searchVal.toLocaleLowerCase()));
    if (index === -1) return { start: name, bold: "", end: "" };
    const endIndex = index + searchVal.length;
    return {
        start: name.substring(0, index),
        bold: name.substring(index, endIndex),
        end: endIndex > name.length ? "" : name.substring(endIndex),
    };
};

export const getFloorManagerColumns = (
    search: string,
    showDeliveryOptions: boolean,
    setUpdateSectionBehaviour?: (item: FloorItem) => void,
    update?: (item: FloorItem) => void
): ColumnProps<FloorItem>[] => {
    const columns: ColumnProps<FloorItem>[] = [
        {
            title: "Display name",
            dataIndex: "displayName",
            key: "displayName",
            render: (displayName: string, item: FloorItem) => {
                if (item.id === ALL_SECTION_ID) {
                    return (
                        <Text className="floor-manager__row__text" preset="g-14" mode="bold">
                            {displayName}
                        </Text>
                    );
                }
                const { start, bold, end } = searchResult(search || "", displayName);
                return (
                    <Text className="floor-manager__row__text" preset="g-14">
                        {start}
                        {bold && <b>{bold}</b>}
                        {end}
                        {item.type === FloorItemType.SECTION && (
                            <Text className="floor-manager__row__text__order-flow" preset="g-14">
                                {getOrderFlowDisplayName(item)}
                            </Text>
                        )}
                    </Text>
                );
            },
        },
    ];

    columns.push({
        title: "Section behaviour",
        key: "sectionBehaviour",
        width: 180,
        render: (record: FloorItem) => {
            return (
                <div className="floor-manager__row__section-behaviour">
                    {getSectionBehaviour(record, showDeliveryOptions, setUpdateSectionBehaviour)}
                </div>
            );
        },
    });

    columns.push({
        title: "Availability",
        dataIndex: "availability",
        key: "availability",
        align: "right",
        width: 220,
        render: (_, record: FloorItem) => <AvailabilityIndicator item={record} update={update} />,
    });

    return columns;
};

const getSectionBehaviour = (
    record: FloorItem,
    showDeliveryOptions: boolean,
    setUpdateSectionBehaviour?: (item: FloorItem) => void
) => {
    if (record.type === FloorItemType.SECTION && record.id !== ALL_SECTION_ID) {
        if (record.orderFlow === SectionOrderFlowTypes.OPEN_TABLE) {
            return getOpenTableSectionBehaviour(record, setUpdateSectionBehaviour);
        } else {
            return getOrderAndPaySectionBehaviour(record, showDeliveryOptions);
        }
    }
    return null;
};
const getOpenTableSectionBehaviour = (record: FloorItem, setUpdateSectionBehaviour?: (item: FloorItem) => void) => (
    <span
        style={{
            display: "flex",
            flexWrap: "wrap",
        }}
    >
        {record.sectionOrderTypes
            ?.filter((option) => option.value === record.sectionBehaviour)
            ?.map((option) => (
                <Indicator
                    key={record.id + option.value}
                    disabled={!setUpdateSectionBehaviour || !!record.availabilityStatus}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setUpdateSectionBehaviour?.(record);
                    }}
                >
                    {option.label}
                </Indicator>
            ))}
    </span>
);

const getOrderAndPaySectionBehaviour = (record: FloorItem, showDeliveryOptions: boolean) => (
    <span
        style={{
            display: "flex",
            flexWrap: "wrap",
        }}
    >
        {showDeliveryOptions &&
            record.deliveryOptions
                ?.filter((option) => option.menuItemType === "food")
                ?.map((option) => (
                    <DeliveryOptionIndicator
                        key={record.id + option.type}
                        section={record}
                        deliveryOption={option}
                        disabled={!!record.availabilityStatus}
                    />
                ))}
    </span>
);

const getOrderFlowDisplayName = (record: FloorItem) => {
    if (record.orderFlow === SectionOrderFlowTypes.OPEN_TABLE) {
        return "Flex";
    }

    if (record.deliveryOptions?.some((option) => option.type === DeliveryOptionType.TAKEAWAY)) {
        return "Takeaway";
    }

    return "Order & Pay";
};
