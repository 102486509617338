import { removeAccent } from "common/utility";
import { getLocationLocale } from "features/location/selectors/getLocationLocale";
import { createSelector } from "reselect";
import {
    FloorItem,
    Section,
    Availability,
    FloorItemType,
    TableAvailabilityStatus,
    OpenTableOrderType,
    SectionBehaviour,
    SectionOrderFlowTypes,
} from "../types";
import { getSearchTermWithoutAccent, getShouldFilterUnavailableItems } from "./getManangeSection";
import { getSections } from "./getSections";

export const ALL_SECTION_ID = "ALL";

export const OPEN_TABLE_ORDER_TYPES: OpenTableOrderType[] = [
    {
        label: "Full service",
        value: SectionBehaviour.ORDER_AND_PAY,
    },
    {
        label: "Payments only",
        value: SectionBehaviour.PAY_ONLY,
    },
];

export const getFloorItems = createSelector(
    getLocationLocale,
    getSections,
    getShouldFilterUnavailableItems,
    getSearchTermWithoutAccent,
    (locale, sections, filterByUnavailable, searchTerm): FloorItem[] => {
        if (!sections.length) return [];
        const floorItems: FloorItem[] = [];

        const getItemMatch = (isUnavailable: boolean, matchesSearchTerm: boolean): boolean => {
            if (searchTerm && filterByUnavailable) return matchesSearchTerm && isUnavailable;
            if (searchTerm) return matchesSearchTerm;
            if (filterByUnavailable) return isUnavailable;
            return false;
        };

        const nameMatchesSearchTerm = (name: string) =>
            searchTerm ? removeAccent(name).toLocaleLowerCase().includes(searchTerm) : undefined;

        sections.forEach((s: Section) => {
            const sectionAvailable = s.availability === Availability.NOW;
            const sectionMatchesSearchTerm = nameMatchesSearchTerm(s.displayName);
            const sectionNotAvailable = filterByUnavailable && !sectionAvailable;
            const sectionFloorItem: FloorItem = {
                type: FloorItemType.SECTION,
                id: s.id,
                key: s.id,
                displayName: s.displayName,
                availabilityStatus: !sectionAvailable ? TableAvailabilityStatus.UNAVAILABLE : undefined,
                statusExpiryUtc: s.availability === Availability.LATER ? s.availabilityDate : undefined,
                deliveryOptions: s.deliveryOptions,
                hasMatch: getItemMatch(sectionNotAvailable, !!sectionMatchesSearchTerm),
                sectionOrderTypes: s.orderFlow === SectionOrderFlowTypes.OPEN_TABLE ? OPEN_TABLE_ORDER_TYPES : undefined,
                sectionBehaviour: s.sectionBehaviour,
                orderFlow: s.orderFlow,
            };
            if (s.tables?.length) {
                sectionFloorItem.children = s.tables.reduce((allItems: FloorItem[], t) => {
                    const showTableIfUnavailable =
                        (filterByUnavailable && t.availabilityStatus === TableAvailabilityStatus.UNAVAILABLE) ||
                        !sectionAvailable;
                    const tableMatchesSearchTerm = nameMatchesSearchTerm(t.name);
                    const itemHasMatch = getItemMatch(showTableIfUnavailable, !!tableMatchesSearchTerm);

                    if (sectionFloorItem.hasMatch || itemHasMatch || (!searchTerm && !filterByUnavailable)) {
                        const tableFloorItem: FloorItem = {
                            type: FloorItemType.TABLE,
                            id: t.id,
                            key: t.id,
                            displayName: t.name,
                            availabilityStatus: !sectionAvailable
                                ? TableAvailabilityStatus.UNAVAILABLE
                                : t.availabilityStatus,
                            // need to check that the expiry is today
                            statusExpiryUtc:
                                t.statusExpiryUtc && locale.isInSameDiningDate(t.statusExpiryUtc)
                                    ? t.statusExpiryUtc
                                    : null,
                            parent: sectionFloorItem,
                            hasMatch: itemHasMatch,
                            orderFlow: s.orderFlow,
                        };
                        allItems.push(tableFloorItem);
                    }
                    return allItems;
                }, [] as FloorItem[]);
            }

            if (searchTerm || filterByUnavailable) {
                // don’t add the section if neither the section or at least one of the children is a match
                if (sectionFloorItem.hasMatch || sectionFloorItem.children?.length) {
                    floorItems.push(sectionFloorItem);
                }
            } else {
                floorItems.push(sectionFloorItem);
            }
        });
        return floorItems;
    }
);
export const getExpandedFloorItemsKeys = createSelector(getFloorItems, (floorItems) => {
    return floorItems.reduce((expandKeys, item) => {
        if (item.children?.some((i) => i.hasMatch)) {
            expandKeys.push(item.id);
        }
        return expandKeys;
    }, [] as string[]);
});
