import { opsApi } from "common/api";
import { ModifierDetails, Availability } from "../types";
import { fetchAsStaff } from "../../staffLogin/selectors/getAccessToken";
import { productItems, MoreItems } from ".";

export const modifierDemo = () => [
    {
        id: "121",
        sku: "1",
        displayName: "Avocado",
        usedIn: "Used in: Any sides?, Extras?, Mare Monti, Penne Gambino, Penne Romana, Ravioli, Ravili Rosa",
        status: Availability.AVAILABLE,
        products: [...productItems()],
    },
    {
        id: "122",
        sku: "2",
        displayName: "Avocado2",
        usedIn: "Any",
        status: Availability.SOLD_OUT,
        products: [...MoreItems()],
    },
    {
        id: "123",
        sku: "2",
        displayName: "Avocado2",
        usedIn: "Any",
        status: Availability.SOLD_OUT,
        products: [...MoreItems()],
    },
];

const mapModifier = (modifier: ModifierDetails, index: number): ModifierDetails => {
    return {
        ...modifier,
        key: `modifiers_${index}_${modifier.sku}`,
        products:
            modifier.products &&
            modifier.products.map((p, i) => {
                return {
                    ...p,
                    key: `modifiers_${index}_product_${i}`,
                };
            }),
    };
};

export async function fetchModifiersApi(locationId: string): Promise<ModifierDetails[]> {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { locationId }, fetchAsStaff(false));
    return res.data.modifierAvailability.map(mapModifier);
}

type GraphQLPayload = { modifierAvailability: ModifierDetails[] };

const query: string = `query ($locationId: ID!) {
    modifierAvailability(locationId: $locationId) {
        displayName
        sku
        status
        products {
            sku
            displayName
            internalName
            price
            categories
            global
        }
    }
}
`;
