import { ServerOrderApi } from "common/api/orderApi";

export interface OrderHubConnectionState {
    status: "connecting" | "connected" | "disconnected" | "failed";
}

export enum ControlTypeKeys {
    DISCONNECT = "ORDER_HUB/DISCONNECT",
    CONNECT = "ORDER_HUB/CONNECT",
}

export const controlActions = {
    connect: () => ({ type: ControlTypeKeys.CONNECT }),
    disconnect: () => ({ type: ControlTypeKeys.DISCONNECT }),
};

export enum StatusTypeKeys {
    INITIALIZED = "ORDER_HUB/INITIALIZED",
    CONNECTING = "ORDER_HUB/CONNECTING",
    CONNECTED = "ORDER_HUB/CONNECTED",
    DISCONNECTED = "ORDER_HUB/DISCONNECTED",
    CONNECTION_FAILED = "ORDER_HUB/CONNECTION_FAILED",
}

export const statusActions = {
    initialized: (api: ServerOrderApi) => ({ type: StatusTypeKeys.INITIALIZED, api }),
    connecting: () => ({ type: StatusTypeKeys.CONNECTING }),
    connected: () => ({ type: StatusTypeKeys.CONNECTED }),
    disconnected: () => ({ type: StatusTypeKeys.DISCONNECTED }),
    connectionFailed: (error: any) => ({ type: StatusTypeKeys.CONNECTION_FAILED, error }),
};

export type ConnectAction = { type: ControlTypeKeys.CONNECT };
export type DisconnectAction = { type: ControlTypeKeys.DISCONNECT };

export type OrderHubControlActions = ConnectAction | DisconnectAction;

export type InitializedAction = { type: StatusTypeKeys.INITIALIZED; api: ServerOrderApi };
export type ConnectingAction = { type: StatusTypeKeys.CONNECTING };
export type ConnectedAction = { type: StatusTypeKeys.CONNECTED };
export type DisconnectedAction = { type: StatusTypeKeys.DISCONNECTED };
export type ConnectionFailedAction = { type: StatusTypeKeys.CONNECTION_FAILED; error: any };

export type OrderHubStatusActions =
    | InitializedAction
    | ConnectingAction
    | ConnectedAction
    | DisconnectedAction
    | ConnectionFailedAction;

const initialState: OrderHubConnectionState = {
    status: "disconnected",
};

export default function (state = initialState, action: OrderHubStatusActions): OrderHubConnectionState {
    if (action.type === StatusTypeKeys.CONNECTING) return { status: "connecting" };

    if (action.type === StatusTypeKeys.CONNECTED) return { status: "connected" };

    if (action.type === StatusTypeKeys.DISCONNECTED) return { status: "disconnected" };

    if (action.type === StatusTypeKeys.CONNECTION_FAILED) return { status: "failed" };

    return state;
}
