import { Spin } from "antd";
import { Order, Party } from "features/orders/types";
import React, { ComponentPropsWithRef, ForwardedRef } from "react";
import { TableComponents, TableProps } from "react-virtuoso";

export const VirtualTableComponents = ({
    loading,
    itemType,
    emptyText,
    colSpan = 7,
}: {
    loading: boolean;
    itemType: string;
    emptyText?: string;
    colSpan?: number;
}): TableComponents<Order | Party, any> => ({
    Table: (props: TableProps) => <table className="ant-table" {...props} />,
    TableHead: React.forwardRef(
        ({ style, ...props }: ComponentPropsWithRef<"thead">, ref: ForwardedRef<HTMLTableSectionElement>) => (
            <thead className="ant-table-thead" style={{ ...style }} {...props} ref={ref} />
        )
    ),
    TableBody: React.forwardRef(
        ({ style, ...props }: ComponentPropsWithRef<"tbody">, ref: ForwardedRef<HTMLTableSectionElement>) => (
            <tbody className="ant-table-body" style={{ ...style }} {...props} ref={ref} />
        )
    ),
    TableFoot: React.forwardRef(
        ({ style, ...props }: ComponentPropsWithRef<"tfoot">, ref: ForwardedRef<HTMLTableSectionElement>) => (
            <tfoot style={{ ...style, position: "initial", minHeight: 40 }} {...props} ref={ref} />
        )
    ),
    EmptyPlaceholder: () => (
        <tbody>
            <tr>
                <td colSpan={colSpan} className="virtual-table__empty-row">
                    {emptyText || `No ${itemType} were found for this date.`}
                    {loading && (
                        <div className="virtual-table__loader">
                            <Spin />
                        </div>
                    )}
                </td>
            </tr>
        </tbody>
    ),
});
