import { fetchAsStaff } from "../../staffLogin/selectors/getAccessToken";
import { opsApi } from "../../../common/api";

export async function resolveUnconfirmedOrder(locationId: string, partyId: string) {
    const request = new Request(`${opsApi.baseUrl}/party/live/resolve/${locationId}/${partyId}`, {
        method: "PUT",
    });

    const response = await fetchAsStaff(true)(request);

    if (!response.ok) {
        throw response;
    }
}
