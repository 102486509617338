import { Order, OrderSearchOptions } from "../types";
import { opsApi } from "common/api";
import { fetchAsStaff } from "../../staffLogin/selectors/getAccessToken";
import moment from "moment";
import { getPaymentsTotal, mapOrder } from "./commonGraphFunctions";
import { getOrderDisplayStatus, toGroupTabsData } from "../utils";
import { OrderWithoutPayment, OrderWithPayment, ActiveParty } from "common/types";
import { getGroupTabFields, getPartyFields } from "./commonGraphFields";

export const defaultOptions: OrderSearchOptions = {
    page: 1,
    pageSize: 15,
    diningDate: moment().format("YYYY-MM-DD"),
    ascending: true,
    filters: null,
    search: null,
    sectionId: null,
};

export async function fetchClosedOrdersApi(
    locationId: string,
    options: OrderSearchOptions = defaultOptions
): Promise<OrdersResponse> {
    const searchOptions = { ...defaultOptions, ...options };
    const statusFilter = searchOptions.filters?.length ? searchOptions.filters : null;
    const res = await opsApi.graphQLQuery<GraphQLPayload>(
        query,
        {
            locationId,
            diningDate: searchOptions.diningDate,
            skip: (searchOptions.page! - 1) * searchOptions.pageSize!,
            limit: searchOptions.pageSize!,
            sectionId: searchOptions.sectionId,
            tableSearch: searchOptions.search,
            ascending: searchOptions.ascending,
            statusFilter,
        },
        fetchAsStaff(true)
    );
    let spreadOrdersCount = 0;

    const orders: Order[] = [];
    res.data.closedParties.data.forEach((p: any) => {
        if (p.orderWithPayment) {
            orders.push(mapPartyToOrderWithPayment(p, p.orderWithPayment));
        } else {
            p.ordersWithoutPayment?.forEach((order: OrderWithoutPayment, index: number) => {
                if (statusFilter && !statusFilter.includes(getOrderDisplayStatus(order.confirmationStatus))) {
                    return;
                }

                ++spreadOrdersCount;
                const mappedOrder = mapPartyToOrderWithoutPayment(p, order);
                orders.push({
                    ...mappedOrder,
                    partyId: mappedOrder.partyId + "-Closed",
                    itemTotal: order.orderTotal,
                });
            });
            --spreadOrdersCount;
        }
    });
    return {
        orders: orders.sort(sortOrders),
        historyOrdersTotal: res.data.closedParties.totalCount + spreadOrdersCount,
        spreadOrdersCount,
    };
}
function sortOrders(a: Order, b: Order) {
    return b.time.getTime() - a.time.getTime();
}

type GraphQLPayload = { closedParties: { data: any[]; totalCount: number } };
export type OrdersResponse = { orders: Order[]; historyOrdersTotal: number; spreadOrdersCount: number };

const query: string = `
query (
    $locationId: ID!, 
    $diningDate: String!, 
    $skip: Int!, 
    $limit: Int!, 
    $sectionId: String, 
    $tableSearch: String, 
    $ascending: Boolean!,
    $statusFilter: [String!]) 
{  
    closedParties(locationId: $locationId, diningDate: $diningDate, skip: $skip, limit: $limit, section: $sectionId, tableSearch: $tableSearch, ascending: $ascending, statusFilter: $statusFilter) 
    {    
        totalCount    
        data {      
            ${getPartyFields}
            groupTab {
                ${getGroupTabFields}
            }
        }
    }
}
`;

export const mapPartyToOrderWithPayment = (party: ActiveParty, order: OrderWithPayment): Order => {
    const mappedOrder = mapOrder(party, order);
    const partyRefund = party.refunds?.[0];
    return {
        ...mappedOrder,
        fullName: party.members[0].displayName,
        itemTotal: order.orderTotal,
        totalValue: order.posTotal,
        promotions: order.promotions,
        cepOffers: order.cepOffers,
        refundablePaymentId: party.refundablePaymentId,
        paymentsTotal: getPaymentsTotal(party, partyRefund),
        pointsPaymentsTotal: party.pointsPaymentsTotal,
        refundableTotal: party.refundableTotal,
        refundedTotal: partyRefund?.transactionTotal,
        payments: party.members[0].payments,
        groupTab: party.groupTab ? toGroupTabsData([party.groupTab])[0] : undefined,
        dateCollected: (party.dateCollected && new Date(party.dateCollected)) || undefined,
        processingFee: party.processingFeeTotal,
        surcharge: order.surcharge,
        discount: order.discount,
        membershipDiscount: order.membershipDiscount,
        trayCharges: order.trayCharges,
        taxTotals: party.taxTotals,
        tip: party.tipsTotal,
        refunds: party.refunds,
        venueCauses: order.venueCauses,
        stampsEarned: order.stampsEarned,
    };
};

export const mapPartyToOrderWithoutPayment = (party: ActiveParty, order: OrderWithoutPayment): Order => {
    const mappedOrder = mapOrder(party, order);
    return {
        ...mappedOrder,
        payments: party.members[0].payments,
        groupTab: party.groupTab ? toGroupTabsData([party.groupTab])[0] : undefined,
        dateCollected: (party.dateCollected && new Date(party.dateCollected)) || undefined,
    };
};
