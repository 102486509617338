import { Drawer } from "antd";
import classNames from "classnames";
import { useTrackEvent } from "common/AppInsights/AppInsights";
import { LoadingOverlayWithSpin } from "common/loader/LoadingSpinner";
import { AppDispatch } from "features";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators } from "../../reducers";
import { getcloseGroupTabResponse, getOrderIsRefunded, getRefundProcessed } from "../../selectors";
import { GroupTabsData, Order } from "../../types";
import { GroupTabsDrawer } from "../GroupTabsDrawer";
import { OrderCard } from "./OrderCard";
import { OrderRefund } from "../OrderRefund";
import "../../assets/OrdersDrawer.scss";
import { getPartyTypeForTracking } from "../../types/orderTracking";

export interface Props {
    order: Order | null;
    onAction?: (order: Order) => boolean;
    getActionText?: (order: Order) => string;
    getActionBgColor?: (order: Order) => string;
    onClose: (cancel: boolean) => void;
    visible: boolean;
    className: string;
    onRefundOrder: (order: Order, amount: number) => void;
    saveSuccess?: boolean;
    loading?: boolean;
    groupTabModalClosing?: boolean;
    closeFailed?: boolean;
    closeSuccess?: boolean;
    oncloseGroupTab?: (groupTabId: string) => void;
    onCloseGroupTabModal?: (cancel: boolean) => void;
    actioningGroupTab?: boolean;
    isGroupTabOrder?: boolean;
    viaTabsCard?: boolean;
    buttonDisabled?: boolean;
}

export const OrdersDrawer: FC<Props> = ({
    order,
    onAction,
    getActionText,
    getActionBgColor,
    onClose,
    visible,
    className,
    onRefundOrder,
    saveSuccess = false,
    loading = false,
    closeFailed,
    closeSuccess,
    onCloseGroupTabModal,
    oncloseGroupTab,
    groupTabModalClosing,
    actioningGroupTab,
    isGroupTabOrder,
    viaTabsCard,
    buttonDisabled,
}) => {
    const [showRefund, setShowRefund] = useState(false);
    const [groupTab, setGroupTab] = useState<GroupTabsData | undefined>();
    const refundProcessed = useSelector(getRefundProcessed);
    const orderStatusIsRefunded = useSelector(getOrderIsRefunded);
    const closeGroupTabResponse = useSelector(getcloseGroupTabResponse);
    const dispatch = useDispatch<AppDispatch>();

    const trackResubmitClicked = useTrackEvent("ORDER/RESUBMIT_CLICKED", {});

    const internalOnAction = useCallback(
        (order: Order) => {
            if (!onAction) return true;
            onAction(order);
            return false;
        },
        [onAction]
    );

    const internalOnClose = useCallback(
        (cancel: boolean) => {
            onClose(cancel);
            setShowRefund(false);
            dispatch(actionCreators.orderRefundReset());
        },
        [onClose, dispatch]
    );

    useEffect(() => {
        let closeModalTimer = 0;
        if (refundProcessed && orderStatusIsRefunded) {
            closeModalTimer = window.setTimeout(() => {
                onClose(true);
                setShowRefund(false);
                dispatch(actionCreators.orderRefundReset());
            }, 3000);
        }

        return () => {
            clearTimeout(closeModalTimer);
        };
    }, [refundProcessed, orderStatusIsRefunded, onClose, dispatch]);

    const onOrderGroupTab = useCallback((order?: Order) => {
        setGroupTab(order?.groupTab);
    }, []);

    const setResubmitOrder = useCallback(
        (order: Order) => {
            trackResubmitClicked({
                nb_times_submitted: order.orderSubmittedCount,
                "party.type": getPartyTypeForTracking(order),
            });
            dispatch(actionCreators.setOrderToResubmit(order));
        },
        [dispatch, trackResubmitClicked]
    );

    return (
        <Drawer
            open={visible}
            className={classNames(className, showRefund && !refundProcessed && "refund-active")}
            width={420}
            onClose={() => internalOnClose(true)}
            style={
                visible && showRefund && !refundProcessed
                    ? { transform: "translateX(-100vw) translateX(420px)", transitionDuration: ".3s" }
                    : { transform: "none" }
            }
            push={false}
            keyboard={!(visible && showRefund && !refundProcessed)}
        >
            {(isGroupTabOrder && order?.groupTab) || (!isGroupTabOrder && order) ? (
                <>
                    <OrderCard
                        order={order}
                        onAction={internalOnAction}
                        getActionText={getActionText}
                        getActionBgColor={getActionBgColor}
                        onClose={internalOnClose}
                        onCloseRefund={() => setShowRefund(false)}
                        saved={saveSuccess}
                        buttonDisabled={buttonDisabled || !onAction}
                        onShowRefund={() => setShowRefund(true)}
                        refundOpen={showRefund && !refundProcessed}
                        loading={loading || saveSuccess}
                        onOrderGroupTab={onOrderGroupTab}
                        viaTabsCard={viaTabsCard}
                        setResubmitOrder={setResubmitOrder}
                    />
                    {visible && (
                        <Drawer
                            open={showRefund && !refundProcessed}
                            className={classNames("refund-modal", showRefund && "hide-mask")}
                            width="calc(100vw - 420px)"
                            onClose={() => setShowRefund(false)}
                            mask={false}
                        >
                            <OrderRefund order={order} onRefundOrder={onRefundOrder} />
                        </Drawer>
                    )}

                    {groupTab && (
                        <GroupTabsDrawer
                            groupTab={groupTab}
                            onClose={(cancel: boolean) => {
                                onOrderGroupTab();
                                onCloseGroupTabModal?.(cancel);
                            }}
                            visible={!!groupTab && !groupTabModalClosing}
                            className="live-orders-modal"
                            onCloseGroupTab={(groupTabId: string) => {
                                oncloseGroupTab?.(groupTabId);
                            }}
                            closeSuccess={
                                closeSuccess ?? closeGroupTabResponse ? true : closeFailed ? false : undefined
                            }
                            actioning={!!actioningGroupTab}
                            viaOrderCard={true}
                        />
                    )}
                </>
            ) : (
                <LoadingOverlayWithSpin visible={true} />
            )}
        </Drawer>
    );
};
