import * as React from "react";
import { FeatureConfiguration } from "..";
import { Route, Redirect } from "react-router";
import { SalesPage } from "./components/SalesPage";
import { useSelector } from "react-redux";
import { getLocationPermissions } from "features/location/selectors/getPermissions";

const GuardedSalesPage = () => {
    const permissions = useSelector(getLocationPermissions);
    const hasSalesPermissions = permissions.has("reports:sales:read");
    
    return hasSalesPermissions ? <SalesPage /> : <Redirect to="/orders/" />;
};

export default function (config: FeatureConfiguration) {
    config.addRoutes(config.routes.locationRoutes, [
        <Route 
            key="sales" 
            path={`/sales`} 
            render={() => <GuardedSalesPage />} />,
    ]);
}
