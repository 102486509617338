import { Button } from "antd";
import { ColumnProps } from "antd/lib/table";
import classNames from "classnames";
import { useTrackEvent } from "common/AppInsights/AppInsights";
import { CheckedIcon, HiddenIcon, TimeIcon } from "common/icons";
import { ReloadData } from "common/loader/ReloadData";
import { AvailabilityPicker } from "components/availabilityPicker/AvailabilityPicker";
import {
    AvailabilityRecord,
    AvailabilityRecordObject,
    AvailabilityTable,
} from "components/availabilityTable/AvailabilityTable";
import { ConfirmModal } from "components/confirmModal/ConfirmModal";
import { Indicator } from "components/indicator/Indicator";
import { Tabs } from "components/tabs/Tabs";
import { Text } from "components/text";
import { getLocationId } from "features/staffLogin/selectors/getLocationId";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../index";
import { fetchCategories, updateCategories } from "../actions";
import { getCategories, getCategoriesLoading, getDrinksCategoriesSplit, getFoodCategoriesSplit } from "../selectors";
import { getLocationPermissions } from "features/location/selectors/getPermissions";
import {
    Availability,
    CategoryAvailabilityChange,
    CategoryAvailabilityChangeHandler,
    CategoryAvailabilityUpdateInput,
    CategoryDetails,
    CategoryDialogMode,
    CategoryDisplay,
    CategoryTypes,
    EditCategoryAvailabilityInfo,
} from "../types";
import { CategoryAvailability } from "./CategoryAvailability";
import { AllCategoriesAvailability } from "./AllCategoriesAvailability";
import { getTimeDifferenceMinutes } from "common/utility";
import { HIDE_WAIT_TIMES } from "../utils";

import "./CategoriesPage.scss";

type EventAction = "activate" | "deactivate" | "wait time" | undefined;

type EventCategory = {
    all_food?: string;
    all_drinks?: string;
    all_categories: boolean;
    data: EventCategoryData;
};

type EventCategoryDataItem = {
    category_name: string;
    live: boolean;
    duration?: number | string | null;
    category_type: CategoryTypes;
    action: EventAction;
    waitTime: string | null;
};

type EventCategoryData = {
    [x: string]: EventCategoryDataItem;
};

type CategoryFilter = (cat: CategoryDetails) => boolean;
interface FilterOptions {
    showUnavailable: boolean;
    showWait: boolean;
    unsavedChanges: {
        [x: string]: CategoryAvailabilityUpdateInput;
    };
    editAvailability: (info: EditCategoryAvailabilityInfo | null) => void;
}

export const CategoriesPage = () => {
    const locationId = useSelector(getLocationId);
    const allCategories = useSelector(getCategories);
    const foodCategories = useSelector(getFoodCategoriesSplit);
    const drinksCategories = useSelector(getDrinksCategoriesSplit);
    const categoriesLoading = useSelector(getCategoriesLoading);
    const [unsavedChanges, setUnsavedChanges] = useState<{ [x: string]: CategoryAvailabilityUpdateInput }>({});
    const [showUnavailable, setShowUnavailable] = useState(false);
    const [showWait, setShowWait] = useState(false);
    const [showTab, setShowTab] = useState<CategoryTypes>("food");
    const [editingCategoryAvailability, setEditingCategoryAvailability] = useState<EditCategoryAvailabilityInfo | null>(
        null
    );
    const permissions = useSelector(getLocationPermissions);
    const canUpdateAvailability = permissions.has("category:availability:update");
    const canUpdateWaitTimes = permissions.has("category:waittime:update");

    const insightsRef = useRef<EventCategory>({
        all_categories: false,
        data: {},
    });

    const trackDisableCategories = useTrackEvent<EventCategory>(
        "CATEGORY/AVAILABILITY_CHANGED",
        getInitialTrackingObject()
    );
    const totalUnsavedChanges = useMemo(() => Object.keys(unsavedChanges).length, [unsavedChanges]);

    const [changesSaved, setChangesSaved] = useState(false);
    const savedRef = useRef(0);
    const dispatch = useDispatch<AppDispatch>();

    const updateUnsavedChanges = useCallback(
        (categories: CategoryDisplay, change: CategoryAvailabilityChange, idFilters: string[] = []) => {
            const { all } = change;
            const changeItems: { [x: string]: CategoryAvailabilityUpdateInput } = { ...unsavedChanges };

            if (all) {
                processUnsavedChanges(categories.inServiceToday, changeItems, false, change, insightsRef, idFilters);
            }

            processUnsavedChanges(categories.inServiceNow, changeItems, true, change, insightsRef, idFilters);

            setUnsavedChanges(changeItems);
            setChangesSaved(false);
        },
        [unsavedChanges]
    );

    const categoryAvailabilityChangeHandler = useCallback<CategoryAvailabilityChangeHandler>(
        (change) => {
            const { type, all } = change;
            const categories = type === "Food" ? foodCategories : drinksCategories;
            const insightsLabel = all ? "All" : "Inservice";
            if (type === "Food") {
                insightsRef.current.all_food = insightsLabel;
            } else {
                insightsRef.current.all_drinks = insightsLabel;
            }
            const idFilters = editingCategoryAvailability ? [editingCategoryAvailability.id] : [];
            updateUnsavedChanges(categories, change, idFilters);
        },
        [updateUnsavedChanges, foodCategories, drinksCategories, editingCategoryAvailability]
    );

    const saveChanges = useCallback(() => {
        if (!locationId || !unsavedChanges) return;

        trackDisableCategories({
            ...insightsRef.current,
            all_categories: totalUnsavedChanges === allCategories.length,
        });

        insightsRef.current = getInitialTrackingObject();
        dispatch(updateCategories(locationId, Object.values(unsavedChanges)));
        window.clearTimeout(savedRef.current);
        setChangesSaved(true);
    }, [locationId, unsavedChanges, dispatch, totalUnsavedChanges, allCategories, trackDisableCategories]);

    useEffect(() => {
        if (!categoriesLoading) {
            savedRef.current = window.setTimeout(() => setChangesSaved(false), 1500);
        }
        return () => window.clearTimeout(savedRef.current);
    }, [categoriesLoading]);

    const cancelChanges = useCallback(() => {
        if (!locationId || !unsavedChanges) return;
        setUnsavedChanges({});
    }, [locationId, unsavedChanges]);

    const fetch = useCallback(() => locationId && dispatch(fetchCategories(locationId)), [locationId, dispatch]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    useEffect(() => {
        setUnsavedChanges({});
    }, [drinksCategories, foodCategories]);

    const editAvailability = useCallback((info: EditCategoryAvailabilityInfo | null) => {
        setEditingCategoryAvailability(info);
    }, []);

    const totalFoodUnsavedChanges = useMemo(
        () =>
            foodCategories.inServiceNow.filter((cat) => !!unsavedChanges[cat.id]).length +
            foodCategories.inServiceToday.filter((cat) => !!unsavedChanges[cat.id]).length,
        [unsavedChanges, foodCategories]
    );

    const totalDrinksUnsavedChanges = useMemo(
        () =>
            drinksCategories.inServiceNow.filter((cat) => !!unsavedChanges[cat.id]).length +
            drinksCategories.inServiceToday.filter((cat) => !!unsavedChanges[cat.id]).length,
        [unsavedChanges, drinksCategories]
    );

    const displayType = showTab === "food" ? "Food" : "Drinks";

    const filters = useMemo(() => {
        const options: JSX.Element[] = [];

        if (!HIDE_WAIT_TIMES) {
            options.push(
                <Indicator
                    key="showWaitTimes"
                    onClick={() => setShowWait(!showWait)}
                    className="disable-category__filter-indicator"
                    primary={showWait}
                    unique
                    icon={TimeIcon}
                    textSize="large"
                    textWeight="medium"
                >
                    <span className="disable-category__filter-label">Show wait</span>
                </Indicator>
            );
        }

        options.push(
            <Indicator
                key="showUnavailable"
                onClick={() => setShowUnavailable(!showUnavailable)}
                className="disable-category__filter-indicator"
                primary={showUnavailable}
                unique
                icon={HiddenIcon}
                textSize="large"
                textWeight="medium"
            >
                <span className="disable-category__filter-label">Show unavailable</span>
            </Indicator>
        );

        return <div className="disable-category__filters">{options}</div>;
    }, [showUnavailable, setShowUnavailable, showWait, setShowWait]);

    const filterOptions = useMemo(
        () => ({
            showUnavailable,
            showWait,
            unsavedChanges,
            editAvailability,
        }),
        [showUnavailable, showWait, unsavedChanges, editAvailability]
    );

    const filteredFoodInServiceNow = useMemo(
        () => applyFilters(canUpdateAvailability, canUpdateWaitTimes, foodCategories.inServiceNow, filterOptions),
        [canUpdateAvailability, canUpdateWaitTimes, foodCategories.inServiceNow, filterOptions]
    );

    const filteredFoodInServiceToday = useMemo(
        () => applyFilters(canUpdateAvailability, canUpdateWaitTimes, foodCategories.inServiceToday, filterOptions),
        [canUpdateAvailability, canUpdateWaitTimes, foodCategories.inServiceToday, filterOptions]
    );

    const filteredDrinkInServiceNow = useMemo(
        () => applyFilters(canUpdateAvailability, canUpdateWaitTimes, drinksCategories.inServiceNow, filterOptions),
        [canUpdateAvailability, canUpdateWaitTimes, drinksCategories.inServiceNow, filterOptions]
    );

    const filteredDrinksInServiceToday = useMemo(
        () => applyFilters(canUpdateAvailability, canUpdateWaitTimes, drinksCategories.inServiceToday, filterOptions),
        [canUpdateAvailability, canUpdateWaitTimes, drinksCategories.inServiceToday, filterOptions]
    );

    return (
        <div className="soldout-items-page disable-category">
            <div className="disable-category__body">
                <Tabs
                    mode="content"
                    activeKey={showTab + "-tab"}
                    rightContent={filters}
                    onTabClick={(key: string) => {
                        setShowTab(key === "food-tab" ? "food" : "drink");
                    }}
                    tabs={[
                        {
                            title: "Food",
                            totalCounter: totalFoodUnsavedChanges,
                            key: "food-tab",
                            content: (
                                <>
                                    <AvailabilityTable
                                        items={filteredFoodInServiceNow}
                                        columns={columns}
                                        loading={categoriesLoading}
                                        title="In service"
                                    />
                                    <AvailabilityTable
                                        items={filteredFoodInServiceToday}
                                        columns={columns}
                                        loading={categoriesLoading}
                                        title="Other"
                                    />
                                </>
                            ),
                        },
                        {
                            title: "Drinks",
                            totalCounter: totalDrinksUnsavedChanges,
                            key: "drink-tab",
                            content: (
                                <>
                                    <AvailabilityTable
                                        items={filteredDrinkInServiceNow}
                                        columns={columns}
                                        loading={categoriesLoading}
                                        title="In service"
                                    />
                                    <AvailabilityTable
                                        items={filteredDrinksInServiceToday}
                                        columns={columns}
                                        loading={categoriesLoading}
                                        title="Other"
                                    />
                                </>
                            ),
                        },
                    ]}
                />
                <ReloadData fetch={fetch} />
            </div>
            {(canUpdateAvailability || canUpdateWaitTimes) && (
                <>
                    <div
                        className={classNames(
                            "disable-category__footer",
                            changesSaved && !categoriesLoading && "saved-changes"
                        )}
                    >
                        {changesSaved && !categoriesLoading ? (
                            <Text preset="g-16" mode="bold" className="disable-category__footer__saved">
                                <CheckedIcon /> Your changes have been saved.
                            </Text>
                        ) : (
                            <>
                                {canUpdateWaitTimes && !HIDE_WAIT_TIMES && (
                                    <AllCategoriesAvailability
                                        changeHandler={categoryAvailabilityChangeHandler}
                                        type={showTab === "food" ? "Food" : "Drinks"}
                                        mode={CategoryDialogMode.ALL_WAIT_TIMES}
                                        disabled={categoriesLoading}
                                        buttonLabel="Set all wait times"
                                        buttonIcon={TimeIcon}
                                        modalTitle={`Set wait time of all ${displayType.toLowerCase()}`}
                                    />
                                )}
                                {canUpdateAvailability && (
                                    <AllCategoriesAvailability
                                        changeHandler={categoryAvailabilityChangeHandler}
                                        type={showTab === "food" ? "Food" : "Drinks"}
                                        mode={CategoryDialogMode.ALL_AVAILABILITY}
                                        disabled={categoriesLoading}
                                        buttonLabel="Set all availability"
                                        buttonIcon={HiddenIcon}
                                        modalTitle={`Set availability of all ${displayType.toLowerCase()}`}
                                    />
                                )}
                                <div className="disable-category__footer__actions">
                                    {!!totalUnsavedChanges && (
                                        <Button
                                            className="disable-category__footer__actions__cancel"
                                            disabled={!totalUnsavedChanges}
                                            onClick={cancelChanges}
                                        >
                                            <Text preset="g-16">
                                                Cancel
                                                <span className="disable-category__footer__actions__cancel-extra">
                                                    {" "}
                                                    changes
                                                </span>
                                            </Text>
                                        </Button>
                                    )}
                                    <Button
                                        className="disable-category__footer__actions__save"
                                        disabled={!totalUnsavedChanges}
                                        onClick={saveChanges}
                                    >
                                        <Text preset="g-16">
                                            Save {!!totalUnsavedChanges && totalUnsavedChanges} change
                                            {totalUnsavedChanges === 1 ? "" : "s"}
                                        </Text>
                                    </Button>
                                </div>
                            </>
                        )}
                    </div>
                    {editingCategoryAvailability && (
                        <CategoryAvailability
                            changeHandler={categoryAvailabilityChangeHandler}
                            type={showTab === "food" ? "Food" : "Drinks"}
                            mode={CategoryDialogMode.SINGLE_CATEGORY}
                            showModal={true}
                            info={editingCategoryAvailability}
                            setShowModal={(show) => {
                                if (!show) {
                                    setEditingCategoryAvailability(null);
                                }
                            }}
                            modalTitle={`Set a status for ${editingCategoryAvailability.displayName}`}
                        />
                    )}
                </>
            )}
            <ConfirmModal
                showModal={!!totalUnsavedChanges}
                title={`You have ${totalUnsavedChanges} unsaved changes`}
                subTitle="Are you sure you want to leave this page without saving?"
                isPrompt={!!totalUnsavedChanges}
                confirmText="Stay and continue editing"
            />
        </div>
    );
};

function getTrackingInfo(
    cat: CategoryDetails,
    change: CategoryAvailabilityChange,
    inServiceNow: boolean
): EventCategoryDataItem {
    const { status, duration, waitTime } = change;
    let action: EventAction;
    let durationToSend: string | number | null;

    if (waitTime) {
        action = "wait time";
    } else if (status === Availability.AVAILABLE) {
        action = "activate";
    } else {
        action = "deactivate";
    }

    if (!duration) {
        if (status === Availability.SOLD_OUT) {
            durationToSend = "Until tomorrow";
        } else {
            // never clear
            durationToSend = null;
        }
    } else {
        durationToSend = duration;
    }

    return {
        duration: durationToSend,
        category_name: cat.displayName,
        live: inServiceNow,
        category_type: cat.type,
        action,
        waitTime: waitTime ? `${waitTime} min` : null,
    };
}

function getInitialTrackingObject() {
    return {
        all_categories: false,
        data: {},
    };
}

function applyFilters(
    canUpdateAvailability: boolean,
    canUpdateWaitTimes: boolean,
    categories: CategoryDetails[],
    { showUnavailable, showWait, unsavedChanges, editAvailability }: FilterOptions
): AvailabilityRecord[] {
    let filterFns: CategoryFilter[] = [];

    if (showUnavailable) {
        filterFns.push(categoryUnavailable);
    }

    if (showWait) {
        filterFns.push(categoryHasWaitTime);
    }

    return categories
        .filter(
            (cat) =>
                filterFns.length === 0 ||
                filterFns.reduce((prev: boolean, curr: CategoryFilter) => prev || curr(cat), false)
        )
        .map((cat) => ({
            object: cat,
            disabled: !canUpdateAvailability && !canUpdateWaitTimes,
            modified: unsavedChanges[cat.id],
            editAvailability,
        }));
}

function processUnsavedChanges(
    categories: CategoryDetails[],
    changeItems: { [x: string]: CategoryAvailabilityUpdateInput },
    inServiceNow: boolean,
    change: CategoryAvailabilityChange,
    insightsRef: React.RefObject<EventCategory>,
    idFilters: string[] = []
) {
    const { status, duration, waitTime } = change;
    categories
        .filter(({ id }) => (idFilters.length === 0 ? true : idFilters.includes(id)))
        .forEach((cat) => {
            const requiresSave = isUnsavedChange(change, cat);
            const existingUnsavedChange = !!changeItems[cat.id];

            if (requiresSave) {
                if (insightsRef.current) {
                    insightsRef.current.data[cat.id] = getTrackingInfo(cat, change, inServiceNow);
                }
                changeItems[cat.id] = {
                    categoryId: cat.id,
                    status,
                    fixedTimeMinutes: duration,
                    waitTime,
                } as CategoryAvailabilityUpdateInput;
            } else {
                if (existingUnsavedChange) {
                    delete changeItems[cat.id];
                    if (insightsRef.current) {
                        delete insightsRef.current.data[cat.id];
                    }
                }
            }
        });
}

function categoryUnavailable(cat: CategoryDetails): boolean {
    return !!cat.availability && !cat.waitTime && cat.availability > Date.now();
}

function categoryHasWaitTime(cat: CategoryDetails): boolean {
    return !!cat.waitTime && !!cat.availability && cat.availability > Date.now();
}

const columns: ColumnProps<CategoryLineProps>[] = [
    {
        title: "Name",
        dataIndex: "name",
        render: (text: string, { object: { displayName, internalName } }: CategoryLineProps) => (
            <Text className="availability-table__row__name" preset="g-14">
                {displayName}
                {!!internalName && (
                    <Text className="availability-table__row__internal" preset="g-14">
                        {" "}
                        ({internalName})
                    </Text>
                )}
            </Text>
        ),
    },
    {
        title: "Unsaved",
        dataIndex: "unsaved",
        width: 185,
        render: (text: string, { modified }: CategoryLineProps) =>
            modified && <Text className="availability-table__row__unsaved-text">Unsaved changes</Text>,
    },
    {
        title: "availability",
        key: "availability",
        width: 200,
        render: (
            text: string,
            {
                object: { id, availability, waitTime, displayName },
                disabled,
                modified,
                editAvailability,
            }: CategoryLineProps
        ) => (
            <AvailabilityPicker
                id={id}
                modified={modified}
                disabled={disabled}
                availability={(!!modified && modified.duration) || availability}
                availabilityOptions={[]}
                waitTime={waitTime}
                onClick={() => editAvailability({ id, displayName, waitTime, availability })}
            />
        ),
    },
];

interface CategoryLineProps extends AvailabilityRecord {
    object: AvailabilityRecordObject & CategoryDetails;
}

function isUnsavedChange(change: CategoryAvailabilityChange, category: CategoryDetails): boolean {
    const { duration: newDuration, status, waitTime, singleCategory } = change;
    const { availability: oldDuration, waitTime: oldWaitTime } = category;
    let wasAvailable = false;

    // simple 'was available' case
    if (!oldDuration && !oldWaitTime) {
        wasAvailable = true;
    }

    // complex 'was available' case: it wasn't available, but time interval has elapsed
    if (oldDuration) {
        if (getTimeDifferenceMinutes(oldDuration) <= 0) {
            wasAvailable = true;
        }
    }

    // wait times should not be applied to items that were unavailable unless editing single category
    if (waitTime !== undefined && !wasAvailable && !oldWaitTime && !singleCategory) {
        return false;
    }

    // any non available change with a duration (availability) should be treated as a change because it will need a new timestamp
    if (newDuration) {
        return true;
    }

    // availability should not be applied to items with wait time unless editing single category or removing wait time
    if (status === Availability.AVAILABLE && waitTime !== 0 && oldWaitTime && !singleCategory) {
        return false;
    }

    // was available and still is
    if (status === Availability.AVAILABLE && wasAvailable) {
        return false;
    }

    // nb there might be more 'unchanged' cases but we cannot do more without
    // knowing what the timestamp of yet to be submitted items would be
    return true;
}
