import { config } from "common/config";
import { GraphQLResult, graphQLQuery as commonGraphQLQuery, APIGateway } from "../../API";
import { regionHelper } from "../../features/region";

export const baseUrl = config.VITE_OPS_API!;

export const fetch = async (url: string, init?: RequestInit) => {
    if (url !== "/region") {
        init = await regionHelper.addRegionHeaders(init);
    }
    return window.fetch(`${baseUrl}${url}`, init);
};

export async function graphQLQuery<T = any>(
    query: string,
    variables: {} | undefined,
    gateway: APIGateway
): Promise<GraphQLResult<T>> {
    const result = await commonGraphQLQuery(`${config.VITE_OPS_API}/graphql`, query, variables, gateway);

    return result as GraphQLResult<T>;
}
