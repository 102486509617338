import { LocationLocale } from "features/location/types/LocationLocale";

export const getSurchargeText = (locationLocale: LocationLocale, surchargePercentage: number | undefined) =>
    `${surchargeText(locationLocale.locale)}${
        surchargePercentage && surchargePercentage > 0
            ? ` (${locationLocale.formatPercentage(surchargePercentage)})`
            : ""
    }`;

const surchargeText = (locale: string) => {
    switch (locale) {
        case "en-GB":
            return "Service charge";
        default:
            return "Venue surcharge";
    }
};
