import { AppDispatch } from "../../index";
import { actionCreators } from "../reducers";
import { fetchOrderByPartyId } from "../api/fetchPartyById";

export const fetchTabOrder = (groupTabId: string, partyId: string, locationId: string) => {
    return async (dispatch: AppDispatch) => {
        dispatch(actionCreators.tabOrderLoading());
        try {
            const orderResponse = await fetchOrderByPartyId(partyId, locationId);
            dispatch(actionCreators.tabOrderLoaded(groupTabId, orderResponse));
        } catch (e) {
            dispatch(actionCreators.tabOrderFailed(e));
        }
    };
};
