import { AppState } from "../../state";
import { createSelector } from "reselect";

export const getOrdersState = (state: AppState) => state.orders;
export const getOrderResolvedState = createSelector(getOrdersState, (orders) => orders.resolvedOrder);
export const getOrderRefundState = createSelector(getOrdersState, (orders) => orders.refundedOrder);
export const getSelectedSection = createSelector(getOrdersState, ({ selectedSection }) => selectedSection);

export const getAlertsTotal = createSelector(
    getOrdersState,
    ({ unconfirmedOrdersTotal, unconfirmedLiveOrders }) => unconfirmedOrdersTotal + unconfirmedLiveOrders.length
);

export const getActiveGroupTabsTotal = createSelector(
    getOrdersState,
    ({ activeGroupTabsTotal, activeGroupTabsCount }) => activeGroupTabsCount.status === "loaded" && activeGroupTabsTotal
);

export const getRefundProcessed = createSelector(
    getOrderRefundState,
    (refundedOrder) => !!(refundedOrder?.status === "loaded")
);

export const getOrderIsRefunded = createSelector(
    getOrderRefundState,
    (refundedOrder) => refundedOrder?.status === "loaded" && refundedOrder?.data.status === "Refunded"
);

export const getRefundAmount = createSelector(getOrdersState, ({ refundedAmount }) => refundedAmount);

export const getRefundedOrder = createSelector(getOrdersState, ({ refundedOrder }) => refundedOrder);

export const getDisplayGroupTabFilter = createSelector(getOrdersState, ({ groupTabsFilter }) =>
    groupTabsFilter.map((filter) => (filter === "Open" ? "Active" : filter)).join(" & ")
);

export const getSelectedHistoryFilters = createSelector(getOrdersState, (orders) => orders.historyOrdersFilter);
export const getSelectedHistorySearch = createSelector(getOrdersState, (orders) => orders.historyOrdersSearch);
export const getcloseGroupTabResponse = createSelector(
    getOrdersState,
    (orders) => orders.closeGroupTabResponse.status === "loaded" && orders.closeGroupTabResponse.data
);
export const getResubmitOrder = createSelector(
    getOrdersState,
    ({ resubmitUnconfirmedOrder }) => resubmitUnconfirmedOrder
);
