import { createSelector } from "reselect";
import { getSections } from "features/floorManager/selectors/getSections";
import { getActiveParties } from "../../shared/selectors";
import { ActivePartyCounts } from "../../shared/types";

const getTakeawayActiveParties = createSelector(getActiveParties, getSections, (parties, sections) => {
    const filteredParties = parties.filter(
        (p) => !p.statusChanging && p.deliveryOptions?.filter((d) => d.type === "takeaway").length
    );
    return filteredParties.map((p) => {
        const section = sections.find((s) => p.sectionId === s.id);
        p.sectionName = section?.displayName;
        return p;
    });
});

export const getFilteredAndSortedTakeawayActiveParties = createSelector(getTakeawayActiveParties, (parties) => {
    if (!parties.length) return parties;

    return parties.sort((a, b) => {
        const [{ dateScheduled: left }] = a.submittedOrders;
        const [{ dateScheduled: right }] = b.submittedOrders;
        const descDateScheduled = right! > left! ? -1 : left! > right! ? 1 : 0;
        const statusOrder = a.status === b.status ? 0 : a.status === "ready" ? -1 : 1;
        const overdueOrder = a.overdue === b.overdue ? 0 : a.overdue ? -1 : 1;

        return descDateScheduled || statusOrder || overdueOrder;
    });
});

export const getActivePartyCounts = createSelector(getFilteredAndSortedTakeawayActiveParties, (parties) => {
    const total = parties.length;
    const preparing = parties.filter((p) => p.status === "preparing").length;
    const ready = parties.length - preparing;

    return {
        total,
        preparing,
        ready,
    } as ActivePartyCounts;
});
