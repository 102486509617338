import { LoadingOutlined } from "@ant-design/icons";
import classNames from "classnames";
import { HiddenIcon, ViewIcon } from "common/icons";
import { Indicator } from "components/indicator/Indicator";
import { LocationLocaleContext } from "features/location/context/LocationLocaleContext";
import React from "react";
import { useSelector } from "react-redux";
import { ALL_SECTION_ID } from "../selectors/getFloorItems";
import { getUpdatingIds } from "../selectors/getManangeSection";
import { FloorItem, FloorItemType } from "../types";

interface Props {
    item: FloorItem;
    update?: (item: FloorItem) => void;
}

export const AvailabilityIndicator = ({ item, update }: Props) => {
    const updatingIds = useSelector(getUpdatingIds);

    const isUpdating = updatingIds?.[item.id];
    let Icon = ViewIcon;
    if (isUpdating) {
        Icon = () => <LoadingOutlined spin />;
    } else if (item.parent?.availabilityStatus || item.availabilityStatus) {
        Icon = HiddenIcon;
    }
    const isDisabled =
        !update ||
        isUpdating ||
        updatingIds?.[ALL_SECTION_ID] ||
        (item?.parent?.id && updatingIds?.[item?.parent.id]) ||
        (item.type === FloorItemType.TABLE && !!item.parent?.availabilityStatus);
    return (
        <Indicator
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                update?.(item);
            }}
            className={classNames(
                "floor-manager__row__filter-indicator",
                "floor-manager__row__filter-indicator--" +
                    (item.parent?.availabilityStatus ? "available" : availabilityStatusClass(item))
            )}
            icon={Icon}
            unique
            disabled={isDisabled}
        >
            {item.parent?.availabilityStatus ? availabilityStatusText(item.parent) : availabilityStatusText(item)}
        </Indicator>
    );
};

const availabilityStatusText = (floorItem: FloorItem) => {
    if (floorItem.availabilityStatus) {
        if (floorItem.statusExpiryUtc) {
            return (
                <LocationLocaleContext.Consumer>
                    {(locale) => <span>Returning {locale.formatTime(floorItem.statusExpiryUtc!)}</span>}
                </LocationLocaleContext.Consumer>
            );
        }
        return floorItem.statusExpiryUtc ?? "Unavailable today";
    }

    return "Available";
};

const availabilityStatusClass = (floorItem: FloorItem) => {
    if (floorItem.availabilityStatus) {
        return floorItem.statusExpiryUtc ? "later" : "tommorrow";
    }

    return "available";
};
