import { AppDispatch, AppState } from "../../index";
import { load } from "./load";
import { polling } from "common/utility/polling";

export const initSections = (locationId: string) => {
    return async function (dispatch: AppDispatch) {
        polling.register("loadSections", () => dispatch(loadSections(locationId)));
    };
};

export const loadSections = (locationId?: string) => {
    return async function (dispatch: AppDispatch, getState: () => AppState) {
        const {
            location,
            floorManager: { sections },
        } = getState();
        const _locationId = locationId || location.locationId;

        if (_locationId === null) {
            return;
        }

        try {
            const skipLoading = sections.status === "loaded";
            await load(dispatch, _locationId, skipLoading);
        } catch (e) {
            console.warn(e);
        }
    };
};
