import { AppState } from "features/state";
import { AppDispatch } from "../../index";
import { RefundRequest, sendRefundRequest } from "../api/sendRefundRequest";
import { actionCreators } from "../reducers";
import { Order } from "../types";

export const refundOrder = (
    locationId: string,
    order: Order,
    amount: number,
    isLiveOrder = false,
    refundSuccessCB?: () => void
) => {
    return async function (dispatch: AppDispatch, getState: () => AppState) {
        const { status } = getState().floorManager.sections;
        if (status === "unloaded" || status === "loading") return;

        if (!order.refundablePaymentId) {
            dispatch(actionCreators.orderRefundFailed("No refundable payment"));
        }

        dispatch(actionCreators.orderRefundProcessing(order));

        const refundRes = await sendRefundRequest(mapRefundRequest(locationId, order, amount, isLiveOrder));

        if (refundRes.status === "Complete" || refundRes.status === "Pending") {
            order.status = "Refunded";
            dispatch(actionCreators.orderRefundProcessed(order, refundRes.amountRefunded || 0, isLiveOrder));

            refundSuccessCB?.();
        } else {
            dispatch(actionCreators.orderRefundFailed(refundRes.error || ""));
        }
    };
};

const mapRefundRequest = (locationId: string, order: Order, amount: number, isLiveOrder: boolean): RefundRequest => {
    return {
        locationId,
        partyId: order.partyId,
        paymentId: order.refundablePaymentId!,
        isActiveParty: isLiveOrder,
        amount: amount,
    };
};
