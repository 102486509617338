import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators } from "features/orders/reducers";
import { getOrdersState } from "features/orders/selectors";
import { VirtualTableHeader } from "../virtualTable/VirtualTableHeader";
import { GroupTabsData } from "features/orders/types";

const groupTabsColumns = [
    {
        title: "Tab owner",
        dataIndex: "ownerName",
        key: "tabOwner",
    },
    {
        title: "Members",
        dataIndex: "members",
        key: "members",
    },
    {
        title: "Duration",
        dataIndex: "duration",
        key: "duration",
    },
    {
        title: "Spend",
        dataIndex: "totalSpend",
        key: "spend",
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        filters: [
            {
                text: "Active",
                value: "Open",
            },
            {
                text: "Closed",
                value: "Closed",
            },
        ],
    },
];

export const GroupTabsHeader = () => {
    const { groupTabsFilter } = useSelector(getOrdersState);

    const dispatch = useDispatch();

    const applyFilters = useCallback(
        (values: string[]) => {
            dispatch(actionCreators.selectGroupTabsFilter(values));
            dispatch(actionCreators.selectGroupTabsPage(1));
        },
        [dispatch]
    );

    return (
        <VirtualTableHeader<GroupTabsData, string>
            columns={groupTabsColumns}
            applyFilters={applyFilters}
            defaultSelectedValue={groupTabsFilter}
        />
    );
};
