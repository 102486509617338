import * as React from "react";
import "./Footer.scss";
import { MessageType } from "../types";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "features/state";
import classNames from "classnames";
import { Text } from "components/text";

export const Footer = () => {
    const { message, onClick, messageType, emphasis, path = "" } = useSelector((state: AppState) => state.footer[0]);
    return (
        <NavLink
            to={path}
            className={classNames(
                "footer",
                messageType === MessageType.ALERT && "alert",
                messageType === MessageType.WARN && "warn"
            )}
            onClick={onClick}
        >
            <Text preset="g-14" className="footer__content">
                {message}{" "}
                <Text preset="g-14" mode="bold">
                    {emphasis}
                </Text>
            </Text>
        </NavLink>
    );
};
