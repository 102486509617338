import React, {
    ReactElement,
    ReactNode,
    FunctionComponent,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
    useReducer,
} from "react";
import { HiddenIcon, TimeIcon, ViewIcon } from "common/icons";
import { MINUTE, getTimeDifferenceMinutes } from "common/utility";
import { Indicator } from "components/indicator/Indicator";
import { Availability, CategoryAvailabilityUpdateInput } from "features/soldOut/types";
import { StatusDisplay } from "features/soldOut/utils";
import { getDurationOptions } from "features/soldOut/types";
import "./AvailabilityPicker.scss";

const MAX_DURATION = getDurationOptions().reduce((prev: number, { duration }) => {
    if (duration === undefined) {
        return prev;
    }
    return Math.max(prev, duration);
}, 0);

// time difference between now and reset time at which we assume 'never' (about a week);
const NEVER_MINUTES = 9999;

interface Props {
    id: string;
    disabled?: boolean;
    modified?: CategoryAvailabilityUpdateInput;
    status?: Availability;
    availability: number | null;
    availabilityText?: StatusDisplay;
    waitTime?: number | null;
    onAvailable?: (id: string, availability: number | null) => void;
    onDisable?: (id: string, status: Availability, minutes?: number) => void;
    availabilityOptions: AvailabilityOptionProps[];
    onClick?: (e?: React.MouseEvent<HTMLElement>) => void;
}

export interface AvailabilityOptionProps {
    displayElement: ReactNode;
    status: Availability;
    time?: number;
}

type Action =
    | { type: "available" }
    | { type: "unavailable"; payload: { resetTime?: number } }
    | { type: "waitTime"; payload: { waitTime: number; resetTime?: number } }
    | { type: "tick" }
    | { type: "setModified"; payload: { modified: AvailabilityStateModifier | undefined } };

type AvailabilityMode = "Available" | "WaitTime" | "Unavailable";

type AvailabilityStateModifier = Pick<AvailabilityState, "mode" | "minutesRemaining" | "waitTime"> & { status: string };

type InitAvailabilityStateProps = Pick<Props, "status" | "availability" | "waitTime">;

interface AvailabilityState {
    mode: AvailabilityMode;
    modified: AvailabilityStateModifier | undefined;
    resetTime: number | undefined;
    minutesRemaining: number | undefined;
    timerEnabled: boolean;
    waitTime: number | undefined;
}

const baseState = {
    minutesRemaining: 0,
    modified: undefined,
    resetTime: undefined,
    timerEnabled: false,
    waitTime: undefined,
};

const baseAvailableState: AvailabilityState = {
    ...baseState,
    mode: "Available",
};

const baseUnavailableState: AvailabilityState = {
    ...baseState,
    mode: "Unavailable",
};

function isTimerRequired(minutesRemaining: number): boolean {
    return minutesRemaining > 0 && minutesRemaining <= MAX_DURATION;
}

function availabilityReducer(availabilityState: AvailabilityState, action: Action): AvailabilityState {
    let newState: AvailabilityState;

    switch (action.type) {
        case "available":
            newState = {
                ...availabilityState,
                resetTime: undefined,
                mode: "Available",
                minutesRemaining: 0,
                timerEnabled: false,
                waitTime: 0,
            };
            break;

        case "unavailable":
            newState = {
                ...availabilityState,
                mode: "Unavailable",
                minutesRemaining:
                    action.payload.resetTime !== undefined
                        ? getTimeDifferenceMinutes(action.payload.resetTime)
                        : undefined,
                timerEnabled: isTimerRequired(getTimeDifferenceMinutes(action.payload.resetTime)),
                waitTime: 0,
            };
            break;

        case "waitTime":
            newState = {
                ...availabilityState,
                mode: "WaitTime",
                minutesRemaining:
                    action.payload.resetTime !== undefined
                        ? getTimeDifferenceMinutes(action.payload.resetTime)
                        : undefined,
                timerEnabled: isTimerRequired(getTimeDifferenceMinutes(action.payload.resetTime)),
                waitTime: action.payload.waitTime,
            };
            break;

        case "tick": {
            const minutesRemaining =
                availabilityState.resetTime !== undefined
                    ? getTimeDifferenceMinutes(availabilityState.resetTime)
                    : undefined;

            if (minutesRemaining !== undefined && minutesRemaining <= 0) {
                newState = {
                    ...baseAvailableState,
                };
            } else {
                newState = { ...availabilityState, minutesRemaining };
            }

            break;
        }

        case "setModified": {
            newState = {
                ...availabilityState,
                modified: action.payload.modified,
            };
            break;
        }

        default:
            newState = availabilityState;
    }

    return newState;
}

function getInitialAvailabilityState({
    status,
    availability,
    waitTime,
}: InitAvailabilityStateProps): AvailabilityState {
    // if we get status we are dealing with a product/modifier
    if (status) {
        return status === Availability.AVAILABLE ? { ...baseAvailableState } : { ...baseUnavailableState };
    }

    // if we get this far we are dealing with a category
    const minutesRemaining = availability ? getTimeDifferenceMinutes(availability) : undefined;

    // availabilty means a time has been set to reset to available after x minutes
    if (availability) {
        if (!minutesRemaining || minutesRemaining <= 0) {
            return { ...baseAvailableState };
        } else {
            if (waitTime) {
                return {
                    ...baseState,
                    mode: "WaitTime",
                    minutesRemaining,
                    resetTime: availability,
                    timerEnabled: isTimerRequired(minutesRemaining),
                    waitTime,
                };
            }

            return {
                ...baseUnavailableState,
                minutesRemaining,
                resetTime: availability,
                timerEnabled: isTimerRequired(minutesRemaining),
            };
        }
    }

    return { ...baseAvailableState };
}

export const AvailabilityPicker = ({
    id,
    disabled,
    modified,
    availability,
    status,
    availabilityOptions,
    availabilityText,
    waitTime,
    onAvailable,
    onDisable,
    onClick,
}: Props) => {
    const [pickerMode, setPickerMode] = useState(false);
    const pickersRef = useRef<HTMLDivElement>(null);
    const timerRef = useRef<number | undefined>(undefined);

    // availability state
    const [availabilityState, dispatch] = useReducer(
        availabilityReducer,
        getInitialAvailabilityState({ availability, status, waitTime })
    );

    const clearPickerMode = useCallback(() => {
        window.removeEventListener("click", clearPickerMode, true);
        if (pickersRef.current) {
            pickersRef.current.addEventListener("animationend", () => setPickerMode(false));
            pickersRef.current.classList.add("hide");
        } else {
            setPickerMode(false);
        }
    }, []);

    const showPicker = useCallback(
        (e: React.MouseEvent<HTMLElement>) => {
            setPickerMode(true);
            window.addEventListener("click", clearPickerMode, true);
            e.preventDefault();
            e.stopPropagation();
        },
        [clearPickerMode]
    );

    // handle user click of 'unavailable' option
    const disable = useCallback(
        (categoryId: string, status: Availability, minutes?: number) => {
            if (onDisable) {
                onDisable(categoryId, status, minutes);
            }
            // nb don't dispatch state update, change is unsaved
        },
        [onDisable]
    );

    // handle user click of 'available' option
    const enable = useCallback(
        (categoryId: string) => {
            // prevents available appearing as an unsaved change after it became available already by countdown
            if (availabilityState.minutesRemaining === 0 && (!status || status === Availability.AVAILABLE)) return;
            if (onAvailable) {
                onAvailable(categoryId, availability);
            }
            // nb don't dispatch state update, change is unsaved
        },
        [onAvailable, availability, status, availabilityState.minutesRemaining]
    );

    // enable/disable timer
    useEffect(() => {
        if (availabilityState.timerEnabled && !timerRef.current) {
            timerRef.current = window.setInterval(() => {
                dispatch({ type: "tick" });
            }, MINUTE);
        }

        if (!availabilityState.timerEnabled && timerRef.current) {
            window.clearInterval(timerRef.current);
            timerRef.current = undefined;
        }

        return () => {
            if (timerRef.current) {
                window.clearInterval(timerRef.current);
                timerRef.current = undefined;
            }
        };
    }, [availabilityState.timerEnabled, availabilityState.minutesRemaining]);

    useEffect(() => {
        if (!modified) {
            dispatch({ type: "setModified", payload: { modified: undefined } });
        } else {
            // modified only present when performing bulk avail op
            if (modified.waitTime) {
                // wait time is being set
                dispatch({
                    type: "setModified",
                    payload: {
                        modified: {
                            mode: "WaitTime",
                            minutesRemaining: modified.fixedTimeMinutes,
                            waitTime: modified.waitTime,
                            status: modified.status,
                        },
                    },
                });
            } else if (modified.fixedTimeMinutes) {
                // unavailable for <time> is being set
                dispatch({
                    type: "setModified",
                    payload: {
                        modified: {
                            mode: "Unavailable",
                            minutesRemaining: modified.fixedTimeMinutes,
                            waitTime: undefined,
                            status: modified.status,
                        },
                    },
                });
            } else if (modified.status === Availability.SOLD_OUT || modified.status === Availability.UNAVAILABLE) {
                // unavailable today/forever is being set
                dispatch({
                    type: "setModified",
                    payload: {
                        modified: {
                            mode: "Unavailable",
                            minutesRemaining: undefined,
                            waitTime: undefined,
                            status: modified.status,
                        },
                    },
                });
            } else {
                dispatch({
                    type: "setModified",
                    payload: {
                        modified: {
                            mode: "Available",
                            minutesRemaining: undefined,
                            waitTime: undefined,
                            status: modified.status,
                        },
                    },
                });
            }
        }
    }, [modified]);

    useEffect(() => {
        return () => window.removeEventListener("click", clearPickerMode, true);
    }, [clearPickerMode]);

    return (
        <div className="availability-pickers">
            <AvailabilityIndicator
                disabled={disabled}
                availabilityText={availabilityText}
                availabilityState={availabilityState}
                onClick={onClick ? onClick : showPicker}
            />
            {pickerMode && (
                <div ref={pickersRef} className="availability-pickers__pickers">
                    {availabilityOptions.map(({ status, time, displayElement }, index) => (
                        <Indicator
                            key={"availability-picker-" + index}
                            onClick={
                                status === Availability.AVAILABLE ? () => enable(id) : () => disable(id, status, time)
                            }
                            className="availability-pickers__picker"
                            primary={status === Availability.AVAILABLE}
                            icon={status === Availability.AVAILABLE ? ViewIcon : HiddenIcon}
                        >
                            {displayElement}
                        </Indicator>
                    ))}
                </div>
            )}
        </div>
    );
};

interface AvailabilityIndicatorProps {
    disabled?: boolean;
    availabilityText?: StatusDisplay;
    availabilityState: AvailabilityState;
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

function AvailabilityIndicator({
    disabled,
    availabilityText,
    availabilityState,
    onClick,
}: AvailabilityIndicatorProps): ReactElement {
    // build icon, label and className from state
    const info: { label: string; icon: FunctionComponent<any>; className: string } = useMemo(() => {
        let label;
        let icon;
        let className;

        if (availabilityText) {
            // we are dealing with Product/Modifier, use passed in text but choose icon/className
            switch (availabilityText) {
                case "Partially available":
                case "Available":
                    icon = ViewIcon;
                    className = "availability-pickers__available";
                    break;
                default:
                    icon = HiddenIcon;
                    className = "availability-pickers__unavailable";
                    break;
            }

            return {
                label: availabilityText,
                icon,
                className,
            };
        }

        // we are dealing with Category
        const state = availabilityState.modified ? availabilityState.modified : availabilityState;
        const { mode, minutesRemaining, waitTime } = state;
        let minutesRemainingDisplay = minutesRemaining ? ` (${minutesRemaining}m)` : "";

        // get display text for minutes remaining
        switch (true) {
            case !minutesRemaining:
                // special case: SOLD OUT in modifier without time means 'today'
                if (availabilityState.modified && availabilityState.modified.status === "SOLD_OUT") {
                    minutesRemainingDisplay = " today";
                } else {
                    minutesRemainingDisplay = "";
                }
                break;
            case minutesRemaining && minutesRemaining > NEVER_MINUTES:
                minutesRemainingDisplay = "";
                break;
            case minutesRemaining && minutesRemaining > MAX_DURATION + 1:
                if (mode === "WaitTime") {
                    minutesRemainingDisplay = " (today)";
                } else {
                    minutesRemainingDisplay = " today";
                }
                break;
            default:
                minutesRemainingDisplay = ` (${minutesRemaining}m)`;
        }

        switch (mode) {
            case "Unavailable":
                icon = HiddenIcon;
                label = `Unavailable${minutesRemainingDisplay}`;
                className = "availability-pickers__unavailable";
                break;
            case "WaitTime":
                icon = TimeIcon;
                label = `${waitTime}m wait${minutesRemainingDisplay}`;
                className = "availability-pickers__wait-time";
                break;
            case "Available":
            default:
                icon = ViewIcon;
                label = "Available";
                className = "availability-pickers__available";
                break;
        }

        return {
            label,
            icon,
            className,
        };
    }, [availabilityText, availabilityState]);

    return (
        <Indicator disabled={disabled} className={info.className} icon={info.icon} onClick={onClick}>
            {info.label}
        </Indicator>
    );
}
