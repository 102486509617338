import { State } from "../reducers";
import { AppDispatch, AppMiddleware } from "features";
import { actionCreators } from "../reducers/staffLogin";
import { regionHelper } from "../../region";

export function getAccessToken(dispatch: AppDispatch, state: State) {
    if (state.staffLogin.status !== "authenticated") {
        throw Error("Not authenticated");
    }

    dispatch(actionCreators.accessed());

    return state.staffLogin.token;
}

export function fetchAsStaff(extendSession: boolean) {
    return async function (request: Request) {
        if (fetchAsStaff.store) {
            const { staffLogin } = fetchAsStaff.store.getState();

            if (extendSession) {
                fetchAsStaff.store.dispatch(actionCreators.accessed());
            }

            if (staffLogin.status === "authenticated") {
                request.headers.append("Authorization", `Bearer ${staffLogin.token}`);
            }
        }

        await regionHelper.addRegionHeaders(request);

        return fetch(request);
    };
}

fetchAsStaff.store = null as AppMiddleware | null;
