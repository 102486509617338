import React, { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { ChevronDownIcon, HiddenIcon, TimeIcon, ViewIcon } from "common/icons";
import { getTimeDifferenceMinutes } from "common/utility";
import { ConfirmModal } from "components/confirmModal/ConfirmModal";
import { Indicator } from "components/indicator/Indicator";
import { Text } from "components/text";
import {
    Availability,
    AvailabilityStatus,
    baseDurationOptions,
    CategoryAvailabilityChangeHandler,
    CategoryDialogMode,
    CategoryTypesDisplay,
    EditCategoryAvailabilityInfo,
    extendedDurationOptions,
    StatusIndicatorProps,
    WaitTimeOption,
    waitTimeOptions,
} from "../types";
import { HIDE_WAIT_TIMES } from "../utils";
import Select, { SingleValue } from "react-select";
import { useSelector } from "react-redux";

import "./CategoryAvailability.scss";
import { getLocationPermissions } from "features/location/selectors/getPermissions";

export interface CategoryAvailabilityProps {
    changeHandler: CategoryAvailabilityChangeHandler;
    type: CategoryTypesDisplay;
    mode: CategoryDialogMode;
    showModal: boolean;
    setShowModal: (b: boolean) => void;
    modalTitle: string;
    info?: EditCategoryAvailabilityInfo | null;
}

export const CategoryAvailability = ({
    changeHandler,
    type,
    mode,
    showModal,
    setShowModal,
    modalTitle,
    info,
}: CategoryAvailabilityProps) => {
    // if we are in single category mode we don't show 'spread' options; preselect first option 'all'
    const [selectedSpread, setSelectedSpread] = useState<number | undefined>(
        mode === CategoryDialogMode.SINGLE_CATEGORY ? 0 : undefined
    );
    const permissions = useSelector(getLocationPermissions);
    const canUpdateAvailability = permissions.has("category:availability:update");
    const canUpdateWaitTimes = permissions.has("category:waittime:update");

    const [selectedStatus, setSelectedStatus] = useState<AvailabilityStatus | undefined>(
        getInitialSelectedStatus(mode, info)
    );

    const [selectedDuration, setSelectedDuration] = useState<number | undefined>();
    const [selectedWaitTime, setSelectedWaitTime] = useState<number | undefined>();

    useEffect(() => {
        setSelectedWaitTime(getInitialSelectedWaitTime(mode, info));
    }, [selectedStatus, info, mode, showModal]);

    const enabledWaitTimeOptions = useMemo(() => {
        if (mode === CategoryDialogMode.SINGLE_CATEGORY) {
            return waitTimeOptions.slice(1);
        }
        return waitTimeOptions;
    }, [mode]);

    const selectedWaitTimeOption = enabledWaitTimeOptions.find(({ value }) => value === selectedWaitTime);

    const statusOptions: ReactElement[] = useMemo(() => {
        const options: ReactElement[] = [];

        options.push(
            <AvailableIndicator
                key="available"
                isSelected={selectedStatus === AvailabilityStatus.AVAILABLE}
                onClick={() => {
                    setSelectedStatus(AvailabilityStatus.AVAILABLE);
                    setSelectedWaitTime(undefined);
                    setSelectedDuration(undefined);
                }}
            />
        );

        if (canUpdateWaitTimes && !HIDE_WAIT_TIMES && mode === CategoryDialogMode.SINGLE_CATEGORY) {
            options.push(
                <WaitTimeIndicator
                    key="waitTime"
                    isSelected={selectedStatus === AvailabilityStatus.WAIT_TIME}
                    onClick={() => {
                        setSelectedStatus(AvailabilityStatus.WAIT_TIME);
                    }}
                />
            );
        }

        if (
            canUpdateAvailability &&
            (mode === CategoryDialogMode.ALL_AVAILABILITY || mode === CategoryDialogMode.SINGLE_CATEGORY)
        ) {
            options.push(
                <UnavailableIndicator
                    key="unavailable"
                    isSelected={selectedStatus === AvailabilityStatus.UNAVAILABLE}
                    onClick={() => {
                        setSelectedStatus(AvailabilityStatus.UNAVAILABLE);
                        setSelectedWaitTime(undefined);
                    }}
                />
            );
        }

        return options;
    }, [
        mode,
        selectedStatus,
        setSelectedStatus,
        setSelectedWaitTime,
        setSelectedDuration,
        canUpdateAvailability,
        canUpdateWaitTimes,
    ]);

    const resetState = () => {
        setShowModal(false);
        setSelectedSpread(mode === CategoryDialogMode.SINGLE_CATEGORY ? 0 : undefined);
        setSelectedDuration(undefined);
        setSelectedWaitTime(undefined);
        setSelectedStatus(mode === CategoryDialogMode.ALL_WAIT_TIMES ? AvailabilityStatus.WAIT_TIME : undefined);
    };

    const canApplySelections = () => {
        if (selectedSpread === undefined) {
            return false;
        }

        if (selectedStatus === AvailabilityStatus.AVAILABLE) {
            return true;
        }

        if (selectedStatus === AvailabilityStatus.WAIT_TIME && selectedWaitTime === 0) {
            return true;
        }

        if (selectedStatus === AvailabilityStatus.WAIT_TIME) {
            return selectedDuration !== undefined && selectedWaitTime !== undefined;
        }

        if (selectedStatus === AvailabilityStatus.UNAVAILABLE) {
            return selectedDuration !== undefined;
        }

        return false;
    };

    const durationOptions = mode === CategoryDialogMode.SINGLE_CATEGORY ? extendedDurationOptions : baseDurationOptions;

    const onDurationChange = useCallback(
        (duration: number | undefined) => {
            setSelectedDuration(duration);
            // Default to first available wait time option if no wait time option has been selected
            if (
                !selectedWaitTimeOption &&
                mode === CategoryDialogMode.SINGLE_CATEGORY &&
                selectedStatus === AvailabilityStatus.WAIT_TIME
            ) {
                setSelectedWaitTime(enabledWaitTimeOptions[0].value);
            }
        },
        [selectedWaitTimeOption, enabledWaitTimeOptions, selectedStatus, mode]
    );

    return (
        <ConfirmModal
            title={modalTitle}
            subTitle=""
            className="category-availability__confirm-modal"
            showModal={showModal}
            onCloseModal={resetState}
            onConfirm={
                canApplySelections()
                    ? () => {
                          const option = durationOptions.find(({ value }) => value === selectedDuration);
                          const { duration, status } = option || {
                              duration: 0,
                              status: Availability.AVAILABLE,
                          };
                          const waitTimeOption = enabledWaitTimeOptions.find(({ value }) => value === selectedWaitTime);
                          const waitTime =
                              waitTimeOption && selectedStatus !== AvailabilityStatus.UNAVAILABLE
                                  ? waitTimeOption.duration
                                  : undefined;

                          changeHandler({
                              type,
                              all: selectedSpread === 0,
                              status,
                              waitTime,
                              duration,
                              singleCategory: mode === CategoryDialogMode.SINGLE_CATEGORY,
                          });
                      }
                    : undefined
            }
        >
            {mode !== CategoryDialogMode.ALL_WAIT_TIMES && (
                <div className="category-availability__confirm__group">
                    <div className="category-availability__confirm__group__controls--spaced">{statusOptions}</div>
                </div>
            )}
            {selectedStatus === AvailabilityStatus.WAIT_TIME && (
                <div className="category-availability__confirm__group">
                    <div className="category-availability__confirm__group__label">
                        <Text preset="g-16" mode={["medium", "block"]}>
                            Set wait time:
                        </Text>
                    </div>
                    <div className="category-availability__confirm__group__controls">
                        <Select
                            className="category-availability__confirm__group__controls__dropdown"
                            classNamePrefix="dropdown-control"
                            defaultValue={selectedWaitTimeOption || enabledWaitTimeOptions[0]}
                            name="waitTime"
                            options={enabledWaitTimeOptions}
                            onChange={(option: SingleValue<WaitTimeOption>) => {
                                if (option && option.value === waitTimeOptions[0].value) {
                                    setSelectedDuration(undefined);
                                }
                                setSelectedWaitTime(option ? option.value : undefined);
                            }}
                            components={{ DropdownIndicator: DropdownIndicator }}
                            isSearchable={false}
                        />
                    </div>
                </div>
            )}
            {(selectedStatus === AvailabilityStatus.UNAVAILABLE ||
                (selectedStatus === AvailabilityStatus.WAIT_TIME && selectedWaitTime !== 0)) && (
                <div className="category-availability__confirm__group">
                    <div className="category-availability__confirm__group__label">
                        <Text preset="g-16" mode={["medium", "block"]}>
                            Clear after:
                        </Text>
                    </div>
                    <div className="category-availability__confirm__group__controls">
                        {durationOptions.map(({ label, value }, index) => (
                            <Indicator
                                key={index}
                                primary={selectedDuration === value}
                                onClick={() => onDurationChange(value)}
                                shape="joined"
                                textSize="large"
                                textWeight="regular"
                            >
                                {label}
                            </Indicator>
                        ))}
                    </div>
                </div>
            )}

            {mode !== CategoryDialogMode.SINGLE_CATEGORY && (
                <div className="category-availability__confirm__group">
                    <div className="category-availability__confirm__group__label">
                        <Text preset="g-16" mode={["medium", "block"]}>
                            Apply to:
                        </Text>
                    </div>
                    <div className="category-availability__confirm__group__controls">
                        <Indicator
                            className="category-availability__indicator--secondary"
                            primary={selectedSpread === 0}
                            onClick={() => setSelectedSpread(0)}
                            shape="joined"
                            textSize="large"
                            textWeight="regular"
                        >
                            All {mode === CategoryDialogMode.ALL_WAIT_TIMES ? `available ${type.toLowerCase()}` : type}
                        </Indicator>
                        <Indicator
                            className="category-availability__indicator--secondary"
                            primary={selectedSpread === 1}
                            onClick={() => setSelectedSpread(1)}
                            shape="joined"
                            textSize="large"
                            textWeight="regular"
                        >
                            {mode === CategoryDialogMode.ALL_WAIT_TIMES ? `Available ${type.toLowerCase()}` : type} in
                            service only
                        </Indicator>
                    </div>
                </div>
            )}
        </ConfirmModal>
    );
};

function getInitialSelectedStatus(mode: CategoryDialogMode, info?: EditCategoryAvailabilityInfo | null) {
    if (mode === CategoryDialogMode.ALL_AVAILABILITY) {
        return undefined;
    }

    if (mode === CategoryDialogMode.ALL_WAIT_TIMES) {
        return AvailabilityStatus.WAIT_TIME;
    }

    if (mode === CategoryDialogMode.SINGLE_CATEGORY && info) {
        const { availability, waitTime } = info;

        let isAvailable = false;

        if (!availability && !waitTime) {
            isAvailable = true;
        }

        if (availability) {
            if (getTimeDifferenceMinutes(availability) <= 0) {
                isAvailable = true;
            }
        }

        if (isAvailable) {
            return AvailabilityStatus.AVAILABLE;
        } else if (waitTime) {
            return AvailabilityStatus.WAIT_TIME;
        } else {
            return AvailabilityStatus.UNAVAILABLE;
        }
    }

    return undefined;
}

function getInitialSelectedWaitTime(mode: string, info?: EditCategoryAvailabilityInfo | null) {
    if (mode === CategoryDialogMode.ALL_WAIT_TIMES) {
        return waitTimeOptions[0].value;
    }
    if (!info || !info.waitTime) {
        return undefined;
    }

    let option = waitTimeOptions.find(({ duration }) => duration === info.waitTime);

    return option ? option.value : undefined;
}

function AvailableIndicator({ onClick, isSelected }: StatusIndicatorProps): ReactElement {
    return (
        <Indicator primary={isSelected} onClick={onClick} icon={ViewIcon} textSize="large">
            Available
        </Indicator>
    );
}

function UnavailableIndicator({ onClick, isSelected }: StatusIndicatorProps): ReactElement {
    return (
        <Indicator primary={isSelected} onClick={onClick} icon={HiddenIcon} textSize="large">
            Unavailable
        </Indicator>
    );
}

function WaitTimeIndicator({ onClick, isSelected }: StatusIndicatorProps): ReactElement {
    return (
        <Indicator primary={isSelected} onClick={onClick} icon={TimeIcon} textSize="large">
            Wait time
        </Indicator>
    );
}

const DropdownIndicator = () => (
    <span className="dropdown-control__indicator">
        <ChevronDownIcon color="#BFBFBF" />
    </span>
);
