import { opsApi } from "common/api";
import { fetchAsStaff } from "../../staffLogin/selectors/getAccessToken";
import { CategoryAvailabilityUpdateInput } from "../types/disable";

export async function updateCategoriesApi(
    locationId: string,
    updates: CategoryAvailabilityUpdateInput[]
): Promise<boolean> {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(
        updateQuery,
        {
            locationId,
            updates,
        },
        fetchAsStaff(true)
    );

    return res.data.updateCategoryAvailability;
}

type GraphQLPayload = { updateCategoryAvailability: boolean };

const updateQuery: string = `mutation ($locationId: ID!, $updates: [CategoryAvailabilityUpdateInput!]!) {
    updateCategoryAvailability(locationId: $locationId, updates: $updates)
}`;
