import { useSelector, useDispatch } from "react-redux";
import { AppState } from "features/state";
import { AppDispatch } from "features";
import { useCallback } from "react";
import { getCanBypassStaffLogin } from "features/enrolment/selectors";
import { login } from "../actions/login";
import { bypassStaffLogin } from "../actions/bypassStaffLogin";
import { actionCreators as StaffLoginAction } from "../reducers/staffLogin";
import { StaffLogin } from "../components/StaffLogin";
import React from "react";

export const StaffLoginContainer = () => {
    const status = useSelector((state: AppState) => state.staffLogin.status);
    const dispatch = useDispatch<AppDispatch>();
    const canBypassLogin = useSelector(getCanBypassStaffLogin);

    const bypassLoginCallback = useCallback(() => dispatch(bypassStaffLogin()), [dispatch]);

    return (
        <StaffLogin
            status={status}
            login={useCallback((pin: string) => dispatch(login(pin)), [dispatch])}
            bypassLogin={canBypassLogin ? bypassLoginCallback : undefined}
            reset={useCallback(() => dispatch(StaffLoginAction.reset()), [dispatch])}
        />
    );
};
