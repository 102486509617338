import React, { FC, useState } from "react";
import "./Numpad.scss";
import { useSelector } from "react-redux";
import { getLocationLocale } from "features/location/selectors/getLocationLocale";
import { Text } from "../../../components/text";
import classNames from "classnames";

export interface NumpadPreset {
    text: string;
    value: number;
    hidden: boolean;
}

export interface NumpadValidation {
    error: string;
    passed: boolean;
}

export interface NumpadValidator {
    validate: (value: number) => NumpadValidation;
}

export interface Props {
    validationSteps: NumpadValidator[];
    submitText: string;
    onSubmit: (value: string, displayValue: string) => void;
    presets?: NumpadPreset[];
}

export const Numpad: FC<Props> = ({ validationSteps, submitText, onSubmit, presets }) => {
    const [amount, setAmount] = useState("");
    const [invalid, setInvalid] = useState(false);
    const [validationText, setValidationText] = useState("");
    const locale = useSelector(getLocationLocale);
    const formatter = Intl.NumberFormat(locale.locale, {
        style: "currency",
        currency: locale.currency,
        minimumFractionDigits: 2,
    });

    const [displayAmount, setDisplayAmount] = useState(formatter.format(0));

    const addDigit = (value: string) => {
        const total = amount + value;
        const numTotal = parseInt(total);
        setAmount(!numTotal ? "0" : total);
        setTotal(numTotal / 100);
    };

    const resetTotal = () => {
        setAmount("");
        setTotal(0);
    };

    const setTotal = (value: number) => {
        !!validationSteps &&
            setInvalid(
                !validationSteps.every((step) => {
                    let result = step.validate(value);
                    if (!result.passed) {
                        setValidationText(result.error);
                    }
                    return result.passed;
                })
            );
        setDisplayAmount(formatter.format(value));
    };

    const setPreset = (value: number) => {
        setAmount((value * 100).toFixed(0));
        setTotal(value);
    };

    const deleteLastDigit = () => {
        if (!!amount.length) {
            const total = amount.slice(0, amount.length - 1);
            setAmount(total);
            setTotal(total ? parseInt(total) / 100 : 0);
        }
    };

    return (
        <div className="order-numpad">
            <div className="order-numpad__display">
                <span className="order-numpad__display-text">Amount to refund</span>
                <span className={classNames(!!amount && amount !== "0" && !invalid && "active")}>{displayAmount}</span>
                <div className="order-numpad__display-error">{invalid && <span>{validationText}</span>}</div>
            </div>
            <div className="order-numpad__entry">
                <div className="order-numpad__row-group">
                    <div className="order-numpad__col">
                        {["1", "4"].map((d) => renderDigits(d, "number", () => addDigit(d), invalid))}
                    </div>
                    <div className="order-numpad__col">
                        {["2", "5"].map((d) => renderDigits(d, "number", () => addDigit(d), invalid))}
                    </div>
                    <div className="order-numpad__col">
                        {["3", "6"].map((d) => renderDigits(d, "number", () => addDigit(d), invalid))}
                    </div>
                    <div className="order-numpad__col">
                        <button className="order-numpad__action" onClick={resetTotal}>
                            CLR
                        </button>
                    </div>
                </div>
                <div className="order-numpad__row-group">
                    <div className="order-numpad__col">
                        <div className="order-numpad__row">
                            {["7", "8"].map((d) => renderDigits(d, "number", () => addDigit(d), invalid))}
                        </div>
                        {["00"].map((d) => renderDigits(d, "number", () => addDigit(d), invalid))}
                    </div>
                    <div className="order-numpad__col">
                        {["9", "0"].map((d) => renderDigits(d, "number", () => addDigit(d), invalid))}
                    </div>
                    <div className="order-numpad__col">
                        <button className="order-numpad__action" onClick={deleteLastDigit} type="button">
                            {"<-"}
                        </button>
                    </div>
                </div>
            </div>
            <div className="order-numpad__commands">
                {(!amount || amount === "0") &&
                    presets &&
                    presets.map((p, i) => renderPresetButton(p, i, () => setPreset(p.value)))}
                {!!amount && amount !== "0" && (
                    <button
                        className="order-numpad__button-refund"
                        onClick={() => onSubmit(amount, displayAmount)}
                        disabled={invalid}
                    >
                        <Text preset="g-16" mode="bold" value={submitText} />
                    </button>
                )}
            </div>
        </div>
    );
};

const renderDigits = (value: string, classSuffix: string, onClick: () => void, disabled: boolean = false) => {
    return (
        <button key={value} className={`order-numpad__${classSuffix}`} onClick={onClick} disabled={disabled}>
            {value}
        </button>
    );
};

const renderPresetButton = (preset: NumpadPreset, index: number, onClick: () => void) => (
    <button key={index} className="order-numpad__button-preset" onClick={onClick} hidden={preset.hidden}>
        <Text preset="g-14" mode="bold" value={preset.text} />
    </button>
);
