import "./TakeawayPage.scss";
import React from "react";

import { withDisabledSessionTimeout } from "features/staffLogin/containers/withDisabledSessionTimeout";
import { TakeawayOrderList } from "./TakeawayOrderList";
import { TakeawayPageHeader } from "./TakeawayPageHeader";

export const TakeawayPage = withDisabledSessionTimeout(() => {
    return (
        <div className="takeaway">
            <TakeawayPageHeader />
            <TakeawayOrderList />
        </div>
    );
});
