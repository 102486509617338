import { getSectionsState } from "features/floorManager/selectors/getSections";
import { getMomentDiningDate } from "features/location/selectors/getDiningDate";
import { getLocationLocale } from "features/location/selectors/getLocationLocale";
import { AppState } from "features/state";
import { AppDispatch } from "../../index";
import { fetchActiveOrdersApi } from "../api/fetchActiveOrdersApi";
import { fetchClosedOrdersApi } from "../api/fetchClosedOrdersApi";
import { actionCreators } from "../reducers";
import { unconfirmedOrdersPageSize } from "../reducers/orders";
import { getActiveOrdersUnloaded } from "../selectors";
import { OrderSearchOptions } from "../types";

export const fetchHistoryOrders = (
    locationId: string,
    options?: OrderSearchOptions,
    forceReload?: boolean,
    loadMore?: boolean
) => {
    return async function (dispatch: AppDispatch, getState: () => AppState) {
        const state = getState();
        const { status } = getSectionsState(state);
        const activeOrdersUnloaded = getActiveOrdersUnloaded(state);
        if (status === "unloaded" || status === "loading") return;

        const {
            orders: { historyOrdersPage },
        } = state;

        if (options) {
            options.page = forceReload ? 1 : historyOrdersPage;
        }

        const today = getMomentDiningDate(state).format("YYYY-MM-DD");
        const sameDate = today === options?.diningDate;
        const loadActive =
            sameDate &&
            (forceReload || (activeOrdersUnloaded && options?.diningDate && options.page && options.pageSize));
        dispatch(actionCreators.historyOrdersLoading());

        try {
            if (loadActive) {
                dispatch(actionCreators.activeOrdersLoading());
                const [ordersRes, activeRes] = await Promise.all([
                    fetchClosedOrdersApi(locationId, options),
                    fetchActiveOrdersApi(locationId, {
                        diningDate: getLocationLocale(getState()).getLocalTime(),
                        skip: 0,
                        limit: unconfirmedOrdersPageSize * 2,
                        sectionId: options?.sectionId ?? null,
                        ascending: false,
                        statusFilter: options?.filters?.length
                            ? options?.filters
                            : ["Confirmed", "Resolved", "Refunded", "Refund failed"],
                    }),
                ]);
                dispatch(
                    actionCreators.historyOrdersLoaded(
                        ordersRes.orders,
                        ordersRes.historyOrdersTotal,
                        ordersRes.spreadOrdersCount,
                        loadMore
                    )
                );
                if (ordersRes.spreadOrdersCount) {
                    dispatch(actionCreators.showHistoryCountChange());
                }
                dispatch(actionCreators.activeOrdersLoaded(activeRes.orders, activeRes.activeOrdersTotal));
            } else {
                if (!sameDate) {
                    dispatch(actionCreators.activeOrdersLoaded([], 0));
                }
                var ordersRes = await fetchClosedOrdersApi(locationId, options);
                dispatch(
                    actionCreators.historyOrdersLoaded(
                        ordersRes.orders,
                        ordersRes.historyOrdersTotal,
                        ordersRes.spreadOrdersCount,
                        loadMore
                    )
                );
            }
        } catch (e) {
            dispatch(actionCreators.historyOrdersFailed(e));
            loadActive && dispatch(actionCreators.activeOrdersFailed(e));
        }
    };
};
