import * as React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { Screen } from "features/structure";
import { CallbackPageContainer } from "features/callback/containers/CallbackPageContainer";
import { auth } from "common/auth";

import "./App.scss";
import { RequireEnrolmentContainer } from "features/enrolment/containers/RequireEnrolmentContainer";
import { RequireStaffLoginContainer } from "features/staffLogin/containers/RequireStaffLoginContainer";
import { EnrolDeviceContainer } from "features/enrolment/containers/EnrolDeviceContainer";
import { RoutesConfiguration } from "features";
import { OfflineModal } from "features/offline";
import { ZendeskChatPlugin } from "features/integrations/zenDesk/ZendeskChatPlugin";
import { useSelector } from "react-redux";
import { getLocationPermissions } from "features/staffLogin/selectors/getPermissions";

export interface Props {
    routes: RoutesConfiguration;
}

const getUserConfirmation = (dialogKey: string, callback: (ok: boolean) => void) => {
    // use "message" as Symbol-based key
    const dialogTrigger = window[Symbol.for(dialogKey)];

    if (dialogTrigger) {
        // pass the callback to delegate
        // to the invoked dialog
        return dialogTrigger(callback);
    }

    // Fallback to allowing navigation
    callback(true);
};

const LocationRoutes = ({ routes }: Props) => {
    const permissions = useSelector(getLocationPermissions);
    return (
        <RequireEnrolmentContainer>
            {() => (
                <Screen>
                    <RequireStaffLoginContainer>{() => routes.locationRoutes}</RequireStaffLoginContainer>
                    <OfflineModal />
                    {permissions.has("zendesk:plugin") && <ZendeskChatPlugin />}
                </Screen>
            )}
        </RequireEnrolmentContainer>
    );
};

const App = (props: Props) => (
    <Router getUserConfirmation={getUserConfirmation}>
        <Switch>
            <Route path="/:regionId/enrol/:locationId" component={EnrolDeviceContainer} />
            <Route path="/auth/callback" render={(props) => <CallbackPageContainer auth={auth} {...props} />} />
            <LocationRoutes {...props} />
        </Switch>
    </Router>
);

export default App;
