import {
    ActiveParty,
    BillAdjustment,
    ItemModifierOption,
    Order,
    OrderHubOrderFlowBehaviorFlags,
    OrderHubPartyType,
    OrderItem,
    OrderItemModifier,
    OrderItemModifierOption,
    OrderItemNestedModifier,
    OrderPromotion,
    OrderStatus,
    OrderTrayCharge,
    OrderVenueCause,
    PartyPayment,
    PartyType,
    PaymentStatus,
    PosPaymentStatus,
    SecuredPaymentStateType,
} from "../../../common/types";
import { Section } from "../../floorManager/types";
import { canForceCharge, hasForceChargedPayment, isPartyClosed, mapPayment } from "../api/commonGraphFunctions";
import { partyTypeMap } from "../api/commonGraphFields";
import {
    GroupTab,
    GroupTabMember,
    GroupTabOrderItem,
    GroupTabsData,
    ItemWithTime,
    Order as LiveOrder,
    OrderStatus as ConfirmedOrderStatus,
    OrderTypes,
    Party,
    Payment,
} from "../types";

export const unconfirmedStatuses = [
    OrderStatus.SUBMITFAILED,
    OrderStatus.REJECTED,
    OrderStatus.ORPHANED,
    OrderStatus.RESUBMITTED,
    OrderStatus.RESUBMITTING,
];

export const isUnconfirmedOrder = (order: Order) => unconfirmedStatuses.includes(order.status);

export const isValidPayment = (payment: PartyPayment) =>
    (!!payment.transactionId && payment.status === PaymentStatus.COMPLETE) ||
    payment.status === "complete" ||
    (payment.isForceCharge && (payment.status === PaymentStatus.FAILED || payment.status === "failed"));

export const isUnconfirmedPayment = (payment: PartyPayment) =>
    payment.posStatus === PosPaymentStatus.REJECTED || payment.posStatus === "rejected";

export const getUnconfirmedOrderStatus = (order: Order) => {
    if ([OrderStatus.RESUBMITTED, OrderStatus.RESUBMITTING].includes(order.status)) return "Resubmit pending";
    return "Unconfirmed";
};

export const toLiveOrder = (party: ActiveParty, sections: Section[]) => {
    const section = party.sectionId ? sections.find((s) => s.id === party.sectionId) : undefined;
    const isTakeaway = !!party.deliveryOptions?.find((i) => i.type === "takeaway");
    let orders = [] as LiveOrder[];

    const isOpenTable = party.type === OrderHubPartyType.MULTIUSER;
    const isOpenTableFlexTab =
        isOpenTable &&
        party.openTableOrderFlowBehaviorFlags !== undefined &&
        (party.openTableOrderFlowBehaviorFlags & OrderHubOrderFlowBehaviorFlags.Tab) ===
            OrderHubOrderFlowBehaviorFlags.Tab;

    const mapOrder = (order: Order, items: OrderItem[]): LiveOrder => ({
        partyId: party.id,
        partyType: getPartyType(party.type),
        phone: party.members[0].phoneNumber,
        table: party.tableNumber.split("-")[0],
        orderId: order.id ?? party.id,
        displayOrderId: party.tableNumber,
        section: section?.displayName,
        time: new Date(party.sortDate || party.dateClosed || order.dateSubmitted),
        isTakeaway,
        status: getUnconfirmedOrderStatus(order),
        items,
        isMerged: !!order.isMerged,
        posReferenceId: order.posReferenceId || undefined,
        promotions: order.bill.adjustments ? mapPromotionAdjustments(order.bill.adjustments) : null,
        type: OrderTypes.ORDER,
        unconfirmedOrders: order.id ? [{ orderId: order.id }] : undefined,
        orderSubmittedCount: order.orderSubmittedCount ?? 1,
        isFlexTab: isOpenTableFlexTab,
    });

    if (isOpenTable) {
        party.submittedOrders.forEach((order) => {
            if (isUnconfirmedOrder(order)) {
                const price: number = order.items?.reduce((prev, curr) => (prev += curr.price || 0), 0);
                const mappedOrder = mapOrder(order, mapOrderItems(order));
                orders.push({
                    ...mappedOrder,
                    itemTotal: price,
                    dateSubmitted: (order?.dateSubmitted && new Date(order.dateSubmitted)) || undefined,
                });
            }
        });

        party.payments.forEach((payment) => {
            if (isValidPayment(payment) && isUnconfirmedPayment(payment)) {
                const paymentOwnerId = payment.owner;
                const linkedMember = party.members.find((member) => member.memberId === paymentOwnerId);
                const mappedPayment = mapPayment(party, payment);
                orders.push({
                    ...mappedPayment,
                    partyType: getPartyType(party.type),
                    section: section?.displayName,
                    itemTotal: payment.amount,
                    status: "Unconfirmed",
                    refundablePaymentId: payment.id,
                    refundableTotal: mapPaymentTotal(payment),
                    paymentsTotal: mapPaymentTotal(payment),
                    fullName: linkedMember?.memberName ?? payment.ownerName,
                    phone: linkedMember?.phoneNumber,
                    processingFeeAbsorbed: payment.processingFeeAbsorbed,
                });
            }
        });
    } else {
        const rejectedOrder = party.submittedOrders.find(isUnconfirmedOrder)!;
        const items = toOrderItems(party.submittedOrders);
        const mappedOrder = mapOrder(rejectedOrder, items);
        const successfulPayment = party.payments.find(isValidPayment);
        const payments: Payment[] = party.payments
            .filter((payment) => isValidPayment(payment))
            .map((payment) => ({
                status: isUnconfirmedPayment(payment) ? "Unconfirmed" : "Confirmed",
                paymentMethod: payment.attemptedPaymentMethods,
                paymentMethodId: payment.paymentMethodId,
            }));

        const takeawayOrder = (isTakeaway && party.submittedOrders[0]) || undefined;
        const payment = successfulPayment ?? party.payments[0];

        orders.push({
            ...mappedOrder,
            fullName: party.members[0].memberName,
            itemTotal: payment.orderAmount,
            totalValue: payment.amount,
            paymentsTotal: mapPaymentTotal(party.payments[0]) ?? undefined,
            refundableTotal: successfulPayment && mapPaymentTotal(successfulPayment),
            refundablePaymentId: successfulPayment?.id,
            payments,
            dateSubmitted: (takeawayOrder?.dateSubmitted && new Date(takeawayOrder.dateSubmitted)) || undefined,
            processingFeeAbsorbed: successfulPayment?.processingFeeAbsorbed,
            processingFee: payment.processingFee,
            membershipDiscount: payment.memberDiscount,
            tip: payment.tip,
            surcharge: { total: payment.surchargeAmount },
            cepOffers: mapCepOfferAdjustments(rejectedOrder.bill.adjustments),
            taxTotals: payment.taxSummary,
            trayCharges: mapTrayChargeAdjustments(rejectedOrder.bill.adjustments),
            discount: payment.discountAmount,
            venueCauses: mapVenueCauseAdjustments(rejectedOrder.bill.adjustments),
            stampsEarned: countStampsEarned(rejectedOrder.bill.adjustments),
        });
    }

    return orders;
};

const toOrderItems = (orders: Order[]) => {
    return orders // If this is a buzzer order we have two submitted orders so we need to combine the items
        .reduce((items: OrderItem[], order) => items.concat(order.items), [])
        .map((orderItem: OrderItem) => ({
            ...orderItem,
            mappedModifiers: orderItem.modifiers && mapModifiers(orderItem.modifiers),
        }));
};

const mapOrderItems = (order: Order) =>
    order.items.map((orderItem) => ({
        ...orderItem,
        mappedModifiers: orderItem.modifiers && mapModifiers(orderItem.modifiers),
    }));

export const mapModifiers = (modifiers: OrderItemModifier[]) => {
    const mappedModifiers: ItemModifierOption[] = [];
    modifiers.forEach((modifier) => {
        modifier.selectedOptions &&
            modifier.selectedOptions.forEach((option) => {
                const tempModifier: ItemModifierOption = {
                    displayName: option.displayName,
                    optionIndex: option.optionIndex,
                };

                const nestedModifiers: OrderItemNestedModifier[] = [];
                mapNestedModifiersRecursive(nestedModifiers, modifier, option);
                if (nestedModifiers.length) {
                    tempModifier.nestedModifiers = nestedModifiers;
                }

                mappedModifiers.push(tempModifier);
            });
    });

    return mappedModifiers;
};

const mapNestedModifiersRecursive = (
    nestedModifiers: OrderItemNestedModifier[],
    modifier: OrderItemModifier,
    option: OrderItemModifierOption,
    nestingLevel: number = 1
) => {
    modifier.optionNestedModifiers
        ?.filter((modifier) => modifier.optionIndex === option.optionIndex)
        .forEach(
            (optionNestedModifier) =>
                optionNestedModifier.modifiers &&
                optionNestedModifier.modifiers.forEach((nestedModifier) => {
                    nestedModifier.selectedOptions &&
                        nestedModifier.selectedOptions.forEach((selectedOption) => {
                            nestedModifiers.push({
                                displayName: selectedOption.displayName,
                                nestingLevel,
                            });
                            mapNestedModifiersRecursive(
                                nestedModifiers,
                                nestedModifier,
                                selectedOption,
                                nestingLevel + 1
                            );
                        });
                })
        );
};

export const getOrderName = (memberName: string) => memberName.substring(0, memberName.indexOf(" ") + 2);
export const getMemberLastName = (lastName: string) => lastName.substring(0, 1);

const mapPaymentTotal = (payment: PartyPayment) => {
    if (payment.transactionTotal) {
        return payment.transactionTotal;
    }

    return payment.amount + payment.tip + (payment.processingFeeAbsorbed ? payment.processingFee : 0);
};

export const toGroupTabsData = (groupTabs: GroupTab[]) => {
    const mappedGroupTabs: GroupTabsData[] = groupTabs.map((groupTab: GroupTab) => ({
        id: groupTab.id,
        type: groupTab.type,
        owner: groupTab.owner,
        limit: groupTab.limit,
        status: groupTab.status === "OPEN" ? "Active" : capitalizeFirstLetter(groupTab.status),
        locationId: groupTab.locationId,
        ownerName: `${
            groupTab.members.length > 0
                ? (groupTab.members[0].firstName || "") + " " + getMemberLastName(groupTab.members[0].lastName || "")
                : groupTab.ownerName || ""
        }.`,
        fullName:
            groupTab.members.length > 0
                ? (groupTab.members[0].firstName || "") + " " + (groupTab.members[0].lastName || "")
                : groupTab.ownerName || "",
        totalSpend: groupTab.paymentTotals.total || 0,
        duration: getDuration(groupTab.dateOpened, groupTab.dateClosed),
        members: groupTab.members.length === 0 ? 1 : groupTab.members.length,
        currency: groupTab.currency,
        locale: groupTab.locale,
        tabName: groupTab.tabName,
        numberOfOrders: groupTab.orders.length,
        ownerPhone: groupTab.members.length > 0 ? groupTab.members[0].phone : "",
        paymentTotals: groupTab.paymentTotals,
        packageName: groupTab.packageName,
        orders: mapGroupTabOrders(groupTab.orders, groupTab.members).reverse(),
    }));

    return mappedGroupTabs;
};

export const capitalizeFirstLetter = (value: string) => {
    return !!value ? value.charAt(0).toUpperCase() + value.slice(1).toLocaleLowerCase() : value;
};

export const getDuration = (openDate: string, closeDate: string) => {
    if (openDate) {
        const tabOpenDateTime = new Date(openDate);
        const tabCloseDateTime = !!closeDate ? new Date(closeDate) : new Date();

        const diff = Math.abs(tabOpenDateTime.valueOf() - tabCloseDateTime.valueOf());
        const diffInHours = diff / 1000 / 60 / 60;
        const diffInMinutes = diff / 1000 / 60;
        const numberofHours = Math.floor(diffInHours);

        return `${numberofHours > 9 || numberofHours === 0 ? numberofHours : "0" + numberofHours}h ${Math.floor(
            diffInMinutes - numberofHours * 60
        )}m`;
    }

    return "0h 0m";
};

export const getOrderDisplayStatus = (confirmationStatus: string | ConfirmedOrderStatus) => {
    if (confirmationStatus === "failed") {
        return "Refund failed";
    }
    if (confirmationStatus === "pending") {
        return "Refunded";
    }
    if (["Resubmitted", "Resubmitting"].includes(confirmationStatus)) {
        return "Resubmit pending";
    }
    return confirmationStatus as ConfirmedOrderStatus;
};

export const mapPromotionAdjustments = (adjustments: BillAdjustment[]) => {
    const promotionAdjustments: OrderPromotion[] = adjustments
        .filter((a) => !!a.promotionId)
        .map((a) => ({
            name: a.name,
            amount: a.value,
        }));

    return promotionAdjustments;
};

export const mapCepOfferAdjustments = (adjustments: BillAdjustment[]) => {
    const cepAdjustments = adjustments
        .filter((a) => a.type === "CepApplicableOffer" && a.value !== 0)
        .map((a) => ({
            name: a.name,
            amount: a.value,
        }));
    return cepAdjustments;
};

export const countStampsEarned = (adjustments: BillAdjustment[]) => {
    const cepAdjustments = adjustments.filter((a) => a.type === "CepApplicableOffer" && a.value === 0);
    return cepAdjustments.length;
};

export const mapTrayChargeAdjustments = (adjustments: BillAdjustment[]) => {
    const trayCharges: OrderTrayCharge[] = adjustments
        .filter((a) => a.type === "TrayCharge")
        .map((a) => ({
            name: a.name,
            amount: a.value,
        }));

    return trayCharges;
};

export const mapVenueCauseAdjustments = (adjustments: BillAdjustment[]) => {
    const venueCauses: OrderVenueCause[] = adjustments
        .filter((a) => a.type === "VenueCause")
        .map((a) => ({
            name: a.name,
            amount: a.value,
        }));

    return venueCauses;
};

const mapGroupTabOrders = (orders: GroupTabOrderItem[], members: GroupTabMember[]) => {
    return orders.map((order) => {
        const member = members.find((m) => m.memberId === order.memberId);

        return member
            ? {
                  ...order,
                  memberName: (member.firstName || "") + " " + (member.lastName || ""),
              }
            : order;
    });
};

const DAY = 1000 * 3600 * 24;
const LIVE_DAYS_COUNT = 30 * DAY;

export const isActiveDays = (diningDate: Date) => Date.now() - diningDate.getTime() < LIVE_DAYS_COUNT;

export const sortItemWithTime = (a: ItemWithTime, b: ItemWithTime) => {
    return b.time.getTime() - a.time.getTime();
};

export const mapActivePartyToPartyPayment = (activeParties: ActiveParty[], hideUnconfirmed: boolean): Party[] => {
    const parties: Party[] = activeParties.reduce((parties: Party[], p: ActiveParty) => {
        const payments = p.payments.filter(
            (payment) => isValidPayment(payment) && (!hideUnconfirmed || !isUnconfirmedPayment(payment))
        );
        if (payments.length || p.securedPaymentState === SecuredPaymentStateType.SATISFIED) {
            const customerSavedPayment = p.members.find(({ hasSecuredPayment }) => hasSecuredPayment);
            const paymentsData = payments.map((payment) => {
                const linkedMember = p.members.find((member) => member.id === payment.memberId);
                return {
                    ...mapPayment(p, payment),
                    fullName: linkedMember?.memberName ?? linkedMember?.displayName ?? "",
                    phone: linkedMember?.phoneNumber,
                };
            });
            paymentsData.sort(sortItemWithTime);
            parties.push({
                partyId: p.id,
                status: isPartyClosed(p) ? "Closed" : "Ongoing",
                partyType: partyTypeMap[p.type],
                fullName: p.members[0]?.displayName,
                phone: p.members[0]?.phoneNumber,
                table: p.tableNumber.split("-")[0],
                displayId: p.tableNumber,
                section: p.sectionName,
                customerSavedPayment,
                time: new Date(p.dateClosed || p.sortDate || p.dateOpened),
                dateClosed: p.dateClosed ? new Date(p.dateClosed) : undefined,
                totalPaymentsAmount: p.paymentsAmount,
                totalPaymentsTotal: p.paymentsTotal,
                totalRefundableAmount: p.refundableTotal,
                totalItemAmount:
                    p.orderWithPayment?.subtotal ??
                    p.ordersWithoutPayment?.reduce((total, order) => total + order.orderTotal, 0),
                totalRefundedAmount: p.refundedTotal,
                totalPaid: (p.paymentsTotal ?? 0) - (p.refundedTotal ?? 0),
                totalTip: p.tipsTotal,
                totalGratuity: p.gratuityTotal,
                totalProcessingFee: p.processingFeeTotal,
                paymentsData,
                processingFeeAbsorbed: !!p.payments.find((payment) =>
                    isValidPayment(payment) ? payment.processingFeeAbsorbed : undefined
                ),
                canForceCharge: canForceCharge(p),
                wasForceCharged: hasForceChargedPayment(p),
                isFlexTab: p.isFlexTab,
            });
        }
        return parties;
    }, []);
    return parties;
};

let mergedIndicatorColor = -1;
const mergedIndicatorsById = new Map<string, number>();

export const clearMergedColors = () => {
    mergedIndicatorColor = -1;
    mergedIndicatorsById.clear();
};

export const getNextColor = (posId: string) => {
    let color = mergedIndicatorsById.get(posId);
    if (color === undefined) {
        if (mergedIndicatorColor > 5) {
            mergedIndicatorColor = -1;
        }
        color = ++mergedIndicatorColor;
        mergedIndicatorsById.set(posId, color);
    }
    return color;
};

export const isResubmitOrderStatus = (status: OrderStatus) =>
    [OrderStatus.RESUBMITTING, OrderStatus.RESUBMITTED].includes(status);

export const mapSubmittedOrders = (submittedOrders: Order[], unconfirmedOrders: LiveOrder[]) => {
    let shouldUpdate = false;

    const updatedUnconfirmedOrders: LiveOrder[] = unconfirmedOrders.flatMap((order) => {
        const matchedOrder = submittedOrders.find((submittedOrder) => submittedOrder.id === order.orderId);
        if (matchedOrder) {
            if (matchedOrder.status === OrderStatus.ACCEPTED) {
                shouldUpdate = true;
                return [];
            }
            if (isResubmitOrderStatus(matchedOrder.status) && order.status !== "Resubmit pending") {
                shouldUpdate = true;
                return {
                    ...order,
                    status: "Resubmit pending",
                };
            }
            if (isUnconfirmedOrder(matchedOrder) && order.status !== "Unconfirmed") {
                shouldUpdate = true;
                return {
                    ...order,
                    status: "Unconfirmed",
                };
            }
            return order;
        }
        return order;
    });
    return shouldUpdate ? updatedUnconfirmedOrders : null;
};

const getPartyType = (orderHubPartyType: OrderHubPartyType) => {
    switch (orderHubPartyType) {
        case OrderHubPartyType.MULTIUSER: {
            return PartyType.MULTIUSER;
        }
        case OrderHubPartyType.PAYONLY: {
            return PartyType.PAYONLY;
        }
        default: {
            return PartyType.SINGLEUSER;
        }
    }
};

export const getPartyOrderFlow = (partyType: PartyType, isFlexTab: boolean) => {
    switch (partyType) {
        case PartyType.MULTIUSER:
            return `Flex, ${isFlexTab ? "Tabs" : "Tables"}`;
        case PartyType.PAYONLY:
            return "Pay Only";
        default:
            return "Order & Pay";
    }
};
