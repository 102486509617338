import classNames from "classnames";
import React from "react";
import { Text } from "../../../../components/text";

export interface LinkRowProps {
    onLinkClick: () => void;
    labelName: string;
    linkName: string;
    displayAsText?: boolean;
    className?: string;
}

export const OrderCardLinkRow = ({ labelName, displayAsText, linkName, onLinkClick, className }: LinkRowProps) => {
    return (
        <div className="details-card__detail-row">
            <Text preset="g-14" mode={["bold", "block"]} value={labelName} />
            {displayAsText ? (
                <Text preset="g-14" mode="block" value={linkName} />
            ) : (
                <Text preset="g-14" mode={["medium", "bold"]}>
                    <a className={classNames("link", className)} onClick={onLinkClick}>
                        {linkName}
                    </a>
                </Text>
            )}
        </div>
    );
};
