import { opsApi } from "common/api";
import { DeliveryOptionType } from "../types";
import { fetchAsStaff } from "features/staffLogin/selectors/getAccessToken";
import { GraphQLSection } from "./updateSection";
import { sectionQuery } from "./fetchSections";

export async function updateSectionDeliveryOptionType(
    locationId: string,
    sectionId: string,
    menuItemType: string,
    deliveryOptionType: DeliveryOptionType
): Promise<GraphQLSection> {
    const res = (
        await opsApi.graphQLQuery<{ updateSectionDeliveryOptionType: GraphQLSection }>(
            updateQuery,
            {
                locationId,
                sectionId,
                menuItemType,
                deliveryOptionType,
            },
            fetchAsStaff(true)
        )
    ).data.updateSectionDeliveryOptionType;

    return res;
}

const updateQuery: string = `mutation ($locationId: ID!, $sectionId: ID!, $menuItemType: String!, $deliveryOptionType: DeliveryOptionType!) {
    updateSectionDeliveryOptionType(locationId: $locationId, sectionId: $sectionId, menuItemType: $menuItemType, deliveryOptionType: $deliveryOptionType) {
        ${sectionQuery}
    }
}`;
