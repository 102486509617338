import { SoldOut, ProductDetails, ModifierDetails, FilterTypes } from "../types";
import { Availability } from "../types";

export type State = SoldOut;

export enum TypeKeys {
    PRODUCTS_LOADING = "SOLD_OUT_ITEMS/PRODCTS/LOADING",
    PRODUCTS_LOADED = "SOLD_OUT_ITEMS/PRODCTS/LOADED",
    PRODUCT_UPDATED = "SOLD_OUT_ITEMS/PRODCTS/UPDATED",
    PRODUCTS_FAILED = "SOLD_OUT_ITEMS/PRODCTS/FAILED",
    MODIFIERS_LOADING = "SOLD_OUT_ITEMS/MODIFIERS/LOADING",
    MODIFIERS_LOADED = "SOLD_OUT_ITEMS/MODIFIERS/LOADED",
    MODIFIER_UPDATED = "SOLD_OUT_ITEMS/MODIFIERS/UPDATED",
    MODIFIERS_FAILED = "SOLD_OUT_ITEMS/MODIFIERS/FAILED",
    APPLY_FILTER = "SOLD_OUT_ITEMS/APPLY_FILTER",
    SET_SEARCH = "SOLD_OUT_ITEMS/SET_SEARCH",
}

export const createAction = {
    productsLoading: () => ({ type: TypeKeys.PRODUCTS_LOADING }),
    productsLoaded: (data: ProductDetails[]) => ({ type: TypeKeys.PRODUCTS_LOADED, data }),
    productUpdated: (product: ProductDetails, status: Availability) => ({
        type: TypeKeys.PRODUCT_UPDATED,
        product,
        status,
    }),
    productsFailed: (error: any) => ({ type: TypeKeys.PRODUCTS_FAILED, error }),
    modifiersLoading: () => ({ type: TypeKeys.MODIFIERS_LOADING }),
    modifiersLoaded: (data: ModifierDetails[]) => ({ type: TypeKeys.MODIFIERS_LOADED, data }),
    modifierUpdated: (sku: string, status: Availability) => ({ type: TypeKeys.MODIFIER_UPDATED, sku, status }),
    modifiersFailed: (error: any) => ({ type: TypeKeys.MODIFIERS_FAILED, error }),
    applyFilter: (filter: FilterTypes) => ({ type: TypeKeys.APPLY_FILTER, filter }),
    setSearch: (search: string) => ({ type: TypeKeys.SET_SEARCH, search }),
};

type SoldOutProductsLoadingAction = { type: TypeKeys.PRODUCTS_LOADING };
type SoldOutProductsLoadedAction = { type: TypeKeys.PRODUCTS_LOADED; data: ProductDetails[] };
type SoldOutProductUpdatedAction = { type: TypeKeys.PRODUCT_UPDATED; product: ProductDetails; status: Availability };
type SoldOutProductsFailedAction = { type: TypeKeys.PRODUCTS_FAILED; error: any };
type SoldOutModifiersLoadingAction = { type: TypeKeys.MODIFIERS_LOADING };
type SoldOutModifiersLoadedAction = { type: TypeKeys.MODIFIERS_LOADED; data: ModifierDetails[] };
type SoldOutModifierUpdatedAction = { type: TypeKeys.MODIFIER_UPDATED; sku: string; status: Availability };
type SoldOutModifiersFailedAction = { type: TypeKeys.MODIFIERS_FAILED; error: any };
type SoldOutApplyFilterAction = { type: TypeKeys.APPLY_FILTER; filter: FilterTypes };
type SoldOutSetSearchAction = { type: TypeKeys.SET_SEARCH; search: string };

export type SoldOutAction =
    | SoldOutProductsLoadingAction
    | SoldOutProductsLoadedAction
    | SoldOutProductUpdatedAction
    | SoldOutProductsFailedAction
    | SoldOutModifiersLoadingAction
    | SoldOutModifiersLoadedAction
    | SoldOutModifierUpdatedAction
    | SoldOutModifiersFailedAction
    | SoldOutApplyFilterAction
    | SoldOutSetSearchAction;

const initialState: State = {
    products: { status: "unloaded" },
    modifiers: { status: "unloaded" },
};

export const reducer = (state: State = initialState, action: SoldOutAction): State => {
    if (action.type === TypeKeys.PRODUCTS_LOADING) {
        return {
            ...state,
            products: { status: "loading" },
        };
    }

    if (action.type === TypeKeys.PRODUCTS_LOADED) {
        const { data } = action;
        return {
            ...state,
            products: {
                status: "loaded",
                data,
            },
        };
    }

    if (action.type === TypeKeys.PRODUCT_UPDATED) {
        const { product, status } = action;
        let data: ProductDetails[];
        if (product.isVariant) {
            data =
                state.products.status === "loaded"
                    ? state.products.data.map((p) =>
                          p.variants
                              ? ({
                                    ...p,
                                    variants: p.variants.map((v) =>
                                        v.sku === product.sku ? ({ ...v, status } as ProductDetails) : v
                                    ),
                                } as ProductDetails)
                              : p
                      )
                    : [];
        } else {
            const variantsSku = product.variants && product.variants.map((v) => v.sku);
            data =
                state.products.status === "loaded"
                    ? state.products.data.map((p) =>
                          p.sku === product.sku
                              ? p.variants && variantsSku
                                  ? ({
                                        ...p,
                                        variants: p.variants.map((v) => ({
                                            ...v,
                                            status: variantsSku.includes(v.sku) ? status : v.status,
                                        })),
                                    } as ProductDetails)
                                  : ({ ...p, status } as ProductDetails)
                              : p
                      )
                    : [];
        }

        return {
            ...state,
            products: {
                status: "loaded",
                data,
            },
        };
    }

    if (action.type === TypeKeys.PRODUCTS_FAILED) {
        const { error } = action;
        return {
            ...state,
            products: {
                status: "failed",
                error,
            },
        };
    }
    if (action.type === TypeKeys.MODIFIERS_LOADING) {
        return {
            ...state,
            modifiers: { status: "loading" },
        };
    }

    if (action.type === TypeKeys.MODIFIERS_LOADED) {
        const { data } = action;
        return {
            ...state,
            modifiers: {
                status: "loaded",
                data,
            },
        };
    }

    if (action.type === TypeKeys.MODIFIER_UPDATED) {
        const { sku, status } = action;
        return {
            ...state,
            modifiers: {
                status: "loaded",
                data:
                    state.modifiers.status === "loaded"
                        ? state.modifiers.data.map((p) => (p.sku === sku ? ({ ...p, status } as ModifierDetails) : p))
                        : [],
            },
        };
    }

    if (action.type === TypeKeys.MODIFIERS_FAILED) {
        const { error } = action;
        return {
            ...state,
            modifiers: {
                status: "failed",
                error,
            },
        };
    }
    if (action.type === TypeKeys.APPLY_FILTER) {
        const { filter } = action;
        return {
            ...state,
            filter,
        };
    }

    if (action.type === TypeKeys.SET_SEARCH) {
        const { search } = action;
        return {
            ...state,
            search,
        };
    }

    return state;
};
