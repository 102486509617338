import { FloorItem } from "../types";
import { DisableSection, EditDeliveryOptionType } from "../types/ManageSection";

export type State = {
    disableSection?: DisableSection;
    deliveryOption?: EditDeliveryOptionType;
    manageAvailability?: FloorItem;
    updatingIds: { [x: string]: boolean };
    floorItemSearchTerm?: string;
    filterUnavailable: boolean;
    updateSectionBehaviour?: FloorItem;
};

export enum TypeKeys {
    SET_DISABLE_SECTION = "SECTION/SET_DISABLE_SECTION",
    SET_SECTION_DELIVERY_OPTION = "SECTION/SET_SECTION_DELIVERY_OPTION",
    SET_MANAGE_AVAILABILITY = "FLOOR_ITEM/SET_MANAGE_AVAILABILITY",
    SET_UPDATE_SECTION_BEHAVIOUR = "FLOOR_ITEM/SET_UPDATE_SECTION_BEHAVIOUR",
    ADD_UPDATING_ID = "FLOOR_ITEM/ADD_UPDATING_ID",
    REMOVE_UPDATING_ID = "FLOOR_ITEM/REMOVE_UPDATING_ID",
    SET_FLOOR_ITEM_SEARCH_TERM = "SECTION/SET_FLOOR_ITEM_SEARCH_TERM",
    SET_FILTER_UNAVAILABLE_ITEMS = "SECTION/SET_FILTER_UNAVAILABLE_ITEMS",
}

export const actionCreator = {
    setDisableSection: (disableSection?: DisableSection) => ({ type: TypeKeys.SET_DISABLE_SECTION, disableSection }),
    setSectionDeliveryOption: (deliveryOption?: EditDeliveryOptionType) => ({
        type: TypeKeys.SET_SECTION_DELIVERY_OPTION,
        deliveryOption,
    }),
    setManageAvailability: (floorItem?: FloorItem) => ({
        type: TypeKeys.SET_MANAGE_AVAILABILITY,
        floorItem,
    }),
    setUpdateSectionBehaviour: (floorItem?: FloorItem) => ({
        type: TypeKeys.SET_UPDATE_SECTION_BEHAVIOUR,
        floorItem,
    }),
    addUpdatingId: (floorItemId: string) => ({
        type: TypeKeys.ADD_UPDATING_ID,
        floorItemId,
    }),
    removeUpdatingId: (floorItemId: string) => ({
        type: TypeKeys.REMOVE_UPDATING_ID,
        floorItemId,
    }),
    setFloorItemSearchTerm: (searchTerm?: string) => ({
        type: TypeKeys.SET_FLOOR_ITEM_SEARCH_TERM,
        searchTerm,
    }),
    setFilterUnavailableItems: (filterUnavailable: boolean) => ({
        type: TypeKeys.SET_FILTER_UNAVAILABLE_ITEMS,
        filterUnavailable,
    }),
};

type SetDisableSectionAction = { type: TypeKeys.SET_DISABLE_SECTION; disableSection?: DisableSection };
type SetSectionDeliveryOptionAction = {
    type: TypeKeys.SET_SECTION_DELIVERY_OPTION;
    deliveryOption?: EditDeliveryOptionType;
};
type SetManageAvailability = { type: TypeKeys.SET_MANAGE_AVAILABILITY; floorItem?: FloorItem };
type SetUpdateSectionBehaviour = { type: TypeKeys.SET_UPDATE_SECTION_BEHAVIOUR; floorItem?: FloorItem };
type AddUpdatingId = {
    type: TypeKeys.ADD_UPDATING_ID;
    floorItemId: string;
};
type RemoveUpdatingId = {
    type: TypeKeys.REMOVE_UPDATING_ID;
    floorItemId: string;
};
type SetFloorItemSearchTerm = { type: TypeKeys.SET_FLOOR_ITEM_SEARCH_TERM; searchTerm?: string };
type SetFilterUnavailableItems = { type: TypeKeys.SET_FILTER_UNAVAILABLE_ITEMS; filterUnavailable: boolean };

export type ManageSectionAction =
    | SetDisableSectionAction
    | SetSectionDeliveryOptionAction
    | SetManageAvailability
    | SetUpdateSectionBehaviour
    | AddUpdatingId
    | RemoveUpdatingId
    | SetFloorItemSearchTerm
    | SetFilterUnavailableItems;

const initialState: State = {
    updatingIds: {},
    filterUnavailable: false,
};

export function reducer(state: State = initialState, action: ManageSectionAction): State {
    if (action.type === TypeKeys.SET_DISABLE_SECTION) {
        const { disableSection } = action;
        return {
            ...state,
            disableSection,
        };
    }
    if (action.type === TypeKeys.SET_SECTION_DELIVERY_OPTION) {
        const { deliveryOption } = action;
        return {
            ...state,
            deliveryOption,
        };
    }
    if (action.type === TypeKeys.SET_MANAGE_AVAILABILITY) {
        const { floorItem } = action;
        return {
            ...state,
            manageAvailability: floorItem,
        };
    }
    if (action.type === TypeKeys.SET_UPDATE_SECTION_BEHAVIOUR) {
        const { floorItem } = action;
        return {
            ...state,
            updateSectionBehaviour: floorItem,
        };
    }
    if (action.type === TypeKeys.ADD_UPDATING_ID) {
        const { floorItemId } = action;
        const updatingIds = { ...state.updatingIds, [floorItemId]: true };
        return {
            ...state,
            updatingIds,
        };
    }
    if (action.type === TypeKeys.REMOVE_UPDATING_ID) {
        const { floorItemId } = action;
        const updatingIds = { ...state.updatingIds };
        delete updatingIds[floorItemId];
        return {
            ...state,
            updatingIds,
        };
    }
    if (action.type === TypeKeys.SET_FLOOR_ITEM_SEARCH_TERM) {
        const { searchTerm } = action;
        return {
            ...state,
            floorItemSearchTerm: searchTerm,
        };
    }
    if (action.type === TypeKeys.SET_FILTER_UNAVAILABLE_ITEMS) {
        const { filterUnavailable } = action;
        return {
            ...state,
            filterUnavailable,
        };
    }
    return state;
}
