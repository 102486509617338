import { ClosedParties } from "../types";
import { opsApi } from "common/api";
import { fetchAsStaff } from "features/staffLogin/selectors/getAccessToken";
import { OrderBase } from "../../../../common/types";

type PartiesGraphQLPayload = { closedPickupParties: ClosedParties };

const getPartiesQuery: string = `query ($locationId: ID!, $skip: Int!, $limit: Int!, $section: String, $searchOrder: String) {
    closedPickupParties(locationId: $locationId, skip: $skip, limit: $limit, section: $section, searchOrder: $searchOrder) {
        totalCount
        data {
            id
            tableNumber
            quantity
            sectionName
            dateSubmitted
            dateClosed
        }
    }
}`;
export async function fetchClosedParties(
    locationId: string,
    skip: number,
    limit: number,
    section: string | null,
    searchOrder: string | null
) {
    const res = await opsApi.graphQLQuery<PartiesGraphQLPayload>(
        getPartiesQuery,
        { locationId, skip, limit, section, searchOrder },
        fetchAsStaff(false)
    );
    return res.data.closedPickupParties || {};
}

export async function fetchClosedParty(locationId: string, partyId: string) {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(getPartyQuery, { locationId, partyId }, fetchAsStaff(false));
    return res.data.closedParty.orderWithPayment || {};
}

type GraphQLPayload = { closedParty: { orderWithPayment: OrderBase } };

const getPartyQuery: string = `query ($locationId: ID!, $partyId: ID!) {
    closedParty(locationId: $locationId, partyId: $partyId) {
        orderWithPayment {
            items {
                id
                variantName
                displayName
                quantity
                modifiers {
                    displayName
                    nestedModifiers {
                        displayName
                    }
                }
                memberNotes
            }
        }
    }
  }`;
