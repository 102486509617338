import React from "react";
import { ActivePartyCounts, BumpScreenType } from "../../shared/types";
import { ClosedPartiesContainer } from "../containers/ClosedPartiesContainer";
import { ActivePartiesContainer } from "../containers/ActivePartiesContainer";
import { ArrowRightIcon, MeAndUIcon } from "common/icons";
import { MenuOutlined } from "@ant-design/icons";
import { Section } from "../../../floorManager/types";
import classNames from "classnames";
import "./BumpScreenPage.scss";
import { SectionSelector } from "../../../floorManager/components/sections/SectionSelector";

export interface Props {
    mode: BumpScreenType;
    forceShow?: boolean;
    isLive?: boolean;
    selectedSection?: Section;
    sections: Section[];
    changeView: (mode: BumpScreenType) => void;
    showNavBar: (show: boolean) => void;
    selectSection: (section: Section | undefined) => void;
    activePartyCounts: ActivePartyCounts;
}

export const BumpScreenPage = ({
    mode,
    forceShow,
    isLive,
    selectedSection,
    changeView,
    showNavBar,
    selectSection,
    activePartyCounts: { total, preparing, ready },
}: Props) => {
    return (
        <div className="bump-screen" onClick={forceShow ? () => showNavBar(false) : undefined}>
            <div className="bump-screen--header">
                <div className="bump-screen--header--left">
                    <MenuOutlined
                        className="bump-screen--header--left--menu"
                        onClick={!forceShow ? () => showNavBar(true) : undefined}
                    />
                    <MeAndUIcon />
                    <div className={classNames("bump-screen--header--left--status", !isLive && "offline")}>
                        {isLive ? "Live" : "Offline"}
                    </div>
                    <div className="bump-screen--header--left--sections">
                        <SectionSelector
                            selectedSection={selectedSection}
                            onSelectSection={selectSection}
                            overlayClassName="bump-screen--header--left--sections--overlay"
                        />
                    </div>
                </div>
                <div className="bump-screen--header--right">
                    <div
                        className={classNames(
                            "bump-screen--header--right--mode",
                            mode === BumpScreenType.ACTIVE && "active"
                        )}
                        onClick={() => changeView(BumpScreenType.ACTIVE)}
                    >
                        All open <span className="count">{total}</span>
                    </div>
                    <div
                        className={classNames(
                            "bump-screen--header--right--mode",
                            mode === BumpScreenType.PREPARING && "active"
                        )}
                        onClick={() => changeView(BumpScreenType.PREPARING)}
                    >
                        Preparing <span className="count">{preparing}</span>
                    </div>
                    <ArrowRightIcon />
                    <div
                        className={classNames(
                            "bump-screen--header--right--mode",
                            mode === BumpScreenType.READY && "active"
                        )}
                        onClick={() => changeView(BumpScreenType.READY)}
                    >
                        Ready <span className="count">{ready}</span>
                    </div>
                    <ArrowRightIcon />
                    <div
                        className={classNames(
                            "bump-screen--header--right--mode",
                            mode === BumpScreenType.CLOSE && "active"
                        )}
                        onClick={() => changeView(BumpScreenType.CLOSE)}
                    >
                        Closed
                    </div>
                </div>
            </div>
            <div className="bump-screen--container">
                {mode === BumpScreenType.CLOSE ? <ClosedPartiesContainer /> : <ActivePartiesContainer />}
            </div>
        </div>
    );
};
