import { Button } from "antd";
import { Text } from "components/text";
import React, { PropsWithChildren, useEffect, useMemo, useRef, useState } from "react";
import ReactModal from "react-modal";
import cuid from "cuid";
import { Prompt } from "react-router-dom";
import { Location } from "history";
import "./ConfirmModal.scss";
import { trackPageView } from "common/AppInsights/AppInsights";
import classNames from "classnames";
import { CrossIcon } from "common/icons";
import { useDispatch } from "react-redux";
import { AppDispatch } from "features";

interface Props {
    showModal?: boolean;
    onCloseModal?: () => void;
    title?: string;
    subTitle?: string;
    onConfirm?: () => void;
    confirmText?: string;
    cancelText?: string;
    isPrompt?: boolean;
    closeOnSubmit?: boolean;
    loading?: boolean;
    className?: string;
}

export const ConfirmModal = ({
    className,
    showModal,
    onCloseModal,
    children,
    title,
    subTitle,
    confirmText,
    cancelText,
    onConfirm,
    isPrompt,
    closeOnSubmit = true,
    loading = false,
}: PropsWithChildren<Props>) => {
    const [isOpen, setIsOpen] = useState(!!showModal);
    const allowTransitionCallback = useRef<(ok: boolean) => void | undefined>();
    const dispatch = useDispatch<AppDispatch>();

    const triggerPrompt = (cb: (ok: boolean) => void) => {
        setIsOpen(true);
        allowTransitionCallback.current = cb;
    };

    const trigger = useMemo(() => Symbol.for(`__PreventTransitionPrompt_${cuid()}`), []);
    useEffect(() => {
        if (isPrompt) {
            window[trigger] = triggerPrompt;
        }
        return () => {
            if (trigger) {
                delete window[trigger];
            }
        };
    }, [isPrompt, trigger]);

    useEffect(() => {
        if (!isPrompt) {
            setIsOpen(!!showModal);
        }
    }, [showModal, isPrompt]);

    const closeModal = () => {
        setIsOpen(false);
        allowTransitionCallback.current && allowTransitionCallback.current(true);
    };

    const saveAndClose = () => {
        onConfirm && onConfirm();
        allowTransitionCallback.current && allowTransitionCallback.current(false);
        closeOnSubmit && setIsOpen(false);
    };
    const handleTransition = (location: Location) => {
        return trigger && isPrompt ? Symbol.keyFor(trigger) || true : true;
    };

    const baseClass = classNames("confirm-modal", className);

    return (
        <>
            {isPrompt && <Prompt when={isPrompt} message={handleTransition} />}
            <ReactModal
                isOpen={isOpen}
                className={{
                    base: baseClass,
                    afterOpen: "confirm-modal--after-open",
                    beforeClose: "confirm-modal--before-close",
                }}
                overlayClassName="confirm-modal-overlay"
                closeTimeoutMS={250}
                onAfterClose={onCloseModal}
                shouldCloseOnEsc={!isPrompt && closeOnSubmit}
                shouldCloseOnOverlayClick={!isPrompt && closeOnSubmit}
                onRequestClose={closeModal}
                onAfterOpen={() => {
                    dispatch(
                        trackPageView(window.location.href + "/ConfirmModal?title=" + title, {
                            pageType: "modal",
                        })
                    );
                }}
            >
                <div className="confirm-modal__body">
                    {title && (
                        <div className="confirm-modal__body__head">
                            <Text mode={["medium", "block"]} preset="title-20">
                                {title}
                            </Text>
                            <button className="confirm-modal__body__close" onClick={closeModal}>
                                <span className="confirm-modal__body__close-icon">
                                    <CrossIcon />
                                </span>
                            </button>
                        </div>
                    )}
                    {subTitle && (
                        <div className="confirm-modal__body__subtitle">
                            <Text mode="block" preset="g-14">
                                {subTitle}
                            </Text>
                        </div>
                    )}
                    <div className="confirm-modal__content">{children}</div>
                    <div className="confirm-modal__actions">
                        <Button className="confirm-modal__actions__cancel" onClick={closeModal} disabled={loading}>
                            <Text preset="g-16" mode="medium">
                                {cancelText || "Dismiss"}
                            </Text>
                        </Button>
                        <Button
                            className="confirm-modal__actions__confirm"
                            disabled={!isPrompt && !onConfirm}
                            onClick={saveAndClose}
                            loading={loading}
                        >
                            <Text preset="g-16" mode="medium">
                                {confirmText || "Confirm"}
                            </Text>
                        </Button>
                    </div>
                </div>
            </ReactModal>
        </>
    );
};
