import { AppDispatch } from "features";
import { AppState } from "features/state";
import { actionCreators } from "../reducers/enrolment";
import { getEnrolmentInfo, getLastLocationId } from "../api/persistence";
import { identify } from "features/location/actions/identify";
import { History } from "history";
import { regionHelper } from "../../region";

export function restoreEnrolment(history: History) {
    return async function (dispatch: AppDispatch, getState: () => AppState) {
        const status = getState().enrolment.status;

        if (status !== "failed" && status !== "initial") {
            return;
        }

        const savedState = getEnrolmentInfo();

        if (savedState !== null) {
            dispatch(identify(savedState.location_id));
            dispatch(actionCreators.restore(savedState));
            return;
        }

        const lastLocationId = getLastLocationId();

        if (lastLocationId && regionHelper.region) {
            history.replace(`${regionHelper.region.id}/enrol/${lastLocationId}`);
            return;
        }

        dispatch(actionCreators.restore(null));
    };
}
