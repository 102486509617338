import { BulletedIcon, LabelIcon } from "common/icons";
import * as React from "react";
import { FiltersCount, FilterTypes } from "../types";
import "./SoldOutPage.scss";
import { MenuItemsPage } from "./MenuItemsPage";
import { CategoriesPage } from "./CategoriesPage";
import { useHistory, useLocation } from "react-router";
import { Tabs } from "components/tabs/Tabs";
import { useSelector } from "react-redux";
import { getLocationPosSupportsCatalogueAvailabilityEvent } from "features/location/selectors/getLocationConfig";

export interface Props {
    productsFilters: FiltersCount;
    modifiersFilters: FiltersCount;
    locationId?: string;
    totalProducts?: number;
    totalModifiers?: number;
    loading: boolean;
    filter: FilterTypes;
    setSearchVal: (val: string) => void;
    setFilter: (selected: FilterTypes) => void;
    fetchProducts: (locationId: string) => void;
    fetchModifier: (locationId: string) => void;
}

export const SoldOutPage = () => {
    const location = useLocation();
    const history = useHistory();
    const showMenuItems = !useSelector(getLocationPosSupportsCatalogueAvailabilityEvent);
    const tabs = [];

    if (showMenuItems) {
        tabs.push({
            title: "Menu items",
            key: "menu-items",
            icon: <LabelIcon />,
            content: <MenuItemsPage />,
        });
    }

    tabs.push({
        title: "Categories",
        key: "categories",
        icon: <BulletedIcon />,
        content: <CategoriesPage />,
    });

    const activeKey = location.pathname.includes("categories") || !showMenuItems ? "categories" : "menu-items";

    return (
        <div className="soldout-page">
            <Tabs
                onTabClick={(key: string) => {
                    history.push("/sold-out/" + key);
                }}
                activeKey={activeKey}
                destroyInactiveTabPane
                mode="page"
                tabs={tabs}
            />
        </div>
    );
};
