import { getCustomerName } from "common/utility/memberUtils";
import React from "react";
import ReactModal from "react-modal";
import { ClosedParty } from "../../shared/types";
import { OrderCard } from "./OrderCard";
import "./OrderCardModal.scss";

export interface Props {
    closedParty?: ClosedParty;
    closeModal: () => void;
}

export const OrderCardModal = ({ closedParty, closeModal }: Props) => {
    return (
        <ReactModal
            isOpen={!!closedParty}
            className="closed-party-modal"
            overlayClassName="closed-party-modal-overlay"
            closeTimeoutMS={0}
        >
            <div className="shield" onClick={closeModal} />
            {!!closedParty && (
                <OrderCard
                    partyId={closedParty.id}
                    memberDetails={getCustomerName(closedParty.tableNumber)}
                    tableNumber={closedParty.tableNumber}
                    sectionName={closedParty.sectionName}
                    submittedOrders={[closedParty.order!]}
                    dateClosed={new Date(closedParty.dateClosed).getTime()}
                    buttonAction={closeModal}
                />
            )}
        </ReactModal>
    );
};
