import _ from "lodash";
import { Section } from "features/floorManager/types";
import { GroupTab, GroupTabsData, Order, OrdersState, OrderStatus, Party, PartyActivityStatus } from "../types";
import {
    chargePartyActionCreators,
    chargePartyInitState,
    ChargePartyAction,
    chargePartyReducer,
} from "./chargePartyReducer";

export const unconfirmedOrdersPageSize = 100;

export const ordersFilterKey = "MEANDU_ORDERS_FILTERS";

export const partyFilterKey = "MEANDU_PARTIES_FILTERS";

export const groupTabsFilterKey = "MEANDU_GROUP_TABS_FILTERS";

export type State = OrdersState;

export enum TypeKeys {
    SELECT_SECTION = "ORDERS/SELECT_SECTION",
    HISTORY_ORDERS_LOADING = "ORDERS/HISTORY_ORDERS_LOADING",
    HISTORY_ORDERS_LOADED = "ORDERS/HISTORY_ORDERS_LOADED",
    HISTORY_ORDERS_FAILED = "ORDERS/HISTORY_ORDERS_FAILED",
    HISTORY_ORDERS_FILTER = "ORDERS/HISTORY_ORDERS_FILTER",
    HISTORY_ORDERS_SEARCH = "ORDERS/HISTORY_ORDERS_SEARCH",
    ACTIVE_ORDERS_LOADING = "ORDERS/ACTIVE_ORDERS_LOADING",
    ACTIVE_ORDERS_LOADED = "ORDERS/ACTIVE_ORDERS_LOADED",
    ACTIVE_ORDERS_RESET = "ORDERS/ACTIVE_ORDERS_RESET",
    ACTIVE_ORDERS_FAILED = "ORDERS/ACTIVE_ORDERS_FAILED",
    HISTORY_ORDER_UPDATED = "ORDERS/HISTORY_ORDER_UPDATED",
    HISTORY_ORDERS_PAGE = "ORDERS/HISTORY_ORDERS_PAGE",
    HISTORY_ORDERS_NEXT_PAGE = "ORDERS/HISTORY_ORDERS_NEXT_PAGE",
    HISTORY_ORDERS_COUNT_CHANGE = "ORDERS/HISTORY_ORDERS_COUNT_CHANGE",
    UNCONFIRMED_ORDERS_LOADING = "ORDERS/UNCONFIRMED_ORDERS_LOADING",
    UNCONFIRMED_ORDERS_LOADED = "ORDERS/UNCONFIRMED_ORDERS_LOADED",
    UNCONFIRMED_ORDERS_FAILED = "ORDERS/UNCONFIRMED_ORDERS_FAILED",
    UNCONFIRMED_LIVE_ORDER_RECEIVED = "ORDERS/UNCONFIRMED_LIVE_ORDER_RECEIVED",
    UNCONFIRMED_ORDER_RESOLVING = "ORDERS/UNCONFIRMED_ORDER_RESOLVING",
    UNCONFIRMED_ORDER_RESOLVED = "ORDERS/UNCONFIRMED_ORDER_RESOLVED",
    UNCONFIRMED_ORDER_RESOLVE_FAILED = "ORDERS/UNCONFIRMED_ORDER_RESOLVED_FAILED",
    RESUBMIT_UNCONFIRMED_ORDER = "ORDERS/RESUBMIT_UNCONFIRMED_ORDER",
    RESUBMIT_ORDER_FAILED = "ORDERS/RESUBMIT_ORDER_FAILED",
    UPDATE_RESUBMITTED_UNCONFIRMED_ORDERS = "ORDERS/UPDATE_RESUBMITTED_UNCONFIRMED_ORDERS",
    SET_ORDER_TO_RESUBMIT = "ORDERS/SET_ORDER_TO_RESUBMIT",
    ORDER_REFUND_PROCESSING = "ORDERS/REFUND_PROCESSING",
    ORDER_REFUND_PROCESSED = "ORDERS/ORDER_REFUND_PROCESSED",
    ORDER_REFUND_FAILED = "ORDERS/UNCONFIRMED_REFUND_FAILED",
    ORDER_REFUND_RESET = "ORDERS/UNCONFIRMED_REFUND_RESET",
    GROUP_TABS_LOADING = "GROUP_TABS/GROUP_TABS_LOADING",
    GROUP_TABS_LOADED = "GROUP_TABS/GROUP_TABS_LOADED",
    GROUP_TABS_FAILED = "GROUP_TABS/GROUP_TABS_FAILED",
    GROUP_TABS_FILTER = "GROUP_TABS/GROUP_TABS_FILTER",
    GROUP_TABS_PAGE = "GROUP_TABS/GROUP_TABS_PAGE",
    GROUP_TABS_NEXT_PAGE = "GROUP_TABTS/GROUP_TABS_NEXT_PAGE",
    GROUP_TABS_Active_TOTAL_LOADING = "GROUP_TABS/Active_TOTAL_LOADING",
    GROUP_TABS_Active_TOTAL_LOADED = "GROUP_TABS/Active_TOTAL_LOADED",
    GROUP_TABS_Active_TOTAL_FAILED = "GROUP_TABS/Active_TOTAL_FAILED",
    GROUP_TABS_CLOSE_RESET = "GROUP_TABS/CLOSE_RESET",
    GROUP_TABS_CLOSE_PROCESSING = "GROUP_TABS/CLOSE_PROCESSING",
    GROUP_TABS_CLOSE_PROCESSED = "GROUP_TABS/CLOSE_PROCESSED",
    GROUP_TABS_CLOSE_FAILED = "GROUP_TABS/CLOSE_FAILED",
    GROUP_TABS_ORDER_LOADED = "GROUP_TABS/ORDER_LOADED",
    GROUP_TABS_ORDER_LOADING = "GROUP_TABS/ORDER_LOADING",
    GROUP_TABS_ORDER_FAILED = "GROUP_TABS/ORDER_FAILED",
    GET_GROUP_TAB_BY_ID_LOADING = "GROUP_TABS/GET_GROUP_TAB_BY_ID_LOADING",
    GET_GROUP_TAB_BY_ID_LOADED = "GROUP_TABS/GET_GROUP_TAB_BY_ID_LOADED",
    GET_GROUP_TAB_BY_ID_FAILED = "GROUP_TABS/GET_GROUP_TAB_BY_ID_FAILED",

    ACTIVE_PAYMENT_PARTIES_LOADING = "ORDERS/ACTIVE_PAYMENT_PARTIES_LOADING",
    ACTIVE_PAYMENT_PARTIES_LOADED = "ORDERS/ACTIVE_PAYMENT_PARTIES_LOADED",
    ACTIVE_PAYMENT_PARTIES_FAILED = "ORDERS/ACTIVE_PAYMENT_PARTIES_FAILED",
    CLOSED_PAYMENT_PARTIES_LOADING = "ORDERS/CLOSED_PAYMENT_PARTIES_LOADING",
    CLOSED_PAYMENT_PARTIES_LOADED = "ORDERS/CLOSED_PAYMENT_PARTIES_LOADED",
    CLOSED_PAYMENT_PARTIES_FAILED = "ORDERS/CLOSED_PAYMENT_PARTIES_FAILED",
    CLOSED_PAYMENT_PARTIES_NEXT_PAGE = "ORDERS/CLOSED_PAYMENT_PARTIES_NEXT_PAGE",
    PAYMENT_PARTY_UPDATED = "ORDERS/PAYMENT_PARTY_UPDATED",
    PAYMENT_PARTIES_FILTER = "ORDERS/PAYMENT_PARTIES_FILTER",
    PAYMENT_PARTIES_SEARCH = "ORDERS/PAYMENT_PARTIES_SEARCH",
    PAYMENT_PARTIES_RESET = "ORDERS/PAYMENT_PARTIES_RESET",

    CHARGE_PARTY_PROCESSING = "ORDERS/CHARGE_PARTY_PROCESSING",
    CHARGE_PARTY_PROCESSED = "ORDERS/CHARGE_PARTY_PROCESSED",
    CHARGE_PARTY_FAILED = "ORDERS/CHARGE_PARTY_FAILED",
    CHARGE_PARTY_RESET = "ORDERS/CHARGE_PARTY_RESET",
}

export const actionCreators = {
    selectSection: (selectedSection: Section | undefined) => ({ type: TypeKeys.SELECT_SECTION, selectedSection }),
    historyOrdersLoading: () => ({ type: TypeKeys.HISTORY_ORDERS_LOADING }),
    historyOrdersLoaded: (orders: Order[], totalCount: number, spreadOrdersCount: number, loadMore?: boolean) => ({
        type: TypeKeys.HISTORY_ORDERS_LOADED,
        orders,
        totalCount,
        loadMore,
        spreadOrdersCount,
    }),
    historyOrdersFailed: (error: any) => ({ type: TypeKeys.HISTORY_ORDERS_FAILED, error }),
    activeOrdersLoading: () => ({ type: TypeKeys.ACTIVE_ORDERS_LOADING }),
    activeOrdersReset: () => ({ type: TypeKeys.ACTIVE_ORDERS_RESET }),
    activeOrdersLoaded: (orders: Order[], totalCount: number) => ({
        type: TypeKeys.ACTIVE_ORDERS_LOADED,
        orders,
        totalCount,
    }),
    activeOrdersFailed: (error: any) => ({ type: TypeKeys.ACTIVE_ORDERS_FAILED, error }),
    selectHistoryOrdersFilter: (values: OrderStatus[]) => ({ type: TypeKeys.HISTORY_ORDERS_FILTER, values }),
    seHistoryOrdersSearch: (value: string) => ({ type: TypeKeys.HISTORY_ORDERS_SEARCH, value }),
    selectHistoryOrdersPage: (page: number) => ({ type: TypeKeys.HISTORY_ORDERS_PAGE, page }),
    historyOrdersNextPage: () => ({ type: TypeKeys.HISTORY_ORDERS_NEXT_PAGE }),
    historyOrderUpdated: (order: Order) => ({ type: TypeKeys.HISTORY_ORDER_UPDATED, order }),
    showHistoryCountChange: (count?: number) => ({ type: TypeKeys.HISTORY_ORDERS_COUNT_CHANGE, count }),
    unconfirmedOrdersLoading: () => ({ type: TypeKeys.UNCONFIRMED_ORDERS_LOADING }),
    unconfirmedOrdersLoaded: (orders: Order[], totalCount: number) => ({
        type: TypeKeys.UNCONFIRMED_ORDERS_LOADED,
        orders,
        totalCount,
    }),
    unconfirmedOrdersFailed: (error: any) => ({ type: TypeKeys.UNCONFIRMED_ORDERS_FAILED, error }),
    unconfirmedLiveOrderReceived: (orders: Order[]) => ({ type: TypeKeys.UNCONFIRMED_LIVE_ORDER_RECEIVED, orders }),
    unconfirmedOrderResolving: (order: Order) => ({ type: TypeKeys.UNCONFIRMED_ORDER_RESOLVING, order }),
    unconfirmedOrderResolved: (order: Order) => ({ type: TypeKeys.UNCONFIRMED_ORDER_RESOLVED, order }),
    unconfirmedOrderResolveFailed: (order: Order, error: any) => ({
        type: TypeKeys.UNCONFIRMED_ORDER_RESOLVE_FAILED,
        order,
        error,
    }),
    setOrderToResubmit: (order?: Order) => ({ type: TypeKeys.SET_ORDER_TO_RESUBMIT, order }),
    resubmitUnconfirmedOrder: (order: Order) => ({ type: TypeKeys.RESUBMIT_UNCONFIRMED_ORDER, order }),
    resubmitOrderFailed: (order: Order) => ({ type: TypeKeys.RESUBMIT_ORDER_FAILED, order }),
    updateResubmittedUnconfirmedOrders: (orders: Order[]) => ({
        type: TypeKeys.UPDATE_RESUBMITTED_UNCONFIRMED_ORDERS,
        orders,
    }),
    orderRefundProcessing: (order: Order) => ({ type: TypeKeys.ORDER_REFUND_PROCESSING, order }),
    orderRefundProcessed: (order: Order, amountRefunded: number, updateLiveOrders: boolean) => ({
        type: TypeKeys.ORDER_REFUND_PROCESSED,
        order,
        amountRefunded,
        updateLiveOrders,
    }),
    orderRefundFailed: (error: any) => ({ type: TypeKeys.ORDER_REFUND_FAILED, error }),
    orderRefundReset: () => ({ type: TypeKeys.ORDER_REFUND_RESET }),
    groupTabOrdersLoading: () => ({ type: TypeKeys.GROUP_TABS_LOADING }),
    groupTabOrdersLoaded: (groupTabs: GroupTabsData[], totalCount: number, loadMore?: boolean) => ({
        type: TypeKeys.GROUP_TABS_LOADED,
        groupTabs,
        totalCount,
        loadMore,
    }),
    groupTabOrdersFailed: (error: any) => ({ type: TypeKeys.GROUP_TABS_FAILED, error }),
    groupTabsOrdersNextPage: () => ({ type: TypeKeys.GROUP_TABS_NEXT_PAGE }),
    selectGroupTabsFilter: (values: string[]) => ({ type: TypeKeys.GROUP_TABS_FILTER, values }),
    selectGroupTabsPage: (page: number) => ({ type: TypeKeys.GROUP_TABS_PAGE, page }),
    activeGroupTabsLoading: () => ({ type: TypeKeys.GROUP_TABS_Active_TOTAL_LOADING }),
    activeGroupTabsLoaded: (totalCount: number) => ({ type: TypeKeys.GROUP_TABS_Active_TOTAL_LOADED, totalCount }),
    activeGroupTabsFailed: (error: any) => ({ type: TypeKeys.GROUP_TABS_Active_TOTAL_FAILED, error }),
    closeGroupTabReset: () => ({ type: TypeKeys.GROUP_TABS_CLOSE_RESET }),
    closeGroupTabProcessing: () => ({ type: TypeKeys.GROUP_TABS_CLOSE_PROCESSING }),
    closeGroupTabProcessed: (groupTab: GroupTabsData, updateGroupTabInOrder: boolean) => ({
        type: TypeKeys.GROUP_TABS_CLOSE_PROCESSED,
        groupTab,
        updateGroupTabInOrder,
    }),
    closeGroupTabFailed: (error: any) => ({ type: TypeKeys.GROUP_TABS_CLOSE_FAILED, error }),
    tabOrderLoaded: (groupTabId: string, order: Order) => ({
        type: TypeKeys.GROUP_TABS_ORDER_LOADED,
        groupTabId,
        order,
    }),
    tabOrderLoading: () => ({ type: TypeKeys.GROUP_TABS_ORDER_LOADING }),
    tabOrderFailed: (error: any) => ({ type: TypeKeys.GROUP_TABS_ORDER_FAILED, error }),
    getTabByIdLoading: () => ({ type: TypeKeys.GET_GROUP_TAB_BY_ID_LOADING }),
    getTabByIdLoaded: (groupTab: GroupTabsData) => ({
        type: TypeKeys.GET_GROUP_TAB_BY_ID_LOADED,
        groupTab,
    }),
    getTabByIdFailed: (error: any) => ({ type: TypeKeys.GET_GROUP_TAB_BY_ID_FAILED, error }),

    activePaymentPartiesLoading: () => ({ type: TypeKeys.ACTIVE_PAYMENT_PARTIES_LOADING }),
    activePaymentPartiesLoaded: (parties: Party[], totalCount: number) => ({
        type: TypeKeys.ACTIVE_PAYMENT_PARTIES_LOADED,
        parties,
        totalCount,
    }),
    activePaymentPartiesFailed: (error: any) => ({ type: TypeKeys.ACTIVE_PAYMENT_PARTIES_FAILED, error }),
    closedPaymentPartiesLoading: () => ({ type: TypeKeys.CLOSED_PAYMENT_PARTIES_LOADING }),
    closedPaymentPartiesLoaded: (parties: Party[], totalCount: number, loadMore: boolean) => ({
        type: TypeKeys.CLOSED_PAYMENT_PARTIES_LOADED,
        parties,
        totalCount,
        loadMore,
    }),
    closedPaymentPartiesFailed: (error: any) => ({ type: TypeKeys.CLOSED_PAYMENT_PARTIES_FAILED, error }),
    closedPaymentPartiesNextPage: () => ({ type: TypeKeys.CLOSED_PAYMENT_PARTIES_NEXT_PAGE }),
    selectPaymentPartyFilter: (values: PartyActivityStatus[]) => ({ type: TypeKeys.PAYMENT_PARTIES_FILTER, values }),
    sePaymentPartiesSearch: (value: string) => ({ type: TypeKeys.PAYMENT_PARTIES_SEARCH, value }),
    paymentPartiesReset: (justClosed?: boolean) => ({ type: TypeKeys.PAYMENT_PARTIES_RESET, justClosed }),
    paymentPartyUpdated: (party: Party) => ({ type: TypeKeys.PAYMENT_PARTY_UPDATED, party }),
    ...chargePartyActionCreators,
};

type OrdersSelectSectionAction = { type: TypeKeys.SELECT_SECTION; selectedSection: Section | undefined };
type HistoryOrdersLoadingAction = { type: TypeKeys.HISTORY_ORDERS_LOADING };
type HistoryOrdersLoadedAction = {
    type: TypeKeys.HISTORY_ORDERS_LOADED;
    orders: Order[];
    totalCount: number;
    spreadOrdersCount: number;
    loadMore?: boolean;
};
type HistoryOrdersFailedAction = { type: TypeKeys.HISTORY_ORDERS_FAILED; error: any };
type ActiveOrdersLoadingAction = { type: TypeKeys.ACTIVE_ORDERS_LOADING };
type ActiveOrdersResetAction = { type: TypeKeys.ACTIVE_ORDERS_RESET };
type ActiveOrdersLoadedAction = { type: TypeKeys.ACTIVE_ORDERS_LOADED; orders: Order[]; totalCount: number };
type ActiveOrdersFailedAction = { type: TypeKeys.ACTIVE_ORDERS_FAILED; error: any };
type SelectHistoryOrdersFilterAction = { type: TypeKeys.HISTORY_ORDERS_FILTER; values: OrderStatus[] };
type SeHistoryOrdersSearchAction = { type: TypeKeys.HISTORY_ORDERS_SEARCH; value: string };
type SelectHistoryOrdersPage = { type: TypeKeys.HISTORY_ORDERS_PAGE; page: number };
type HistoryOrdersNextPageAction = { type: TypeKeys.HISTORY_ORDERS_NEXT_PAGE };
type ShowHistoryCountChange = { type: TypeKeys.HISTORY_ORDERS_COUNT_CHANGE; count?: number };
type UnconfirmedOrdersLoadingAction = { type: TypeKeys.UNCONFIRMED_ORDERS_LOADING };
type UnconfirmedOrdersLoadedAction = { type: TypeKeys.UNCONFIRMED_ORDERS_LOADED; orders: Order[]; totalCount: number };
type UnconfirmedOrdersFailedAction = { type: TypeKeys.UNCONFIRMED_ORDERS_FAILED; error: any };
type UnconfirmedLiveOrderReceivedAction = { type: TypeKeys.UNCONFIRMED_LIVE_ORDER_RECEIVED; orders: Order[] };
type UnconfirmedOrderResolvingAction = { type: TypeKeys.UNCONFIRMED_ORDER_RESOLVING; order: Order };
type UnconfirmedOrderResolvedAction = { type: TypeKeys.UNCONFIRMED_ORDER_RESOLVED; order: Order };
type UnconfirmedOrderResolveFailedAction = {
    type: TypeKeys.UNCONFIRMED_ORDER_RESOLVE_FAILED;
    order: Order;
    error: any;
};
type UnconfirmedOrderResubmitAction = { type: TypeKeys.RESUBMIT_UNCONFIRMED_ORDER; order: Order };
type ResubmitOrderFailedAction = { type: TypeKeys.RESUBMIT_ORDER_FAILED; order: Order };
type SetUnconfirmedResubmitOrder = { type: TypeKeys.SET_ORDER_TO_RESUBMIT; order: Order };
type UpdateResubmittedUnconfirmedOrder = {
    type: TypeKeys.UPDATE_RESUBMITTED_UNCONFIRMED_ORDERS;
    orders: Order[];
};
type OrderRefundProcessingAction = { type: TypeKeys.ORDER_REFUND_PROCESSING; order: Order };
type OrderRefundProcessedAction = {
    type: TypeKeys.ORDER_REFUND_PROCESSED;
    order: Order;
    amountRefunded: number;
    updateLiveOrders: boolean;
};
type OrderRefundFailed = { type: TypeKeys.ORDER_REFUND_FAILED; error: any };
type OrderRefundReset = { type: TypeKeys.ORDER_REFUND_RESET };
type HistoryOrderUpdated = { type: TypeKeys.HISTORY_ORDER_UPDATED; order: Order };
type GroupTabsLoadingAction = { type: TypeKeys.GROUP_TABS_LOADING };
type GroupTabsLoadedAction = {
    type: TypeKeys.GROUP_TABS_LOADED;
    groupTabs: GroupTabsData[];
    totalCount: number;
    loadMore?: boolean;
};
type GroupTabsFailedAction = { type: TypeKeys.GROUP_TABS_FAILED; error: any };
type GroupTabsNextPage = { type: TypeKeys.GROUP_TABS_NEXT_PAGE };
type SelectGroupTabsFilterAction = { type: TypeKeys.GROUP_TABS_FILTER; values: string[] };
type SelectGroupTabsPage = { type: TypeKeys.GROUP_TABS_PAGE; page: number };
type ActiveGroupTabsLoadingAction = { type: TypeKeys.GROUP_TABS_Active_TOTAL_LOADING };
type ActiveGroupTabsLoadedAction = { type: TypeKeys.GROUP_TABS_Active_TOTAL_LOADED; totalCount: number };
type ActiveGroupTabsFailedAction = { type: TypeKeys.GROUP_TABS_Active_TOTAL_FAILED; error: any };
type GroupTabCloseReset = { type: TypeKeys.GROUP_TABS_CLOSE_RESET; groupTab: GroupTab };
type GroupTabCloseProcessing = { type: TypeKeys.GROUP_TABS_CLOSE_PROCESSING; groupTab: GroupTab };
type GroupTabCloseProcessedAction = {
    type: TypeKeys.GROUP_TABS_CLOSE_PROCESSED;
    groupTab: GroupTabsData;
    updateGroupTabInOrder: boolean;
};
type GroupTabCloseFailed = { type: TypeKeys.GROUP_TABS_CLOSE_FAILED; error: any };
type GroupTabOrderLoadedAction = {
    type: TypeKeys.GROUP_TABS_ORDER_LOADED;
    groupTabId: string;
    order: Order;
};
type GroupTabOrderLoadingAction = { type: TypeKeys.GROUP_TABS_ORDER_LOADING };
type GroupTabOrderFailedAction = { type: TypeKeys.GROUP_TABS_ORDER_FAILED; error: any };
type GetGroupTabByIdLoadingAction = { type: TypeKeys.GET_GROUP_TAB_BY_ID_LOADING };
type GetGroupTabByIdLoadedAction = {
    type: TypeKeys.GET_GROUP_TAB_BY_ID_LOADED;
    groupTab: GroupTabsData;
};
type GetGroupTabByIdFailedAction = { type: TypeKeys.GET_GROUP_TAB_BY_ID_FAILED; error: any };

type ActivePaymentPartiesLoadingAction = { type: TypeKeys.ACTIVE_PAYMENT_PARTIES_LOADING };
type ActivePaymentPartiesLoadedAction = {
    type: TypeKeys.ACTIVE_PAYMENT_PARTIES_LOADED;
    parties: Party[];
    totalCount: number;
};
type ActivePaymentPartiesFailedAction = { type: TypeKeys.ACTIVE_PAYMENT_PARTIES_FAILED; error: any };
type PaymentPartiesResetdAction = { type: TypeKeys.PAYMENT_PARTIES_RESET; justClosed?: boolean };
type ClosedPaymentPartiesLoadingAction = { type: TypeKeys.CLOSED_PAYMENT_PARTIES_LOADING };
type ClosedPaymentPartiesLoadedAction = {
    type: TypeKeys.CLOSED_PAYMENT_PARTIES_LOADED;
    parties: Party[];
    totalCount: number;
    loadMore: boolean;
};
type ClosedPaymentPartiesFailedAction = { type: TypeKeys.CLOSED_PAYMENT_PARTIES_FAILED; error: any };
type ClosedPaymentPartiesNextPageAction = { type: TypeKeys.CLOSED_PAYMENT_PARTIES_NEXT_PAGE };
type SelectPaymentPartyFilterAction = { type: TypeKeys.PAYMENT_PARTIES_FILTER; values: PartyActivityStatus[] };
type SePaymentPartiesSearchActive = { type: TypeKeys.PAYMENT_PARTIES_SEARCH; value: string };
type PaymentPartyUpdated = { type: TypeKeys.PAYMENT_PARTY_UPDATED; party: Party };

export type OrdersAction =
    | OrdersSelectSectionAction
    | HistoryOrdersLoadingAction
    | HistoryOrdersLoadedAction
    | HistoryOrdersFailedAction
    | HistoryOrderUpdated
    | SelectHistoryOrdersFilterAction
    | SelectHistoryOrdersPage
    | ShowHistoryCountChange
    | UnconfirmedOrdersLoadingAction
    | UnconfirmedOrdersLoadedAction
    | UnconfirmedOrdersFailedAction
    | UnconfirmedLiveOrderReceivedAction
    | UnconfirmedOrderResolvingAction
    | UnconfirmedOrderResolvedAction
    | UnconfirmedOrderResolveFailedAction
    | UnconfirmedOrderResubmitAction
    | SetUnconfirmedResubmitOrder
    | ResubmitOrderFailedAction
    | UpdateResubmittedUnconfirmedOrder
    | OrderRefundProcessingAction
    | OrderRefundProcessedAction
    | OrderRefundFailed
    | OrderRefundReset
    | GroupTabsLoadingAction
    | GroupTabsLoadedAction
    | GroupTabsFailedAction
    | GroupTabsNextPage
    | SelectGroupTabsFilterAction
    | SelectGroupTabsPage
    | ActiveGroupTabsLoadingAction
    | ActiveGroupTabsLoadedAction
    | ActiveGroupTabsFailedAction
    | GroupTabCloseReset
    | GroupTabCloseProcessing
    | GroupTabCloseProcessedAction
    | GroupTabCloseFailed
    | GroupTabOrderLoadedAction
    | GroupTabOrderLoadingAction
    | GroupTabOrderFailedAction
    | GetGroupTabByIdLoadingAction
    | GetGroupTabByIdLoadedAction
    | GetGroupTabByIdFailedAction
    | SeHistoryOrdersSearchAction
    | ActiveOrdersLoadingAction
    | ActiveOrdersResetAction
    | ActiveOrdersLoadedAction
    | ActiveOrdersFailedAction
    | ActivePaymentPartiesLoadingAction
    | ActivePaymentPartiesLoadedAction
    | ActivePaymentPartiesFailedAction
    | ClosedPaymentPartiesLoadingAction
    | ClosedPaymentPartiesLoadedAction
    | ClosedPaymentPartiesFailedAction
    | ClosedPaymentPartiesNextPageAction
    | SelectPaymentPartyFilterAction
    | SePaymentPartiesSearchActive
    | PaymentPartiesResetdAction
    | PaymentPartyUpdated
    | HistoryOrdersNextPageAction
    | ChargePartyAction<TypeKeys.CHARGE_PARTY_FAILED>
    | ChargePartyAction<TypeKeys.CHARGE_PARTY_PROCESSED>
    | ChargePartyAction<TypeKeys.CHARGE_PARTY_PROCESSING>
    | ChargePartyAction<TypeKeys.CHARGE_PARTY_RESET>;

const selectedFilters = JSON.parse(sessionStorage.getItem(ordersFilterKey) || "[]");
const selectedPaymentFilters = JSON.parse(sessionStorage.getItem(partyFilterKey) || "[]");
const groupTabsSelectedFilters = JSON.parse(sessionStorage.getItem(groupTabsFilterKey) || "[]");

export const initialState: State = {
    historyOrdersFilter: selectedFilters,
    paymentPartiesFilter: selectedPaymentFilters,
    historyOrdersSearch: "",
    unconfirmedOrders: { status: "unloaded" },
    activeOrders: { status: "unloaded" },
    historyOrdersPage: 1,
    unconfirmedOrdersTotal: 0,
    unconfirmedLiveOrders: [],
    unconfirmedLiveOrderIds: [],
    refundedAmount: 0,
    groupTabsFilter: groupTabsSelectedFilters,
    groupTabsPage: 1,
    activeGroupTabsCount: { status: "unloaded" },
    activeGroupTabsTotal: 0,
    closeGroupTabResponse: { status: "unloaded" },
    fetchTabOrderResponse: { status: "unloaded" },
    fetchTabByIdResponse: { status: "unloaded" },
    activePaymentParties: { status: "unloaded" },
    paymentPartiesSearch: "",
    closedPaymentPartiesPage: 1,
    ...chargePartyInitState,
};

const ascending = (a: Order, b: Order): number => {
    return a.time.valueOf() - b.time.valueOf();
};

export const reducer = (state: State = initialState, action: OrdersAction): State => {
    if (action.type === TypeKeys.SELECT_SECTION) {
        const { selectedSection } = action;
        return {
            ...state,
            selectedSection,
        };
    }

    if (action.type === TypeKeys.HISTORY_ORDERS_LOADING) {
        return {
            ...state,
            historyOrders: {
                ...state.historyOrders,
                status: "loading",
            },
        };
    }

    if (action.type === TypeKeys.HISTORY_ORDERS_LOADED) {
        const historyOrders = action.loadMore
            ? _.unionBy((state.historyOrders as any)?.data ?? [], action.orders, "orderId")
            : action.orders;
        return {
            ...state,
            historyOrders: {
                status: "loaded",
                data: historyOrders,
            },
            historyOrdersTotal: action.totalCount,
            historyOrdersFetchedCount: historyOrders.length,
        };
    }

    if (action.type === TypeKeys.HISTORY_ORDER_UPDATED) {
        if (
            state.historyOrders?.status === "loaded" &&
            state.historyOrders.data.find((o) => o.orderId === action.order.orderId)
        ) {
            return {
                ...state,
                historyOrders: {
                    status: "loaded",
                    data: state.historyOrders.data.map((order) =>
                        order.orderId === action.order.orderId ? action.order : order
                    ),
                },
            };
        }

        if (
            state.activeOrders?.status === "loaded" &&
            state.activeOrders.data.find((o) => o.orderId === action.order.orderId)
        ) {
            return {
                ...state,
                activeOrders: {
                    status: "loaded",
                    data: state.activeOrders.data.map((order) =>
                        order.orderId === action.order.orderId ? action.order : order
                    ),
                },
            };
        }
    }

    if (action.type === TypeKeys.ACTIVE_ORDERS_FAILED) {
        return {
            ...state,
            activeOrders: {
                status: "failed",
                error: action.error,
            },
        };
    }

    if (action.type === TypeKeys.ACTIVE_ORDERS_LOADING) {
        return {
            ...state,
            activeOrders: {
                ...state.activeOrders,
                status: "loading",
            },
        };
    }

    if (action.type === TypeKeys.ACTIVE_ORDERS_LOADED) {
        return {
            ...state,
            activeOrders: {
                status: "loaded",
                data: action.orders,
            },
            activeOrdersTotal: action.totalCount,
            activeOrdersFetchedCount: action.orders.length,
        };
    }

    if (action.type === TypeKeys.ACTIVE_ORDERS_RESET) {
        return {
            ...state,
            activeOrders: {
                status: "unloaded",
            },
            activeOrdersTotal: undefined,
            activeOrdersFetchedCount: undefined,
        };
    }

    if (action.type === TypeKeys.HISTORY_ORDERS_FAILED) {
        return {
            ...state,
            historyOrders: {
                status: "failed",
                error: action.error,
            },
        };
    }

    if (action.type === TypeKeys.HISTORY_ORDERS_FILTER) {
        return {
            ...state,
            historyOrdersFilter: action.values,
        };
    }

    if (action.type === TypeKeys.HISTORY_ORDERS_SEARCH) {
        return {
            ...state,
            historyOrdersSearch: action.value,
        };
    }

    if (action.type === TypeKeys.HISTORY_ORDERS_PAGE) {
        return {
            ...state,
            historyOrdersPage: action.page,
        };
    }

    if (action.type === TypeKeys.HISTORY_ORDERS_NEXT_PAGE) {
        return {
            ...state,
            historyOrdersPage: state.historyOrdersPage + 1,
        };
    }

    if (action.type === TypeKeys.HISTORY_ORDERS_COUNT_CHANGE) {
        return {
            ...state,
            historyOrdersCountChange: action.count,
        };
    }

    if (action.type === TypeKeys.UNCONFIRMED_ORDERS_LOADING) {
        return {
            ...state,
            unconfirmedOrders: {
                ...state.unconfirmedOrders,
                status: "loading",
            },
        };
    }

    if (action.type === TypeKeys.UNCONFIRMED_ORDERS_LOADED) {
        const { orders, totalCount } = action;
        const { unconfirmedLiveOrders } = state;

        if (orders.length < unconfirmedOrdersPageSize && unconfirmedLiveOrders.length) {
            while (unconfirmedLiveOrders.length && orders.length < unconfirmedOrdersPageSize) {
                const order = unconfirmedLiveOrders.shift();
                if (order && orders.findIndex((o) => o.orderId === order.orderId) === -1) {
                    orders.push(order);
                }
            }
        }

        return {
            ...state,
            unconfirmedOrders: {
                status: "loaded",
                data: orders.sort(ascending),
            },
            unconfirmedOrdersTotal: totalCount + unconfirmedLiveOrders.length,
            unconfirmedLiveOrders: [],
            unconfirmedLiveOrderIds: [],
        };
    }

    if (action.type === TypeKeys.UNCONFIRMED_ORDERS_FAILED) {
        const { unconfirmedLiveOrders } = state;

        if (unconfirmedLiveOrders.length) {
            return {
                ...state,
                unconfirmedOrders: {
                    status: "loaded",
                    data: unconfirmedLiveOrders,
                },
                unconfirmedOrdersTotal: unconfirmedLiveOrders.length,
                unconfirmedLiveOrders: [],
                unconfirmedLiveOrderIds: [],
            };
        }

        return {
            ...state,
            unconfirmedOrders: {
                status: "failed",
                error: action.error,
            },
            unconfirmedOrdersTotal: 0,
            unconfirmedLiveOrders: [],
            unconfirmedLiveOrderIds: [],
        };
    }

    if (action.type === TypeKeys.UNCONFIRMED_LIVE_ORDER_RECEIVED) {
        const newUnconfirmedOrders = action.orders?.filter(
            (order) =>
                state.unconfirmedLiveOrderIds.indexOf(order.orderId) < 0 &&
                (state.unconfirmedOrders.status === "loaded"
                    ? !state.unconfirmedOrders.data?.some(
                          (unconfirmedOrder: Order) => unconfirmedOrder.orderId === order.orderId
                      )
                    : true) &&
                (state.selectedSection?.displayName === order.section || !state.selectedSection)
        );
        const newUnconfirmedOrderIds = newUnconfirmedOrders?.map((order) => order.orderId);
        if (!newUnconfirmedOrders?.length) {
            // Ignore this live order update
            return state;
        }

        if (state.unconfirmedOrders.status === "unloaded" || state.unconfirmedOrders.status === "loading") {
            // We are loading a new page so store the live orders coming in
            // in case we need to add them to the current page
            return {
                ...state,
                unconfirmedLiveOrders: state.unconfirmedLiveOrders.concat(newUnconfirmedOrders).sort(ascending),
                unconfirmedLiveOrderIds: state.unconfirmedLiveOrderIds.concat(newUnconfirmedOrderIds),
            };
        }

        if (
            state.unconfirmedOrders.status === "failed" ||
            (state.unconfirmedOrders.status === "loaded" &&
                state.unconfirmedOrders.data.length < unconfirmedOrdersPageSize)
        ) {
            // We've loaded a page of server data but new orders are coming in that can also be displayed
            // so add them into the current data
            return {
                ...state,
                unconfirmedOrders: {
                    ...state.unconfirmedOrders,
                    status: "loaded", // just in case we previously failed
                    data: (state.unconfirmedOrders.status === "loaded" ? state.unconfirmedOrders.data : [])
                        .concat(newUnconfirmedOrders)
                        .sort(ascending),
                },
                unconfirmedOrdersTotal: state.unconfirmedOrdersTotal + newUnconfirmedOrders.length,
                unconfirmedLiveOrderIds: state.unconfirmedLiveOrderIds.concat(newUnconfirmedOrderIds),
            };
        }

        // We are already viewing a full page of unconfirmed orders so just
        // increase the count of alerts we have
        return {
            ...state,
            unconfirmedOrdersTotal: state.unconfirmedOrdersTotal + newUnconfirmedOrders.length,
            unconfirmedLiveOrderIds: state.unconfirmedLiveOrderIds.concat(newUnconfirmedOrderIds),
        };
    }

    if (action.type === TypeKeys.UNCONFIRMED_ORDER_RESOLVING) {
        if (state.unconfirmedOrders.status !== "loaded") return state;

        return {
            ...state,
            unconfirmedOrders: {
                ...state.unconfirmedOrders,
                data: state.unconfirmedOrders.data.filter((o) => o !== action.order).sort(ascending),
            },
            resolvedOrder: {
                status: "loading",
            },
            unconfirmedOrdersTotal: --state.unconfirmedOrdersTotal,
        };
    }

    if (action.type === TypeKeys.UNCONFIRMED_ORDER_RESOLVE_FAILED) {
        return {
            ...state,
            unconfirmedOrders: {
                ...state.unconfirmedOrders,
                status: "loaded", // just in case we previously failed
                data: (state.unconfirmedOrders.status === "loaded" ? state.unconfirmedOrders.data : [])
                    .concat(action.order)
                    .sort(ascending),
            },
            resolvedOrder: {
                status: "failed",
                error: action.error,
            },
        };
    }

    if (action.type === TypeKeys.UNCONFIRMED_ORDER_RESOLVED) {
        return {
            ...state,
            resolvedOrder: {
                status: "loaded",
                data: action.order,
            },
        };
    }

    if (action.type === TypeKeys.RESUBMIT_UNCONFIRMED_ORDER) {
        if (state.unconfirmedOrders.status === "loaded") {
            const filteredOrders = state.unconfirmedOrders.data.filter(
                (order) => order.orderId !== action.order.orderId
            );
            return {
                ...state,
                unconfirmedOrders: {
                    ...state.unconfirmedOrders,
                    data: [
                        ...filteredOrders,
                        {
                            ...action.order,
                            orderSubmittedCount: action.order.orderSubmittedCount
                                ? action.order.orderSubmittedCount + 1
                                : undefined,
                            status: "Resubmit pending",
                        },
                    ],
                },
            };
        }
    }

    if (action.type === TypeKeys.RESUBMIT_ORDER_FAILED) {
        if (state.unconfirmedOrders.status === "loaded") {
            const filteredOrders = state.unconfirmedOrders.data.filter(
                (order) => order.orderId !== action.order.orderId
            );
            return {
                ...state,
                unconfirmedOrders: {
                    ...state.unconfirmedOrders,
                    data: [
                        ...filteredOrders,
                        {
                            ...action.order,
                            status: "Unconfirmed",
                        },
                    ],
                },
            };
        }
    }

    if (action.type === TypeKeys.UPDATE_RESUBMITTED_UNCONFIRMED_ORDERS) {
        if (state.unconfirmedOrders.status === "loaded") {
            return {
                ...state,
                unconfirmedOrders: {
                    ...state.unconfirmedOrders,
                    data: action.orders,
                },
                unconfirmedOrdersTotal: action.orders.length,
            };
        }
    }

    if (action.type === TypeKeys.ORDER_REFUND_PROCESSING) {
        return {
            ...state,
            refundedOrder: {
                status: "loading",
            },
        };
    }

    if (action.type === TypeKeys.ORDER_REFUND_PROCESSED) {
        let newState = state;

        if (state.unconfirmedOrders.status === "loaded") {
            newState = {
                ...newState,
                unconfirmedOrders: {
                    ...state.unconfirmedOrders,
                    data: state.unconfirmedOrders.data.filter((o) => o !== action.order).sort(ascending),
                },
                unconfirmedOrdersTotal: action.updateLiveOrders
                    ? --state.unconfirmedOrdersTotal
                    : state.unconfirmedOrdersTotal,
            };
        }

        return {
            ...newState,
            refundedOrder: {
                status: "loaded",
                data: action.order,
            },
            refundedAmount: action.amountRefunded,
        };
    }

    if (action.type === TypeKeys.ORDER_REFUND_FAILED) {
        return {
            ...state,
            refundedOrder: {
                status: "failed",
                error: action.error,
            },
        };
    }

    if (action.type === TypeKeys.ORDER_REFUND_RESET) {
        return {
            ...state,
            refundedOrder: {
                status: "unloaded",
            },
            resolvedOrder: {
                status: "unloaded",
            },
            refundedAmount: 0,
        };
    }

    if (action.type === TypeKeys.GROUP_TABS_LOADING) {
        return {
            ...state,
            groupTabs: {
                ...state.groupTabs,
                status: "loading",
            },
        };
    }

    if (action.type === TypeKeys.GROUP_TABS_LOADED) {
        const groupTabs = action.loadMore
            ? _.unionBy((state.groupTabs as any)?.data ?? [], action.groupTabs, "id")
            : action.groupTabs;
        return {
            ...state,
            groupTabs: {
                status: "loaded",
                data: groupTabs,
            },
            groupTabsTotal: action.totalCount,
        };
    }

    if (action.type === TypeKeys.GROUP_TABS_FAILED) {
        return {
            ...state,
            groupTabs: {
                status: "failed",
                error: action.error,
            },
        };
    }

    if (action.type === TypeKeys.GROUP_TABS_FILTER) {
        return {
            ...state,
            groupTabsFilter: action.values,
        };
    }

    if (action.type === TypeKeys.GROUP_TABS_NEXT_PAGE) {
        return {
            ...state,
            groupTabsPage: state.groupTabsPage + 1,
        };
    }

    if (action.type === TypeKeys.GROUP_TABS_PAGE) {
        return {
            ...state,
            groupTabsPage: action.page,
        };
    }

    if (action.type === TypeKeys.GROUP_TABS_Active_TOTAL_LOADING) {
        return {
            ...state,
            activeGroupTabsCount: {
                ...state.activeGroupTabsCount,
                status: "loading",
            },
        };
    }

    if (action.type === TypeKeys.GROUP_TABS_Active_TOTAL_FAILED) {
        return {
            ...state,
            activeGroupTabsCount: {
                status: "failed",
                error: action.error,
            },
            activeGroupTabsTotal: 0,
        };
    }

    if (action.type === TypeKeys.GROUP_TABS_Active_TOTAL_LOADED) {
        return {
            ...state,
            activeGroupTabsCount: {
                status: "loaded",
                data: [],
            },
            activeGroupTabsTotal: action.totalCount,
        };
    }

    if (action.type === TypeKeys.GROUP_TABS_CLOSE_RESET) {
        return {
            ...state,
            closeGroupTabResponse: {
                status: "unloaded",
            },
        };
    }

    if (action.type === TypeKeys.GROUP_TABS_CLOSE_PROCESSING) {
        return {
            ...state,
            closeGroupTabResponse: {
                status: "loading",
            },
        };
    }

    if (action.type === TypeKeys.GROUP_TABS_CLOSE_PROCESSED) {
        if (!action.updateGroupTabInOrder && state.groupTabs && state.groupTabs.status === "loaded") {
            let existingGroupTabs = state.groupTabs.data.map((a) =>
                a.id === action.groupTab.id ? action.groupTab : a
            );

            return {
                ...state,
                groupTabs: {
                    status: "loaded",
                    data: existingGroupTabs,
                },
                closeGroupTabResponse: {
                    status: "loaded",
                    data: true,
                },
                activeGroupTabsTotal: --state.activeGroupTabsTotal,
            };
        }

        if (action.updateGroupTabInOrder) {
            if (state.unconfirmedOrders.status === "loaded") {
                if (!!state.historyOrders && state.historyOrders.status === "loaded") {
                    return {
                        ...state,
                        historyOrders: {
                            ...state.historyOrders,
                            data: state.historyOrders.data.filter((order) => {
                                if (!!order.groupTab && order.groupTab.id === action.groupTab.id) {
                                    order.groupTab = action.groupTab;
                                }
                                return order;
                            }),
                        },
                        unconfirmedOrders: {
                            ...state.unconfirmedOrders,
                            data: state.unconfirmedOrders.data.filter((order) => {
                                if (!!order.groupTab && order.groupTab.id === action.groupTab.id) {
                                    order.groupTab = action.groupTab;
                                }
                                return order;
                            }),
                        },
                        closeGroupTabResponse: {
                            status: "loaded",
                            data: true,
                        },
                    };
                }

                return {
                    ...state,
                    unconfirmedOrders: {
                        ...state.unconfirmedOrders,
                        data: state.unconfirmedOrders.data.filter((order) => {
                            if (!!order.groupTab && order.groupTab.id === action.groupTab.id) {
                                order.groupTab = action.groupTab;
                            }
                            return order;
                        }),
                    },
                    closeGroupTabResponse: {
                        status: "loaded",
                        data: true,
                    },
                };
            }
        }

        return {
            ...state,
            closeGroupTabResponse: {
                status: "loaded",
                data: true,
            },
            activeGroupTabsTotal: --state.activeGroupTabsTotal,
        };
    }

    if (action.type === TypeKeys.GROUP_TABS_CLOSE_FAILED) {
        return {
            ...state,
            closeGroupTabResponse: {
                status: "failed",
                error: action.error,
            },
        };
    }

    if (state.groupTabs && state.groupTabs.status === "loaded" && action.type === TypeKeys.GROUP_TABS_ORDER_LOADED) {
        const newState = state.groupTabs.data.map((gt) => {
            if (gt.id !== action.groupTabId) return gt;

            return {
                ...gt,
                orders: gt.orders.map((o) => {
                    if (o.partyId !== action.order.partyId) return o;

                    return {
                        ...o,
                        orderDetails: {
                            ...action.order,
                            groupTab: gt,
                        },
                    };
                }),
            };
        });

        return {
            ...state,
            groupTabs: {
                status: "loaded",
                data: newState,
            },
            fetchTabOrderResponse: {
                status: "loaded",
                data: true,
            },
        };
    }

    if (action.type === TypeKeys.GROUP_TABS_ORDER_LOADING) {
        return {
            ...state,
            fetchTabOrderResponse: {
                status: "loading",
            },
        };
    }

    if (action.type === TypeKeys.GROUP_TABS_ORDER_FAILED) {
        return {
            ...state,
            fetchTabOrderResponse: {
                status: "failed",
                error: action.error,
            },
        };
    }

    if (action.type === TypeKeys.GET_GROUP_TAB_BY_ID_LOADING) {
        return {
            ...state,
            fetchTabByIdResponse: {
                status: "loading",
            },
        };
    }

    if (state.groupTabs && state.groupTabs.status === "loaded" && action.type === TypeKeys.GET_GROUP_TAB_BY_ID_LOADED) {
        const newState = state.groupTabs.data.map((gt) => {
            if (gt.id !== action.groupTab.id) return gt;

            return {
                ...action.groupTab,
            };
        });

        return {
            ...state,
            groupTabs: {
                status: "loaded",
                data: newState,
            },
            fetchTabByIdResponse: {
                status: "loaded",
                data: true,
            },
        };
    }

    if (action.type === TypeKeys.GET_GROUP_TAB_BY_ID_FAILED) {
        return {
            ...state,
            fetchTabByIdResponse: {
                status: "failed",
                error: action.error,
            },
        };
    }

    if (action.type === TypeKeys.ACTIVE_PAYMENT_PARTIES_FAILED) {
        return {
            ...state,
            activePaymentParties: {
                status: "failed",
                error: action.error,
            },
        };
    }

    if (action.type === TypeKeys.ACTIVE_PAYMENT_PARTIES_LOADING) {
        return {
            ...state,
            activePaymentParties: {
                ...state.activePaymentParties,
                status: "loading",
            },
        };
    }

    if (action.type === TypeKeys.ACTIVE_PAYMENT_PARTIES_LOADED) {
        return {
            ...state,
            activePaymentParties: {
                status: "loaded",
                data: action.parties,
            },
            activePaymentPartiesTotal: action.totalCount,
        };
    }

    if (action.type === TypeKeys.CLOSED_PAYMENT_PARTIES_FAILED) {
        return {
            ...state,
            closedPaymentParties: {
                status: "failed",
                error: action.error,
            },
        };
    }

    if (action.type === TypeKeys.CLOSED_PAYMENT_PARTIES_LOADING) {
        return {
            ...state,
            closedPaymentParties: {
                ...state.closedPaymentParties,
                status: "loading",
            },
        };
    }

    if (action.type === TypeKeys.CLOSED_PAYMENT_PARTIES_NEXT_PAGE) {
        return {
            ...state,
            closedPaymentPartiesPage: state.closedPaymentPartiesPage + 1,
        };
    }

    if (action.type === TypeKeys.CLOSED_PAYMENT_PARTIES_LOADED) {
        const closedPaymentParties = action.loadMore
            ? _.unionBy((state.closedPaymentParties as any)?.data ?? [], action.parties, "partyId")
            : action.parties;
        return {
            ...state,
            closedPaymentParties: {
                status: "loaded",
                data: closedPaymentParties,
            },
            closedPaymentPartiesTotal: action.totalCount,
        };
    }

    if (action.type === TypeKeys.PAYMENT_PARTIES_FILTER) {
        return {
            ...state,
            paymentPartiesFilter: action.values,
            closedPaymentPartiesPage: 1,
        };
    }

    if (action.type === TypeKeys.PAYMENT_PARTIES_SEARCH) {
        return {
            ...state,
            paymentPartiesSearch: action.value,
            closedPaymentPartiesPage: 1,
        };
    }

    if (action.type === TypeKeys.PAYMENT_PARTIES_RESET) {
        return {
            ...state,
            activePaymentParties: {
                status: "unloaded",
            },
            activePaymentPartiesTotal: action.justClosed ? state.activePaymentPartiesTotal : undefined,
            closedPaymentPartiesPage: 1,
        };
    }

    if (action.type === TypeKeys.PAYMENT_PARTY_UPDATED) {
        if (
            state.activePaymentParties?.status === "loaded" &&
            state.activePaymentParties.data.find((p) => p.partyId === action.party.partyId)
        ) {
            return {
                ...state,
                activePaymentParties: {
                    status: "loaded",
                    data: state.activePaymentParties.data.map((party) =>
                        party.partyId === action.party.partyId ? action.party : party
                    ),
                },
            };
        }

        if (
            state.closedPaymentParties?.status === "loaded" &&
            state.closedPaymentParties.data.find((p) => p.partyId === action.party.partyId)
        ) {
            return {
                ...state,
                closedPaymentParties: {
                    status: "loaded",
                    data: state.closedPaymentParties.data.map((party) =>
                        party.partyId === action.party.partyId ? action.party : party
                    ),
                },
            };
        }
    }

    if (action.type === TypeKeys.SET_ORDER_TO_RESUBMIT) {
        return {
            ...state,
            resubmitUnconfirmedOrder: action.order,
        };
    }

    state = chargePartyReducer(state, action);

    return state;
};
