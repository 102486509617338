import "./virtualTable/VirtualiseTable.scss";

import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { ItemProps, TableVirtuoso } from "react-virtuoso";
import {
    getHistoryPageOrders,
    getHistoryPageOrdersFetchedPartyCount,
    getHistoryPageOrdersLoading,
    getHistoryPageOrdersTotal,
    getSelectedSection,
} from "../selectors";
import { useDispatch, useSelector } from "react-redux";
import { Order, TablePage } from "../types";
import { LocationLocaleContext } from "features/location/context/LocationLocaleContext";
import classNames from "classnames";
import { OrdersDrawer } from "./OrderDetails/OrdersDrawer";
import { FetchOptions, getOrderStatus } from "./HistoryOrders";
import { refundOrder } from "../actions";
import { getLocationId } from "features/staffLogin/selectors/getLocationId";
import { AppDispatch } from "features";
import { closeGroupTab } from "../actions/closeGroupTab";
import { reloadUnconfirmedOrders } from "../actions/reloadUnconfirmedOrders";
import { Spin } from "antd";
import { OrderRowContent } from "./virtualOrderTable/OrderRowContent";
import { OrdersHeader } from "./virtualOrderTable/OrdersHeader";
import { VirtualTableFooter } from "./virtualTable/VirtualTableFooter";
import { showMoreOrders } from "../actions/showMoreOrders";
import { VirtualTableComponents } from "./virtualTable/VirtualTableComponents";
import { fetchHistoryOrder } from "../actions/fetchHistoryOrder";

interface Props {
    fetch: (options?: FetchOptions) => void;
    scrollRef?: React.MutableRefObject<HTMLElement | Window | null | undefined>;
}

export const OrdersTable = ({ fetch, scrollRef }: Props) => {
    const [orders, setOrders] = useState<Order[]>([]);
    const [order, setOrder] = useState<Order | null>(null);
    const [closing, setClosing] = useState(false);
    const [actioning, setActioning] = useState(false);
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [actionNextOrder, setActionNextOrder] = useState(false);
    const [groupTabModalclosing, setGroupTabModalclosing] = useState(false);
    const [actioningGroupTab, setActioningGroupTab] = useState(false);
    const [isGroupTabOrder, setIsGroupTabOrder] = useState(false);

    const locationId = useSelector(getLocationId);
    const historyOrdersLoading = useSelector(getHistoryPageOrdersLoading);
    const historyOrders = useSelector(getHistoryPageOrders);
    const partyCount = useSelector(getHistoryPageOrdersFetchedPartyCount);
    const historyOrdersTotal = useSelector(getHistoryPageOrdersTotal);
    const selectedSection = useSelector(getSelectedSection);

    const closeModalTimerRef = useRef(0);
    const closeGroupTabModalTimerRef = useRef(0);
    const locationLocale = useContext(LocationLocaleContext);

    const dispatch = useDispatch<AppDispatch>();

    const onClose = useCallback((cancel: boolean) => {
        if (cancel) {
            setActionNextOrder(false);
            setActioning(false);
        }
        setClosing(true);
        closeModalTimerRef.current = window.setTimeout(() => {
            setOrder(null);
            setClosing(false);
            setSaveSuccess(false);
        }, 200);
    }, []);

    const oncloseGroupTab = useCallback(
        (groupTabId: string) => {
            locationId && dispatch(closeGroupTab(groupTabId, locationId, true));
        },
        [locationId, dispatch]
    );

    const onCloseGroupTabModal = useCallback((cancel: boolean) => {
        setGroupTabModalclosing(true);
        setActioningGroupTab(false);
        closeGroupTabModalTimerRef.current = window.setTimeout(() => {
            setGroupTabModalclosing(false);
        }, 200);
    }, []);

    const onOrderRowClick = useCallback(
        (selectedOrder: Order) => {
            const groupTabPayment =
                selectedOrder &&
                selectedOrder?.payments?.find(
                    (payment) => payment.status === "complete" && payment.paymentMethod === "GroupTab"
                );
            setIsGroupTabOrder(!!groupTabPayment);
            if (groupTabPayment && !selectedOrder?.groupTab) {
                locationId &&
                    groupTabPayment.paymentMethodId &&
                    dispatch(
                        reloadUnconfirmedOrders(locationId, locationLocale.getLocalTime(), selectedSection?.id ?? null)
                    );
            } else {
                setOrder(selectedOrder);
            }
        },
        [locationLocale, dispatch, locationId, selectedSection]
    );

    const loadMore = useCallback(() => {
        dispatch(showMoreOrders());
        fetch({ forceReLoad: false, loadMore: true });
    }, [fetch, dispatch]);

    useEffect(() => {
        if (!historyOrdersLoading) {
            setOrders((orders) => {
                if (scrollRef?.current && historyOrders[0]?.orderId !== orders[0]?.orderId) {
                    scrollRef.current.scrollTo({ top: 0 });
                }
                return historyOrders;
            });
        }
    }, [historyOrdersLoading, historyOrders, scrollRef]);

    const components = useMemo(
        () => ({
            ...VirtualTableComponents({ loading: historyOrdersLoading, itemType: "orders", colSpan: 8 }),
            TableRow: (props: ItemProps<Order>) => (
                <tr
                    onClick={() => {
                        onOrderRowClick(props.item);
                    }}
                    className={classNames(
                        "ant-table-row",
                        `orders-table_row_status-${props.item.status}`,
                        "actionable-row"
                    )}
                    data-row-key={`orders_${props.item.orderId}`}
                    data-row-party={`party_${props.item.partyId}`}
                    {...props}
                />
            ),
        }),
        [historyOrdersLoading, onOrderRowClick]
    );

    useEffect(() => {
        return () => {
            window.clearTimeout(closeModalTimerRef.current);
            window.clearTimeout(closeGroupTabModalTimerRef.current);
        };
    }, []);

    return (
        <>
            <div className="virtual-table__container">
                <TableVirtuoso
                    data={orders}
                    scrollerRef={scrollRef ? (r) => (scrollRef.current = r) : undefined}
                    initialTopMostItemIndex={0}
                    style={{ height: "100%" }}
                    totalCount={partyCount}
                    className={classNames("orders-table", "ant-table", "virtual-table")}
                    components={components}
                    fixedHeaderContent={() => <OrdersHeader page={TablePage.ORDERS} />}
                    itemContent={(_, order) => <OrderRowContent order={order} />}
                    fixedFooterContent={
                        orders.length
                            ? () => (
                                  <VirtualTableFooter
                                      visible={orders.length < historyOrdersTotal}
                                      onClick={loadMore}
                                      loading={historyOrdersLoading}
                                  />
                              )
                            : null
                    }
                />

                {historyOrdersLoading && !!orders.length && (
                    <div className="virtual-table__loader">
                        <Spin />
                    </div>
                )}
                <OrdersDrawer
                    order={order}
                    getActionText={order ? () => getOrderStatus(order.status) : undefined}
                    onClose={onClose}
                    visible={!!order && !closing}
                    className={classNames(
                        "live-orders-modal",
                        actioning && "actioning",
                        !actioning && actionNextOrder && "next"
                    )}
                    onRefundOrder={(order: Order, amount: number) =>
                        locationId &&
                        dispatch(
                            refundOrder(locationId, order, amount, false, () => {
                                dispatch(fetchHistoryOrder(locationId, order.partyId, order.orderId));
                            })
                        )
                    }
                    saveSuccess={saveSuccess}
                    loading={actioning}
                    groupTabModalClosing={groupTabModalclosing}
                    oncloseGroupTab={(groupTabId: string) => {
                        setActioningGroupTab(true);
                        oncloseGroupTab(groupTabId);
                    }}
                    onCloseGroupTabModal={onCloseGroupTabModal}
                    actioningGroupTab={actioningGroupTab}
                    isGroupTabOrder={isGroupTabOrder}
                />
            </div>
        </>
    );
};
