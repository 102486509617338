import { AppDispatch, AppMiddleware } from "../../index";
import { StaffLoginAction, TypeKeys } from "features/staffLogin/reducers/staffLogin";
import { initSections } from "../../floorManager/actions";
import { polling } from "common/utility/polling";

export const locationMiddleware = () => (store: AppMiddleware) => (next: AppDispatch) => {
    return (action: StaffLoginAction) => {
        if (action.type === TypeKeys.SUCCESS || action.type === TypeKeys.RESTORE) {
            const { locationId } = action;
            polling.reset();
            store.dispatch(initSections(locationId));
            polling.start();
        }

        if (action.type === TypeKeys.FAILED || action.type === TypeKeys.RESET) {
            polling.reset();
        }

        next(action);
    };
};
