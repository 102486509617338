export const SECOND = 1000; // millisecond
export const MINUTE = 60 * SECOND;
export const AN_HOUR = 60 * MINUTE;
export const HALF_AN_HOUR = 30 * MINUTE;

export function getTimeDifferenceMinutes(from: number | null | undefined) {
    return from ? Math.max(Math.ceil((from - Date.now()) / MINUTE), 0) : 0;
}

export const formatTime = (millisecondsElapsed: number) => {
    const ms = millisecondsElapsed % SECOND;
    const secondsElapsed = (millisecondsElapsed - ms) / SECOND;
    const seconds = secondsElapsed % 60;
    const minutes = (secondsElapsed - seconds) / 60;
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
};

export const parseDate = (dateStr?: string) => {
    if (!dateStr) return undefined;
    try {
        const date = Date.parse(dateStr);
        return !date || isNaN(date) ? undefined : date;
    } catch (err) {
        return undefined;
    }
};
