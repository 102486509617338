import { opsApi } from "common/api";
import { Availability, DisableReason } from "../types";
import { fetchAsStaff } from "features/staffLogin/selectors/getAccessToken";
import { GraphQLSection } from "./updateSection";
import { sectionQuery } from "./fetchSections";

export async function updateAllSections(
    locationId: string,
    availability: Availability,
    disableReason?: DisableReason,
    offlineDurationMinutes?: number | null,
    disableSubReason?: string
): Promise<GraphQLSection[]> {
    const res = (
        await opsApi.graphQLQuery<{ updateSectionsAvailability: GraphQLSection[] }>(
            updateQuery,
            {
                locationId,
                availability,
                disableReason,
                offlineDurationMinutes,
                disableSubReason,
            },
            fetchAsStaff(true)
        )
    ).data.updateSectionsAvailability;

    return res;
}

const updateQuery: string = `mutation ($locationId: ID!, $availability: SectionAvailability!, $disableReason: SectionDisableReason, $offlineDurationMinutes: Int, $disableSubReason: String) {
    updateSectionsAvailability(locationId: $locationId, availability: $availability, disableReason: $disableReason, offlineDurationMinutes: $offlineDurationMinutes, disableSubReason: $disableSubReason) {
        ${sectionQuery}
    }
}`;
