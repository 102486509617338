import { Drawer } from "antd";
import classNames from "classnames";
import { LoadingOverlayWithSpin } from "common/loader/LoadingSpinner";
import React, { FC } from "react";
import { useCallback } from "react";
import { GroupTabsData, Order } from "../types";
import { GroupTabCard } from "./GroupTabCard";
import { getOrderStatus } from "./HistoryOrders";
import { OrdersDrawer } from "./OrderDetails/OrdersDrawer";
import "../assets/OrdersDrawer.scss";

export interface Props {
    groupTab?: GroupTabsData;
    onClose: (cancel: boolean) => void;
    visible: boolean;
    className: string;
    onCloseGroupTab: (groupTabId: string) => void;
    closeSuccess: boolean | undefined;
    actioning: boolean;
    onShowGroupTabOrder?: (groupTabId: string, partyId: string) => void;
    order?: Order | null;
    onCloseGroupTabOrderModal?: (cancel: boolean) => void;
    groupTabOrderModalClosing?: boolean;
    actioningTabOrder?: boolean;
    onRefundTabOrder?: (order: Order, amount: number) => void;
    loadingTabOrder?: boolean;
    viaOrderCard?: boolean;
    failedToLoadTabOrder?: boolean;
}

export const GroupTabsDrawer: FC<Props> = ({
    groupTab,
    onClose,
    visible,
    className,
    onCloseGroupTab,
    closeSuccess,
    actioning,
    onShowGroupTabOrder,
    order,
    onCloseGroupTabOrderModal,
    groupTabOrderModalClosing,
    actioningTabOrder,
    onRefundTabOrder,
    loadingTabOrder,
    viaOrderCard,
    failedToLoadTabOrder,
}) => {
    const internalOnClose = useCallback(
        (cancel: boolean) => {
            onClose(cancel);
        },
        [onClose]
    );

    return (
        <>
            <Drawer
                open={visible}
                className={classNames(className)}
                contentWrapperStyle={{
                    width: 420,
                }}
                placement="right"
                onClose={() => internalOnClose(true)}
                closable={false}
            >
                <GroupTabCard
                    groupTab={groupTab}
                    onClose={internalOnClose}
                    onCloseGroupTab={onCloseGroupTab}
                    closeSuccess={visible ? closeSuccess : undefined}
                    actioning={actioning}
                    onShowGroupTabOrder={onShowGroupTabOrder}
                    viaOrderCard={viaOrderCard}
                    failedToLoadTabOrder={failedToLoadTabOrder}
                />

                {order ? (
                    //When viewing an order through tab screen, user can only refund an order, the order can only be resolved from the alerts page.
                    <OrdersDrawer
                        order={order}
                        onAction={undefined}
                        getActionText={(order) => getOrderStatus(order.status)}
                        onClose={(cancel: boolean) => {
                            onCloseGroupTabOrderModal && onCloseGroupTabOrderModal(cancel);
                        }}
                        visible={!!order && !groupTabOrderModalClosing}
                        className={classNames("live-orders-modal", !actioningTabOrder && "actioning")}
                        onRefundOrder={(order: Order, amount: number) =>
                            onRefundTabOrder && onRefundTabOrder(order, amount)
                        }
                        loading={actioningTabOrder}
                        isGroupTabOrder={true}
                        viaTabsCard={true}
                    />
                ) : (
                    loadingTabOrder && <LoadingOverlayWithSpin visible={true} />
                )}
            </Drawer>
        </>
    );
};
