import React, { useCallback, useState } from "react";
import { AppDispatch } from "../../index";
import { Availability, ModifierDetails, SoldOutTrackingData } from "../types";
import { Table, Tag } from "antd";
import { ColumnProps } from "antd/lib/table";
import { availabilityOptions, productColumns } from "./ProductsTab";
import { getStatusDisplay } from "../utils";
import { removeAccent } from "common/utility";
import { AvailabilityPicker } from "components/availabilityPicker/AvailabilityPicker";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { getExpandedModifierKeys, getFilteredModifiers, getSearch, isLoadingModifiers } from "../selectors";
import { getLocationId } from "features/staffLogin/selectors/getLocationId";
import { updateModifier } from "../actions";
import { useTrackEvent } from "common/AppInsights/AppInsights";
import { useEffect } from "react";

const searchResult = (searchVal: string, name: string) => {
    const index = removeAccent(name.toLocaleLowerCase()).indexOf(removeAccent(searchVal.toLocaleLowerCase()));
    if (index === -1) return { start: name, bold: "", end: "" };
    const endIndex = index + searchVal.length;
    return {
        start: name.substring(0, index),
        bold: name.substring(index, endIndex),
        end: endIndex > name.length ? "" : name.substring(endIndex),
    };
};

const columns = (
    search: string,
    locationId: string,
    update: (locationId: string, item: any, status: Availability) => void
): ColumnProps<ModifierDetails>[] => [
    {
        title: "Display name",
        dataIndex: "displayName",
        key: "displayName",
        className: "soldout-items__content__table__row__display-name",
        render: (displayName: string, item: ModifierDetails) => {
            const { start, bold, end } = searchResult(search || "", displayName);
            const content = (
                <span>
                    {start}
                    <b>{bold}</b>
                    {end}
                </span>
            );
            return (
                <div className="soldout-items__content__table__item__name">
                    {content}
                    <div className="soldout-items__content__table__item__used-in">{item.usedIn}</div>
                </div>
            );
        },
    },
    {
        title: "Price",
        dataIndex: "price",
        key: "price",
        width: "0%",
    },
    {
        title: "Category",
        dataIndex: "categories",
        key: "categories",
        width: "5%",
        render: (categories: string[] | string) => (
            <span>
                {!!categories &&
                    (Array.isArray(categories) ? categories : categories.split(",")).map((category) => {
                        return <Tag key={category}>{category}</Tag>;
                    })}
            </span>
        ),
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        align: "right",
        width: 190,
        className: "soldout-items__content__table__row__status",
        render: (status: Availability, item: ModifierDetails) => (
            <AvailabilityPicker
                id={item.sku}
                onAvailable={() => update(locationId!, item, Availability.AVAILABLE)}
                onDisable={(sku: string, status: Availability) => update(locationId!, item, status)}
                availability={null}
                status={item.status}
                availabilityText={getStatusDisplay(item.status || Availability.AVAILABLE)}
                availabilityOptions={availabilityOptions}
            />
        ),
    },
];

export const ModifiersTab = () => {
    const search = useSelector(getSearch);
    const modifiers = useSelector(getFilteredModifiers);
    const locationId = useSelector(getLocationId);
    const loadingModifiers = useSelector(isLoadingModifiers);
    const searchKeys = useSelector(getExpandedModifierKeys);
    const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

    const trackEvent = useTrackEvent("SOLD_OUT/MODIFER", {} as SoldOutTrackingData);
    const dispatch = useDispatch<AppDispatch>();
    const updateModiferAvailability = useCallback(
        (locationId: string, item: any, status: Availability) => {
            dispatch(updateModifier(locationId, item, status));
            trackEvent({
                sku: item.sku,
                displayName: item.displayName,
                status,
                children: item.products,
            });
        },
        [dispatch, trackEvent]
    );

    const updateSelectedKeys = (expanded: boolean, record: ModifierDetails) => {
        setExpandedKeys(expanded ? [...expandedKeys, record.key!] : expandedKeys.filter((x) => x !== record.key));
    };

    useEffect(() => {
        setExpandedKeys(searchKeys);
    }, [searchKeys]);

    if (!locationId) return null;

    return (
        <Table
            className="soldout-items__content__table"
            loading={loadingModifiers}
            expandRowByClick
            columns={columns(search!, locationId!, updateModiferAvailability)}
            dataSource={modifiers}
            rowKey="key"
            showHeader={false}
            tableLayout="auto"
            expandedRowKeys={expandedKeys}
            onExpand={updateSelectedKeys}
            expandedRowRender={(item: ModifierDetails, index: number) => {
                if (!item.products || !item.products.length) return null;
                return (
                    <Table
                        key="key"
                        className={classNames("soldout-items__content__table", "modifiers-child")}
                        showHeader={false}
                        rowKey={(product, productIndex) =>
                            `modifiers_product_${productIndex}_${item.sku}_${product.sku}`
                        }
                        columns={productColumns(search!)}
                        dataSource={item.products}
                        pagination={false}
                        tableLayout="auto"
                        rowClassName={(item) => "soldout-items__content__table__row"}
                    />
                );
            }}
            pagination={{
                hideOnSinglePage: true,
                pageSize: 15,
                onChange: () => {
                    const scrollElement = document.querySelector(".soldout-items__content .ant-tabs-tabpane-active");
                    if (scrollElement) scrollElement.scrollTo({ top: 0 });
                },
                showSizeChanger: false,
            }}
            rowClassName={(item) => "soldout-items__content__table__row"}
        />
    );
};
