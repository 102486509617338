import "./FloorManagerFooter.scss";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { HiddenIcon } from "common/icons";
import { Text } from "components/text";
import { updateAllSectionsAvailability } from "../actions/updateSectionAvailability";
import { AppDispatch } from "features";
import { getLocationPermissions } from "features/location/selectors/getPermissions";

export const FloorManagerFooter = () => {
    const dispatch = useDispatch<AppDispatch>();
    const hasSectionEditPermissions = useSelector(getLocationPermissions).has("section:update");

    if (!hasSectionEditPermissions) return null;

    return (
        <div className="floor-manager-footer">
            <button
                type="button"
                className="floor-manager-footer__button"
                onClick={() => {
                    dispatch(updateAllSectionsAvailability());
                }}
            >
                <HiddenIcon />
                <Text preset="g-16" mode="bold">
                    Set all availability
                </Text>
            </button>
        </div>
    );
};
