import { ChatIcon } from "common/icons";
import { useEffect } from "react";
import styles from "./ZendeskChatPlugin.module.scss";
import React from "react";
import { config } from "common/config";

declare global {
    interface Window {
        zE: any;
    }
}

export const ZendeskChatPlugin = () => {
    useEffect(() => {
        if (!document.getElementById("ze-snippet")) {
            const embedScript = document.createElement("script");
            embedScript.src = `https://static.zdassets.com/ekr/snippet.js?key=${config.VITE_ZENDESK_KEY}`;
            embedScript.id = "ze-snippet";
            document.head.appendChild(embedScript);
        }
    }, []);

    return (
        <div className="zendesk-chat-plugin">
            <button
                className={styles.launcher}
                onClick={() => {
                    window.zE("messenger", "open");
                }}
            >
                <span className={styles.icon}>
                    <ChatIcon />
                </span>
                <span>Need help?</span>
            </button>
        </div>
    );
};
