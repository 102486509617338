import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import "./StatusBadge.scss";

export type StatusBadgeMode = "info" | "success" | "warning" | "error";

interface Props {
    mode: StatusBadgeMode;
    className?: string;
}

export const StatusBadge = ({ mode, className, children }: PropsWithChildren<Props>) => {
    return <span className={classNames("statusBadge", mode, className)}>{children}</span>;
};
