import { AppDispatch } from "features";
import { getSectionsState } from "features/floorManager/selectors/getSections";
import { AppState } from "features/state";
import { fetchOrder } from "../api/fetchOrder";
import { actionCreators } from "../reducers";

export const fetchHistoryOrder = (locationId: string, partyId: string, orderId: string) => {
    return async function (dispatch: AppDispatch, getState: () => AppState) {
        const state = getState();
        const { status } = getSectionsState(state);
        if (status === "unloaded" || status === "loading") return;

        const order = await fetchOrder(locationId, partyId, orderId);
        if (order) {
            dispatch(actionCreators.historyOrderUpdated(order));
        }
    };
};
