import auth0, { Auth0DecodedHash } from "auth0-js";
import { AUTH_CONFIG } from "./auth0-variables";
import { History } from "history";
import { SECOND } from "common/utility";

const STORAGE_KEY = "manage-auth";

class Auth {
    auth0 = new auth0.WebAuth({
        domain: AUTH_CONFIG.domain,
        clientID: AUTH_CONFIG.clientId,
        redirectUri: AUTH_CONFIG.callbackUrl,
        audience: `https://${AUTH_CONFIG.domain}/userinfo`, //TODO need to change this
        responseType: "token id_token",
        scope: "openid profile email",
    });

    login = (postLoginUrl: string) => {
        this.auth0.authorize({
            redirectUri: `${AUTH_CONFIG.callbackUrl}?after=${encodeURI(postLoginUrl)}`,
        });
    };

    handleAuthentication = (history: History, targetUrl: string) => {
        this.auth0.parseHash((err, authResult) => {
            if (authResult && authResult.accessToken && authResult.idToken) {
                this.setSession(authResult);

                const params = new URLSearchParams(history.location.search);

                history.replace(params.get("after") || "/");
                // history.push(targetUrl)
            } else if (err) {
                console.log(err.errorDescription);
                this.logout();
            }
        });
    };

    setSession({ idToken, expiresIn, idTokenPayload }: Auth0DecodedHash) {
        if (idToken === undefined || expiresIn === undefined) {
            return;
        }

        const model: AuthState = {
            idToken,
            defaultLocation: idTokenPayload["https://meandu.com/access"].location,
            name: idTokenPayload.name,
            email: idTokenPayload.email,
            expiresAt: Date.now() + expiresIn * SECOND,
        };
        localStorage.setItem(STORAGE_KEY, JSON.stringify(model));
    }

    logout = () => {
        // TODO
        // Clear access token and ID token from local storage
        localStorage.removeItem(STORAGE_KEY);

        this.auth0.logout({
            clientID: AUTH_CONFIG.clientId,
            returnTo: window.location.origin,
        });
    };

    isAuthenticated = () => {
        const authState = this.getState();

        if (authState === null) {
            return false;
        }

        const { expiresAt } = authState;
        return Date.now() < expiresAt;
    };

    getState() {
        const authValue = localStorage.getItem("manage-auth");
        if (authValue === null) {
            return null;
        }
        try {
            return JSON.parse(authValue) as AuthState;
        } catch (e) {
            return null;
        }
    }

    getAccessToken() {
        const authState = this.getState();

        if (authState === null) {
            return null;
        }

        return authState.idToken;
    }

    getDefaultLocation() {
        const authState = this.getState();

        if (authState === null) {
            return null;
        }

        return authState.defaultLocation;
    }
}

interface AuthState {
    idToken: string;
    expiresAt: number;
    defaultLocation: string | undefined;
    name: string;
    email: string;
}

export const auth = new Auth();
