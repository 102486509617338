import "./virtualTable/VirtualiseTable.scss";
import { Spin } from "antd";
import classNames from "classnames";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { GroupTabsData, Order } from "../types";
import "../assets/GroupTabsTable.scss";
import { GroupTabsDrawer } from "./GroupTabsDrawer";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, AppState } from "features";
import { useEffect } from "react";
import { actionCreators } from "../reducers";
import { refundOrder } from "../actions";
import { fetchTabById } from "../actions/fetchTabById";

import { ItemProps, TableVirtuoso } from "react-virtuoso";
import { GroupTabsHeader } from "./virtualGroupTabsTable/GroupTabsHeader";
import { GroupTabsRowContent } from "./virtualGroupTabsTable/GroupTabsRowContent";
import { VirtualTableFooter } from "./virtualTable/VirtualTableFooter";
import { VirtualTableComponents } from "./virtualTable/VirtualTableComponents";
import { FetchOptions } from "./HistoryOrders";
import { getGroupTabPageOrders, getGroupTabsLoading, getGroupTabsTotal } from "../selectors";
import { closeGroupTab } from "../actions/closeGroupTab";
import { fetchTabOrder } from "../actions/fetchTabOrder";
import { getLocationId } from "features/staffLogin/selectors/getLocationId";
import { useUnmount } from "react-use";

interface Props {
    fetch: (options?: FetchOptions) => void;
    scrollRef?: React.MutableRefObject<HTMLElement | Window | null | undefined>;
}

export const GroupTabsTable = ({ fetch, scrollRef }: Props) => {
    const dispatch = useDispatch<AppDispatch>();
    const [groupTab, setGroupTab] = useState<GroupTabsData | undefined>();
    const [closing, setClosing] = useState(false);
    const modalTimerRef = useRef(0);
    const {
        orders: { closeGroupTabResponse, fetchTabOrderResponse, fetchTabByIdResponse },
    } = useSelector((state: AppState) => state);
    const [closeSuccess, setCloseSuccess] = useState<boolean | undefined>();
    const [actioning, setActioning] = useState(false);
    const [tabOrderId, setTabOrderId] = useState<string | null>(null);
    const [tabOrder, setTabOrder] = useState<Order | null | undefined>(null);
    const [tabOrderModalClosing, setTabOrderModalClosing] = useState(false);
    const [actioningTabOrder, setActioningTabOrder] = useState(false);
    const [loadingTabOrder, setloadingTabOrder] = useState(false);
    const [failedToLoadTabOrder, setfailedToLoadTabOrder] = useState(false);
    const locationId = useSelector(getLocationId);
    const groupTabs = useSelector(getGroupTabPageOrders);
    const loadingGroupTabs = useSelector(getGroupTabsLoading);
    const totalGroupTabs = useSelector(getGroupTabsTotal);

    useEffect(() => {
        if (fetchTabByIdResponse.status === "loaded" && fetchTabByIdResponse.data) {
            if (groupTab) {
                const updatedGroupTab = groupTabs && groupTabs.find((gt) => gt.id === groupTab.id);
                if (updatedGroupTab) {
                    setGroupTab(updatedGroupTab);
                }
            }
        }
    }, [fetchTabByIdResponse, groupTab, groupTabs]);

    useEffect(() => {
        if (fetchTabOrderResponse.status === "loaded" && fetchTabOrderResponse.data) {
            if (tabOrderId && groupTab) {
                const updateGroupTab = groupTabs && groupTabs.find((gt) => gt.id === groupTab.id);
                if (updateGroupTab) {
                    setGroupTab(updateGroupTab);
                    const selectedOrder = groupTab.orders.find((o) => o.partyId === tabOrderId);
                    if (selectedOrder && selectedOrder.orderDetails) {
                        setTabOrder(selectedOrder.orderDetails);
                        setTabOrderId(null);
                        setloadingTabOrder(false);
                    }
                }
            }
        } else if (fetchTabOrderResponse.status === "failed") {
            setfailedToLoadTabOrder(true);
            setTabOrderId(null);
            setloadingTabOrder(false);
            clearTimeout(modalTimerRef.current);
            modalTimerRef.current = window.setTimeout(() => {
                setfailedToLoadTabOrder(false);
            }, 1000);
        }

        return () => {
            clearTimeout(modalTimerRef.current);
        };
    }, [fetchTabOrderResponse, tabOrderId, groupTab, groupTabs]);

    const onTabOrderBackButtonClick = useCallback(() => {
        setTabOrderModalClosing(true);
        setActioningTabOrder(false);
        clearTimeout(modalTimerRef.current);
        modalTimerRef.current = window.setTimeout(() => {
            setTabOrder(null);
            setTabOrderModalClosing(false);
        }, 200);
    }, [setTabOrderModalClosing]);

    const onRefundTabOrder = useCallback(
        (order: Order, amount: number) => {
            locationId &&
                dispatch(
                    refundOrder(
                        locationId,
                        order,
                        amount,
                        undefined,
                        order.groupTab ? () => dispatch(fetchTabById(order.groupTab!.id, locationId)) : undefined
                    )
                );
        },
        [locationId, dispatch]
    );

    useUnmount(() => {
        dispatch(actionCreators.closeGroupTabReset());
    });

    useEffect(() => {
        if (closeGroupTabResponse.status === "unloaded") {
            setCloseSuccess(undefined);
        } else if (closeGroupTabResponse.status === "loaded") {
            setCloseSuccess(true);
            setActioning(false);
            clearTimeout(modalTimerRef.current);
            modalTimerRef.current = window.setTimeout(() => {
                setGroupTab(undefined);
                setClosing(false);
                setCloseSuccess(undefined);
                dispatch(actionCreators.closeGroupTabReset());
            }, 1000);
        } else if (closeGroupTabResponse.status === "failed") {
            setCloseSuccess(false);
            setActioning(false);
            clearTimeout(modalTimerRef.current);
            modalTimerRef.current = window.setTimeout(() => {
                setCloseSuccess(undefined);
            }, 1000);
        }
        return () => {
            clearTimeout(modalTimerRef.current);
        };
    }, [closeGroupTabResponse, dispatch, groupTab, groupTabs]);

    const onClose = useCallback(() => {
        setClosing(true);
        setActioning(false);
        clearTimeout(modalTimerRef.current);
        modalTimerRef.current = window.setTimeout(() => {
            setGroupTab(undefined);
            setClosing(false);
        }, 200);
    }, [setGroupTab, setClosing]);

    const components = useMemo(
        () => ({
            ...VirtualTableComponents({ loading: loadingGroupTabs, itemType: "Group Tabs" }),
            TableRow: (props: ItemProps<GroupTabsData>) => {
                return (
                    <tr
                        className={classNames(
                            "ant-table-row",
                            `groupTabs-table_row_status-${props.item.status}`,
                            "actionable-row"
                        )}
                        data-row-key={`group-tabs_${props.item.id}`}
                        onClick={() => setGroupTab(props.item)}
                        {...props}
                    />
                );
            },
        }),
        [loadingGroupTabs]
    );

    const loadMore = useCallback(() => {
        dispatch(actionCreators.groupTabsOrdersNextPage());
        fetch({ loadMore: true });
    }, [fetch, dispatch]);

    const onCloseGroupTab = useCallback(
        (groupTabId: string) => {
            locationId && dispatch(closeGroupTab(groupTabId, locationId, false));
        },
        [locationId, dispatch]
    );

    const onFetchTabOrder = useCallback(
        (groupTabId: string, partyId: string) => {
            locationId && dispatch(fetchTabOrder(groupTabId, partyId, locationId));
        },
        [locationId, dispatch]
    );

    return (
        <>
            <div className="virtual-table__container">
                <TableVirtuoso
                    data={groupTabs}
                    scrollerRef={scrollRef ? (r) => (scrollRef.current = r) : undefined}
                    initialTopMostItemIndex={0}
                    style={{ height: "100%" }}
                    totalCount={totalGroupTabs}
                    className={classNames("groupTabs-table", "ant-table", "virtual-table")}
                    components={components}
                    fixedHeaderContent={GroupTabsHeader}
                    itemContent={(_, groupTab) => <GroupTabsRowContent groupTab={groupTab} />}
                    fixedFooterContent={
                        groupTabs?.length
                            ? () => (
                                  <VirtualTableFooter
                                      visible={groupTabs.length < totalGroupTabs}
                                      onClick={loadMore}
                                      loading={loadingGroupTabs}
                                  />
                              )
                            : null
                    }
                />

                {loadingGroupTabs && !!groupTabs?.length && (
                    <div className="virtual-table__loader">
                        <Spin />
                    </div>
                )}
            </div>
            <GroupTabsDrawer
                groupTab={groupTab}
                order={tabOrder}
                onClose={onClose}
                visible={!!groupTab && !closing}
                className="live-orders-modal"
                onCloseGroupTab={(groupTabId: string) => {
                    setActioning(true);
                    onCloseGroupTab(groupTabId);
                }}
                onShowGroupTabOrder={(groupTabId: string, partyId: string) => {
                    setTabOrderId(partyId);
                    setloadingTabOrder(true);

                    const selectedOrder = groupTab?.orders.find((o) => o.partyId === partyId)?.orderDetails;

                    if (selectedOrder) {
                        setTabOrder(selectedOrder);
                        setloadingTabOrder(false);
                    } else {
                        onFetchTabOrder(groupTabId, partyId);
                    }
                }}
                closeSuccess={closeSuccess}
                actioning={actioning}
                groupTabOrderModalClosing={tabOrderModalClosing}
                onCloseGroupTabOrderModal={onTabOrderBackButtonClick}
                actioningTabOrder={actioningTabOrder}
                onRefundTabOrder={onRefundTabOrder}
                loadingTabOrder={loadingTabOrder}
                failedToLoadTabOrder={failedToLoadTabOrder}
            />
        </>
    );
};
