import "./SectionSelector.scss";

import { Dropdown } from "antd";
import React, { FC } from "react";
import { DownArrow } from "../../../../common/icons";
import { useSelector } from "react-redux";
import { getSections } from "../../selectors/getSections";
import { Section } from "../../types";
import { Text } from "../../../../components/text";
import classNames from "classnames";
import { ALL_SECTION_ID } from "features/floorManager/selectors/getFloorItems";

export interface Props {
    selectedSection?: Section;
    onSelectSection: (section: Section | undefined) => void;
    overlayClassName?: string;
}

export const SectionSelector: FC<Props> = ({ selectedSection, onSelectSection, overlayClassName }) => {
    const sections = useSelector(getSections);

    if (!sections.length) return null;

    const sectionItems = sections.map((section) => ({
        onClick: () => onSelectSection(section),
        key: section.id,
        label: section.displayName,
    }));
    sectionItems.push({
        onClick: () => onSelectSection(undefined),
        key: ALL_SECTION_ID,
        label: "All sections",
    });

    return (
        <Dropdown
            menu={{
                items: sectionItems,
            }}
            trigger={["click"]}
            overlayClassName={classNames("section-selector__overlay", overlayClassName)}
        >
            <div className="section-selector" onClick={(e) => e.preventDefault()}>
                <Text preset="g-14" mode="bold">
                    {selectedSection ? `Section: ${selectedSection.displayName}` : "All sections"}
                </Text>
                <DownArrow />
            </div>
        </Dropdown>
    );
};
