import * as React from "react";

export interface CallbackProps {
    handleAuthentication: () => {};
}

export class CallbackPage extends React.Component<CallbackProps> {
    constructor(props: any) {
        super(props);
        this.props.handleAuthentication();
    }

    render() {
        return <div>call back page</div>;
    }
}
