import { connect } from "react-redux";
import { AppState } from "features/index";
import { DataProps } from "types/utils";
import { getOrderHubConnected } from "../../shared/selectors";
import { ActivePartiesContent, Props } from "../components/ActivePartiesContent";
import { getFilteredAndSortedActiveParties } from "../selectors";
import { getSections } from "features/floorManager/selectors/getSections";

const mapStateToProps = (state: AppState): DataProps<Props> => {
    const parties = getFilteredAndSortedActiveParties(state);
    const connected = getOrderHubConnected(state);
    const { mode } = state.bumpScreen;
    const sections = getSections(state);

    return {
        parties,
        connected,
        sections,
        mode,
    };
};

export const ActivePartiesContainer = connect(mapStateToProps)(ActivePartiesContent);
