import React from "react";
import "./NoOrders.scss";
import { useSelector } from "react-redux";
import { AppState } from "../../../state";
import { BumpScreenType } from "../../shared/types";

export const NoOrders = () => {
    const selectedSection = useSelector((state: AppState) => state.bumpScreen.selectedSection);

    const bumpScreenMode = useSelector((state: AppState) => state.bumpScreen.mode);

    const getMode = () => {
        switch (bumpScreenMode) {
            case BumpScreenType.PREPARING:
                return "preparing";
            case BumpScreenType.READY:
                return "ready";
            default:
                return "open";
        }
    };

    return (
        <div className="no-orders">
            No {getMode()} orders{selectedSection ? ` for ${selectedSection.displayName}` : "."}
        </div>
    );
};
