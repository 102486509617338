import { opsApi } from "common/api";
import { fetchAsStaff } from "../../staffLogin/selectors/getAccessToken";
import { getPartyFields } from "./commonGraphFields";
import { Party } from "../types";
import { mapActivePartyToParty } from "./commonGraphFunctions";
import { ActiveParty } from "common/types";

const forceChargeRequest = `mutation($partyId: ID!, $locationId: ID!) {
    forceChargeParty(partyId: $partyId, locationId: $locationId){
        ${getPartyFields}
    }
}`;

type GraphQLPayload = { forceChargeParty: ActiveParty };

export async function forceChargePartyApi(partyId: string, locationId: string): Promise<Party> {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(
        forceChargeRequest,
        { partyId, locationId },
        fetchAsStaff(true)
    );
    return mapActivePartyToParty(res.data.forceChargeParty);
}
