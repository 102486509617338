import { fetchAsStaff } from "../../staffLogin/selectors/getAccessToken";
import { opsApi } from "../../../common/api";
import { trackException } from "common/AppInsights/AppInsights";

export async function sendRefundRequest(refund: RefundRequest) {
    const request = new Request(`${opsApi.baseUrl}/payment/refund`, {
        method: "Post",
        body: JSON.stringify(refund),
    });

    const response: Promise<RefundResponse> = await fetchAsStaff(true)(request)
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch((err) => {
            console.log(err);
            trackException(err);
            return {
                isSuccessful: false,
                error: err,
            };
        });

    return response;
}

export interface RefundRequest {
    locationId: string;
    partyId: string;
    isActiveParty: boolean;
    paymentId: string;
    amount: number;
}

export type RefundStatus = "Complete" | "Pending" | "Failed";
export interface RefundResponse {
    amountRefunded?: number;
    error?: string;
    status: RefundStatus;
}
