import { connect } from "react-redux";
import { AppState } from "features";
import { DataProps } from "types/utils";
import { Props, HelpPage } from "features/help/components/HelpPage";

const mapStateToProps = (state: AppState): DataProps<Props> => {
    const { location } = state;
    return {
        locationName: location.status === "loaded" ? location.data.displayName : "",
    };
};

export const HelpPageContainer = connect(mapStateToProps)(HelpPage);
