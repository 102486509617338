import { AppDispatch } from "features";
import { AppState } from "features/state";
import { actionCreators, DeviceEnrolmentResult } from "../reducers/enrolment";
import { saveEnrolmentInfo } from "../api/persistence";
import { opsApi } from "common/api";
import { SECOND } from "common/utility";

export function extendEnrolment() {
    return async function (dispatch: AppDispatch, getState: () => AppState) {
        const { enrolment } = getState();

        if (enrolment.status !== "enroled") {
            return;
        }

        const result = await extendEnrolledDevice(enrolment.access_token);

        saveEnrolmentInfo(result);
        dispatch(actionCreators.success(result));
    };
}

async function extendEnrolledDevice(accessToken: string): Promise<DeviceEnrolmentResult> {
    const response = await opsApi.fetch(`/staff/auth/device/extend`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });

    if (!response.ok) {
        throw response;
    }

    const { id, access_token, location_id, expires_in, can_bypass_staff_login } = await response.json();

    return {
        id,
        access_token,
        location_id,
        expires_at: new Date().getTime() + expires_in * SECOND,
        can_bypass_staff_login,
    };
}
