import React, { CSSProperties } from "react";
import classNames from "classnames";
import "./Text.scss";

export type TextModes = "bold" | "extra-bold" | "block" | "medium" | "emphasis";
export type TextPreset =
    | "g-8"
    | "g-10"
    | "g-12"
    | "g-14"
    | "g-16"
    | "g-18"
    | "title-20"
    | "title-24"
    | "title-28"
    | "title-32"
    | "title-36"
    | "title-48";

interface PropsBase {
    mode?: TextModes | TextModes[];
    preset?: TextPreset;
    className?: string;
    style?: CSSProperties;
    id?: string;
}

interface OptionalChild extends PropsBase {
    children: React.ReactNode;
}

interface OptionalValue extends PropsBase {
    value: string;
}

type Props = OptionalChild | OptionalValue;

export const Text = (props: Props) => {
    const child = "value" in props ? props.value : props.children;
    const { mode, preset = "content", className, id, style } = props;
    const modeClassNames = !mode ? undefined : ([] as TextModes[]).concat(mode).map((m) => `mode--${m}`);
    return (
        <span id={id} style={style} className={classNames("text", modeClassNames, preset, className)}>
            {child}
        </span>
    );
};
