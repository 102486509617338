import { AppDispatch } from "../../index";
import { fetchGroupTabsApi } from "../api/fetchLiveGroupTabs";
import { actionCreators } from "../reducers";
import { GroupTabSearchOptions } from "../types";

export const fetchGroupTabs = (
    locationId: string,
    options?: GroupTabSearchOptions,
    forceReload?: boolean,
    loadMore?: boolean
) => {
    return async (dispatch: AppDispatch) => {
        dispatch(actionCreators.groupTabOrdersLoading());
        try {
            const groupTabsResponse = await fetchGroupTabsApi(locationId, options);
            dispatch(
                actionCreators.groupTabOrdersLoaded(
                    groupTabsResponse.groupTabs,
                    groupTabsResponse.groupTabsTotal,
                    loadMore
                )
            );
        } catch (e) {
            dispatch(actionCreators.groupTabOrdersFailed(e));
        }
    };
};
