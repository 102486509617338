import { CategoryDetails, DisableCategories } from "../types";

export type State = DisableCategories;

export enum TypeKeys {
    CATEGORIES_LOADING = "DISABLE_CATEGORIES/LOADING",
    CATEGORIES_LOADED = "DISABLE_CATEGORIES/LOADED",
    CATEGORIES_FAILED = "DISABLE_CATEGORIES/FAILED",
}

export const createAction = {
    categoriesLoading: () => ({ type: TypeKeys.CATEGORIES_LOADING }),
    categoriesLoaded: (data: CategoryDetails[]) => ({ type: TypeKeys.CATEGORIES_LOADED, data }),
    categoriesFailed: (error: any) => ({ type: TypeKeys.CATEGORIES_FAILED, error }),
};

type DisableCategoriesLoadingAction = { type: TypeKeys.CATEGORIES_LOADING };
type DisableCategoriesLoadedAction = { type: TypeKeys.CATEGORIES_LOADED; data: CategoryDetails[] };
type DisableCategoriesFailedAction = { type: TypeKeys.CATEGORIES_FAILED; error: any };

export type DisableAction =
    | DisableCategoriesLoadingAction
    | DisableCategoriesLoadedAction
    | DisableCategoriesFailedAction;

const initialState: State = {
    categories: { status: "unloaded" },
};

export const reducer = (state: State = initialState, action: DisableAction): State => {
    if (action.type === TypeKeys.CATEGORIES_LOADING) {
        return {
            ...state,
            categories: { status: "loading" },
        };
    }

    if (action.type === TypeKeys.CATEGORIES_LOADED) {
        const { data } = action;
        return {
            ...state,
            categories: {
                status: "loaded",
                data,
            },
        };
    }

    if (action.type === TypeKeys.CATEGORIES_FAILED) {
        const { error } = action;
        return {
            ...state,
            categories: {
                status: "failed",
                error,
            },
        };
    }

    return state;
};
