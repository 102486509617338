import { useEffect } from "react";
import { usePrevious } from "react-use";
import { useInterval } from "./useInterval";

/**
 * Anchors an interval timer to clock segment and runs the provided callback fn.
 *
 * Examples:
 *
 *  anchorInternalMin = 1 -> will start an interval the next time the second hand ticks over to zero.
 *  anchorInternalMin = 5 -> will start an interval the next time the second hand ticks over to zero and the minute hand lands on [12, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]
 *  anchorInternalMin = 15 -> will start an interval the next time the second hand ticks over to zero and the minute hand lands on [12, 15, 30, 45]
 *
 */
export const useAnchoredInterval = (callback: (ticks: number) => void, anchorIntervalMin = 1) => {
    const delayMs = anchorIntervalMin * 60 * 1000;
    const prevCallbackInstance = usePrevious(callback);
    const [start, stop] = useInterval(prevCallbackInstance!, delayMs);

    useEffect(() => {
        const now = new Date();
        const nowMins = now.getMinutes();
        const nowSecs = now.getSeconds();
        const catchupMinsMs = (anchorIntervalMin - (nowMins % anchorIntervalMin)) * 60 * 1000;
        const intervalStartDelayMs = Math.abs(catchupMinsMs - nowSecs * 1000);
        const timeout = setTimeout(() => {
            prevCallbackInstance!(0);
            start();
        }, intervalStartDelayMs);
        return () => {
            stop();
            clearTimeout(timeout);
        };
    }, [anchorIntervalMin, start, stop, prevCallbackInstance]);
};
