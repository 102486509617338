import { AppDispatch } from "features";
import { AppState } from "features/state";
import { actionCreators } from "../reducers";
import { fetchLocation } from "../api/fetchLocation";

export function loadLocation(locationId: string, token: string) {
    return async function (dispatch: AppDispatch, getState: () => AppState) {
        dispatch(actionCreators.loading());

        try {
            const location = await fetchLocation(locationId, token);

            dispatch(actionCreators.loaded(location));
        } catch (e) {
            dispatch(actionCreators.failed(e));
        }
    };
}
