import "./DeliveryOptionModal.scss";

import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../..";
import { updateDeliveryOptionType } from "../actions/updateDeliveryOptionType";
import { actionCreator } from "../reducers/manageSection";
import { getSectionDeliveryOption } from "../selectors/getManangeSection";
import { DeliveryOptionType, EditDeliveryOptionType } from "../types";
import { ConfirmModal } from "components/confirmModal/ConfirmModal";
import { Indicator } from "components/indicator/Indicator";
import { Text } from "components/text";
import { getDeliveryOptionTypeText } from "./DeliveryOptionIndicator";

export const DeliveryOptionModal = () => {
    const editDeliveryOptionType = useSelector(getSectionDeliveryOption);
    const [newSectionDeliveryType, setNewSectionDeliveryType] = useState<DeliveryOptionType | undefined>();
    const [openModal, setOpenModal] = useState(!!editDeliveryOptionType);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (editDeliveryOptionType) {
            setOpenModal(true);
        }
    }, [editDeliveryOptionType]);

    const setDeliveryOption = useCallback(
        (editDeliveryOptionType?: EditDeliveryOptionType) => {
            dispatch(actionCreator.setSectionDeliveryOption(editDeliveryOptionType));
        },
        [dispatch]
    );

    const closeModal = useCallback(() => {
        setOpenModal(false);
        setNewSectionDeliveryType(undefined);
    }, []);

    useEffect(
        () => () => {
            setDeliveryOption();
        },
        [setDeliveryOption]
    );

    const onConfirm = () => {
        if (newSectionDeliveryType && editDeliveryOptionType) {
            const { sectionId, menuItemType } = editDeliveryOptionType;
            dispatch(updateDeliveryOptionType(sectionId, menuItemType, newSectionDeliveryType));
            closeModal();
        }
    };

    return (
        <ConfirmModal
            title={`Update behaviour for ${editDeliveryOptionType?.sectionName ?? "this section"}`}
            showModal={openModal}
            onCloseModal={closeModal}
            className="update-delivery-option-modal"
            onConfirm={newSectionDeliveryType ? onConfirm : undefined}
        >
            <>
                <Text preset="g-14" mode="bold" className="update-delivery-option-modal__select-behaviour">
                    Select behaviour
                </Text>
                <div className="update-delivery-option-modal__options-container">
                    <Indicator
                        key={DeliveryOptionType.TABLE}
                        onClick={() => setNewSectionDeliveryType(DeliveryOptionType.TABLE)}
                        primary={newSectionDeliveryType === DeliveryOptionType.TABLE}
                    >
                        {getDeliveryOptionTypeText(DeliveryOptionType.TABLE)}
                    </Indicator>
                    <Indicator
                        key={DeliveryOptionType.PICKUP}
                        onClick={() => setNewSectionDeliveryType(DeliveryOptionType.PICKUP)}
                        primary={newSectionDeliveryType === DeliveryOptionType.PICKUP}
                    >
                        {getDeliveryOptionTypeText(DeliveryOptionType.PICKUP)}
                    </Indicator>
                </div>
                {newSectionDeliveryType && (
                    <Text preset="g-14" mode="block" className="update-delivery-option-modal__option-description">
                        {getSectionBehaviourDescription(newSectionDeliveryType)}
                    </Text>
                )}
            </>
        </ConfirmModal>
    );
};

const getSectionBehaviourDescription = (deliverOptionType: DeliveryOptionType) =>
    deliverOptionType === DeliveryOptionType.PICKUP
        ? "Bump screens are required to confirm orders. Customers will be notified when their order is ready to collect from the counter."
        : "Items will be delivered to the customer’s table.";
