import { Order, OrderStatus, TablePage } from "features/orders/types";
import React from "react";
import { getTableColumns } from "../virtualOrderTable/OrdersHeader";
import { VirtualTableHeader } from "../virtualTable/VirtualTableHeader";

interface Props {
    page: TablePage;
}

export const AlertsHeader = ({ page }: Props) => {
    return <VirtualTableHeader<Order, OrderStatus> columns={getTableColumns(page)} />;
};
