import { removeAccent } from "common/utility";
import { createSelector } from "reselect";
import { AppState } from "../../state";

export const getDisableSection = ({ floorManager }: AppState) => floorManager.manageSection.disableSection;
export const getSectionDeliveryOption = ({ floorManager }: AppState) => floorManager.manageSection.deliveryOption;
export const getManageAvailability = ({ floorManager }: AppState) => floorManager.manageSection.manageAvailability;
export const getUpdatingIds = ({ floorManager }: AppState) => floorManager.manageSection.updatingIds;
export const getFloorItemSearchTerm = ({ floorManager }: AppState) =>
    floorManager.manageSection.floorItemSearchTerm?.toLowerCase();
export const getShouldFilterUnavailableItems = ({ floorManager }: AppState) =>
    floorManager.manageSection.filterUnavailable;
export const getSearchTermWithoutAccent = createSelector(getFloorItemSearchTerm, (searchTerm) =>
    searchTerm ? removeAccent(searchTerm) : undefined
);
export const getUpdateSectionBehaviour = ({ floorManager }: AppState) =>
    floorManager.manageSection.updateSectionBehaviour;
